<template>
  <div>
    <section class="section" v-if="!entityListLoading">
      <div class="container">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="columns is-vcentered">
              <div class="column is-1 has-text-primary">
                  <i class="fas fa-arrow-left fa-2x" v-if="currentSteps.value > 0" @click="previousStep"></i>
              </div>
              <div class="column is-10">
                <multiselect
                  class="has-text-primary has-text-weight-bold"
                  v-model="currentSteps"
                  :options="stepsAccess"
                  :searchable="false"
                  :allow-empty="false"
                  track-by="name"
                  label="name"
                >
                </multiselect>
              </div>
              <div class="column is-1 has-text-primary">
                <i class="fas fa-arrow-right fa-2x" v-if="currentSteps.value < validateStep" @click="nextStep"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="displayError && lastStepValid.errors[currentSteps.value].length > 0" class="notification is-danger">
          <span v-for="(error, index) in lastStepValid.errors[currentSteps.value]" :key="index">
            {{error}}
          </span>
        </div>

        <TransferStepsForm
          :value="value"
          :loading="loading"
          :currentSteps="currentSteps"
          :transporterList="transporters"
          :enterpriseList="enterpriseList"
          :entityList="entityList"
          :entityListLoading="entityListLoading"
          :errorsData="errorsData"
          @cancel="$emit('cancel')"
          @steps-finish="validationFunction"
          @change-step="changeStep"
          @error-loading="trowErrorLoading"
          @next-step-demo="nextStep"
          @next-step="nextStep"
          @previous-step="previousStep"
          @validate-transfer="$emit('validate-transfer')"
          @input="updateFromObject($event)">
        </TransferStepsForm>
      </div>
    </section>
    <section v-else class="section">
      <div class="container">
          <div class="loader-wrapper">
              <div class="loader is-loading"></div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import expeditionFormMixin from '@/mixins/expeditionFormMixin'
import { checkAddress, checkContacts, deepCopy } from '@/utils/helpers'
import { Expedition } from '@/models/expedition'
import TransferStepsForm from '@/apps/transfer/components/transferStepsForm'
import vModelMixin from '@/mixins/vModelMixin'

export default {
  name: "TransferForm",
  mixins: [expeditionFormMixin, vModelMixin],
  components: {
    TransferStepsForm
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    originalLoan: {
      type: Expedition,
      required: true,
    },
    errorsData: {
      type: Object
    },
    transporters: {
      type: Array
    }
  },
  data() {
    return {
      steps: [
        // Expedition
        { name: this.$t("transporter-title"), value: 0, $isDisabled: true},
        { name: this.$t("delivery-title"), value: 1, $isDisabled: true},
        // { name: this.$t("content-title"), value: 2, $isDisabled: true},

        // Reprise
        { name: this.$t("pick-up-and-return-delivery-date-title"), value: 2, $isDisabled: true},
        { name: this.$t("pick-up-and-return-delivery-title"), value: 3, $isDisabled: true},

        // Global resume
        { name: this.$t("resume-title"), value: 4, $isDisabled: true},
      ],
      storageTypes: {},
      komaksEntities: []
    }
  },

  beforeMount(){
    this.currentSteps = this.steps[0];
    this.getEnterpriseList()

    if(this.step !== undefined){
      for(let i = 0; i < this.step; i++){
        this.nextStep();
      }
    } else{
      this.value.storageType = "BATCH"
    }
  },

  watch: {
    entityList() {
      if (this.entityList.length == 1) {
        this.value.expedition.senderEntity = deepCopy(this.entityList[0]);
        this.value.expedition.sender = this.entityList[0].enterprise.id
      }
    },
    errorsData: {
      handler: function(val){
           if(val !== null){
             if(val.errorsExpedition!== null){
               let errorsExpedition = val.errorsExpedition
                 if(errorsExpedition.transporter !== undefined || errorsExpedition.transporterContacts !== undefined) {
                   this.changeStep(0)
                 } else if(errorsExpedition.recipient !== undefined || errorsExpedition.recipientEntity !== undefined || errorsExpedition.deliveryDate !== undefined){
                   this.changeStep(1)
                 }
             }else if(val.errorsPickUp!=null) {
               let errorsPickUp = val.errorsPickUp
               if(errorsPickUp.removalDate){
                 this.changeStep(2)
               }
               if(errorsPickUp.recipientEntity || errorsPickUp.deliveryDate){
                 this.changeStep(3)
               }
             }
           }
         },
      deep: true
    }
  },
  computed: {
    lastStepValid () {
      // Each time we valid a step, lastStepValid is increased by one
      let value = 0 // Value of the last step valid to validate
      const errors = {} // All errors
      for (let i = 0; i < this.steps.length; i++) {
        errors[i] = []
      }

      // STEP 1 transporter
      if(this.value.expedition.transporter === null ||
        this.value.transporter === "") {
        errors[value].push(this.$t('transporter-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.transporterContacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 2 recipient
      const receiverRecipientAddressErrors = checkAddress(
        this.value.expedition.recipientEntity.address
      )
      if(receiverRecipientAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.expedition.deliveryDate === null || this.value.expedition.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }

      if(new Date(this.value.expedition.deliveryDate).getTime() < new Date(this.originalLoan.deliveryDate).getTime()){
        errors[value].push(
        this.$t('transfer-delivery-date-must-be-after-orginal-expedition-delivery-date') +
        ` (${this.originalLoan.deliveryDate})`
        )
        return {value, errors}
      }
      value += 1

      if(this.value.reprise.removalDate === null || this.value.reprise.removalDate === "" || this.value.reprise.removalDate === undefined){
        errors[value].push(this.$t('pick-up-and-return-date-desired-label'))
        return {value, errors}
      }

      if(new Date(this.value.reprise.removalDate).getTime() < new Date(this.value.expedition.deliveryDate).getTime()){
        errors[value].push(
          this.$t('pick-up-removal-date-must-be-after-expedition-delivery-date') +
          ` (${this.value.expedition.deliveryDate})`
        )
        return {value, errors}
      }
      value += 1

      const receiverPickUpSenderAddressErrors = checkAddress(
        this.value.reprise.recipientEntity.address
      )
      if(receiverPickUpSenderAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.reprise.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.reprise.deliveryDate === null || this.value.reprise.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }
      if(new Date(this.value.reprise.deliveryDate).getTime() < new Date(this.value.reprise.removalDate)){
        errors[value].push(this.$t('delivery-date-must-be-after-removal-date'))
        return {value, errors}
      }

      // STEP 6 to 8 nothing to do
      value += 1
      return {value, errors}
    },
  },
}
</script>
