<template>
  <nav id="navigation" class="is-hidden-print">
    <div class="columns is-mobile is-marginless">
      <div class="column is-3">
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
              <span class="dropdown--icon icon is-small">
                <i class="fas fa-bars" aria-hidden="true"></i>
              </span>
              <span class="dropdown--text">{{$t("menu-title")}}</span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <router-link to="/" class="dropdown-item">
                {{$t("dashboard-title")}}
              </router-link>
              <router-link v-if="user && user.haveExpeditionAccess" to="/expedition-module/" class="dropdown-item">
                {{$t("expedition-title")}}
              </router-link>
              <router-link v-if="user && user.havePickUpAccess" to="/pick-up-and-return-module/" class="dropdown-item">
                {{$t("pick-up-and-return-title")}}
              </router-link>
              <router-link v-if="user && user.haveDemoAccess" to="/demo-module/" class="dropdown-item">
                {{$t("demo-title")}}
              </router-link>
              <router-link v-if="user && user.haveStocksLogisticsAccess" to="/stock-module/" class="dropdown-item">
                {{$t("stock-title")}}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="logo column">
        <router-link to="/">
          <img src="@/assets/logo/logo_fond_noir.png" width="112" height="28">
        </router-link>
      </div>

      <div class="column is-3 has-text-right">
        <div class="dropdown is-hoverable is-right">
          <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
              <span class="dropdown--text">{{username}}</span>
              <span class="dropdown--icon icon is-small">
                <i class="fas fa-user" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu4" role="menu">
            <div class="dropdown-content">
              <!-- <a class="dropdown-item">
                {{$t("messages-title")}}
              </a>
              <a class="dropdown-item">
                {{$t("settings-title")}}
              </a> -->
              <a class="dropdown-item" @click="$emit('logout')">
                {{$t("logout-button")}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    user() {
      return this.$store.state.user
    },
    username(){
      if(this.user !== null) {
        return this.user.username
      }
      return "";
    }
  }
}
</script>
