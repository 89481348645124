<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card" :class="extraCss">
      <header class="modal-card-head" :class="titleCss">
        <slot name="header"></slot>
      </header>
      <section class="modal-card-body">
        <slot name="body"></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    modalData: {
      type: Object,
    },
    extraCss: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    titleCss () {
      if(this.modalData) {
          return this.modalData.title.css
      }
      return ''
    },
  }
}
</script>
<style lang="scss" scoped>
@charset "utf-8";
@import "~bulma/bulma";

.modal-card.big {
  min-width: 90%;
  height: 100%;
}

.modal-card-head.is-danger {
  background-color: $danger;
  color: $white;
}
.modal-card-head.is-warning {
  background-color: $warning;
}
.modal-card-foot {
  justify-content: flex-end;
}
</style>
