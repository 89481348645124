<template>
  <div v-if="pickUpAndReturn !== null && accessGranted">
    <PickUpAndReturnForm
      v-model="pickUpAndReturn"
      @validate="createPickUpAndReturn"
      @cancel="cancel"
      :errorsData="errorsData"
      :loading="loading">
    </PickUpAndReturnForm>
  </div>
</template>
<script>
import apiClient from '@/client/client_logistics'

import isEqual from 'lodash/isEqual'

import PickUpAndReturnForm from '@/apps/pick_up_and_return/components/PickUpAndReturnForm'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'

export default {
  name: 'PickUpAndReturnCreate',
  components: {
    PickUpAndReturnForm
  },
  mixins: [confirmationCancelMixin, deniedAccessMixin],
  props:{
    id: {
      type: Number,
      required: true
    }
  },

  data: function(){
    return {
      pickUpAndReturn: null,
      loading: false,
      finalRoutePath: '/pick-up-and-return-module/validate/:id',
      // Errors from django server
      errors: null,
      // Data sended to the server (must be assign before send the data to server)
      dataSended: {}
    }
  },
  computed: {
    errorsData() {
      if (this.errors === null) return this.errors
      const errorsData = {}
      Object.keys(this.errors).forEach(key => {
        // Special case for Entity
        if (this.pickUpAndReturn[key] instanceof Entity) {
          // Recreate Entity with data to avoid extra data added by vue
          // then compare the two "new" entities to have a correct comparaison
          if (isEqual(new Entity(this.dataSended[key]), new Entity(this.pickUpAndReturn[key]))) {
            errorsData[key] = this.errors[key]
          }
        }
        // Change each time expedition error field is changed
        else{
          if (isEqual(this.dataSended[key], this.pickUpAndReturn[key])) {
            errorsData[key] = this.errors[key]
          }
        }
      })
      return errorsData
    },
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.havePickUpAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
  },
  beforeMount(){
    this.getExpedition()
  },
  methods: {
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
            text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/pick-up-and-return-module/')
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    getExpedition(){
      apiClient.getExpedition(this.id).then((response) => {
        let expedition = new Expedition(response.data);
        this.pickUpAndReturn = new Expedition();
        this.pickUpAndReturn.purchaser = expedition.purchaser;
        this.pickUpAndReturn.purchaserEntity = expedition.purchaserEntity
        this.pickUpAndReturn.senderEntity = expedition.recipientEntity
        this.pickUpAndReturn.senderEntity.enterprise = {
          isKomaks: false
        }
        this.pickUpAndReturn.transporter = expedition.transporter
        this.pickUpAndReturn.transporterContacts = expedition.transporterContacts
        this.pickUpAndReturn.removalDate = expedition.deliveryDate
        this.pickUpAndReturn.expedition = this.id
        this.pickUpAndReturn.kind = 'PICK_UP_AND_RETURN'
        this.getPurchaserName(expedition.purchaser)
      }).catch(() => {
          this.$store.dispatch (
              'openModal',
              new ModalData(
              {
                text: this.$t('error-title'),
                css: 'is-danger'
              },
              [{
                text: this.$t('data-receive-error'),
              }],
              {
                refresh: {
                  active: true,
                  css: 'is-danger',
                  icon: 'fa-redo',
                  onClick: () => {
                    this.$router.go()
                    this.$store.dispatch('closeModal');
                  }
                },
                home: {
                  active: true,
                  css: 'is-success',
                  icon: 'fa-home',
                  onClick: () => {
                    this.$router.push('/')
                    this.$store.dispatch('closeModal');
                  }
                },
              }
            )
          )
      })
    },
    getPurchaserName(purchaserId){
      apiClient.getEnterprise(purchaserId).then((response) => {
        this.pickUpAndReturn.purchaserEntity.enterpriseName = response.data.businessName
        this.pickUpAndReturn.purchaserEntity.enterprise = response.data
        delete this.pickUpAndReturn.purchaserEntity.enterprise.entities
      })
    },
    async createPickUpAndReturn(){
      this.loading = true
      this.errors = null
      this.dataSended = this.pickUpAndReturn.getFormData()
      return apiClient
        .createPickUpAndReturn(this.dataSended)
        .then((response) => {
          this.pickUpAndReturn.pdfUrl = response.data.pdfUrl
          this.pickUpAndReturn.reference = response.data.reference
          this.pickUpAndReturn.id = response.data.id
          this.loading = false;
          this.$router.push(`/pick-up-and-return-module/validate/${this.pickUpAndReturn.id}`)
        })
        .catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('pick-up-and-return-create-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
      }
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
