import {ModalData} from '@/models/modal'
import { Expedition } from '@/models/expedition'
import apiClient from '@/client/client_logistics'
import Multiselect from 'vue-multiselect'
export default {
  props: {
    value: { // EXPEDITION OBJECT
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number
    }
  },
  components: {
    Multiselect,
  },
  beforeMount() {
    this.$store.dispatch('commonData/getStaircaseTypes')
    this.$store.dispatch('commonData/getStorageTypes')
  },
  data: function (){
    return {
      // Data sended to server:
      validationFunction: () => this.$emit('validate'),
      steps: [],
      currentSteps: null,
      displayError: false,
      loadingError: false,
      validateStep: this.step || 0,

      // Datas from server
      transporterList: [],
      contentAvailable: [],
      enterpriseList: [],
      // Loadings
      enterpriseListLoading: false,
    }
  },
  computed: {
    entityListLoading () {
      if(this.value['reprise'] === undefined && this.value.isNormalExpedition()) {
        return this.$store.getters['commonData/expeditionEntitiesCollectionIsLoading']
      } else {
        return this.$store.getters['commonData/allEntitiesCollectionIsLoading']
      }
    },

    entityList () {
      if(this.value['reprise'] === undefined && this.value.isNormalExpedition()) {
        return this.$store.getters['commonData/expeditionEntitiesCollection']
      } else {
        return this.$store.getters['commonData/allEntitiesCollection']
      }
    },

    lastStepValid () {
      // Each time we valid a step, lastStepValid is increased by one
      let value = 0 // Value of the last step valid to validate
      const errors = {} // All errors
      for (let i = 0; i < this.steps.length; i++) {
        errors[i] = []
      }
      return {value, errors}
    },
    stepsAccess (){
      // Check foreach step if the step is disabled or not
      const steps = this.steps
      steps.forEach(step => {
        step.$isDisabled = !(step.value <= this.lastStepValid.value) || (step.value > this.validateStep)
      })
      return steps
    },
  },
  methods: {
    getEntitiesCollection() {
      if(this.value.isNormalExpedition()) {
        return this.$store.getters['commonData/expeditionEntitiesCollection']
      } else {
        return this.$store.getters['commonData/allEntitiesCollection']
      }
    },
    nextStep () {
      // Back to the top
      window.scrollTo(0, 0)
      // Prevent to go to the next step if the lastStepValid is not the currentSteps value
      if (this.lastStepValid.value <= this.currentSteps.value) {
        this.displayError = true
        return
      }
      this.displayError = false
      const nextStepIndex = this.steps.indexOf(this.currentSteps) + 1
      if (nextStepIndex > this.validateStep && nextStepIndex < this.steps.length) {
        this.validateStep = nextStepIndex
      }
      this.currentSteps = this.steps[nextStepIndex]
    },
    previousStep () {
      // Back to the top
      window.scrollTo(0, 0)
      // Prevent to go to the next step if the lastStepValid is not the currentSteps value
      this.displayError = false
      const previousStepIndex = this.steps.indexOf(this.currentSteps) - 1
      this.currentSteps = this.steps[previousStepIndex]
    },
    changeStep(step){
      let index = this.steps.findIndex((element) => element.value === step)
      this.currentSteps = this.steps[index]
    },
    trowErrorLoading() {
      if(this.loadingError) {
        return
      }
      this.loadingError = true
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
            text: this.$t('data-receive-error'),
          }],
          {
            refresh: {
              active: true,
              css: 'is-danger',
              icon: 'fa-redo',
              onClick: () => {
                  this.$store.dispatch('closeModal')
                  this.$router.go()
              }
            },
            home: {
              active: true,
              css: 'is-info',
              icon: 'fa-home',
              onClick: () => {
                this.$store.dispatch('closeModal')
                this.$router.push('/')
              }
            }
          }
        )
      )
    },
    setPurchaserValue(entity) {
      this.value.purchaserEntity = entity;
      this.value.purchaser = entity.enterprise.id
    },
    // Datas retrievement
    async getEnterpriseList(){
      this.enterpriseListLoading = true
      apiClient.getUserEnterprise().then( response => {
        this.enterpriseList = []
        // Only one result : We asign the purchaser to the expedition
        if(response.data.results.length == 1){
          let entity = response.data.results[0]
          this.setPurchaserValue(entity)
        }else{
          this.enterpriseList = response.data.results
        }
        this.enterpriseListLoading = false
      }).catch(() => {
        this.enterpriseListLoading = false
        this.trowErrorLoading()
      });
    },
    getTransporterList(){
      apiClient.getListTransporters().then((response) => {
        this.transporterList = [...new Set(response.data.results)];
        for(let i=0; i<this.transporterList.length; i++){
          if(this.transporterList[i].businessName === this.value.transporter){
            this.value.transporterId = this.transporterList[i].id;
            break;
          }
        }
      }).catch(() => {
        this.trowErrorLoading()
      })
    },
    prepareGetStockList(){
      if(this.value.senderEntity.id !== -1 && this.value.kind !== 'LOAN'){
        if(this.value.isSenderKomaks()){
          this.getStockList(this.value.senderEntity.id);
        }else{
          this.getKomaksCatalogue()
        }
      }
    },
    async getStockList(senderId){
      apiClient.getStocks(null, senderId, false, this.value.kind === 'LOAN')
        .then((response) => {
          this.contentAvailable = response.data
        }).catch(() => {
          this.trowErrorLoading()
      })
    },
    async getKomaksCatalogue(){
      this.contentAvailable = []
      apiClient.getKomaksCatalogue().then((response) => {
        for(let i in response.data){
          let product = response.data[i]
          let item = ""
          for(const p in product){
            if(product[p] !== "" && p !== "id"){
              if(item === ""){
                item = product[p]
              }else{
                item += " - " + product[p]
              }
            }
          }
          this.contentAvailable.push(item);
        }
      })
    },
    update(value) {
      this.$emit('input', new Expedition(value))
    }
  }
}
