<template>
  <div>
    <!-- Recipient Step -->
    <ExpeditionStepFormTemplate
      v-if="steps.indexOf('date') !== -1 && currentSteps.value - stepsStart == steps.indexOf('date')"
      :isFirstStep="!isForDemo"
      :stepFinishEventName="'pick-up-date-finish'"
      @pick-up-date-finish="$emit('step-finish')"
      @step-cancel="$emit('cancel')">
      <template v-slot:content>
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div v-if="isRemovalDatePassed" class="box">
              <div class="notification is-warning">
                {{$t('content-after-removal-date')}}
              </div>
            </div>
            <div class="box">
              <div class="field">
                <label class="label">{{$t("pick-up-and-return-date-label")}}*</label>
                <input
                  class="input"
                  :class="{'is-danger': errorsData && errorsData.removalDate}"
                  :disabled="isRemovalDatePassed"
                  type="date"
                  :value="value.removalDate"
                  @change="update('removalDate', $event.target.value)"
                  :min="minDate"
                  required>
                <p
                  v-if="errorsData && errorsData.removalDate"
                  class="help is-danger">
                  {{errorsData.removalDate[0]}}
                </p>
              </div>
            </div>
            <div class="box">
              <SchedulesField
                :value="value.senderEntity"
                @input="update('senderEntity', $event)"
                :autoComplete="isRemovalDatePassed"
                :toggleTimePreferences="true"
                :errorsData="(errorsData) ? errorsData.senderEntity : null"
                :title="'pick-up-and-return-schedule-title'"
              >
              </SchedulesField>
            </div>
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Recipient Step -->
    <ExpeditionStepFormTemplate
      v-if="steps.indexOf('recipient') !== -1 && currentSteps.value - stepsStart == steps.indexOf('recipient')"   :stepFinishEventName="'pick-up-recipient-finish'"
      @previous-step="$emit('previous-step')"
      @step-cancel="$emit('cancel')"
      @pick-up-recipient-finish="$emit('step-finish')">
      <template v-slot:content>
        <RecipientField :value="value" @input="updateFromObject($event)" :entityList="entityList" :editable="!isEditionClosed" :errorsData="errorsData">
        </RecipientField>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Content Step -->
    <ExpeditionStepFormTemplate
      v-if="steps.indexOf('content') !== -1 && currentSteps.value - stepsStart == steps.indexOf('content')"
      :stepFinishEventName="'pick-up-content-finish'"
      @pick-up-content-finish="$emit('step-finish')"
      @step-cancel="$emit('cancel')"
      @previous-step="$emit('previous-step')">
        <template v-slot:content>
          <div v-if="isRemovalDatePassed" class="box">
            <div class="notification is-warning">
              {{$t('content-after-removal-date')}}
            </div>
          </div>
          <CatalogueContent
            :value="value.content"
            :contentAvailable="contentAvailable"
            :editable="!isRemovalDatePassed"
            @input="update('content', $event)"
          >
          </CatalogueContent>
        </template>
    </ExpeditionStepFormTemplate>

    <!-- Insurance Step -->
    <ExpeditionStepFormTemplate
      v-if="steps.indexOf('insurance') !== -1 && currentSteps.value - stepsStart == steps.indexOf('insurance')"
      :stepFinishEventName="'pick-up-insurance-finish'"
      @pick-up-insurance-finish="$emit('step-finish')"
      @step-cancel="$emit('cancel')"
      @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box container">
              <div class="field">
                <label class="label">{{$t("insurance-content-label")}}</label>
                <div class="buttons control">
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="value.insurance" @change="update('insurance', true)">
                     {{$t("yes")}}
                  </label>
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="!value.insurance" @change="update('insurance', false)">
                     {{$t("no")}}
                  </label>
                </div>
              </div>
              <div class="field">
                <label class="label">{{$t("amount-to-ensure-label")}}</label>
                <p class="control has-icons-right">
                  <input
                    type="text"
                    :class="{'is-danger': errorsData && errorsData.amountToEnsure}"
                    class="input"
                    :value="value.amountToEnsure"
                    :disabled="!value.insurance"
                    @change="update('amountToEnsure', $event.target.value)"
                  />
                  <span class="icon is-right">
                    <i class="fas fa-euro-sign"></i>
                  </span>
                </p>
                <p
                  v-if="errorsData && errorsData.amountToEnsure"
                  class="help is-danger">
                  {{errorsData.amountToEnsure[0]}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>

    <ExpeditionStepFormTemplate
      v-if="steps.indexOf('storage') !== -1 && currentSteps.value - stepsStart == steps.indexOf('storage')"
      :stepFinishEventName="'pick-up-storage-finish'"
      @pick-up-storage-finish="$emit('step-finish')"
      @step-cancel="$emit('cancel')"
      @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box container">
              <div class="field">
                <label class="label">{{$t("storage-type-title")}}</label>
                <div class="buttons control select">
                  <select :value="value.storageType" @change="update('storageType', $event.target.value)" :class="{'is-danger': errorsData && errorsData.storageType}" v-if="storageTypes !== null">
                    <option v-for="(storageItem, storageKey, storageIndex) in storageTypes"
                      v-bind:key="storageIndex"
                      :value="storageKey">
                      {{storageItem}}
                    </option>
                  </select>
                </div>
                <p
                  v-if="errorsData && errorsData.storageType"
                  class="help is-danger">
                  {{errorsData.storageType[0]}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Resume Step -->
    <ExpeditionStepFormTemplate v-if="steps.indexOf('resume') !== -1 && currentSteps.value - stepsStart == steps.indexOf('resume')" @pick-up-resume-finish="$emit('steps-finish')" @step-cancel="$emit('cancel')" :stepFinishEventName="'pick-up-resume-finish'">
      <template v-slot:content>
        <ResumeExpedition
          :expedition="value"
          @change-step="(step) => $emit('change-step', step)">
        </ResumeExpedition>
      </template>
      <template v-slot:buttons>
        <div class="buttons is-centered">
          <div class="button is-warning" @click="$emit('previous-step')">
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
            <span>{{$t("previous-button")}}</span>
          </div>
          <button class="button is-primary" :disabled="loading" :class="{'is-loading': loading}">
            <span>{{$t("validate-button")}}</span>
          </button>
        </div>
        <div class="buttons is-centered">
          <div class="button is-danger" @click="$emit('cancel')">
            {{$t('cancel-button')}}
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>
  </div>
</template>
<script>
import RecipientField from '@/components/fields/RecipientField'
import CatalogueContent from '@/components/fields/CatalogueContentField'
import ResumeExpedition from '@/apps/expedition/components/ResumeExpedition'
import SchedulesField from '@/components/fields/SchedulesField'
import ExpeditionStepFormTemplate from '@/components/templates/ExpeditionStepFormTemplate'
import { addBusinessDays, dateToString } from '@/utils/helpers'

import StepsFormMixin from '@/mixins/StepsFormMixin'
import vModelMixin from '@/mixins/vModelMixin'

export default {
  name: "PickUpAndReturnStepsForm",
  mixins: [StepsFormMixin, vModelMixin],
  components: {
    ExpeditionStepFormTemplate,
    RecipientField,
    CatalogueContent,
    ResumeExpedition,
    SchedulesField
  },
  props: {
    steps: {
      type: Array,
      default: () => [
        'date', 'recipient', 'content', 'insurance', 'storage', 'resume'
      ]
    },
    stepsStart: {
      type: Number,
      default: 0,
    },
    entityList: {
      type: Array,
      default: () => []
    },
    catalogue: {
      type: Array,
      default: () => []
    },
    transporterList: {
      type: Array,
      default: () => []
    },
    contentAvailable: {
      type: Array,
      default: () => []
    },
    enterpriseList: {
      type: Array,
      default: () => []
    },
    enterpriseListLoading: {
      type: Boolean,
      default: false,
    },
    entityListLoading: {
      type: Boolean,
      default: false
    },
    isForDemo: {
      type: Boolean,
      default: false
    },
    errorsData: {
      type: Object
    }
  },
  data() {
    return ({
      minWishedDate: null,
    })
  },
  computed: {
    // Return the current day date in string format
    minDate() {
      if(this.value.id === null) {
        return dateToString(new Date())
      } else {
        return null
      }
    },
    storageTypes(){
      return this.$store.getters['commonData/storageTypes']
    }
  },
  watch: {
    'value.removalDate': function(newRemovalDate){
      this.update('removalDate', newRemovalDate)
      if(this.value.deliveryDate === null){
        this.update('deliveryDate', addBusinessDays(newRemovalDate, 2))
      }
    },
    'value.amountToEnsure': function(newAmount){
      this.update('amountToEnsure', newAmount)
    },
    'value.storageType': function(newStorage){
      this.update('storageType', newStorage)
    },
  }
}
</script>
