<template>
  <div class="columns is-multiline">
    <p
      v-if="errorsData && errorsData.recipient"
      class="help is-danger has-text-centered">
      {{errorsData.recipient[0]}}
    </p>
    <div class="column is-12" v-if="!editable">
      <div class="box">
        <div class="notification is-warning">
          {{$t('content-after-delivery-date')}}
        </div>
      </div>
    </div>
    <div class="column is-6">
      <div>
        <div class="box">
          <div class="has-text-centered section-title">
            <label>{{$t("recipient-name-label")}}</label>
          </div>
          <div class="field">
            <label class="label">{{$t("name-label")}} *</label>
            <Autocomplete
              v-if="entityList.length >0"
              :initialValue="value.recipientEntity"
              :class="{'is-danger': errorOnAddress('name')}"
              :suggestions="entityList"
              :track-by="'address.name'"
              :loading="false"
              :placeholder="$t('recipient-name-label')"
              :freeTyping="true"
              :addCurrentInputToSuggestion="true"
              :disabled="!editable"
              @select-value="chooseSelection"
              required>
            </Autocomplete>
            <input v-else
              class="input"
              :class="{'is-danger': errorOnAddress('name')}"
              type="text"
              :value="value.recipientEntity.address.name"
              @change="update('recipientEntity.address.name', $event.target.value)"
              :disabled="!editable"
              required>
            <!-- if the value is komaks, add restriction to address and show error -->
            <p
              v-if="value.recipientEntity.address.name.toLowerCase().includes('komaks') && (value.recipientEntity.id > 10 || value.recipientEntity.id === -1)"
              class="help is-danger">
              {{$t("need-valid-komaks-recipient")}}
            </p>
            <p
              v-if="errorOnAddress('name')"
              class="help is-danger">
              {{errorsData.recipientEntity.address.name[0]}}
            </p>
          </div>
          <div class="field">
            <label class="label"> {{$t("name-additional-info-label")}} </label>
            <input
              :value="value.recipientEntity.address.nameAdditionalInfo"
              class="input"
              :class="{'is-danger': errorOnAddress('nameAdditionalInfo')}"
              type="text"
              :disabled="!editable"
              @change="update('recipientEntity.address.nameAdditionalInfo', $event.target.value);">
            <p
              v-if="errorOnAddress('nameAdditionalInfo')"
              class="help is-danger">
              {{errorsData.recipientEntity.address.nameAdditionalInfo[0]}}
            </p>
          </div>
        </div>
        <div class="box">
          <AddressField
            :value="value.recipientEntity.address"
            :title="$t('recipient-address-label')"
            :autoComplete="isKomaks()"
            @input="update('recipientEntity.address', $event)"
            :errorsData="(errorsData && errorsData.recipientEntity) ? errorsData.recipientEntity.address : null">
          </AddressField>
        </div>
        <ContactsField
          :editable="editable"
          :value="value.recipientEntity.contacts"
          @input="update('recipientEntity.contacts', $event)"
          :errorsData="(errorsData && errorsData.recipientEntity) ? errorsData.recipientEntity.contacts : null">
        </ContactsField>
      </div>
    </div>
    <div class="column is-6">
      <ConstraintsField v-if="editable"
        :value="value.recipientEntity"
        @input="update('recipientEntity', $event)"
        :errorsData="(errorsData) ? errorsData.recipientEntity : null">
      </ConstraintsField>
      <div v-if="!updateAsTransporter" class="box content-highlight">
        <SectionTitle
          imgName="fas fa-calendar-alt"
          :title="$t('delivery-planification-label')"
        />
        <div class="field">
          <label class="label">{{$t("expedition-date-desired-label")}}*</label>
          <input
            id="inputDeliveryDate"
            class="input"
            :class="{'is-danger': errorOnValue('deliveryDate')}"
            type="date"
            :value="value.deliveryDate"
            :min="minDate"
            :disabled="!editable"
            required
            @input="update('deliveryDate', $event.target.value)"
          />
          <p
            v-if="errorOnValue('deliveryDate')"
            class="help is-danger">
            {{errorsData.deliveryDate[0]}}
          </p>
        </div>
      </div>
      <div class="box">
        <SchedulesField
          :value="value.recipientEntity"
          :autoComplete="!editable"
          :toggleTimePreferences="true"
          @input="update('recipientEntity', $event)"
          :errorsData="(errorsData) ? errorsData.recipientEntity : null"
        >
        </SchedulesField>
      </div>
      <div class="box">
        <SectionTitle
          imgName="fas fa-hand-point-right"
          :title="$t('observations-label')"
        />
        <div class="field">
          <label class="label label-prep-komaks">{{$t("observations-label-komaks")}}</label>
          <textarea
            class="textarea"
            :value="value.observations"
            :disabled="!editable"
            @change="update('observations', $event.target.value)">
          </textarea>
          <p
            v-if="errorsData && errorsData.observations"
            class="help is-danger">
            {{errorsData.observations[0]}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import AddressField from '@/components/fields/AddressField'
import SchedulesField from '@/components/fields/SchedulesField'
import ContactsField from '@/components/fields/ContactsField'
import ConstraintsField from '@/components/fields/ConstraintsField'
import SectionTitle from '@/components/SectionTitle'
import {dateToString} from '@/utils/helpers'
import { Entity } from '@/models/expedition'
import Autocomplete from '@/components/utils/Autocomplete'
import vModelMixin from '@/mixins/vModelMixin'


export default {
  name: 'RecipientField',
  mixins: [vModelMixin],
  components: {
    Autocomplete,
    AddressField,
    SchedulesField,
    ContactsField,
    ConstraintsField,
    SectionTitle
  },
  props:{
    entityList: {
      type: Array,
      default : () => []
    },
    editable: {
      type: Boolean,
      default: true
    },
    updateAsTransporter: {
      type: Boolean,
      default: false
    },
    errorsData: {
      type: Object
    }
  },
  data: function(){
    return {
      previousIndexValue: -1
    }
  },
  watch:{
    entityList() {
      this.previousIndexValue = this.entityList.findIndex((entity) => entity.address.name === this.value.recipientEntity.address.name)
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    minDate() {
      // No min date for admins
      if (this.isAdmin) {
        return null
      } else {
        // Min date at the creation
        if(this.value.id === null) {
          return dateToString(new Date())
        }
        return null
      }
    }
  },
  methods: {
    chooseSelection(selection){
      let index = this.entityList.findIndex((entity) => entity.address.name === selection.address.name)
      if(index === -1){
        // if this previousIndexValue === -1 we have to keep data in address, in the other case we wipe everythings
        const entity =  new Entity(this.previousIndexValue === -1 ? this.value.recipientEntity : null)
        entity.address.name = selection.address.name
        entity.id = -1
        // Update the recipient to remove it from data (set null)
        this.updateFromObject({recipientEntity: entity, recipient: null})
        this.previousIndexValue = -1
      }else{
        this.updateFromObject({recipientEntity: selection, recipient: selection.enterprise ? selection.enterprise.id : null})
        this.previousIndexValue = index
      }
    },
    errorOnAddress(nameField){
      if(this.errorsData){
        if(this.errorsData.recipientEntity && this.errorsData.recipientEntity && this.errorsData.recipientEntity.address[nameField]){
          return true
        }
      }
      return false
    },
    errorOnValue(nameField){
      if(this.errorsData && this.errorsData[nameField]){
        return true
      }
      return false
    },
    isKomaks(){
      // Check if the recipient is valid and if this is Komaks
      // If recipient exist
      if (this.value.recipientEntity.address.line) {
        // And if his enterprise is Komaks
        if (
          this.value.recipientEntity.enterprise &&
          this.value.recipientEntity.enterprise.businessName === "Komaks France"
        ) {
          // Then, the address is not editable
          return true
        }
        // Else the address is editable
        return false
      } else {
        // If recipient does not exit and the value is komaks
        if (this.value.recipientEntity.address.name.toLowerCase() === "komaks") {
          // Then the address is not editable
          return true
        }
        // Else the is editable
        return false
      }
    }
  }
}


</script>
