<template>
  <div class="main-list">
    <section class="main-list--header has-background-white">
      <div class="columns is-multiline is-mobile is-vcentered is-marginless">
        <div class="main-list--header--return column is-one-quarter">
          <button v-show="currentChoice.id != -1" @click="$emit('unselect')" class="button is-light is-small is-hidden-tablet">
            <span class="icon"><i class="fas fa-chevron-left"></i></span>
            <span>{{$t('back')}}</span>
          </button>
        </div>
        <h1 class="main-list--header--title column is-marginless is-half title has-text-primary is-size-5-mobile has-text-centered">
          {{$t(title)}}
        </h1>
        <div class="column is-one-quarter is-hidden-mobile">
        </div>
        <div class="main-list--header--tabs column is-marginless tabs is-small is-toggle is-toggle-rounded">
          <ul>
            <li v-for="(value, key) in menuFilters" :key="key" :class="{'is-active': activeKey === key}">
              <a @click="$emit('set-filter', key)">
                <span class="icon is-small is-hidden-mobile"><i class="fas" :class="value.icon"></i></span>
                <span>{{$t(value.text)}}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="column main-list--header--search">
          <div class="control has-icons-left has-icons-right">
            <input class="input is-small is-rounded" type="text" @input="(e) => $emit('listing-search',e.target.value)">
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
        <div class="main-list--header--actions column tabs is-small is-toggle is-toggle-rounded">
        <!--   <ul> -->
        <!--     <li class="tab&#45;&#45;print"> -->
        <!--       <a> -->
        <!--         <span class="icon is&#45;small"><i class="fas fa&#45;print"></i></span> -->
        <!--         <span class="is&#45;hidden&#45;touch">Télcharger le pdf</span> -->
        <!--       </a> -->
        <!--     </li> -->
        <!--     <li> -->
        <!--       <a> -->
        <!--         <span class="icon is&#45;small"><i class="fas fa&#45;file&#45;pdf"></i></span> -->
        <!--         <span class="is&#45;hidden&#45;touch">Imprimer la liste</span> -->
        <!--       </a> -->
        <!--     </li> -->
        <!--   </ul> -->
        </div>
      </div>
    </section>
    <div
     :class="{ 'show-details': currentChoice.id !== -1 }"
      class="main-list--content columns is-marginless"
    >
      <div class="main-list--content--list column is-one-third is-paddingless">
        <div class="container list-expedition">
          <!-- <div class="filters has&#45;background&#45;white&#45;ter"> -->
          <!--   <button class="button is&#45;small"> -->
          <!--     <span class="icon"> -->
          <!--       <i class="fas fa&#45;filter"></i> -->
          <!--     </span> -->
          <!--     <span>{{$t("filters&#45;title")}}</span> -->
          <!--   </button> -->
          <!-- </div> -->
          <ul>
            <progress v-if="loading" class="progress is-small is-primary" max="100">15%</progress>
            <template v-else v-for="(sublist, id) in list">
              <template v-for="date in Object.keys(sublist).sort()">
                <span :key="date + id">
                  <h2 class="has-text-centered">
                    <template v-if="id !== 'canceled'">
                    <span v-if="id === 'notValidated'">
                      {{$t("initially-expected-on")}}
                    </span>
                    <span v-else-if="id === 'validated'">
                      {{$t("expected-on")}}
                    </span>
                    <span v-else-if="id === 'delivered'">
                      {{$t("delivered-on")}}
                    </span>: {{date}}
                    </template>
                    <span v-else>
                      {{$t("expeditions-canceled")}}
                    </span>
                  </h2>
                  <li
                    v-for="expedition in sublist[date]"
                    :key="expedition.id"
                    class="expedition-item"

                    :class="{
                      selected:expedition.id == currentChoice.id,
                      'has-background-white': expedition.id !== currentChoice.id
                    }"
                    @click="$emit('select', expedition)"
                  >
                    <!-- <i class="fas fa-truck fa-2x"></i> -->
                    <div class="expedition-item--content">
                      <slot name="button" :expedition="expedition">
                        <ExpeditionButton :expedition="expedition"></ExpeditionButton>
                      </slot>
                    </div>
                    <a class="expedition-item--button has-text-primary">
                      <i class="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </span>
              </template>
            </template>
          </ul>
      </div>
      </div>
      <div class="main-list--content--details column is-two-thirds">
        <template v-if="currentChoice.id !== -1">
          <slot name="details">
            <ExpeditionDetails
              :expedition="currentChoice"
              :key="currentChoice.id">
            </ExpeditionDetails>
          </slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ExpeditionDetails from '@/apps/expedition/expedition/details';
import ExpeditionButton from '@/components/ExpeditionButton';

export default {
  name: 'ExpeditionList',
  components: {
    ExpeditionDetails, ExpeditionButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    activeKey: {
      type: String,
      required: true
    },
    list: {
      type: Object,
      required: true
      /*
      {
        notValidated: {
          'date': [list],
          'date': [list],
          ...
        },
        validated: {
          'date': [list],
          'date': [list],
          ...
        },
        delivered: {
          'date': [list],
          'date': [list],
          ...
        }
      }
      */
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentChoice: {
      type: Object,
      default: () => {
        return {
          id: -1
        }
      }
    },
    menuFilters: {
      type: Object,
      default: () => {
        return {
          0: {
            icon: 'fa-rss',
            text: 'new-expeditions-filter'
          },
          1: {
            icon: 'fa-check-square',
            text: 'validated-expeditions-filter'
          },
          2: {
            icon: 'fa-truck',
            text: 'delivered-expeditions-filter'
          },
          3: {
            icon: 'fa-list',
            text: 'all-expeditions-filter'
          },
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 769px){
    .main-list{
      display: flex;
      flex-direction: column;
      // 100% viewport height - navigation's height
      height: calc(100vh - 4rem);
      overflow-y: hidden;

      &--content{
        max-height: 100%;
        overflow-y: hidden;

        &--list, &--details{
          max-height: 100%;
          overflow-y: auto;
        }

        &--list{
          border-right: solid 1px;
        }

        &--details{
          padding: 1em 2em;
        }
      }
    }
  }

  @media screen and (max-width: 1152px){
    .main-list--header{
      &--tabs, &--search, &--actions{
        flex: initial;
      }

      &--search{
        flex-basis: 15em;
        margin-left: auto;
      }
    }
  }

  .main-list{
    &--header{
      border-bottom: solid 1px #ccc;
    }

    .tab--print{
      margin-left: auto;
    }
  }

  @media screen and (max-width: 769px){
    .tabs .icon:first-child{
      margin: 0;
    }

    .main-list{
      &--header{
        &--title{
          font-size: 1.15rem !important;
          padding: 0;
          width: 30% !important;
        }

        &--return, &--actions{
          width: 35% !important;
        }

        &--tabs, &--search{
          order: 2;
        }

        &--search{
          flex: 1;
          margin-left: 0;
        }
      }

      &--content{
        display: flex;
        min-width: 200vw;

        &--list, &--details{
          max-width: 100vw;
          min-width: 100vw;
          width: 100vw;
        }

        &--list{
          transition: margin 500ms 100ms;
        }

        &.show-details &--list{
          margin-left: -100vw;
        }
      }
    }
  }

  /* .main-list--content--details{ */
  /*    margin-bottom: 3em; */
  /* } */

  .list-expedition{
    border-bottom: solid 1em #f5f5f5;
  }

  .filters{
    border-bottom: solid 1px #ccc;
    padding: .75em;
  }

  .expedition-item{
    align-items: center;
    border-bottom: solid 1px #bbb;
    display: flex;
    padding: 1em 1.5em;
    position: relative;
    transition: box-shadow 200ms ease, background-color 200ms ease;

    &.selected{
      background-color: #fff;
    }

    &:hover, &.selected{
      box-shadow: 5px 0 0 0 inset #93c123;
      background-color: #f4fade;
    }

    &--content{
      flex: 1;
    }

    &--button{
      &::before{
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .truck-icon {
    max-width: 15%;
  }
</style>
