<template>
  <div v-if="accessGranted" class="container box m-t-md" id="stock">
    <section class="columns is-marginless">
      <div class="column is-one-third">
        <div class ="menu-stock">
          <div class="field has-addons m-sm">
            <div class="control">
              <input class="input" v-model.lazy="search" id="quickSearch" type="text" @keyup.enter="searchNewItems()" :disabled="loading" :placeholder="$t('research-label')">
            </div>
            <div class="control">
              <button v-if="!loading" class="button is-info" v-on:click="searchNewItems()" :disabled="loading">
                <span class="fa fa-search" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="main-list--header--title column is-one-third title has-text-primary has-text-centered">
        <p align="center">
          <img src="@/assets/icons/Stock_green_icon.png" width="30px" heigth="30px">
          {{$t('dashboard-stock-title')}}
        </p>
      </div>
      <div class="column is-one-third buttons">
        <button :title="$t('refresh-stock')" :disabled="loading" class="button is-primary is-inline m-l-sm" v-on:click="requestStockData">
          <span class="fa fa-redo" aria-hidden="true"></span>
        </button>
        <button :title="$t('actions-title')"
                class="button is-primary"
                @click="computeShowAction">
          <i class="fas fa-cogs"></i>
        </button>
      </div>
    </section>
    <section v-if="showAction" class="colums">
      <div class="column is-half is-offset-one-quarter">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">{{$t("actions-title")}}</p>
          </header>
          <div class="card-content is-multiline">
            <template>
              <a>
                <download-excel :data="filtredAndSearchContent"
                                name="stock"
                                :fields="fieldsToShowInExcel">
                  {{$t('download-excel-label')}}
                </download-excel>
              </a>
              <a @click="displaySoldArticlesTrueFalse" :class="{'disabled': loading}">{{$t('display-not-sold-label')}} <span v-if="displaySoldArticles === false">{{$t('sold')}}</span><span v-else>{{$t('not-sold')}}</span></a>
            </template>
          </div>
        </div>
      </div>
    </section>
    <!-- Loading -->
    <div class="text-info alert-stock m-md" v-if="loading">
      <progress class="progress is-small is-primary" max="100">15%</progress>
    </div>
    <!-- No Result -->
    <p v-if="!loading && filtredAndSearchContent.length == 0" class="is-info alert-stock has-text-centered">
      {{$t('stocks-no-data-sentence')}} <br>
      <button v-if="Object.keys(filters).length != 0 && this.lastFilter" v-on:click="removeLastFilter()" class="button is-info" >
        {{ $t('remove-last-filter-sentence') }}
      </button>
    </p>
    <div id="gc">
      <!-- Grid -->
      <div class="stock-container" v-if="filtredAndSearchContent.length > 0 && !loading">
        <vue-good-table
          :columns="contentColumns"
          :rows="filtredAndSearchContent"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 50,
            dropdownAllowAll: false,
          }"
          :sort-options="{
            enabled: false,
          }">
          <template slot="table-column" slot-scope="props">
            <div
              :key="'header_' + props.column.field"
              v-on:click="showFilterMenu($event, props.column.field)"
              class="header-filter"
              :class="{filtred: filters[props.column.field] && filters[props.column.field].filter.length > 0}"
            >
              {{ props.column.label }}
            </div>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'qrcode'">
              <a target="_blank" :href="'/stock-module/articles/' + props.row.pk">
                <span v-if="problematic">!</span> {{ formatQrcode(props.row.qrcode) }}
              </a>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
        <StockFilter v-if="selectedFilterHeader !== ''" v-click-outside="closeFilter" :header="selectedFilterHeader.name" :gridContainer="selectedFilterHeader.el" :values="filtersValues" :updateFilter="updateFilter" :removeFilter="removeFilter" :filters="filters" :orderBy="orderBy"/>
      </div>
    </div>

  </div>

</template>

<script>
import apiClient from '@/client/client_logistics'
import Vue from 'vue'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import StockFilter from '@/apps/stock/components/StockFilter'


export default {
  name: 'Stock',
  props: {
    // If an order is passed in props the default order will change
    initialOrderColumn: {
      type: Array,
      default: () => []
    },
    initialEntity: {
      type: Number,
      required: false
    }
  },
  components: {
    VueGoodTable,
    StockFilter
  },
  mixins: [deniedAccessMixin],
  data () {
    return {
      showAction: false,
      // Server datas
      content: [], // All curent content (articles values)
      filtredContent: [], // Current filtred content
      filtredAndSearchContent: [],

      contentColumns: [
        {
         label: 'ID',
         field: 'qrcode',
          width: "100px",
        },
        {
          label: "Denomination",
          field: "denomination",
          width: "180px",
        },
        {
          label: "Type",
          field: "type",
          width: "180px",
        },
        {
          label: "Numéro de série",
          field: "serialNumber",
        },
        {
          label: "Note",
          field: "note",
        },
        {
          label: "Ref achat",
          field: "purchaseReference",
          width: "50px",
        },
        {
          label: "Date de reception",
          field: "receiptDate",
        },
        {
          label: "Propriétaire",
          field: "owner",
        },
        {
          label: "Entité",
          field: "entity",
          width: "180px",
        },
        {
          label: "Etat",
          field: "state",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      fieldsToShowInExcel: {
        "ID": "qrcode",
        "Dénomination": "denomination",
        "Type": "type",
        "Numéro de série": "serialNumber",
        "Note": "note",
        "Ref achat": "purchaseRef",
        "Date de reception": "receiptDate",
        "Propriétaire": "owner",
        "Entité": "entity",
        "Etat": "state",
        "Status": "status",
      },

      // Filters attributes
      problematic: false, // Boolean to ask only problematic articles
      displaySoldArticles: false, // Boolean to ask only sold (or not) articles

      search: '',
      filters: {}, // Lists of filters
      initialFiltersValues: {}, // Values of all filters
      filtersValues: {}, // Values of all filters (displayed in FilterMenu) adapted with filters
      lastFilter: '',
      filterHistory: [],

      // FilterMenu management
      selectedFilterHeader: '', // Current header selected to display filter menu

      scrollerMaxHeight: 0,

      // component state
      loading: false, // Loading is true when request are processing

      // MenuHidden pre-state:
      previousSearchValue: "",
    }
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.haveStocksLogisticsAccess){
          Vue.nextTick(() =>
            this.initScrollerMaxHeight()
          )
          return true
        }else{
          this.accessDeniedError()
          return false
        }
      }
    }
  },
  beforeMount () {
    // Initialisation of custom order
    // Ask data
    this.initFilters()
    this.requestStockData()
  },
  methods: {
    computeShowAction(){
      this.showAction = !this.showAction
    },
    initFilters(){
      this.contentColumns.forEach(column => {
        this.createFilter(column.field, [], true)
      })
    },
    createFilter(header, filter, shown) {
      this.filters[header] = { header, filter, shown }
    },
    updateFilter (header, filtersValues) {
      if(this.filterHistory.indexOf(header) === -1) {
        this.filterHistory.push(header)
      }
      this.filters[header].filter = filtersValues
      this.lastFilter = header
      this.localSearch()
    },
    closeFilter(){
      this.selectedFilterHeader = ''
    },
    removeFilter (header) {
      const indexOfFilter = this.filterHistory.indexOf(header)
      this.filterHistory.splice(indexOfFilter, 1)
      this.filters[header].filter = []
      this.localSearch(indexOfFilter)
    },
    removeLastFilter() {
      this.removeFilter(this.lastFilter)
      this.lastFilter = ''
    },
    formatQrcode (qrcode) {
      if (qrcode.length < 10) {
          return qrcode
      }
      return qrcode.slice(0, 3) + " " + qrcode.slice(3, 7) + " " + qrcode.slice(7)
    },
    showFilterMenu (e, name) {
      // If user click on the header of the already open filter menu : close the menu
      if (this.selectedFilterHeader.name && this.selectedFilterHeader.name === name) {
        this.selectedFilterHeader = ''
      } else { // Else open a new filter menu
        this.selectedFilterHeader = {
          el: e.target,
          name
        }
      }
      // We stop the propagation of the event to avoid the instant close of the menu
      e.stopPropagation()
    },
    initSearch() {
      this.filtredContent = this.content
      this.filtredAndSearchContent = this.filtredContent
      this.search = ''
      this.filtersValues = this.initialFiltersValues
    },
    localSearch (removeFilterIndex=null) {
      const filtersFiltredValues = {}
      // Take only the filters with somethings for better performance
      const filtersActive = Object.keys(this.filters).filter(
        header => {
          const isActive = this.filters[header].filter.length > 0 && this.filters[header].shown
          if (isActive) {
            // If there is a filter: We don't change the values inside
            filtersFiltredValues[header] = this.filtersValues[header]
          }
          return isActive
        }
      )
      const nonActiveFilter = Object.keys(this.filters).filter(
        header => filtersActive.indexOf(header) === -1
      )

      if (this.filterHistory.length === 0 || filtersActive.length === 0) {
        this.initSearch()
        return
      }

      // Get the index of the filter in history:
      // - If this is the index of last element: this is a new filter or an update on the last filter,
      // we have nothing to do in this both case
      // - In others case :
      //   - We have to clear filtersValues of filter that follow the updated filter and iterate
      //   over articles to recreate all possibles values
      const indexInHistory = (removeFilterIndex === null) ?  this.filterHistory.indexOf(this.lastFilter) : removeFilterIndex -1
      let filterToUpdate = []
      // check if the last filter is a filter update
      if (indexInHistory !== this.filterHistory.length - 1) {
        filterToUpdate = this.filterHistory.slice(indexInHistory + 1)
        // Clear values for filters that depends on the updated filter
        filterToUpdate.forEach(filter => {
          filtersFiltredValues[filter] = []
        })
      }
      const filtredContent = [] // The new filtred content to display

      // For all articles
      this.content.forEach(article => {
        // ==============  PART 1: UPDATE sub filters ======================= //
        let mustUpdateSubFilter = indexInHistory !== this.filterHistory.length -1
        let index = 0

        // if there is an update of a filter that is not the last filter, we have to update the
        // linked filters:
        // - Check for all filter parent of the updated filter if the article should be display
        while (mustUpdateSubFilter && index <= indexInHistory) {
          const header = this.filterHistory[index]
          mustUpdateSubFilter = mustUpdateSubFilter && this.filters[header].filter.indexOf(article[header]) !== -1
          index ++
        }

        // if the article must be displayed:
        // - We add the value of the articles to all shild filter
        if (mustUpdateSubFilter) {
          for (let i = indexInHistory + 1; i < this.filterHistory.length; i++) {
            const header = this.filterHistory[i]
            // Add the value to the filterValues
            if(filtersFiltredValues[header].indexOf(article[header])== -1){
              filtersFiltredValues[header].push(article[header])
            }
          }
        }

        // ==============  PART 2: Filter articles to create content  ======================= //

        let mustBeShown = true // check if the article can be shown with ALL filters active
        index = 0

        // Check if the the article should be included in content with all filters
        while (mustBeShown && index < filtersActive.length) {
          const header = filtersActive[index]
          mustBeShown = mustBeShown && this.filters[header].filter.indexOf(article[header]) !== -1
          index ++
        }
        if (mustBeShown) {
          filtredContent.push(article)
          // Rebuild only the non activeFilter with only the remains values
          nonActiveFilter.forEach(header => {
            if (!article[header]) return
            if (filtersFiltredValues[header] === undefined) {
              filtersFiltredValues[header] = []
              filtersFiltredValues[header].push(article[header])
            } else {
              if(filtersFiltredValues[header].indexOf(article[header])== -1){
                filtersFiltredValues[header].push(article[header])
              }
            }
          })
        }
      })
      this.filtersValues = filtersFiltredValues
      this.filtredContent = filtredContent
      this.filtredAndSearchContent = this.filtredContent
      this.search = ''
      this.previousSearchValue = ''
    },
    // POST data to handle filtering / hide column / orderBy ...
    async requestStockData () {
      this.loading = true
      apiClient.getStocks(null, null, this.displaySoldArticles, null).then(
        response => {
          this.filtredAndSearchContent = response.data
          this.filtredContent = this.filtredAndSearchContent
          this.content = this.filtredAndSearchContent

          this.initInitialFiltersValues()
          this.loading = false
        }
      ).catch(() => {
        this.loading = false
        this.content = []
      })
    },
    compareValues(key, order){
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    },
    orderBy(header, order){
      this.filtredAndSearchContent.sort(this.compareValues(header, order))
      this.filtredContent.sort(this.compareValues(header, order))
      this.content.sort(this.compareValues(header, order))
    },
    initInitialFiltersValues(){
      this.initialFiltersValues = {}
      this.contentColumns.forEach(column => {
        this.initialFiltersValues[column.field] = []
      })
      for(let i=0; i<this.filtredAndSearchContent.length; i++){
        let item = this.filtredAndSearchContent[i]
        Object.keys(this.initialFiltersValues).forEach(key => {
          if(key != 'SEARCH' && key != 'pk'){
            if(item[key] === undefined){
              this.initialFiltersValues[key].push('')
            } else if(key == 'qrcode' || this.initialFiltersValues[key].indexOf(item[key]) == -1) {
              this.initialFiltersValues[key].push(item[key])
            }
          }
        })
      }
      this.filtersValues = this.initialFiltersValues
    },
    searchNewItems () {

      if(!this.search.toLowerCase().includes(this.previousSearchValue.toLowerCase())) {
        this.filtredAndSearchContent = this.filtredContent
      }
      let filters = this.search.split(" ")
      // this.listIntermediaire = [];
      this.filtredAndSearchContent = this.filtredAndSearchContent.filter(element => {
        let filterInSEARCH = true
        // property is equivalent to stock headers
        filters.forEach(filter => {
          if(!element["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
            filterInSEARCH = false
          }
        })
        return filterInSEARCH
      })

      this.previousSearchValue = this.search
    },
    initScrollerMaxHeight(){
      const gridContainerRect = document.getElementById('gc').getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.scrollerMaxHeight = windowHeight - gridContainerRect.y - 10
    },
    displaySoldArticlesTrueFalse () {
      this.displaySoldArticles = !this.displaySoldArticles
      this.initialFiltersValues = []
      this.filtredAndSearchContent = []
      this.filtredContent = []
      this.content = []
      this.filtersValues = []
      this.initFilters()
      this.requestStockData()
    },
    formatPurchaseReference(value) {
      let valueRef = ''
      value.split('/').forEach(item => {
        valueRef += '<span>'+ item + '</span>'
      })
      return valueRef
    }
  }
}

</script>
<style>

.header-filter {
  cursor: pointer;
}

.vgt-left-align {
  font-size: 12px;
}

#stock {
  max-width: 90%;
}

.filtred {
  color: red;
}
.disabled{
    pointer-events:none
}


</style>
