import apiClient from '@/client/client_logistics'
import { Expedition } from '@/models/expedition.js'

export default {
  namespaced: true,
  state: {
    pickUpAndReturns: {
      loading: true,
      collection: []
    }
  },
  getters: {
    pickUpAndReturns: state => {
      return state.pickUpAndReturns
    }
  },
  mutations: {
    setPickUpAndReturns(state, pickUpAndReturns) {
      state.pickUpAndReturns = {
        loading: false,
        collection: pickUpAndReturns
      }
    },
    // Set the loading to true, remove the current collections
    loadPickUpAndReturns(state) {
      state.pickUpAndReturns = {
        loading: true,
        collection: []
      }
    },
    updatePickUpAndReturn(state, pickUpAndReturn) {
      const pickUpAndReturns = state.pickUpAndReturns
      const index = pickUpAndReturns.collection.findIndex((exp) => exp.id === pickUpAndReturn.id)
      if (index !== -1) {
        pickUpAndReturns.collection[index] = pickUpAndReturn
      }
      state.pickUpAndReturns = Object.assign({}, pickUpAndReturns) // Copy to force update !
    },
    removePickUpAndReturn(state, pickUpAndReturnId) {
      const pickUpAndReturns = state.pickUpAndReturns
      const index = pickUpAndReturns.collection.findIndex((exp) => pickUpAndReturnId === exp.id)
      if (index !== -1) {
        pickUpAndReturns.collection.splice(index, 1)
      }
      state.pickUpAndReturns = pickUpAndReturns
    },
  },
  actions: {
    async getPickUpAndReturns({ commit }) {
      commit('loadPickUpAndReturns') // Set global expeditions loadings
      return apiClient.getPickUpAndReturns({expedition_kind: "NORMAL"}).then((response) => {
        commit('setPickUpAndReturns', response.data.map(pickUpAndReturn => {
          let searchKey = pickUpAndReturn.senderEntity.address.name
          searchKey += " " + pickUpAndReturn.recipientEntity.address.nameAdditionalInfo
          searchKey += " " + pickUpAndReturn.recipientEntity.address.line
          searchKey += " " + pickUpAndReturn.recipientEntity.address.lineAdditionalInfo
          searchKey += " " + pickUpAndReturn.recipientEntity.address.zipCode
          searchKey += " " + pickUpAndReturn.recipientEntity.address.city

          searchKey += " " + pickUpAndReturn.senderEntity.address.name
          searchKey += " " + pickUpAndReturn.senderEntity.address.nameAdditionalInfo
          searchKey += " " + pickUpAndReturn.senderEntity.address.line
          searchKey += " " + pickUpAndReturn.senderEntity.address.lineAdditionalInfo
          searchKey += " " + pickUpAndReturn.senderEntity.address.zipCode
          searchKey += " " + pickUpAndReturn.senderEntity.address.city

          searchKey += " " + pickUpAndReturn.purchaserEntity.address.name
          searchKey += " " + pickUpAndReturn.purchaserEntity.address.nameAdditionalInfo
          searchKey += " " + pickUpAndReturn.purchaserEntity.address.line
          searchKey += " " + pickUpAndReturn.purchaserEntity.address.lineAdditionalInfo
          searchKey += " " + pickUpAndReturn.purchaserEntity.address.zipCode
          searchKey += " " + pickUpAndReturn.purchaserEntity.address.city

          searchKey += " " + pickUpAndReturn.reference

          pickUpAndReturn.content.forEach(item => {
            if(item.attributeQuality !== undefined){
              searchKey = searchKey + " " + item.attributeQuality.serialNumber
            }
            if (item.purchaseReference) {
              searchKey += " " + item.purchaseReference
            }
            if (item.qrcode) {
              searchKey += " " + item.qrcode
            }
          })
          const pickUpObject = new Expedition(pickUpAndReturn)
          pickUpObject['SEARCH'] = searchKey
          if(this.id !== undefined && pickUpObject.id == this.id) {
            this.currentChoice = pickUpObject
          }
          return pickUpObject
        }))
      })
    },

    updatePickUpAndReturn({ commit }, pickUpAndReturn) {
      commit('updatePickUpAndReturn', pickUpAndReturn) // Update the pickUpAndReturn
    },
    async cancelPickUpAndReturn({ commit }, pickUpAndReturnId) {
      return apiClient.updatePickUpAndReturn({status: 'CANCELED'}, pickUpAndReturnId).then((response) => {
        commit('updatePickUpAndReturn', new Expedition(response.data)) // Update the pickUpAndReturn
      })
    },
  },
}
