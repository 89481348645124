import PickUpAndReturnModule from '@/apps/pick_up_and_return/pick_up_and_return/module'
import PickUpAndReturnCreate from '@/apps/pick_up_and_return/pick_up_and_return/create'
import PickUpAndReturnUpdate from '@/apps/pick_up_and_return/pick_up_and_return/update'
import PickUpAndReturnList from '@/apps/pick_up_and_return/pick_up_and_return/list'
import PickUpAndReturnValidation from '@/apps/pick_up_and_return/pick_up_and_return/validation'

const basePath = '/pick-up-and-return-module/'

const routes = [
  {
    path: basePath,
    name: 'pick-up-and-return-module',
    component: PickUpAndReturnModule,
  },
  {
    path: basePath + 'create/:id',
    component: PickUpAndReturnCreate,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: basePath + 'list/:id?',
    component: PickUpAndReturnList,
    props(route){
      const props = { ...route.params }
      props.id = +props.id
      return props
    }
  },
  {
    path: basePath + 'validate/:id',
    component: PickUpAndReturnValidation,
    props: true
  },
  {
    path: basePath +  'update/:id/:step/',
    component: PickUpAndReturnUpdate,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      props.step = +props.step
      return props
    },
  }
];

export default routes;
