var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"expedition-button columns is-mobile is-multiline vcentered"},[_c('div',{staticClass:"column is-two-third"},[_c('h3',{staticClass:"is-size-6 has-text-primary has-text-weight-bold"},[_vm._v(_vm._s(_vm.pickUpAndReturn.reference)+" "),_c('span',{staticClass:"purchaser"},[_vm._v("("+_vm._s(_vm.enterpriseName)+")")])])]),_c('div',{staticClass:"column is-one-third has-text-right"},[_c('p',{staticClass:"has-text-grey-light is-size-7"},[_vm._v(_vm._s(_vm.pickUpAndReturn.removalDate))])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"media"},[_vm._m(0),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.pickUpAndReturn.senderEntity.address.name))]),_c('p',[_vm._v(_vm._s(_vm.pickUpAndReturn.senderEntity.address.zipCode)+" - "+_vm._s(_vm.pickUpAndReturn.senderEntity.address.city))])])])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas",class:{
              'fa-truck': !_vm.pickUpAndReturn.isCanceled(),
              'fa-times': _vm.pickUpAndReturn.isCanceled(),
              'has-text-danger': !_vm.pickUpAndReturn.validation,
              'has-text-blue': _vm.pickUpAndReturn.validation && !_vm.pickUpAndReturn.isDelivered(),
              'has-text-green': _vm.pickUpAndReturn.validation && _vm.pickUpAndReturn.isDelivered()
            }})]),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.pickUpAndReturn.transporter))])])])]),_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"media"},[_vm._m(1),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.pickUpAndReturn.articleQuantity)+" "+_vm._s(_vm.$t("articles-label"))+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas fa-user-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas fa-box-open"})])
}]

export { render, staticRenderFns }