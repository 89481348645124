<template>
  <div>
    <progress v-if="loadingRetrive" class="progress is-small is-primary" max="100">15%</progress>
    <TransferForm v-else
      v-model="demo"
      :loading="loading"
      :step="step"
      :originalLoan="originalLoan"
      :errorsData="errorsData"
      :transporters="transporterList"
      @validate-transfer="updateTransfer"
      @cancel="cancel">
    </TransferForm>
  </div>
</template>
<script>
import TransferForm from '@/apps/transfer/components/transferForm'
import apiClient from '@/client/client_logistics'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import updateFormMixin from '@/mixins/updateFormMixin.js'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'

import isEqual from 'lodash/isEqual'

export default {
  name: 'TransferUpdate',
  components: {
    TransferForm
  },
  props: {
    id: { type: Number,
     required: true,
    },
    idPickUpAndReturn: {
     type: Number,
     required: true,
    },
    step: {
     type: Number,
     required: true,
    },
    idDemo: {
     type: Number,
     required: true
    }
  },

  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin,
    updateFormMixin,
  ],

  data: function(){
    return {
      demo: {
        expedition: null,
        reprise: null,
      },
      originalDemo: {
        expedition: null,
        reprise: null,
      },
      loading: false,
      loadingRetrive: true,
      isExpeditionCreated: false,
      finalRoutePath: '/demo-module/validate/:id/:idPickUpAndReturn',
      errors: {
        errorsExpedition: null,
        errorsPickUp: null
      },
      originalLoan: new Expedition(),
      dataExpeditionSended: {},
      dataPickUpSended: {},
      transporterList: []
    }
  },

  computed: {
    errorsData() {
      const errorsData = {}
      if (this.errors.errorsExpedition === null) {
        errorsData['errorsExpedition'] = null
      } else {
        errorsData['errorsExpedition'] = {}
        Object.keys(this.errors.errorsExpedition).forEach(key => {
          // Special case for Entity
          if (this.demo.expedition[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataExpeditionSended[key]), new Entity(this.demo.expedition[key]))) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataExpeditionSended[key], this.demo.expedition[key])) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          }
        })
      }

      if (this.errors.errorsPickUp === null) {
        errorsData['errorsPickUp'] = null
      } else {
        errorsData['errorsPickUp'] = {}
        Object.keys(this.errors.errorsPickUp).forEach(key => {
          // Special case for Entity
          if (this.demo.reprise[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataPickUpSended[key]), new Entity(this.demo.reprise[key]))) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataPickUpSended[key], this.demo.reprise[key])) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          }
        })
      }
      return errorsData
    },
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveDemoAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
    user() {
      return this.$store.state.user
    }
  },
  created() {
    this.getTransporterList()
    // Retrieving expedition
    apiClient.getLoan(this.id).then(res => {
      const expedition = res.data
      const promises = []
      promises.push(this.getEnterprise(expedition.purchaser))
      if (expedition.sender !== null) {
        promises.push(this.getEnterprise(expedition.sender))
      }
      Promise.all(promises).then(datas => {
        this.demo.expedition = new Expedition(expedition)
        this.demo.expedition.purchaserEntity.enterprise = datas[0]
        delete this.demo.expedition.purchaserEntity.enterprise.entities

        this.originalDemo.expedition = new Expedition(expedition)
        this.originalDemo.expedition.purchaserEntity.enterprise = datas[0]
        delete this.originalDemo.expedition.purchaserEntity.enterprise.entities

        if (this.demo.expedition.sender !== null) {
          this.demo.expedition.senderEntity.enterprise = datas[1]
          delete this.demo.expedition.senderEntity.enterprise.entities

          this.originalDemo.expedition.senderEntity.enterprise = datas[1]
          delete this.originalDemo.expedition.senderEntity.enterprise.entities
        }

        if(this.demo.reprise !== null) {
          this.retrieveOriginalLoan()
          this.redirectIfContentCantBeUpdated()
          this.initTransporter()
        }
      }).catch((e) => this.trowErrorLoading(e))
    }).catch((e) => {
      this.trowErrorLoading(e)
    })
    // Retriveving PickUp
    apiClient.getPickUpAndReturn(this.idPickUpAndReturn).then(res => {
      const recovery = res.data
      this.getEnterprise(recovery.purchaser).then(data => {
        this.demo.reprise = new Expedition(recovery)
        this.demo.reprise.removalDate = recovery['removalDate']
        this.demo.reprise.purchaserEntity.enterprise = data
        delete this.demo.reprise.purchaserEntity.enterprise.entities

        this.originalDemo.reprise = new Expedition(recovery)
        this.originalDemo.reprise.removalDate = recovery['removalDate']
        this.originalDemo.reprise.purchaserEntity.enterprise = data
        delete this.originalDemo.reprise.purchaserEntity.enterprise.entities

        if(this.demo.expedition !== null) {
          this.retrieveOriginalLoan()
          this.redirectIfContentCantBeUpdated()
          this.initTransporter()
        }
      })
    }).catch((e) => {
      this.trowErrorLoading(e)
    })
  },

  methods: {
    retrieveOriginalLoan() {
      apiClient.getLoan(this.idDemo).then(result => {
        // Retrieve data from the original loan
        const originalLoan = new Expedition(result.data)
        this.originalLoan = originalLoan
        this.demo.expedition.purchaser = originalLoan.purchaser
        this.demo.expedition.purchaserEntity = originalLoan.purchaserEntity
        this.demo.expedition.sender = originalLoan.pickUp.sender
        this.demo.expedition.senderEntity = originalLoan.pickUp.senderEntity
        this.demo.expedition.content = originalLoan.content
        this.demo.reprise.purchaser = originalLoan.purchaser
        this.demo.reprise.purchaserEntity = originalLoan.purchaserEntity

        this.originalDemo.expedition.purchaser = originalLoan.purchaser
        this.originalDemo.expedition.purchaserEntity = originalLoan.purchaserEntity
        this.originalDemo.expedition.sender = originalLoan.sender
        this.originalDemo.expedition.senderEntity = originalLoan.senderEntity
        this.originalDemo.expedition.content = originalLoan.content
        this.originalDemo.reprise.purchaser = originalLoan.purchaser
        this.originalDemo.reprise.purchaserEntity = originalLoan.purchaserEntity

        this.loadingRetrive = false

      }).catch((e) => {
        let errorsMsg =  []
        Object.keys(e.response.data).forEach(key => {
          if (Array.isArray(e.response.data[key])) {
            e.response.data[key].forEach(error => {
              errorsMsg.push(error)
            })
          }
        })
        this.loading = false
        this.$store.dispatch (
          'openModal',
          new ModalData(
            {
              text: this.$t('error-title'),
              css: 'is-danger'
            },
            [{
                text: [this.$t('loading-error')].concat(errorsMsg),
            }],
            {
              cancel: {
                active: true,
                css: '',
                onClick: () => {
                  this.forceChangeRoute = true;
                  this.$router.push('/demo-module/')
                  this.$store.dispatch('closeModal');
                }
              }
            }
          )
        )
      })

    },
    getTransporterList(){
      apiClient.getListTransporters().then((response) => {
        this.transporterList = [...new Set(response.data.results)]
        if (this.demo.expedition !== null && this.demo.reprise !== null) {
          this.initTransporter()
        }
      }).catch(() => {
        this.trowErrorLoading()
      })
    },

    initTransporter() {
        if (this.transporterList.length !== 0) {
          const index = this.transporterList.findIndex(element => element.businessName === this.demo.expedition.transporter)
          if (index !== -1) {
            this.demo.expedition.transporterId = this.transporterList[index].id
            this.originalDemo.expedition.transporterId = this.transporterList[index].id
            this.demo.reprise.transporterId = this.transporterList[index].id
            this.originalDemo.reprise.transporterId = this.transporterList[index].id
          }
        }
    },
    redirectIfContentCantBeUpdated() {
      if(!this.demo.expedition.canBeUpdated() && !this.demo.reprise.canBeUpdated()) {
        this.forceChangeRoute = true;
        this.cannotEditError()
        return;
      }
    },
    async getEnterprise(enterpriseId){
      return apiClient.getEnterprise(enterpriseId).then((response) => {
        return response.data
      })
    },
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push(`/demo-module/list/${this.id}`)
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    async updateTransfer() {
      this.loading = true
      this.updateExpedition()
    },
    async updateExpedition() {
      if (!this.demo.expedition.canBeUpdated()) {
        this.updatePickUp()
      } else {

        if(this.demo.expedition.transporterId === null) {
          let transporter = {
            businessName: this.demo.expedition.transporter,
            contactsList: this.demo.expedition.transporterContacts
          }
          const [transporterResp, transporterError] = await handlePromise(
            this.createTransporter(transporter)
          )
          if (transporterError) { // createTransporter have an issue
            this.loading = false
            this.throwTransporterError()
            return // Stop here, block the rest of the creation
          } else {
            this.demo.expedition.transporterId = transporterResp.id
          }
        }

        const dataFromExpedition = this.demo.expedition.getFormData(this.user.isAdministrator)
        const dataToSendFromOriginalExpedition = this.originalDemo.expedition.getFormData(
          this.user.isAdministrator
        )

        const dataSended = this.getDifferenceWithOriginal(dataToSendFromOriginalExpedition, dataFromExpedition)
        // Nothing to update
        if (Object.keys(dataSended).length === 0) {
          this.updatePickUp()
          return
        }

        this.dataExpeditionSended = dataSended

        return apiClient.partialUpdateLoan(
          dataSended,
          this.demo.expedition.id
        ).then(() => {
          this.updatePickUp()
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsExpedition= e.response.data
            window.scrollTo(0, 0)
          } else {
            let errorsMsg =  []
            Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  errorsMsg.push(error)
                })
              }
            })
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-update-error')].concat(errorsMsg),
                    css: 'is-danger'
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }});
      }
    },
    async updatePickUp() {
      const dataFromPickup = this.demo.reprise.getFormData(this.user.isAdministrator)
      const dataToSendFromOriginalExpedition = this.originalDemo.reprise.getFormData(
        this.user.isAdministrator
      )
      const dataSended = this.getDifferenceWithOriginal(dataToSendFromOriginalExpedition, dataFromPickup)

      // Nothing to update
      if (Object.keys(dataSended).length === 0) {
        this.$router.push(`/demo-module/validate/${this.demo.expedition.id}/${this.demo.reprise.id}`)
        return
      }

      this.dataPickUpSended = dataSended

      return apiClient
        .updatePickUpAndReturn(dataSended, this.demo.reprise.id)
        .then((response) => {
          this.demo.reprise.pdfUrl = response.data.pdfUrl
          this.demo.reprise.reference = response.data.reference
          this.demo.reprise.id = response.data.id
          this.loading = false;
          this.$router.push(`/demo-module/validate/${this.demo.expedition.id}/${this.demo.reprise.id}`)
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsPickUp = e.response.data
            window.scrollTo(0, 0)
          }else{
            let errorsMsg =  []
            Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  errorsMsg.push(error)
                })
              }
            })
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('pick-up-and-return-update-error')].concat(errorsMsg),
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }});
    },
    trowErrorLoading(error) {
      if (error.response.status === 403) {
        this.accessDeniedError()
        return
      }

      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
            text: this.$t('data-receive-error'),
          }],
          {
            refresh: {
              active: true,
              css: 'is-danger',
              icon: 'fa-redo',
              onClick: () => {
                  this.$store.dispatch('closeModal')
                  this.$router.go()
              }
            },
            home: {
              active: true,
              css: 'is-info',
              icon: 'fa-home',
              onClick: () => {
                this.$store.dispatch('closeModal')
                this.$router.push('/')
              }
            }
          }
        )
      )
    },
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
