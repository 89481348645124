<template>
  <ValidationTemplate :validatedObject="validatedObject">
    <template v-slot:validation-content>
      <div class="box has-text-centered">
        <p>
          {{$t("validation-pickup-message")}}{{$t("colon")}}
          <br>
          <br>
          <strong class="is-size-1">{{validatedObject.reference}}</strong>
        </p>
        <br>
        <br>
        <button class="button is-link" @click="getResumePDF">{{$t("pdf-resume-button")}}</button>
      </div>
      <div class="box has-text-centered">
        <p>
          {{$t("redirection-pickup-message")}}
        </p>
        <br>
        <router-link :to="'/pick-up-and-return-module/list/' + validatedObject.id">
          <button class="button is-success is-medium">{{$t("ok-button")}}</button>
        </router-link>
      </div>
    </template>
  </ValidationTemplate>
</template>
<script>
import apiClient from "@/client/client_logistics";
import ValidationMixin from '@/mixins/validationMixin'
import ValidationTemplate from '@/components/templates/ValidationTemplate'
export default {
    name: "PickUpAndReturnValidation",
    mixins: [ValidationMixin],
    components: {ValidationTemplate},
    data() {
      return {
        errorKey: "pick-up-and-return-retrieve-error",
      }
    },
    methods: {
      async getValidatedObject() {
        return apiClient.getPickUpAndReturn(this.id)
      }
    }
}
</script>
