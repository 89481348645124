<template>
  <div v-if="expedition !== null && accessGranted">
    <ExpeditionForm v-if="!isLoadingSender && !isLoadingPurchaser"
      v-model="expedition"
      :step="step"
      :loading="loading"
      @cancel="cancel"
      @validate="updateExpedition"
      :errorsData="errorsData">
    </ExpeditionForm>
  </div>
</template>

<script>
import ExpeditionForm from '@/apps/expedition/components/ExpeditionForm'
import apiClient from '@/client/client_logistics'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
import isEqual from 'lodash/isEqual'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import updateFormMixin from '@/mixins/updateFormMixin.js'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'

export default {
  name: "ExpeditionUpdate",
  components: { ExpeditionForm },
  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin,
    updateFormMixin,
  ],
  props: {
    id: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    }
  },
  data: function(){
    return {
      isLoadingPurchaser: true,
      isLoadingSender: true,
      loading: false,
      error: false,
      finalRoutePath: '/expedition-module/validate/:id',
      expedition: {},
      originalExpedition: {}, // kind of backup to send only modified field to back
      errors: null,
      dataSended: {},
    }
  },
  created(){
    this.getExpedition()
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveExpeditionAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
    errorsData() {
      if (this.errors === null) return this.errors
      const errorsData = {}
      Object.keys(this.errors).forEach(key => {
        // Special case for Entity
        if (this.expedition[key] instanceof Entity) {
          // Recreate Entity with data to avoid extra data added by vue
          // then compare the two "new" entities to have a correct comparaison
          if (isEqual(new Entity(this.dataSended[key]), new Entity(this.expedition[key]))) {
            errorsData[key] = this.errors[key]
          }
        }
        // Change each time expedition error field is changed
        else {
          if (isEqual(this.dataSended[key], this.expedition[key])) {
            errorsData[key] = this.errors[key]
          }
        }
      })
      return errorsData
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/expedition-module/list/' + this.id)
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    getExpedition(){
      apiClient.getExpedition(this.id).then((response) => {
        this.expedition = new Expedition(response.data)
        this.originalExpedition = new Expedition(response.data)
        // 48h after the deliveryDate: We can't edit anymore
        if(!this.expedition.canBeUpdated() && this.accessGranted) {
          this.forceChangeRoute = true;
          this.cannotEditError(this.expedition)
          return;
        }
        this.getPurchaserName(this.expedition.purchaser)
        this.getSenderName(this.expedition.sender)
      }).catch(() => {
          this.$store.dispatch (
              'openModal',
              new ModalData(
              {
                text: this.$t('error-title'),
                css: 'is-danger'
              },
              [{
                  text: this.$t('data-receive-error'),
              }],
              {
                refresh: {
                  active: true,
                  css: 'is-danger',
                  icon: 'fa-redo',
                  onClick: () => {
                    this.$router.go()
                    this.$store.dispatch('closeModal');
                  }
                },
                home: {
                  active: true,
                  css: 'is-success',
                  icon: 'fa-home',
                  onClick: () => {
                    this.$router.push('/')
                    this.$store.dispatch('closeModal');
                  }
                },
              }
            )
          )
      })
    },
    getPurchaserName(purchaserId){
      apiClient.getEnterprise(purchaserId).then((response) => {
        this.expedition.purchaserEntity.entityName = this.expedition.purchaserEntity.address.name
        this.expedition.purchaserEntity.enterprise = response.data
        delete this.expedition.purchaserEntity.enterprise.entities

        this.originalExpedition.purchaserEntity.entityName = this.originalExpedition.purchaserEntity.address.name
        this.originalExpedition.purchaserEntity.enterprise = response.data
        delete this.originalExpedition.purchaserEntity.enterprise.entities

        this.isLoadingPurchaser = false;
      })
    },
    getSenderName(senderId){
      apiClient.getEnterprise(senderId).then((response) => {
        this.expedition.senderEntity.entityName = this.expedition.senderEntity.address.name
        this.expedition.senderEntity.enterprise = response.data
        delete this.expedition.senderEntity.enterprise.entities

        this.originalExpedition.senderEntity.entityName = this.originalExpedition.senderEntity.address.name
        this.originalExpedition.senderEntity.enterprise = response.data
        delete this.originalExpedition.senderEntity.enterprise.entities

        this.isLoadingSender = false;
      })
    },
    async updateExpedition(){
      this.loading = true
      if(this.expedition.transporterId === null){
        let transporter = {
          businessName: this.expedition.transporter,
          contactsList: this.expedition.transporterContacts
        }
        const [transporterResp, transporterError] = await handlePromise(
          this.createTransporter(transporter)
        )
        if (transporterError) { // createTransporter have an issue
          this.loading = false
          this.throwTransporterError()
          return // Stop here, block the rest of the creation
        } else {
          this.expedition.transporterId = transporterResp.id
        }
      }

      // This is ugly, transporter should be a FK on the model to avoid this
      if(this.expedition.transporter === this.originalExpedition.transporter) {
        this.originalExpedition.transporterId = this.expedition.transporterId;
      }

      if(this.expedition.purchaserEntity.id === this.originalExpedition.purchaserEntity.id) {
        this.originalExpedition.purchaserEntity.entityName = this.expedition.purchaserEntity.entityName
      }

      if(this.expedition.senderEntity.id === this.originalExpedition.senderEntity.id) {
        this.originalExpedition.senderEntity.entityName = this.expedition.senderEntity.entityName
      }

      this.errors = null
      const dataToSend = this.expedition.getFormData(this.user.isAdministrator)
      const dataToSendFromOriginalExpedition = this.originalExpedition.getFormData(this.user.isAdministrator)
      this.dataSended = this.getDifferenceWithOriginal(dataToSendFromOriginalExpedition, dataToSend)

      // Nothing to update
      if (Object.keys(this.dataSended).length === 0) {
        this.$router.push(`/expedition-module/validate/${this.expedition.id}`)
        return
      }
      return apiClient.partialUpdateExpedition(this.dataSended, this.expedition.id).then((response) => {
          this.expedition.pdfUrl=response.data.pdfUrl;
          this.expedition.reference=response.data.reference;
          this.$router.push(`/expedition-module/validate/${this.expedition.id}`)
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        })
    },
  }
}

</script>
