<template>
  <div class="container">
    <SectionTitle :title="$t(title)" imgName="fas fa-clock"></SectionTitle>
    <div v-if="toggleTimePreferences" class="field">
      <label class="checkbox">
        <input
          id="haveTimePreferences"
          :class="{'is-danger': errorOnValue('haveTimePreferences')}"
          :checked="value.haveTimePreferences"
          @change="update('haveTimePreferences', !value.haveTimePreferences)"
          type="checkbox"
          :disabled="autoComplete">
         {{$t("indifferent")}}
      </label>
      <p
        v-if="errorOnValue('haveTimePreferences')"
        class="help is-danger">
        {{errorsData.haveTimePreferences[0]}}
      </p>
    </div>
    <span v-if="toggleTimePreferences && !value.haveTimePreferences" class="separator"></span>
    <div v-if="!value.haveTimePreferences">
      <div class="columns schedules">
        <div class="column is-4 field">
          <label class="checkbox">
            <input
              id="haveAmSchedule"
              type="checkbox"
              :class="{'is-danger': errorOnValue('haveAmSchedule')}"
              :checked="value.haveAmSchedule"
              @change="update('haveAmSchedule', !value.haveAmSchedule)"
              :disabled="autoComplete">
              {{$t("morning")}}
          </label>
          <p
            v-if="errorOnValue('haveAmSchedule')"
            class="help is-danger">
            {{errorsData.haveAmSchedule[0]}}
          </p>
        </div>
        <div class="column field">
          <input
            id="amOpenStart"
            class="input"
            :class="{'is-danger': errorOnValue('amOpenStart')}"
            type="time"
            :value="value.amOpenStart"
            :disabled="!value.haveAmSchedule || autoComplete"
            @change="update('amOpenStart', $event.target.value)">
          <p
            v-if="errorOnValue('amOpenStart')"
            class="help is-danger">
            {{errorsData.amOpenStart[0]}}
          </p>
        </div>
        <div class="column field">
          <input
            id="amOpenEnd"
            class="input"
            :class="{'is-danger': errorOnValue('amOpenEnd')}"
            type="time"
            :value="value.amOpenEnd"
            :disabled="!value.haveAmSchedule || autoComplete"
            @change="update('amOpenEnd', $event.target.value)">
          <p
            v-if="errorOnValue('amOpenEnd')"
            class="help is-danger">
            {{errorsData.amOpenEnd[0]}}
          </p>
        </div>
      </div>
      <div class="columns schedules">
        <div class="column is-4 field">
          <label class="checkbox">
            <input
              id="havePmSchedule"
              type="checkbox"
              :checked="value.havePmSchedule"
              :class="{'is-danger': errorOnValue('havePmSchedule')}"
              :disabled="autoComplete"
              @change="update('havePmSchedule', !value.havePmSchedule)">
              {{$t("afternoon")}}
          </label>
          <p
            v-if="errorOnValue('havePmSchedule')"
            class="help is-danger">
            {{errorsData.havePmSchedule[0]}}
          </p>
        </div>
        <div class="column field">
          <input
            id="pmOpenStart"
            class="input"
            :class="{'is-danger': errorOnValue('pmOpenStart')}"
            type="time"
            :value="value.pmOpenStart"
            :disabled="!value.havePmSchedule || autoComplete"
            @change="update('pmOpenStart', $event.target.value)">
          <p
            v-if="errorOnValue('pmOpenStart')"
            class="help is-danger">
            {{errorsData.pmOpenStart[0]}}
          </p>
        </div>
        <div class="column field">
          <input
            id="pmOpenEnd"
            class="input"
            :class="{'is-danger': errorOnValue('pmOpenEnd')}"
            type="time"
            :value="value.pmOpenEnd"
            :disabled="!value.havePmSchedule || autoComplete"
            @change="update('pmOpenEnd', $event.target.value)">
          <p
            v-if="errorOnValue('pmOpenEnd')"
            class="help is-danger">
            {{errorsData.pmOpenEnd[0]}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SectionTitle from '@/components/SectionTitle'
import vModelMixin from '@/mixins/vModelMixin'

export default {
  name: 'SchedulesField',
  components: {
    SectionTitle
  },
  mixins: [vModelMixin],
  props: {
    toggleTimePreferences: {
      type: Boolean,
    },
    autoComplete: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: 'schedule-title'
    },
    errorsData: {
      type: Object
    }
  },
  methods: {
    errorOnValue(nameField){
      if(this.errorsData && this.errorsData[nameField]){
        return true
      }
      return false
    }
  },

  watch: {
    'value.haveTimePreferences'(timePreference) {
      if (timePreference) {
        this.updateFromObject({
            haveAmSchedule: false,
            amOpenStart: '',
            amOpenEnd: '',
            havePmSchedule: false,
            pmOpenStart: '',
            pmOpenEnd: '',
        })
      }
    },
    'value.haveAmSchedule'(haveAmSchedule) {
      if (haveAmSchedule === false) {
        this.updateFromObject({
            amOpenStart: '',
            amOpenEnd: '',
        })
      }
    },
    'value.havePmSchedule'(havePmSchedule) {
      if (havePmSchedule === false) {
        this.updateFromObject({
            pmOpenStart: '',
            pmOpenEnd: '',
        })
      }
    }
  }
}
</script>

<style>

  .schedules{
    overflow: auto;
  }

</style>
