<template>
  <section class="hero is-light is-fullheight has-bg-img">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <div class="column is-two-thirds-desktop is-full-mobile">
              <img class="logo-login" src="@/assets/logo_logistics.png" align="left">
          </div>
          <div class="column is-one-third-desktop is-full-mobile">
            <form @submit.prevent="authenticate" class="box">
              <div class="container has-text-centered">
                <figure class="image is-48x48 is-inline-block">
                  <img src="@/assets/icons/authentication_icon.png">
                </figure>
                <h1 class="title"> {{$t('identification-title')}}</h1>
              </div>
              <transition name="fade">
              <template v-if="error">
                <div class="message is-danger">
                  <div class="message-header">
                    {{$t('connexion-error-title')}}
                  </div>
                  <div class="message-body">
                    {{$t('invalid-credential-sentence')}}
                  </div>
                </div>
              </template>
              </transition>
              <div class="field">
                <label class="label" for="username"> {{$t("username-label")}} </label>
                <div class="control has-icons-left">
                  <input type="text" :placeholder="$t('username-label')" class="input" v-model="username" required>
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label" for="password"> {{$t("password-label")}} </label>
                <div class="control has-icons-left">
                  <input type="password" placeholder="*******" class="input" v-model="password" required>
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <div class="field has-text-centered">
                <button class="button is-success is-fullwidth" :disabled="loading">
                  {{$t("login-button")}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-login is-right">
        © Copyright KOMAKS-FRANCE {{getCurrentYears()}}
    </div>
  </section>
</template>
<script>
import apiClient from '@/client/client_logistics';
import { mapActions } from 'vuex'
//import store from '@/store';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      error: false,
    };
  },
  methods: {
    ...mapActions({
      getEntitiesCollection: 'commonData/getAllEntitiesCollection',
      getExpeditionEntitiesCollection: 'commonData/getExpeditionEntitiesCollection'
    }),
    async authenticate() {
      this.loading = true;
      return apiClient
        .authenticate(this.username, this.password)
        .then(() => {
          this.$emit('login')
          this.getEntitiesCollection()
          this.getExpeditionEntitiesCollection()
          this.loading = false
          this.error = false
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    getCurrentYears() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.has-bg-img {
  background-image: url(../assets/BG.jpg);
}
.footer-login {
  text-align: right;
  color: white;
  margin-bottom: 10%;
  margin-right: 10%;
}


</style>
