<template>
  <ValidationTemplate :validatedObject="validatedObject">
    <template v-slot:validation-content>
      <div class="notification is-danger" v-if="validatedObject.expedition.transfers.length > 0">
        {{$t("update-check-needed-for-transfers-sentence")}}
      </div>
      <div class="box has-text-centered" v-if="validatedObject !== null">
        <p>{{$t("validation-message")}}{{$t("colon")}}
        <br>
        <br>
        <strong class="is-size-1">{{validatedObject.expedition.reference}}</strong></p>
        <br>
        <br>
        <button class="button is-link" @click="getResumePDF(validatedObject.expedition.reference)">
          {{$t("pdf-resume-button")}}
        </button>
      </div>

      <div class="box has-text-centered" v-if="validatedObject !== null">
        <p>
           {{$t("validation-pickup-message")}}{{$t("colon")}}
          <br>
          <br>
          <strong class="is-size-1">{{validatedObject.pickUpAndReturn.reference}}</strong>
        </p>
        <br>
        <br>
        <button class="button is-link" @click="getResumePDF(validatedObject.pickUpAndReturn.reference)">
         {{$t("pdf-resume-button")}}
         </button>
      </div>

      <div class="box has-text-centered" v-if="validatedObject !== null">
        <p>
          {{$t("redirection-demo-message")}}
        </p>
        <br>
        <router-link :to="`/demo-module/list/${validatedObject.expedition.id}`">
          <button class="button is-success is-medium">{{$t("ok-button")}}</button>
        </router-link>
      </div>
    </template>
  </ValidationTemplate>
</template>
<script>
import apiClient from "@/client/client_logistics"
import { Expedition } from "@/models/expedition"
import {downloadPdf} from  '@/utils/helpers'
import ValidationMixin from '@/mixins/validationMixin'
import ValidationTemplate from '@/components/templates/ValidationTemplate'
export default {
    name: "DemoValidation",
    mixins: [ValidationMixin],
    components: {ValidationTemplate},
    props: {
        idPickUpAndReturn: {
            type: String,
            required: true
        }
    },
    data() {
      return {
        errorKey: "demo-retrieve-error",
      }
    },
    methods: {
      async getValidatedObject() {
        const validatedObject = {}
        return await Promise.all([
            apiClient.getLoan(this.id).then(response => validatedObject['expedition'] = new Expedition(response.data)),
            apiClient.getPickUpAndReturn(this.idPickUpAndReturn).then(response => validatedObject['pickUpAndReturn'] = response.data)
        ]).then(() => { return {data:validatedObject}})
      },
      getResumePDF (reference) {
        apiClient.getResumePDF(reference).then(
          response => {
            downloadPdf(
              response.request.response,
              `resume_expedition_${reference}.pdf`,
              'application/pdf'
            )
          })
      },
    }
}
</script>
