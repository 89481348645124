<template>
    <div class="card resume-card">
      <div class="card-header">
        <h2 class="card-header-title">
          {{step}}. {{$t(title)}}
        </h2>
        <slot name="details-update">
          <router-link v-if="updateCondition" :to="getUrl()">
            <button class="router-button button is-small is-text">
              <span class="icon">
                <i class="fas fa-edit"></i>
              </span>
              <span>{{$t("update-button")}}</span>
            </button>
          </router-link>
        </slot>
      </div>
      <div class="card-content">
        <slot name="details-content">
        </slot>
      </div>
    </div>
</template>
<script>
export default {
  name: 'detailsStepTemplate',
  props: {
    step: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    getUrl: {
      type: Function,
      required: true,
    },
    updateCondition: {
      type: Boolean,
      required: true,
    }
  }
}
</script>
