<template>
  <ListingTemplate
    v-if="accessGranted"
    :activeKey="filterValue"
    :list="list"
    :loading="loading"
    :title="'demos-tracking-title'"
    :currentChoice="currentChoice"
    @set-filter="(val) => setFilter(val)"
    @select="(expedition) => setCurrentChoice(expedition)"
    @unselect="unselect"
    @listing-search="(newSearchValue) => search = newSearchValue">
    <template v-slot:details>
      <DemoDetails
        :expedition="currentChoice"
        :key="currentChoice.id">
      </DemoDetails>
    </template>
  </ListingTemplate>
</template>

<script>
import {ModalData} from '@/models/modal'
import expeditionListMixin from '@/mixins/expeditionListMixin'
import ListingTemplate from '@/components/templates/ListingTemplate'
import DemoDetails from '@/apps/demo/demo/details'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
export default {
  name: 'DemoList',
  mixins: [expeditionListMixin, deniedAccessMixin],
  components: { ListingTemplate, DemoDetails },
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      collectionStorePath: "demoModule/demos"
    }
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.haveDemoAccess){
          return true
        }else{
          this.accessDeniedError()
          return false
        }
      }
    },
  },
  mounted () {
    if(this.user !== null && this.accessGranted) this.get()
  },
  methods: {
    setCurrentChoice(choice){
      if(choice.id !== this.id){
        this.$router.push({ path: `/demo-module/list/${choice.id}`})
      }
    },
    async get(){
      this.$store.dispatch('demoModule/getDemos')
      .catch(() => {
        this.$store.dispatch (
          'openModal',
          new ModalData(
            {
              text: this.$t('error-title'),
              css: 'is-danger'
            },
            [{
              text: this.$t('expeditions-retrieve-error'),
            }],
            {
              refresh: {
                active: true,
                css: 'is-danger',
                icon: 'fa-redo',
                onClick: () => {
                  this.$router.go()
                  this.$store.dispatch('closeModal');
                }
              },
              home: {
                active: true,
                css: 'is-info',
                icon: 'fa-home',
                onClick: () => {
                  this.$router.push('/')
                  this.$store.dispatch('closeModal');
                }
              },
            }
          )
        )
      });
    }
  },
}
</script>
