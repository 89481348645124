import { render, staticRenderFns } from "./ContentField.vue?vue&type=template&id=53ea6bb7&scoped=true"
import script from "./ContentField.vue?vue&type=script&lang=js"
export * from "./ContentField.vue?vue&type=script&lang=js"
import style0 from "./ContentField.vue?vue&type=style&index=0&id=53ea6bb7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ea6bb7",
  null
  
)

export default component.exports