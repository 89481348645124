<template>
  <div>
    <section class="section" v-if="!entityListLoading">
      <div class="container">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="columns is-vcentered">
              <div class="column is-1 has-text-primary">
                  <i class="fas fa-arrow-left fa-2x" v-if="currentSteps.value > 0" @click="previousStep"></i>
              </div>
              <div class="column is-10">
                <multiselect
                  class="has-text-primary has-text-weight-bold"
                  v-model="currentSteps"
                  :options="stepsAccess"
                  :searchable="false"
                  :allow-empty="false"
                  track-by="name"
                  label="name"
                >
                </multiselect>
              </div>
              <div class="column is-1 has-text-primary">
                <i class="fas fa-arrow-right fa-2x" v-if="currentSteps.value < validateStep" @click="nextStep"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="displayError && lastStepValid.errors[currentSteps.value].length > 0" class="notification is-danger">
          <span v-for="(error, index) in lastStepValid.errors[currentSteps.value]" :key="index">
            {{error}}
          </span>
        </div>

        <DemoStepsForm
          :value="value"
          :loading="loading"
          :currentSteps="currentSteps"
          :transporterList="transporters"
          :contentAvailable="contentAvailable"
          :enterpriseList="enterpriseList"
          :entityList="entityList"
          :entityListLoading="entityListLoading"
          :allArticles="allArticles"
          :allConsumables="allConsumables"
          :catalogue="catalogue"
          :errorsData="errorsData"
          @cancel="$emit('cancel')"
          @steps-finish="validationFunction"
          @next-step-expedition-demo="nextStep"
          @change-step="changeStep"
          @error-loading="trowErrorLoading"
          @next-step="nextStep"
          @previous-step="previousStep"
          @validate-demo="$emit('validate-demo')"
          @input="updateFromObject($event)">
        </DemoStepsForm>
      </div>
    </section>
    <section v-else class="section">
      <div class="container">
          <div class="loader-wrapper">
              <div class="loader is-loading"></div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import expeditionFormMixin from '@/mixins/expeditionFormMixin'
import vModelMixin from '@/mixins/vModelMixin'
import {checkAddress, checkContacts, deepCopy} from '@/utils/helpers'
import DemoStepsForm from '@/apps/demo/components/DemoStepsForm'
import apiClient from '@/client/client_logistics'

export default {
  name: "DemoForm",
  mixins: [expeditionFormMixin, vModelMixin],
  components: {
    DemoStepsForm
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    errorsData: {
      type: Object
    },
    transporters: {
      type: Array
    }
  },
  data() {
    return {
      steps: [
        // Expedition
        { name: this.$t("purchaser-title"), value: 0, $isDisabled: false},
        { name: this.$t("removal-title"), value: 1, $isDisabled: true},
        { name: this.$t("transporter-title"), value: 2, $isDisabled: true},
        { name: this.$t("delivery-title"), value: 3, $isDisabled: true},
        { name: this.$t("content-title"), value: 4, $isDisabled: true},

        // Reprise
        { name: this.$t("pick-up-and-return-delivery-date-title"), value: 5, $isDisabled: true},
        { name: this.$t("pick-up-and-return-delivery-title"), value: 6, $isDisabled: true},

        // Global resume
        { name: this.$t("resume-title"), value: 7, $isDisabled: true},
      ],
      allArticles: [],
      allConsumables: [],
      catalogue: [],
      storageTypes: {},
      komaksEntities: []
    }
  },

  beforeMount(){
    this.currentSteps = this.steps[0];
    this.getEnterpriseList()
    this.getKomaksProductCatalogue()
    this.getKomaksMedicalConsumableCatalogue()
    if (!this.entityListLoading) {
      this.initDataAfterReceiveEntityList()
    }

    if(this.step !== undefined){
      for(let i=0; i<this.step; i++){
        this.nextStep();
      }
    } else{
      this.update({
        'storageType': "BATCH"
      })
    }
  },

  computed: {
    lastStepValid () {
      // Each time we valid a step, lastStepValid is increased by one
      let value = 0 // Value of the last step valid to validate
      const errors = {} // All errors
      for (let i = 0; i < this.steps.length; i++) {
        errors[i] = []
      }

      // STEP 1 purchaser
      if(this.value.expedition.purchaserEntity.id === undefined || this.value.expedition.purchaserEntity.id === -1) {
        errors[value].push(this.$t('purchaser-entity-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.purchaserEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 2 sender
      const receiverSenderAddressErrors = checkAddress(
        this.value.expedition.senderEntity.address
      )
      if(receiverSenderAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.senderEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 3 transporter
      if(this.value.expedition.transporter === null ||
        this.value.transporter === "") {
        errors[value].push(this.$t('transporter-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.transporterContacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 4
      const receiverRecipientAddressErrors = checkAddress(
        this.value.expedition.recipientEntity.address
      )
      if(receiverRecipientAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.expedition.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.expedition.deliveryDate === null || this.value.expedition.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }
      value += 1

      // STEP 5
      if(this.value.expedition.content.length === 0) {
        errors[value].push(this.$t('content-empty'))
        return {value, errors}
      }

      value += 1
      if(this.value.reprise.removalDate === null || this.value.reprise.removalDate === "" || this.value.reprise.removalDate === undefined){
        errors[value].push(this.$t('pick-up-and-return-date-desired-label'))
        return {value, errors}
      }

      if(new Date(this.value.reprise.removalDate).getTime() < new Date(this.value.expedition.deliveryDate).getTime()){
        errors[value].push(this.$t('pick-up-removal-date-must-be-after-expedition-delivery-date'))
        return {value, errors}
      }
      value += 1

      const receiverPickUpSenderAddressErrors = checkAddress(
        this.value.reprise.recipientEntity.address
      )
      if(receiverPickUpSenderAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.reprise.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.reprise.deliveryDate === null || this.value.reprise.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }
      if(new Date(this.value.reprise.deliveryDate).getTime() < new Date(this.value.reprise.removalDate)){
        errors[value].push(this.$t('delivery-date-must-be-after-removal-date'))
        return {value, errors}
      }

      // STEP 6 to 8 nothing to do
      value += 2
      return {value, errors}
    },
  },
  methods: {
    initDataAfterReceiveEntityList() {
      if (this.entityList.length == 1) {
        this.value.expedition.senderEntity = deepCopy(this.entityList[0]);
        this.value.expedition.sender = this.entityList[0].enterprise.id
      }

      // get demo stock from komaks entities
      const komaksEntities = this.entityList.filter(
        entity => entity.enterprise !== null && entity.enterprise.isKomaks
      )
      this.getStockDemo(komaksEntities)
    },
    async getStockDemo(komaksEntities){
      apiClient.getStocks(null, komaksEntities, false, true).then((response) => {
        this.allArticles = response.data
      }).catch(() => {
        this.trowErrorLoading()
      })
    },
    async getKomaksProductCatalogue(){
      this.catalogue = []
      apiClient.getKomaksCatalogue().then((response) => {
        this.catalogueAsObjectList(this.catalogue, response)
      }).catch(() => {
          this.$emit('error-loading')
      })
    },
    async getKomaksMedicalConsumableCatalogue(){
      this.allConsumables = []
      apiClient.getKomaksCatalogue({
        categories: 'MEDICAL CONSUMABLES'
      }).then((response) => {
        this.catalogueAsObjectList(this.allConsumables, response)
      }).catch(() => {
          this.$emit('error-loading')
      })
    },

    // Format product in objects instead of string (in expeditionFormMixin)
    catalogueAsObjectList(arrayToStoreData, response){
      const heads = ['brand', 'series', 'model', 'reference', 'oem-code']
      for(let j in response.data){
        let item = Object.assign({}, response.data[j])
        item['id'] = response.data[j]['id']
        item['SEARCH'] = response.data[j]['id']
        item['quantity'] = 1
        item['serialNumber'] = ''
        item['purchaseReference'] = ''
        item['denomination'] = ''
        heads.forEach(head => {
          if (response.data[j][head] === '' || response.data[j][head] ===  undefined) return
          item['SEARCH'] += response.data[j][head]
          if (item['denomination'] === ''){
            item['denomination'] = response.data[j][head]
          } else {
            item['denomination'] += (' - ' + response.data[j][head])
          }
        })
        arrayToStoreData.push(item)
      }
    },
    setPurchaserValue(entity) {
      this.value.expedition.purchaserEntity = entity;
      this.value.expedition.purchaser = entity.enterprise.id
    }
  },
  watch:{
    errorsData: {
      handler: function(val){
          if(val !== null){
            if(val.errorsExpedition!== null){
              let errorsExpedition = val.errorsExpedition
              if(errorsExpedition.purchaserEntity !== undefined || errorsExpedition.purchaser !== undefined){
                this.changeStep(0)
              }else if(errorsExpedition.senderEntity !== undefined || errorsExpedition.sender !== undefined || errorsExpedition.removalDate !== undefined){
                this.changeStep(1)
              }else if(errorsExpedition.transporter !== undefined && errorsExpedition.transporterContacts !== undefined){
                this.changeStep(2)
              }else if(errorsExpedition.recipient !== undefined || errorsExpedition.recipientEntity !== undefined || errorsExpedition.deliveryDate !== undefined){
                this.changeStep(3)
              }else if(errorsExpedition.content !== undefined){
                this.changeStep(4)
              }
            }else if(val.errorsPickUp!=null){
              let errorsPickUp = val.errorsPickUp
              if(errorsPickUp.removalDate){
                this.changeStep(5)
              }
              if(errorsPickUp.recipientEntity || errorsPickUp.deliveryDate){
                this.changeStep(6)
              }else if(errorsPickUp.recipient !== undefined || errorsPickUp.recipientEntity !== undefined || errorsPickUp.deliveryDate !== undefined){
                this.changeStep(7)
              }
            }

          }
      },
      deep: true
    },
    entityList() {
      this.initDataAfterReceiveEntityList()
    },
  }
}
</script>
