import TransferCreate from '@/apps/transfer/transfer/create'
import TransferUpdate from '@/apps/transfer/transfer/update'

const basePath = '/transfer-module/'

const routes = [
  {
    path: basePath + 'create/:idDemo',
    component: TransferCreate,
    props(route) {
      const props = { ...route.params }
      props.idDemo = +props.idDemo
      return props
    },
  },
  {
    path: basePath +  'update/:id/:idPickUpAndReturn/:idDemo/:step/',
    component: TransferUpdate,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      props.idPickUpAndReturn = +props.idPickUpAndReturn
      props.idDemo = +props.idDemo
      props.step = +props.step
      return props
    },
  },
];

export default routes;
