<template>
  <div class="filter-menu-stock" :style="displayPosition" :class="{reversed: reversedMenu}">
    <section class="menu-item" @click.stop>
      <div class="wrap-filter m-t-md" v-if="canBeFiltred()">
        <input type="text" v-model="search" @keyup.enter="sendFilter">
        <p>
          <a class="m-xxs" @click="orderByName('asc')">{{ $t('ascending-label') }} &uarr;</a>
          <a class="m-xxs" @click="orderByName('desc')">{{ $t('descending-label') }} &darr;</a>
        </p>
        <p class="filter-menu-stock-selects-buttons">
          <a class="button is-success m-xxs p-xxs" @click="selectAll">{{ $t('select-all-button') }}</a>
          <a class="button is-success m-xxs p-xxs" @click="unSelectAll">{{ $t('unselect-all-button') }}</a>
        </p>
        <p>
          <a class="button is-success m-xxs p-xxs" @click="sendFilter">{{ $t('validate-button') }}</a>
        </p>
      </div>
      <RecycleScroller v-if="canBeFiltred()" :items="filtredValues" class="scroller-filter" :item-size="30" key-field="id">
        <!-- Headers -->
        <div class="element" slot-scope="props">
          <template>
            <label class="checkbox" :for="props.item">
              <input class="checkbox" type="checkbox" :id="props.item" :value="props.item" v-model="selectedValues">
              <span v-if="props.item && props.item !== NULL_VARIABLE_NAME">{{ formatQrcode(props.item) }}</span>
              <span v-else>∅</span>
            </label>
          </template>
        </div>
      </RecycleScroller>
    </section>
  </div>
</template>
<script>
import { RecycleScroller } from 'vue-virtual-scroller'
/*
  Small menu in stock wich appear when user click on column's header
  - This component call some Stock's method transfered to this component's by this props (updateFilter ...)
 */
const MENU_SIZE_IN_PX = 350
const NULL_VARIABLE_NAME = "__null__variable__"
export default {
  name: "FilterMenu",
  components: { RecycleScroller },
  props: [
    "gridContainer", "header", "values", "filters",
    "updateFilter", "removeFilter", "orderBy",
  ],
  data() {
    return {
      search: "",
      selectedHeader: '',
      selectedValues: [],
      filtredValues: [],
      notFiltredHeaders: ['total_article', 'conceived_for', 'related_articles'],
      filter: {},
      // Display
      displayPosition: {}, // computed position
      reversedMenu: false, // Is the component out of screen when it appear?
      NULL_VARIABLE_NAME
    };
  },
  created() {
    this.init()
  },
  watch: {
    header() {
      this.selectedHeader = ''
      this.init()
    },
    search (value) {
      this.search = value.toLowerCase()
      this.filtredValues = this.values[this.header].filter(val => val.toLowerCase().indexOf(this.search) !== -1)
      this.selectedValues = this.filtredValues
    }
  },
  computed: {},
  methods: {
    init () {
      this.displayMenuToCorrectLocation(this.gridContainer)
        if (this.selectedHeader === '') {
          this.selectedHeader = this.header
        }
        this.filter = this.filters[this.selectedHeader]
        if (this.filter && this.filter.filter.length > 0) {
          this.setSelectedValues(this.filter.filter)
        } else {
          this.selectedValues = this.values[this.selectedHeader]
        }

        this.filtredValues = this.values[this.selectedHeader]
    },
    selectHeader(header) {
      this.selectedHeader = header
      this.init()
    },
    orderByName(order) {
      this.orderBy(this.selectedHeader, order)
    },
    setSelectedValues (values) {
      this.selectedValues = values.map(filtValue => {
          if (filtValue === '') {
            return NULL_VARIABLE_NAME
          }
          return filtValue
      })
    },
    canBeFiltred () {
      return true
    },
    /* Display the menu correctly
     - If the menu will go out the screen : display in other way */
    getScreenCordinates(obj) {
        var p = {};
        p.x = obj.offsetLeft;
        p.y = obj.offsetTop;
        while (obj.offsetParent) {
            p.x = p.x + obj.offsetParent.offsetLeft;
            p.y = p.y + obj.offsetParent.offsetTop;
            if (obj == document.getElementsByTagName("body")[0]) {
                break;
            }
            else {
                obj = obj.offsetParent;
            }
        }
        return p;
    },
    displayMenuToCorrectLocation(currentColumnHeader) {
      // Get the header div element
      let el = currentColumnHeader
      // Sometimes, the text element is catch, we want the div container
      if (el.classList === undefined || (!currentColumnHeader.offsetLeft && !el.classList.contains("header"))) {
        el = currentColumnHeader.parentElement
      }
      const gc = document.getElementById("gc")
      const stock = document.getElementById("stock")

      const boudingClientRect = el.parentElement.getBoundingClientRect()
      const pos = this.getScreenCordinates(el.parentElement)
      let left = pos.x + Math.floor(boudingClientRect.width)
      this.reversedMenu = left + MENU_SIZE_IN_PX > window.screen.availWidth
      if (this.reversedMenu) {
        // 2px more to allow the arrow to touch the header it's just aesthetic
        left = boudingClientRect.x - MENU_SIZE_IN_PX + 2
      }

      this.displayPosition = {
        left: left - stock.offsetLeft + "px",
        top: gc.offsetTop + "px"
      }
    },

    selectAll() {
      this.selectedValues = this.values[this.selectedHeader]
    },
    unSelectAll() {
      this.selectedValues = []
    },
    sendFilter() {
      if (this.selectedValues.length !== 0) {
        const allValuesSelected = this.values[this.selectedHeader].length === this.selectedValues.length
        if (allValuesSelected && this.filter.filter.length !== 0) {
          this.removeFilter(this.selectedHeader)
        } else if(!allValuesSelected) {
          const indexNullValue = this.selectedValues.indexOf(NULL_VARIABLE_NAME)
          const selected = this.selectedValues
          if (indexNullValue !== -1) {
            selected[indexNullValue] = ''
          }
          this.updateFilter(this.selectedHeader, this.selectedValues)
        }
      } else {
        alert(this.$t("select-at-least-one-value-sentence"))
      }
    },
    formatQrcode (qrcode) {
      if (this.selectedHeader !== "qrcode" || qrcode.length < 10) {
          return qrcode
      }
      return qrcode.slice(0, 3) + " " + qrcode.slice(3, 7) + " " + qrcode.slice(7)
    }
  }
};
</script>
<style lang="scss" scoped>
.element {
  padding-left: 8px;
  width: 100%;
  font-size: 10px;
  font-weight: normal;
  border-top: solid 1px #bfbfbf;
  min-height: 30px;
  overflow: hidden;
  vertical-align: baseline;
  position: absolute;
}
.scroller-filter {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 200px;
}
.scroller-filter .vue-recycle-scroller__item-wrapper {
  width: 100%;
}
.scroller-filter .vue-recycle-scroller__item-view {
  max-height: 30px;
  min-height: 30px;
  overflow: hidden;
}

.filter-menu-stock {
  font-style: 10px !important;
  position: absolute;
}
.filter-menu-stock label {
  display: block;
  margin: 0;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
}

.filter-menu-stock label * {
  vertical-align: middle;
}

.filter-menu-stock .wrap-filter {
  text-align: center;
}

.filter-menu-stock .wrap-filter .button{
  height: auto;
}
.filter-menu-stock label:hover {
  color: #00bcd4;
  text-decoration: underline;
}
.filter-menu-stock .show-column {
  padding: 0.5em;
  text-align: center;
}
.filter-menu-stock .menu-item {
  top: 3em !important;
  padding: 0 !important;
  width: 350px;
  position: absolute;
  background: white;
  z-index: 99;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border: solid 2px #bfbfbf;
}
.filter-menu-stock .menu-item.displayed {
  max-height: 250px;
  display: block;
}
.filter-menu-stock .menu-item::after {
  content: "";
  width: 0;
  height: 0;
  top: -30px;
  left: -2px;
  position: absolute;
  border-style: solid;
  border-width: 30px 0 0 30px;
  border-color: transparent transparent transparent #bfbfbf;
  box-shadow: -2px -3px 6px -6px rgba(0, 0, 0, 0.3);
}

.filter-menu-stock.reversed .menu-item::after {
  width: 0;
  height: 0;
  right: 0px;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #bfbfbf transparent;
  left: calc(100% - 30px);
}

.filter-menu-stock .menu-item.reverse::after {
  left: auto;
  right: 0;
  -webkit-transform: rotateY(180deg);
}
.filter-menu-stock .content-value i {
  color: limegreen;
}
.filter-menu-stock .content-value li {
  padding: 0.5em 3em;
  border-top: 1px solid #e8e8e8;
  text-align: left;
  justify-content: space-between;
  font-size: 10px !important;
}
.filter-menu-stock a.disabled {
  color: gainsboro;
}

.filter-menu-stock-selects-buttons {
  display: inline-flex;
}

</style>
