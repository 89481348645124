<template>
  <div v-if="expedition !== null && accessGranted">
    <ExpeditionForm
      v-model="expedition"
      @validate="createExpedition"
      @cancel="cancel"
      :loading="loading"
      :errorsData="errorsData">
    </ExpeditionForm>
  </div>
</template>
<script>
import ExpeditionForm from '@/apps/expedition/components/ExpeditionForm'
import apiClient from '@/client/client_logistics'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
import isEqual from 'lodash/isEqual'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'

export default {
  name: 'ExpeditionCreate',
  components: {
    ExpeditionForm
  },
  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin,
  ],
  data: function(){
    return {
      expedition: new Expedition(),
      loading: false,
      finalRoutePath: '/expedition-module/validate/:id',
      dataSended: {},
      errors: null
    }
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveExpeditionAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
    errorsData() {
      if (this.errors === null) return this.errors
      const errorsData = {}
      Object.keys(this.errors).forEach(key => {
        // Special case for Entity
        if (this.expedition[key] instanceof Entity) {
          // Recreate Entity with data to avoid extra data added by vue
          // then compare the two "new" entities to have a correct comparaison
          if (isEqual(new Entity(this.dataSended[key]), new Entity(this.expedition[key]))) {
            errorsData[key] = this.errors[key]
          }
        }
        // Change each time expedition error field is changed
        else {
          if (isEqual(this.dataSended[key], this.expedition[key])) {
            errorsData[key] = this.errors[key]
          }
        }
      })
      return errorsData
    },
    user() {
      return this.$store.state.user
    },
  },
  mounted(){
    if(this.$route.query && Object.keys(this.$route.query).length !== 0 && this.$route.query.constructor === Object){
      this.expedition.purchaser = this.$route.query.purchaser
      this.expedition.purchaserEntity = JSON.parse(this.$route.query.purchaserEntity)
      this.expedition.recipient = this.$route.query.recipient
      this.expedition.recipientEntity = JSON.parse(this.$route.query.recipientEntity)
      this.expedition.sender = this.$route.query.sender
      this.expedition.senderEntity = JSON.parse(this.$route.query.senderEntity)
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/expedition-module/')
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    async createExpedition(){
      this.loading = true
      if(this.expedition.transporterId === null){
        let transporter = {
          businessName: this.expedition.transporter,
          contactsList: this.expedition.transporterContacts
        }
        const [transporterResp, transporterError] = await handlePromise(
          this.createTransporter(transporter)
        )
        if (transporterError) { // createTransporter have an issue
          this.loading = false
          this.throwTransporterError()
          return // Stop here, block the rest of the creation
        } else {
          this.expedition.transporterId = transporterResp.id
        }
      }
      this.dataSended = this.expedition.getFormData(this.user.isAdministrator)
      this.errors = null
      return apiClient
        .createExpedition(this.dataSended)
        .then((response) => {
          this.expedition.pdfUrl = response.data.pdfUrl
          this.expedition.reference = response.data.reference
          this.expedition.id = response.data.id
          this.loading = false;
          this.$router.push(`/expedition-module/validate/${this.expedition.id}`)
        })
        .catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('expedition-create-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
    },
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
