<template>
  <section class="section">
    <div class="container" v-if="validatedObject === null">
      <progress class="progress is-small is-primary" max="100">15%</progress>
    </div>
    <div class="container" v-else>
      <div class="columns is-centered">
        <div class="column is-half">
          <slot name="validation-content" v-if="validatedObject !== null">
          </slot>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
    name: "Validation",
    props: {
      validatedObject: {
        default: null
      }
    },
};
</script>
