<template>
  <div v-if="accessGranted" class="container">
  <section class="articleDetail_title">
    <h1 class="columns articleDetail_title_h1">
      <div class="column is-three-fifths label-bold is-pulled-left mb10px-small-screen">
        <span class="green-komaks">
          <img src="@/assets/icons/articles_green_icon.png" width="20px" heigth="20px">
        </span>
        <span class="green-komaks small"> ARTICLE - </span><span class="font-35">{{ article.qrcode }}</span> <span :class="articleStatusClass">{{ article.getStatusDisplay }}</span>
      </div>

      <button class="button is-primary title-button mr1em">
        <a target="blank" :href="'/stock-module/articles/' + id + '/history'">
          <img src="@/assets/icons/history_white_icon.png" width="20px" heigth="20px">
        </a>
      </button>

      <button class="button is-primary blue title-button" @click="print()">
        <img src="@/assets/icons/print_white_icon.png" width="20px" heigth="20px">
      </button>

    </h1>
  </section>


  <div class="columns">
  </div>

  <section class="columns">
    <div class="column is-one-fifth is-hidden-print" id="carousel">
      <Carousel :pk="id" />
      <span class="green-komaks label-bold">{{ article.picturesCount }} {{$t('articlePictures')}}</span>
    </div>
    <div class="column is-two-thirds">
      <div class="columns">
        <div class="column is-two-thirds grey-komaks">
          <span v-if="article.currentShipment.reference">
            <img src="@/assets/icons/expedition_red_icon.png" width="12px" heigth="12px">
            <a class="grey-link" target="blank" :href="'/expedition-module/list/' + article.currentShipment.expeditionPk">
              {{ article.currentShipment.reference }} <span v-if="article.currentShipment.sendDatetime">({{ article.currentShipment.sendDatetime }})</span>
            </a>
          </span>
          <span v-else>{{ article.entity_name }}</span>
          <br>
          <span v-if="article.delivery">
            <img src="@/assets/icons/reception_green_icon.png" width="12px" heigth="12px">
            <a class="grey-link" :href="'/pick-up-and-return-module/list/' + article.delivery.expeditionPk">{{ article.delivery.reference }} ({{ article.delivery.receiptDate }})</a>
          </span>
        </div>
        <div class="column is-one-third grey-komaks">
          <span class="red-komaks font-bold">{{ article.addressing }}</span>
          <br>
          <i class="fa fa-lock red-komaks"></i> {{ article.delivery.onBehalfOf }}
        </div>
      </div>
      <div class="columns">
        <div class="column is-full background-white pt05em pb05em">
          {{ article.product.brand }} - {{ article.product.category }}
          <div class="label-bold green-komaks">
            <span v-if="article.isMachineLike">
              {{ article.product.series }}
              {{ article.product.model }}
            </span>
            <span v-else>
              {{ article.product.manufacturer_ref }}
              {{ article.product.oem_code }}
            </span>
          </div>
          <span v-if="article.isMachineLike">
            {{$t('serialNumberArticleDetail')}}{{ article.qualityInfos.serialNumber }}
          </span>
          <span v-else>
            {{$t('colorArticleDetail')}}{{ article.product.attributes["COLOR"] }}
          </span>
        </div>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <span class="red-komaks label-bold">{{$t("articleNote")}}</span>
    <span v-if="article.problematic">
      <p v-for="reason in article.problematicReason" :key="reason">{{ reason }} / </p>
      <p>{{ article.problematic_note }}</p>
    </span>
    <span v-else>
      <p>{{ article.note }}</p>
    </span>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('quality')}}</div>
    <div v-for="qualityTopDict in article.qualityInfos.qualityTop" :key="qualityTopDict.name" class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
          {{ qualityTopDict.name }}:
      </div>
      <div class="column is-half has-text-left smaller-padding">
        <span>
          {{ qualityTopDict.value }}
        </span>
      </div>
    </div>
    <hr class="detail-page-separator" />
    <div v-for="qualityOtherDict in article.qualityInfos.qualityOther" :key="qualityOtherDict.name" class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
        {{ qualityOtherDict.name }}:
      </div>
      <div class="column is-half has-text-left smaller-padding">
        <span>
          {{ qualityOtherDict.value }}
        </span>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('accessoryLinked')}}<span class="is-pulled-right pr1em"><img src="@/assets/icons/demo_white_icon.png" width="15px" heigth="15px" alt=""></span></div>
    <div v-for="accessory in article.accessories" :key="accessory.id" class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
        <a :href="'/stock-module/articles/' + accessory.id" target="_blank">{{ accessory.qrcode }}</a>:
      </div>
      <div class="column is-half has-text-left smaller-padding">
        {{ accessory.product.brand }} | {{ accessory.product.type }}
        <span v-if="accessory.isMachineLike || accessory.isIt">
          <span v-if="accessory.product.series">
            | {{ accessory.product.series }}
          </span>
          | {{ accessory.product.model }}
        </span>
        <span v-else-if="accessory.isConsumable">
          | {{ accessory.product.oemCode }} | {{ accessory.product.manufacturerRef }}
        </span>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('bundleArticleDetail')}}<span class="is-pulled-right pr1em"><img src="@/assets/icons/demo_white_icon.png" width="15px" heigth="15px" alt=""></span></div>
    <div v-for="bundled in article.bundle_content" :key="bundled.id" class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
        <a :href="'/stock-module/articles/' + bundle.pk" target="_blank">{{ bundled.qrcode }}: </a>
      </div>
      <div class="column is-half has-text-left smaller-padding">
        {{ bundled.product.brand }} | {{ bundled.product.category }}
        <span v-if="bundled.isMachineLike || bundled.isIt">
          <span v-if="bundled.product.series">
            | {{ bundled.product.series }}
          </span>
          | {{ bundled.product.model }}
        </span>
        <span v-else-if="bundled.isConsumable">
          | {{ bundled.product.oemCode }} | {{ bundled.product.manufacturerRef }}
        </span>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('inventoryQualification')}}</div>
    <div class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding mb05em">
        {{$t('lastQualification')}}
      </div>
      <div class="column is-half has-text-left smaller-padding mb05em">
        <span v-if="article.lastQualificationBy">{{ article.lastQualificationBy }}</span>
        <span v-else>{{$t('noQualificationYet')}}</span>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('otherFeature')}}</div>
    <div v-for="(value, key) in article.product.attributes" :key="key" class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding mb05em">
        {{ key }}:
      </div>
      <div class="column is-half has-text-left smaller-padding">
        <!-- TODO vérifier l'affichage correct pour les string ET les float -->
          {{ value }}
      </div>
    </div>
    <div class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding mb05em">
        {{$t('conceivedFor')}}
      </div>
      <div class="column is-half has-text-left smaller-padding">
        <span v-for="conceivedFor in article.product.displayConceivedFor" :key="conceivedFor">
          {{ conceivedFor.series }} <span v-for="model in conceivedFor.models" :key="model">{{ model }}/</span><br>
        </span>
      </div>
    </div>
  </section>

  <section class="background-white has-text-centered mb1em">
    <div class="background-grey label-bold">{{$t('logisticsAndStorage')}}</div>
    <div class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
        {{$t('ownerArticleDetail')}}
      </div>
      <div class="column is-half has-text-left smaller-padding">
        {{ article.delivery.onBehalfOf }}
      </div>
    </div>
    <div class="columns">
      <div class="column is-half has-text-right label-bold smaller-padding">
        {{$t('storageTypeArticleDetail')}}
      </div>
      <div class="column is-half has-text-left smaller-padding">
        {{ article.delivery.storageType }}
      </div>
    </div>
  </section>
  </div>
</template>

<script>
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
import Carousel from '@/apps/stock/components/Carousel'
import apiClient from '@/client/client_logistics'

export default {
  name: 'ArticleDetail',
  mixins: [deniedAccessMixin],
  components: {
    Carousel
  },
  props: {
    id: {
      type: Number,
      required: true
    },
  },
  data (){
    return {
      loading: true,
      article: undefined
    }
  },
  computed: {
    articleStatusClass(){
      return "article-status-" + this.article.status
    },
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.haveStocksLogisticsAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    }
  },
  created(){
    this.getArticle()
  },
  watch: {
    '$route.path': function(){
      this.getArticle();
    }
  },
  methods: {
    print() {
      return window.print()
    },
    getArticle(){
      this.loading = true
      apiClient.getArticleDetail(this.id).then(
        response => {
          this.article = response.data
        }
      ).catch(() => {
        this.loading = false
        this.article = null
      })
    }
  }
}

</script>

<style>

.smaller-padding {
  padding: 0.2em !important;
}

.articleDetail_title {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.articleDetail_title_h1 {
    border-bottom: 2px solid #cdcdcd;
    font-size: 1.3em;
    padding-bottom: 0;
}

.label-bold {
  font-weight: 700;
}

.font-bold {
  font-weight: bold;
}

.font-35 {
  font-size: 1.5em;
}

.pt05em {
  padding-top: 0.5em !important;
}

.pb05em {
  padding-bottom: 0.5em !important;
}

.pr1em {
  padding-right: 1em !important;
}

.mb1em {
  margin-bottom: 1em !important;
}

.mr1em {
  margin-right: 1em !important;
}

.mt1em {
  margin-top: 1em !important;
}

.mb05em {
  margin-bottom: .5em !important;
}

.background-white {
  background-color: #ffffff !important;
}

.background-grey {
  background-color: #cdcdcd !important;
  color: #fefefe !important;
  margin-bottom: 0.5em;
}

.title-button {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-top: 10px;
}

.blue {
  background-color: #009ee3 !important;
}

.blue:hover {
  background-color: #009fe3b7 !important;
}


.article-status-STORED {
  color: #93c123 !important;
}
.article-status-RESERVED {
  color: #ff931e !important;
}
.article-status-PICKED, .article-status-PREPARED {
  color: #009ee3 !important;
}
.article-status-PACKED {
  color: #f42e58 !important;
}
.article-status-IN_DEMO {
  color: #733287 !important;
}
.article-status-SOLD, .article-status-RECYCLED, .article-status-USED, .article-status-LOST, .article-status-NOT_RETURNED, .article-status-INSTALLED {
  color: #d63d33 !important;
}

.green-komaks {
  color: #93c123 !important;
}

.grey-komaks {
  color: #999999 !important;
}

.red-komaks {
  color: #d63d33 !important;
}

a.grey-link {
  color: #999999 !important;
}

@media (max-width: 768px) {
    .mb10px-small-screen {
      margin-bottom: 10px;
    }
}

@media print {
  /* Show simpler page when printing (eg: no buttons nor forms) */
  .articleDetail_title .btn, .articleDetail_title form, .articleDetail_title .templateCarouselDocuments .mb1em, .alert {
    display: none;
  }
  #app_article_detail {
    font-size: 0.8em;
  }
  #app_article_detail th, #app_article_detail td {
    padding: 5px;
  }
  .articleDetail_title h1, .articleDetail_title h4 {
      font-size: 1.5em;
  }
}
</style>
