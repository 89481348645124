import { ModalData } from '@/models/modal'
export default {
  data () {
    return {
      finalRoutePath: 'finalRoutePath', // To overide in subclasses
      forceChangeRoute: false,
    }
  },
  methods: {
    updateForceChangeRoute(value){
      this.forceChangeRoute = value
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.matched[0].path === this.finalRoutePath || this.forceChangeRoute) {
      next()
      return
    }
    this.$store.dispatch (
      'openModal',
      new ModalData(
        {
          text: this.$t('warning-title'),
          css: 'is-warning'
        },
        [{
          text: this.$t('cancel-warning-sentence'),
        }],
        {
          validate: {
            active: true,
            css: 'is-warning',
            onClick: () => {
              this.$store.dispatch('closeModal');
              next()
            }
          },
          cancel: {
            active: true,
            css: '',
            onClick: () => {
              this.$store.dispatch('closeModal');
            }
          },
        }
      )
    )
  },
}
