<template>
  <div v-if="accessGranted">
    <DemoForm
      ref="form"
      v-model="demo"
      :loading="loading"
      :errorsData="errorsData"
      :transporters="transporterList"
      @validate-demo="createDemo"
      @cancel="cancel">
    </DemoForm>
  </div>
</template>
<script>
import apiClient from '@/client/client_logistics'
import DemoForm from '@/apps/demo/components/DemoForm'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'

import isEqual from 'lodash/isEqual'

export default {
  name: 'DemoCreate',
  components: {
    DemoForm
  },
  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin
  ],
  data: function(){
    return {
      demo: {
        expedition: new Expedition(),
        reprise: new Expedition()
      },
      loading: false,
      isExpeditionCreated: false,
      isPickUpCreated: false,
      finalRoutePath: '/demo-module/validate/:id/:idPickUpAndReturn',
      dataExpeditionSended: {},
      dataPickUpSended: {},
      errors: {
        errorsExpedition: null,
        errorsPickUp: null
      },
      transporterList: [],
    }
  },

  created() {
    this.demo.expedition.kind = 'LOAN'
    this.demo.reprise.kind = 'PICK_UP_AND_RETURN'
    this.getTransporterList()
  },
  computed: {
    errorsData() {
      const errorsData = {}
      if (this.errors.errorsExpedition === null) {
        errorsData['errorsExpedition'] = null
      } else {
        errorsData['errorsExpedition'] = {}
        Object.keys(this.errors.errorsExpedition).forEach(key => {
          // Special case for Entity
          if (this.demo.expedition[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataExpeditionSended[key]), new Entity(this.demo.expedition[key]))) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataExpeditionSended[key], this.demo.expedition[key])) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          }
        })
      }

      if (this.errors.errorsPickUp === null) {
        errorsData['errorsPickUp'] = null
      } else {
        errorsData['errorsPickUp'] = {}
        Object.keys(this.errors.errorsPickUp).forEach(key => {
          // Special case for Entity
          if (this.demo.reprise[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataPickUpSended[key]), new Entity(this.demo.reprise[key]))) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataPickUpSended[key], this.demo.reprise[key])) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          }
        })
      }
      return errorsData
    },
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveDemoAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
    user() {
      return this.$store.state.user
    }
  },
  mounted(){
    if(this.$route.query && Object.keys(this.$route.query).length !== 0 && this.$route.query.constructor === Object){
      this.demo.expedition.purchaser = this.$route.query.purchaser
      this.demo.expedition.purchaserEntity = JSON.parse(this.$route.query.purchaserEntity)
      this.demo.expedition.recipient = this.$route.query.recipient
      this.demo.expedition.recipientEntity = JSON.parse(this.$route.query.recipientEntity)
      this.demo.expedition.sender = this.$route.query.sender
      this.demo.expedition.senderEntity = JSON.parse(this.$route.query.senderEntity)

      this.demo.reprise.purchaser = this.$route.query.purchaser
      this.demo.reprise.purchaserEntity = JSON.parse(this.$route.query.purchaserEntity)
      this.demo.reprise.recipient = this.$route.query.pickUpRecipient
      this.demo.reprise.recipientEntity = JSON.parse(this.$route.query.pickUpRecipientEntity)
      this.demo.reprise.sender = this.$route.query.pickUpSender
      this.demo.reprise.senderEntity = JSON.parse(this.$route.query.pickUpSenderEntity)
    }
  },
  methods: {
    getTransporterList(){
      apiClient.getListTransporters().then((response) => {
        this.transporterList = [...new Set(response.data.results)]
      }).catch(() => {
        this.trowErrorLoading()
      })
    },
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/demo-module/')
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    async createDemo() {
      this.loading = true
      if(this.demo.expedition.transporterId === null) {
        let transporter = {
          businessName: this.demo.expedition.transporter,
          contactsList: this.demo.expedition.transporterContacts
        }
        const [transporterResp, transporterError] = await handlePromise(
          this.createTransporter(transporter)
        )
        if (transporterError) { // createTransporter have an issue
          this.loading = false
          this.throwTransporterError()
          return // Stop here, block the rest of the creation
        } else {
          this.demo.expedition.transporterId = transporterResp.id
        }
      }
      if(!this.isExpeditionCreated){
        this.createExpedition()
      }else if(!this.isPickUpCreated){
        this.updateExpedition()
      }
    },
    async updateExpedition() {
      this.dataExpeditionSended = this.demo.expedition.getFormData(this.user.isAdministrator)
      this.errors.errorsExpedition = null
      return apiClient.partialUpdateLoan(this.dataExpeditionSended, this.demo.expedition.id)
      .then(() => {
        this.createPickUp()
      }).catch((e) => {
        this.loading = false
        if(typeof e.response.data !== 'string'){
          this.errors.errorsExpedition= e.response.data
          window.scrollTo(0, 0)
        }else{
          this.$store.dispatch (
            'openModal',
            new ModalData(
              {
                text: this.$t('error-title'),
                css: 'is-danger'
              },
              [{
                  text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
              }],
              {
                cancel: {
                  active: true,
                  css: '',
                  onClick: () => {
                    this.$store.dispatch('closeModal');
                  }
                }
              }
            )
          )
        }
      });
    },
    async createExpedition() {
      this.dataExpeditionSended = this.demo.expedition.getFormData(this.user.isAdministrator)
      this.errors.errorsExpedition = null
      return apiClient
        .createLoan(this.dataExpeditionSended)
        .then((response) => {
          this.demo.expedition.reference = response.data.reference
          this.demo.expedition.id = response.data.id
          this.demo.reprise.expedition = this.demo.expedition.id
          this.isExpeditionCreated = true
          this.createPickUp()
          return response
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsExpedition= e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-create-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
    },
    async createPickUp() {
      this.dataPickUpSended = this.demo.reprise.getFormData()
      this.errors.errorsPickUp = null
      return apiClient
          .createPickUpAndReturn(this.dataPickUpSended)
        .then((response) => {
          this.demo.reprise.pdfUrl = response.data.pdfUrl
          this.demo.reprise.reference = response.data.reference
          this.demo.reprise.id = response.data.id
          this.$router.push(`/demo-module/validate/${this.demo.reprise.expedition}/${this.demo.reprise.id}`)
          this.loading = false;
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsPickUp = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('pick-up-and-return-create-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
    },
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
