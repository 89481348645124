<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :locked="locked" title="insurance-title" :returnToStep="returnToStep">
    <template v-slot:resume-content>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("insurance-content-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="expedition.insurance">{{$t("yes")}}</span>
          <span v-else>{{$t("no")}}</span>
        </div>
      </div>
      <div v-if="expedition.insurance" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("amount-to-ensure-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{ expedition.amountToEnsure }}
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumeInsurance',
  mixins: [resumeStepsMixin],
}
</script>
