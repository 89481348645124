<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-third has-text-right">
          <strong> {{$t("pick-up-and-return-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <div v-if="expedition.expeditionReturn">
            {{$t("yes")}}
          </div>
          <div v-else>
            {{$t("no")}}
          </div>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
export default {
  name: 'detailsPickUpAndReturn',
  mixins: [detailsStepMixin]
}
</script>
