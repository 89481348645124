import ExpeditionModule from '@/apps/expedition/expedition/module'
import ExpeditionCreate from '@/apps/expedition/expedition/create'
import ExpeditionList from '@/apps/expedition/expedition/list'
import ExpeditionUpdate from '@/apps/expedition/expedition/update'
import ExpeditionValidation from '@/apps/expedition/expedition/validation'
import Expedition from '@/apps/expedition/expedition/expedition'

const basePath = '/expedition-module/'

const routes = [
  {
    path: basePath,
    name: 'expedition-module',
    component: ExpeditionModule,
  },
  {
    path: basePath + 'list/:id?',
    component: ExpeditionList,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: basePath + 'create',
    name: 'create-expedition',
    component: ExpeditionCreate,
    props: true
  },
  {
    path: basePath + 'validate/:id',
    component: ExpeditionValidation,
    props: true
  },
  {
    path: basePath +  'update/:id/:step/',
    component: ExpeditionUpdate,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      props.step = +props.step
      return props
    },
  },
  {
    path: basePath + ':id',
    component: Expedition,
  }
];

export default routes;
