<template>
  <div class="wrapper-autocomplete">
    <!-- Component loading -->
    <div v-if="loading" class="small-loader" />
      <div v-else>
        <!-- Input -->
        <input
          :id="id"
          :placeholder="placeholder"
          :name="name"
          :value="(trackBy) ? getTrackByProperty(value, trackBy) : value"
          class="input"
          type="text"
          autocomplete="no"
          @input="updateValue"
          @focus="focus = true"
          @blur="blur"
          @keyup.enter="selectSuggestionKeyboard"
          @keyup.down="navSuggestion(1)"
          @keyup.up="navSuggestion(-1)"
          :required="required"
          :disabled="disabled"
          />
      <!-- Autocomplete -->
      <section v-if="!loading && focus && isEnoughTyping() && filteredSuggestions.length != 0" class="template-autocomplete">
        <div v-for="(suggestion, index) in filteredSuggestions"
             v-bind:key="index"
             class="elm-autocomplete"
             :class="{ focus: index == selectIndex }"
             @mousedown="selectSuggestion(suggestion)">
          {{ (trackBy) ? getTrackByProperty(suggestion, trackBy) : suggestion }}
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { deepCopy } from '@/utils/helpers'
// This component is only to complete the user, it will not select complexe objects and return id, juste complete the text
export default {
  name: 'autocomplete',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    // Can select an other suggestion that is not in suggestions list (Free typing)
    freeTyping: {
      type: Boolean,
      default: false,
    },
    addCurrentInputToSuggestion: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      default: '',
    },
    minLengthBeforeDisplaySuggestion: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: 'autocomplete-id'
    },
    name: {
      type: String,
      default: 'autocomplete-name'
    },
    placeholder: {
      type: String,
      default: ''
    },
    filtreSuggestionsFn: {
      type: Function,
      required: false
    },
    removeValueOnSelect: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    trackBy: {
      type: String,
      required: false,
    }
  },
  data: function () {
    return {
      value: this.initialValue,
      focus: false,
      selectIndex: -1,
    }
  },
  created: function () {
    if (this.initialValue !== undefined) {
      this.value = this.initialValue
    }
    if (this.initialSuggestions !== undefined) {
      this.suggestions = this.initialSuggestions
    }
    if (this.addCurrentInputToSuggestion) {
      this.selectIndex = 0
    }
  },
  computed:{
    filteredSuggestions() {
      // If there is no filter, we display all
      if(this.trackBy){
        let trackByValue = this.getTrackByProperty(this.value, this.trackBy)
        if(trackByValue === '' ||trackByValue <= this.minLengthBeforeDisplaySuggestion){
          const orderedValues = this.suggestions.slice(0) // Slice to copy value
          orderedValues.sort((a,b) => {
            if(this.getTrackByProperty(a, this.trackBy) < this.getTrackByProperty(b, this.trackBy)){
              return -1;
            }else if(this.getTrackByProperty(a, this.trackBy) > this.getTrackByProperty(b, this.trackBy)){
              return 1;
            }
            return 0;
          })
          return orderedValues
        }else { // else filter all values
          let filteredValues = []
          if(this.filtreSuggestionsFn){
            filteredValues = this.filtreSuggestionsFn(this.value)
          } else {
            const filters = this.getTrackByProperty(this.value, this.trackBy).split(" ")
            filteredValues =  this.suggestions.filter((item) => {
              let keepIt = true
              filters.forEach((filter) => {
                if(!this.getTrackByProperty(item, this.trackBy).toLowerCase().includes(filter.toLowerCase())){
                  keepIt = false;
                }
              })
              return keepIt;
            })
          }
          if(this.addCurrentInputToSuggestion) {
            if(filteredValues.findIndex((item) => {
              return this.getTrackByProperty(item, this.trackBy) === this.getTrackByProperty(this.value, this.trackBy)
            }) === -1) {
              filteredValues.unshift(deepCopy(this.value))
            }
          }

          filteredValues.sort((a,b) => {
            if(this.getTrackByProperty(a, this.trackBy) < this.getTrackByProperty(b, this.trackBy)){
              return -1;
            }else if(this.getTrackByProperty(a, this.trackBy) > this.getTrackByProperty(b, this.trackBy)){
              return 1;
            }
            return 0;
          })
          return filteredValues
        }

      }

      if (this.value === '' || this.value.length <= this.minLengthBeforeDisplaySuggestion) {
        const orderedValues = this.suggestions.slice(0) // Slice to copy value
        orderedValues.sort()
        return orderedValues
      } else { // else filter all values
        let filteredValues = []
        if(this.filtreSuggestionsFn){
          filteredValues = this.filtreSuggestionsFn(this.value)
        } else {
          const filters = this.value.split(" ")
          filteredValues =  this.suggestions.filter((item) => {
            let keepIt = true
            filters.forEach((filter) => {
              if(!item.toLowerCase().includes(filter.toLowerCase())){
                keepIt = false;
              }
            })
            return keepIt;
          })
        }
        if(this.addCurrentInputToSuggestion) {
          filteredValues.unshift(this.value)
        }
        filteredValues.sort()
        return filteredValues
      }
    }
  },
  methods: {
    // Move selected suggestion (+ 1 / - 1) with up and down arrows keys
    navSuggestion (movement) {
      // Born the index beetween 0 and max suggestion
      this.selectIndex = Math.min(Math.max(this.selectIndex + movement, 0), this.suggestions.length - 1)
    },
    selectSuggestion (value) {
      if(this.trackBy){
        this.value = deepCopy(value)
      }else{
        this.value = value
      }
      this.$emit('select-value', this.value)
      if(this.removeValueOnSelect){
        this.value = ""
      }
      this.focus = false
      document.activeElement.blur()
    },

    blur() {
      if (this.freeTyping && this.focus) {
        this.selectSuggestion(this.value)
      }
      this.focus = false
    },
    // Select the suggestion selected by user (with arrow keys)
    selectSuggestionKeyboard (e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.selectIndex !== -1) {
        this.selectSuggestion(this.filteredSuggestions[this.selectIndex])
      }
      if (this.addCurrentInputToSuggestion) {
        this.selectIndex = 0
      } else {
        this.selectIndex = -1;
      }
    },
    getTrackByProperty(o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      let a = s.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
          let k = a[i];
          if (k in o) {
              o = o[k];
          } else {
              return;
          }
      }
      return o;
    },
    setTrackByProperty(o, s, newValue){
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      let a = s.split('.');
      for (let i = 0, n = a.length - 1; i < n; ++i) {
          let k = a[i];
          if (k in o) {
              o = o[k];
          }
      }
      o[a[a.length-1]] = newValue
    },
    isEnoughTyping(){
      if(this.trackBy){
        return this.getTrackByProperty(this.value, this.trackBy).length >= this.minLengthBeforeDisplaySuggestion
      }else{
        return this.value.length >= this.minLengthBeforeDisplaySuggestion
      }
    },
    updateValue(e){
      if(this.trackBy){
        this.setTrackByProperty(this.value, this.trackBy, e.target.value)
      }else{
        this.value = e.target.value
      }
    }
  }
}
</script>

<style scoped>
  .wrapper-autocomplete {
    position: relative;
  }
  .template-autocomplete {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 99;
    border: 0.5px solid rgba(0,0,0,0.3);
    max-height: 220px;
    overflow: auto;
  }
  .template-autocomplete div {
    padding: 0.5em;
    border-bottom: 0.5px solid rgba(0,0,0,0.3);
    font-weight: bold;
  }
  .template-autocomplete div:hover, .template-autocomplete div:focus {
    background-color: #f2f2f2;
    outline: none;
  }
  .elm-autocomplete {
    height: 2.3em !important;
  }
  .elm-autocomplete.focus {
    color: white;
    background-color: #337ab7;
  }
  .elm-autocomplete.focus:hover{
    background-color: #286090;
  }
</style>
