<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :title="'transporter-title'" :locked="locked || expedition.kind === 'PICK_UP_AND_RETURN'" :returnToStep="returnToStep">
    <template v-slot:resume-content>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-primary has-text-weight-bold">
          {{expedition.transporter}}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <ul>
            <li v-for="(contact, index) in expedition.transporterContacts" v-bind:key="index">
              {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
            </li>
          </ul>
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumeTransporter',
  mixins: [resumeStepsMixin],
}
</script>
