const api = {
  createTransfer(expedition){
    return this.apiClient().post('expedition/transfers/', expedition)
  },
  partialUpdateTransfer(expedition, id){
    return this.apiClient().patch('expedition/transfers/' + id + '/', expedition)
  },
}

export default api;
