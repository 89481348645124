<template>
  <DetailsTemplate :expedition="expedition">
    <template v-slot:extra-actions>
      <template v-if="!isTransporter && !expedition.isCanceled()">
        <div v-if="!isRemovalDatePassed && !isEditionClosed && !expedition.isDelivered()">
          <a
            @click="confirmeCancel = true">
            {{ $t("cancel-pick-up-and-return-button") }}
          </a>
        </div>
        <div class="has-text-warning" v-if="loadingCancel">
          {{$t("loading")}}
        </div>

        <modal v-if="confirmeCancel" @close="confirmeCancel = false">
          <template slot="header">
            <p class="modal-card-title has-text-centered">{{$t("cancel-confirmation-button")}}</p>
            <button
              type="button"
              class="button is-pulled-right"
              :disabled="loadingCancel"
              aria-label="close"
              @click="confirmeCancel = false">
              <i class="fas fa-times"></i>
            </button>
          </template>
          <!-- actionValue to true if we add article, false otherwise -->
          <div slot="body">
            <Confirmation
              v-if="!loadingCancel"
              :message="$t('cancel-pick-up-and-return-confirmation-message')"
              @cancel="confirmeCancel=false"
              @confirm="cancel()">
            </Confirmation>
            <progress v-else class="progress is-small is-primary" max="100">15%</progress>
          </div>
        </modal>
      </template>
    </template>
    <template v-slot:detail-content>
      <DetailsPurchaser
        :expedition="expedition"
        :step="'1'"
        :title="'purchaser-title'"
        :getUrl="() => expedition.getHrefUpdate(0)"
        :updateCondition="false">
      </DetailsPurchaser>

      <DetailsTransporter
        :expedition="expedition"
        :step="'2'"
        :title="'transporter-title'"
        :getUrl="() => expedition.getHrefUpdate(2)"
        :updateCondition="false"
        :isTransporter="!isTransporter && !isRemovalDatePassed && !isEditionClosed"
        >
      </DetailsTransporter>

      <DetailsSender
        :expedition="expedition"
        :step="'3'"
        :title="'removal-title'"
        :getUrl="() => expedition.getHrefUpdate(0)"
        :updateCondition="!isTransporter && !isRemovalDatePassed && !isEditionClosed">
      </DetailsSender>

      <DetailsRecipient
        :expedition="expedition"
        :step="'4'"
        :title="'delivery-title'"
        :getUrl="() => expedition.getHrefUpdate(1)"
        :updateCondition="!isTransporter && !isEditionClosed">
      </DetailsRecipient>

      <DetailsContent
        :expedition="expedition"
        :step="'5'"
        :title="'content-title'"
        :getUrl="() => expedition.getHrefUpdate(2)"
        :updateCondition="!isTransporter && !isRemovalDatePassed && !isEditionClosed"
        :isKomaks="isKomaks">
      </DetailsContent>

      <DetailsInsurance
        :expedition="expedition"
        :step="'5.1'"
        :title="'insurance-title'"
        :getUrl="() => expedition.getHrefUpdate(3)"
        :updateCondition="!isTransporter && !isEditionClosed">
      </DetailsInsurance>

      <DetailsStorage
        :expedition="expedition"
        :step="'5.2'"
        :title="'storage-type-title'"
        :getUrl="() => expedition.getHrefUpdate(4)"
        :updateCondition="!isTransporter && !isEditionClosed">
      </DetailsStorage>

      <div v-if="user && user.haveExpeditionAccess" class="buttons is-centered">
        <router-link :to="'/expedition-module/list/' + expedition.expedition">
          <button class="button is-info">
            {{ $t("expedition-tracking") }}
          </button>
        </router-link>
      </div>
    </template>
  </DetailsTemplate>
</template>
<script>
import DetailsMixin from '@/mixins/detailsMixin'
export default {
  name: 'PickUpAndReturnDetails',
  mixins: [DetailsMixin],
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  data () {
    return {
      cancelStorePath: 'pickUpAndReturnModule/cancelPickUpAndReturn',

    }
  }
}
</script>
