<template>
  <div class="card resume-card has-green-border">
    <div class="card-header">
      <h2 class="card-header-title">
        {{$t('dates-expedition-title')}}
      </h2>
      <button
        v-if="!isUpdating && expedition.canBeUpdated() && ((isPurchaser && !expedition.validation) || isAdmin || isSender || isTransporter)"
        class="router-button button is-small is-text"
        @click="isUpdating=true"
       >
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
        <span>{{$t("update-button")}}</span>
      </button>
    </div>
    <div class="card-content has-background-green-light">
      <div v-if="!isUpdating">
        <div class="columns is-multiline is-mobile">
          <div class="column is-one-third has-text-right">
            <strong>{{$t("removal-date-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.removalDate }}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("delivery-date-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.deliveryDate }}
          </div>
        </div>
        <div class="buttons is-centered" v-if="!expedition.validation && (isAdmin || isTransporter)">
          <button @click="validateDate" class="button is-primary" :class="{'is-loading': loading}" :disabled="loading">
            {{$t("validate-dates-button")}}
          </button>
        </div>
      </div>
      <div class="p-sm" v-else>
        <form @submit="updateDate">
          <div class="columns is-multiline is-marginless">
            <h3 class="title is-size-6 column is-full">{{$t("set-dates-title")}} :</h3>
            <div class="column is-half field">
              <label class="label">{{$t("removal-date-label")}}</label>
              <div class="control">
                <input v-model="removalUpdateDate" type="date"
                  class="input"
                  :class="{ 'is-danger': errorRemovalDate }"
                  :required="isRemovalDateUpdatable"
                  :disabled="!isRemovalDateUpdatable">
              </div>
              <p v-if="errorRemovalDateLater" class="help is-danger">
                {{$t("later-removal-date-error")}}
              </p>
            </div>
            <div class="column is-half field">
              <label class="label">{{$t("delivery-date-label")}}</label>
              <div class="control">
                <input v-model="deliveryUpdateDate" type="date"
                  class="input"
                  :class="{ 'is-danger': errorDeliveryDate }"
                  required
                  :disabled="!expedition.canBeUpdated()">
              </div>
              <p v-if="errorDeliveryDateLater" class="help is-danger">
                {{$t("later-expedition-date-error")}}
              </p>
              <p v-if="errorPickupBeforeShipping" class="help is-danger">
                {{$t("pickup-before-shipping-error")}}
              </p>
              <p v-if="updateDateAccepted" class="help is-success">
                {{$t("update-date-validation")}}
              </p>
            </div>
          </div>
          <div class="buttons is-centered">
            <button type="button" @click="cancelFormDate()" class="button is-danger" :disabled="loading">
              {{$t("cancel-button")}}
            </button>
            <button class="button is-primary" :class="{'is-loading': loading}" :disabled="loading">
              {{$t("confirm-button")}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import apiClient from '@/client/client_logistics'
import { addDays, dateToString } from '@/utils/helpers'
import { ModalData } from '@/models/modal'
import { mapGetters } from 'vuex'

import { Expedition } from '@/models/expedition'

export default {
  name: 'DetailsDateManagement',
  components: {

  },
  props: {
    expedition: {
      type: Expedition
    }
  },
  data() {
    return {
      // Datas
      isUpdating: false,
      removalUpdateDate: Date.now(),
      deliveryUpdateDate: Date.now(),
      pickUpRemovalDate: undefined,
      pickUpDeliveryDate: undefined,

      loading: false,

      // confirmation
      updateDateAccepted: false,
      // Errors
      errorRemovalDate: false,
      errorDeliveryDate: false,
      errorRemovalDateLater: false,
      errorDeliveryDateLater: false,
      errorPickupBeforeShipping: false,
    }
  },
  beforeMount(){
    this.initFormDate()
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isTransporter () {
      const user  = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.isTransporter
      }
      return false;
    },
    isRemovalDateUpdatable() {
      // Admin case
      if (this.isAdmin) return true
      // Transporter / Sender case
      if (this.isSender || this.isTransporter) return !this.expedition.isRemovalDatePassed() 
      // Purchaser case
      // The purchaser can't change the date from this form when the expedition validation is already done
      // He need to make it's changes from the expedition form
      return !this.expedition.isRemovalDatePassed() && !this.expedition.validation
    },
    isPurchaser() { // DO
      const user  = this.$store.state.user
      if(this.expedition.purchaser !==null && user !== null){
        return this.$store.state.user.groups.includes(this.expedition.purchaser)
      }
      return false
    },
    isSender(){
      const user  = this.$store.state.user
      if(this.expedition.sender !==null && user !== null){
        return this.$store.state.user.groups.includes(this.expedition.sender)
      }
      return false
    }
  },
  methods: {
    initFormDate(){
      this.removalUpdateDate = this.expedition.removalDate
      this.deliveryUpdateDate = this.expedition.deliveryDate
      if (this.expedition.pickUp !== null) {
        this.pickUpRemovalDate = this.expedition.pickUp.removalDate
        this.pickUpDeliveryDate = this.expedition.pickUp.deliveryDate
      }
    },
    cancelFormDate(){
      this.isUpdating = false
      this.initFormDate()
    },
    validateDate(){
      this.expedition.updateDates(this.expedition.removalDate, this.expedition.deliveryDate, true)
      this.expedition.validation = true
    },
    updateDate(event){
      event.preventDefault()
      this.errorRemovalDate = false
      this.errorRemovalDateLater = false
      this.errorDeliveryDate = false
      this.errorDeliveryDateLater = false
      this.errorPickupBeforeShipping = false
      this.updateDateAccepted = false
      let deliveryUpdateDate = new Date(this.deliveryUpdateDate)
      let removalUpdateDate = new Date(this.removalUpdateDate)
      
      // Block the update of removal date
      if (!this.isRemovalDateUpdatable) {
        this.removalUpdateDate = this.expedition.removalDate
      }

      if(removalUpdateDate.getTime() <= deliveryUpdateDate.getTime()){
          let previousDeliveryDate = this.expedition.deliveryDate
          this.loading = true
          // Do the validation with the confirmations
          let validation = this.isAdmin || this.isTransporter
          this.expedition.updateDates(this.removalUpdateDate, this.deliveryUpdateDate, validation).then(() => {
            this.loading = false

            // Bad practice, we should never update a props
            this.expedition.validation = validation
            this.expedition.removalDate = this.removalUpdateDate
            this.expedition.deliveryDate = this.deliveryUpdateDate

            this.updateDateAccepted = true
            this.isUpdating = false
            if(
              // the previous deliveryDate is the same as pickUpremovalDate and
              // The new deliveryDate as changed
              previousDeliveryDate === this.pickUpRemovalDate &&
              this.expedition.deliveryDate !== previousDeliveryDate
            ){
              // Get the difference beetwen pick up deliveryDate and its removal date
              const diffTime = Math.abs(new Date(this.pickUpDeliveryDate) - new Date(this.pickUpRemovalDate));
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              const pickUpDates = {
                // Move the removal date to the expedition delivery Date
                removalDate: this.expedition.deliveryDate, 
                // Move deliveryDate (pickup) with the same diff days than before
                deliveryDate: dateToString(addDays(this.expedition.deliveryDate, diffDays))
              }
              this.loading = true
              // Update with the new dates
              apiClient.updatePickUpAndReturn(pickUpDates, this.expedition.pickUp.id).then(() => {
                this.loading = false
                // Bad practice, we should never update a props
                this.pickUpRemovalDate = pickUpDates.removalDate
                this.pickUpDeliveryDate = pickUpDates.deliveryDate
                this.expedition.pickUp.removalDate = pickUpDates.removalDate
                this.expedition.pickUp.deliveryDate = pickUpDates.deliveryDate

                this.displayWarning('date-update-pick-up-simultaneous-error');
              })
            } else if(this.expedition.pickUp !== null && this.expedition.deliveryDate !== previousDeliveryDate){
              // The pickup up is not simultaneous, we just show a warning
              this.displayWarning('date-update-pick-up-deferred-error')
            }
          }).catch((e) => {
            this.loading = false
            let errorsMsg =  []
            Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  errorsMsg.push(error)
                })
              }
            })
            this.loading = false
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('date-update-error')].concat(errorsMsg),
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          });
        }else{
          this.errorDeliveryDate = true
          this.errorPickupBeforeShipping = true
        }
    },
    displayWarning(messageKey){
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
            text: this.$t(messageKey),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.$store.dispatch('closeModal');
              }
            }
          }
        )
      )
    },
  }
}
</script>
