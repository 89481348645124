<template>
  <div>
    <div class="columns">
      <div class="column">
      <div
          class="expedition-validation-indicator"
          :class="{
            'has-text-danger': !expedition.validation || expedition.isCanceled(),
            'has-text-blue': expedition.validation && !expedition.isDelivered(),
            'has-text-green': expedition.validation && expedition.isDelivered()
          }"
        >
          <i class="fas fa-truck fa-2x m-r-md"></i>
          <b v-if="expedition.isCanceled()" class="is-uppercase">
            {{$t('expedition-canceled')}}
          </b>
          <span v-else-if="!expedition.validation">
            {{$t('not-fixed-appointment')}}
          </span>
          <span v-else-if="expedition.validation && !expedition.isDelivered()">
            {{$t('fixed-appointment')}}
          </span>
          <span v-else>
            {{$t('expedition-delivered')}}
          </span>
        </div>
      </div>
      <div class="column has-text-centered">
        <h1 class="is-size-4 has-text-weight-bold" :class="{'has-crossed-text': expedition.isCanceled()}">
          {{ expedition.reference }}
          <a v-if="haveWarehouseAccess && !expedition.isPickUp() && isKomaks" :href="shipmentUrl" target="_blank">
            <button class="button is-primary">
              <i class="fas fa-warehouse"></i>
            </button>
          </a>
          <a v-if="haveWarehouseAccess && expedition.deliveryId" :href="deliveryUrl" target="_blank">
            <button class="button is-primary">
              <i class="fas fa-warehouse"></i>
            </button>
          </a>
        </h1>
        <div>
          <p>
            <i class="fas fa-box-open"></i>
            {{ expedition.articleQuantity }} {{$t("articles-label")}}
          </p>
        </div>
        <div class="buttons is-centered" v-if="!expedition.isHistory()">
          <button
            :title="$t('chat-title')"
            class="button is-primary"
            @click="setShowTab('chat')">
            <i class="fas fa-comment-alt"></i>
          </button>
          <button
            :title="$t('download-title')"
            class="button is-primary"
            @click="setShowTab('files')">
            <i class="fas fa-download"></i>
          </button>
          <button
            :title="$t('actions-title')"
            class="button is-primary"
            @click="setShowTab('actions')">
            <i class="fas fa-cogs"></i>
          </button>
        </div>
      </div>
      <div class="column has-text-right">
        <p class="has-text-grey-light is-size-7">{{ expedition.created }}</p>
      </div>
    </div>

    <div v-if="showTab==='chat'" class="card" :key="expedition.id">
      <header class="card-header">
        <p class="card-header-title">{{$t("chat-title")}}</p>
      </header>
      <Chat objectType="expedition" v-model="expedition"></Chat>
    </div>

    <div v-if="showTab==='actions'" class="card" :key="expedition.id">
      <header class="card-header">
        <p class="card-header-title">{{$t("actions-title")}}</p>
      </header>
      <div class="card-content is-multiline">
        <template v-if="!expedition.isCanceled() && (isTransporter || isAdmin)">
            <!-- Validate expedition -->
            <a v-if="!expedition.validation && !loadingValidate"
              class="link"
              :class="{'disabled': loadingValidate || expedition.validation}"
              @click="validate(expedition)"
              :disabled="expedition.validation || loadingValidate">
              {{$t("confirm-delivery-button")}}
            </a>
            <div v-if="expedition.validation">
              {{$t("confirm-delivery-button")}} (<i class="fas fa-info"></i> {{$t('already-confirmed-sentence')}})
            </div>
            <div class="has-text-warning" v-if="loadingValidate && !expedition.validation">
              {{$t("loading")}}
            </div>

            <!-- Delivered expedition -->
            <div v-if="!expedition.isDelivered() && !loadingDeliver">
              <a
                :class="{'is-loading': loadingDeliver}"
                @click="deliver(expedition)"
                :disabled="expedition.isDelivered() || loadingDeliver">
                {{$t("delivered-done-button")}}
              </a>
            </div>
            <div v-if="expedition.isDelivered() && !loadingDeliver">
              {{$t("delivered-done-button")}} (<i class="fas fa-info"></i> {{$t('already-delivered-sentence')}})
            </div>
            <div class="has-text-warning" v-if="loadingDeliver">
              {{$t("loading")}}
            </div>

            <div v-if="expedition.isLoan()">

              <!-- Delivered Pickup (LOAN) -->
              <a v-if="!expedition.pickUp.validation && !loadingValidate"
                :class="{'is-loading': loadingValidate}"
                @click="validate(expedition.pickUp)"
              >
                {{$t("confirm-delivery-button")}} ({{$t("pick-up-and-return-title")}})
              </a>
              <div v-if="expedition.pickUp.validation && !loadingValidate">
                {{$t("confirm-delivery-button")}} ({{$t("pick-up-and-return-title")}})
                (<i class="fas fa-info"></i> {{$t('already-confirmed-sentence')}})
              </div>
              <div class="has-text-warning" v-if="loadingValidate && !expedition.pickUp.validation">
                {{$t("loading")}}
              </div>
            </div>
          </template>
        <slot name="extra-actions">
        </slot>
      </div>
    </div>

    <div v-if="showTab === 'files'" class="card" :key="expedition.id">
      <header class="card-header">
        <p class="card-header-title">{{$t("download")}}</p>
      </header>
      <slot name="details-files">
        <FilesExpedition
          :expeditionId="expedition.id"
          :expeditionReference="expedition.reference"
          :expeditionPV="expedition.pdfUrl"
          :isDemo="expedition.isLoan()"
          :isKomaks="isKomaks">
        </FilesExpedition>
      </slot>
    </div>
    <DetailsDateManagement :expedition="expedition" v-if="!expedition.isCanceled()"/>
    <div class="notification is-danger" v-if="expedition.isCanceled()">
      {{$t('delete-from-planning-sentence')}}
    </div>
    <slot name="detail-content">
    </slot>
  </div>
</template>


<script>
import { ModalData } from '@/models/modal'
import { Expedition } from '@/models/expedition'
import { deepCopy, getBaseUrlWarehouse } from '@/utils/helpers'
import FilesExpedition from '@/components/details/FilesExpedition'
import DetailsDateManagement from '@/components/details/detailsDateManagement'
import Chat from '@/components/Chat'
import apiClient from '@/client/client_logistics'

import {mapGetters} from 'vuex'
export default {
  name: 'DetailsTemplate',
  components: {
    FilesExpedition,
    Chat,
    DetailsDateManagement,
  },
  props: {
    expedition: {
      type: Object
    },
    isEditionClosed: {
      type: Boolean,
      default: false,
    },
    isKomaks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTab: "",
      loadingValidate: false,
      loadingDeliver: false,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isTransporter () {
      const user  = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.isTransporter
      }
      return false;
    },
    haveWarehouseAccess(){
      const user = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.haveWarehouseAccess
      }
      return false
    },
    shipmentUrl(){
      return getBaseUrlWarehouse() + '/shipment/list/' + this.expedition.reference
    },
    deliveryUrl(){
      return getBaseUrlWarehouse() + '/delivery/' + this.expedition.reference
    }
  },
  methods: {
    setShowTab(option){
      if(this.showTab === option){
        this.showTab = ""
      }else{
        this.showTab = option
      }
    },

    error (errorObject) {
      let errorsMsg =  []
      Object.keys(errorObject.response.data).forEach(key => {
        if (Array.isArray(errorObject.response.data[key])) {
          errorObject.response.data[key].forEach(error => {
            errorsMsg.push(error)
          })
        } else if(errorObject.response.status === 400){
            errorsMsg.push(errorObject.response.data[key])
        }
      })
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
              text: [this.$t('expedition-update-error')].concat(errorsMsg),
          }],
          {
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal');
              }
            }
          }
        )
      )
    },

    deliver(expedition) {
      this.loadingDeliver = true
      apiClient.deliver(expedition.id).then(
        () => {
          const newExpedition = new Expedition(deepCopy(expedition))
          newExpedition.status = 'DELIVERED'
          // Classic case
          if (!this.expedition.isLoan()) {
            this.$store.dispatch(expedition.getUpdateStorePath(), newExpedition)
          } else {
            if (expedition.id === this.expedition.id) {
              newExpedition.pickUp = new Expedition(newExpedition.pickUp);
              this.$store.dispatch(expedition.getUpdateStorePath(), newExpedition)
            } else {
              // Pick up on demo
              this.expedition.pickUp = newExpedition
              this.$store.dispatch(this.expedition.getUpdateStorePath(), this.exedition)
            }
          }
          this.loadingDeliver = false
        }
      ).catch((errorObject) => {
        this.loadingDeliver = false
        this.error(errorObject)
      })
    },

    validate(expedition) {
      this.loadingValidate = true
      apiClient.validate(expedition).then(
        () => {
          const newExpedition = new Expedition(deepCopy(expedition))
          newExpedition.validation = true
          // classic case
          if (!this.expedition.isLoan() || expedition.id === this.expedition.id) {
            this.$store.dispatch(expedition.getUpdateStorePath(), newExpedition)
          } else {
            // Pick up on demo
            this.expedition.pickUp = newExpedition
            this.$store.dispatch(this.expedition.getUpdateStorePath(), this.expedition)
          }
          this.loadingValidate = false
        }
      ).catch((errorObject) => {
        this.loadingValidate = false
        this.error(errorObject)
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .card{
    margin-bottom: 1.5rem;
  }

  .section-title{
    .button{
      margin-left: .5rem;
    }
  }

  .table{
    margin: 1rem auto;
    width: 100%;
  }

  .router-button{
    margin: .5em;
  }
  .buttons:not(:last-child){
    margin-bottom: 0rem;
  }
</style>
