<template>
  <section class="templateCarouselDocuments has-text-center">

    <!-- vignette visible -->
    <section v-if="loading && !display">
      <div class="loader"></div>
    </section>
    <section v-else-if="allDatas.length == 0" class="grey-komaks" v-on:click="display = true">
      <img src="@/assets/icons/no_photo_icon.png" width="50px" heigth="50px">
    </section>
    <section v-else class="thumbnail-carousel" v-on:click="display = true">
      <img v-if="thumb.url !== undefined" :src="thumb.url" :alt="thumb.name" />
      <i v-else class="fa fa-file-pdf-o fa-5x" aria-hidden="true"></i>
      <div class="thumbnail-photo-nbr">
        + {{ allDatas.length }}
      </div>
    </section>
    <section v-if="display" class="wrap-carousel">
      <div v-if="allDatas.length > 0" class="counter-carousel">{{ current + 1 }} / {{ allDatas.length }}</div>
      <div v-on:click="display=false" class="close-carousel">&times;</div>
      <div class="gallery-carousel">
        <div class="control-carousel" @click="prev"><span v-if="allDatas.length > 1" class="icon"><i class="fas fa-arrow-left"></i></span></div>
      <div v-if="allDatas.length != 0" :class="{'content-document-carousel': allDatas[current].isDocument, 'content-img-carousel': !allDatas[current].isDocument}">

          <embed v-if="allDatas[current].isDocument" :src="allDatas[current].url" />
          <p v-if="allDatas[current].isDocument" class="title-document">{{ allDatas[current].title }}</p>
          <img v-else :src="allDatas[current].carouselPictureUrl"/>
          <div class="actions-buttons">
            <a class="button is-primary" download :href="allDatas[current].url">
              {{ $t('download') }}
            </a>
          </div>
      </div>
      <div v-else>
        <p class="text-light no-photo">{{ $t("No file.") }}</p>
      </div>
      <div class="control-carousel" @click="next"><span v-if="allDatas.length > 1" class="icon"><i class="fas fa-arrow-right"></i></span></div>
      </div>
    </section>
  </section>
</template>

<script>
import apiClient from '@/client/client_logistics'

export default {
  props: ['pk'],

  data: () => {
    return {
      // Datas
      documents: [],
      pictures: [],
      allDatas: [],
      thumb: {},

      // Display datas
      loading: false,
      loadingPictures: false,
      loadingDocuments: false,

      message: '',
      error: false,

      current: 0,
      display: false
    }
  },
  created () {
    this.get()
  },
  methods: {
    get () {
      this.current = 0
      this.loading = true

      this.loadingPictures = true
      this.loadingDocuments = true

      apiClient.getArticleDocuments(this.pk).then(
        response => {
          this.loadingDocuments = false
          if (response.data.documents !== null && response.data.documents !== undefined) {
            this.processCallback(response.data.documents, true)
          }
        }
      )
      apiClient.getArticlePictures(this.pk).then(
        response => {
          this.loadingPictures = false
          this.processCallback(response.data.pictures, false)
        }
      )
    },
    processCallback (datasFromRequest, isDocument) {
      const datas = []
      datasFromRequest.forEach(
        element => datas.push(Object.assign(element, {isDocument}))
      )
      if (isDocument) {
        this.documents = datas
      } else {
        this.pictures = datas
        if (datas.length !== 0) {
          this.thumb = {
            "url": datas[0].urlThumb,
            "name": datas[0].title
          }
        }
      }
      this.loading = this.loadingPictures || this.loadingDocuments
      this.allDatas = this.pictures.concat(this.documents)
      this.$emit('nbr', this.allDatas.length)
    },
    next () {
      this.current += 1
      if (this.current > this.allDatas.length - 1) {
        this.current = 0
      }
    },
    prev () {
      this.current -= 1
      if (this.current < 0) {
        this.current = this.allDatas.length - 1
      }
    },
    downloadZip () {
      apiClient.getArticleDocZip(this.pk)
    }
  }
}
</script>


<style>
  .wrap-carousel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding-top: 1em;
    z-index: 99;
    background: rgba(0, 0, 0, 0.6);
  }

  .no-photo {
    font-size: 2em;
  }

  .close-carousel {
    font-size: 2.5em;
    position: absolute;
    right: 0.5em;
    top: 0;
    color: white;
    cursor: pointer;
  }

  .gallery-carousel {
    max-height: 80vh;
    min-height: 80vh;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
  }

  .control-carousel {
    color: white;
    font-size: 3em;
    padding: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  .control-carousel:first-child:hover {
    transform: translate(-10px, 0);
    -webkit-transform: translate(-10px, 0);
  }

  .control-carousel:last-child:hover {
    transform: translate(10px, 0);
    -webkit-transform: translate(10px, 0);
  }

  .content-document-carousel {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .actions-buttons {
    text-align: center;
    margin-top: 0.5em;
    position: absolute;
    width: 100%;
  }

  .content-document-carousel embed {
    max-height: 80vh;
    min-height: 80vh;
    width: 100%;
    background-color: white;
  }

  .content-img-carousel {
    position: relative;
  }
  .content-img-carousel img {
    max-height: 80vh;
    max-width: 80vw;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .alert-carousel {
    position: fixed;
    width: 90vw;
    left: 0;
    right: 0;
    top: -4em;
    margin: auto;
    z-index: 100;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  .alert-carousel.in {
    top: 0;
  }

  .add-photos-carousel {
    text-align: center;
    padding: 0.5em;
  }

  .thumbnail-carousel {
    width: 80px;
    position: relative;
    margin: auto;
  }

  .thumbnail-carousel img {
    box-shadow: 0px 0px 2px 2px #b9b9b9;
    cursor: pointer;
  }

  .thumbnail-photo-nbr {

    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 0.28em;
    background: #040404ba;
    color: white;
    border-radius: 50%;
    width: 2em;
    text-align: center;
    height: 2em;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    cursor: pointer;
  }

  .thumbnail-carousel:hover:after {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }

  @media screen and (max-width: 600px) {
    .control-carousel {
      font-size: 2em;
      padding: 0.5em;
    }
    .content-img-carousel img {
      max-width: 70vw;
    }
  }
  .title-document {
    color: white;
    margin-bottom: 0px;
    text-align: center;
  }

  .counter-carousel {
    color: white;
    position: absolute;
    left: 10px;
    font-size: 24px;
  }
</style>
