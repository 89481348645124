<template>
  <ValidationTemplate :validatedObject="validatedObject">
    <template v-slot:validation-content>
      <div class="box has-text-centered">
        <p>{{$t("validation-message")}}{{$t("colon")}}
          <br />
          <br />
          <strong class="is-size-1">{{validatedObject.reference}}</strong>
        </p>
        <br />
        <br />
        <button class="button is-link" @click="getResumePDF">
          {{$t("pdf-resume-button")}}
        </button>
      </div>
      <div class="box has-text-centered">
        <p>
            {{$t("redirection-message")}}
        </p>
        <br />
        <router-link :to="'/expedition-module/list/' + validatedObject.id">
          <button class="button is-success is-medium">{{$t("ok-button")}}</button>
        </router-link>
      </div>
      <div  v-if="validatedObject.expeditionReturn" class="box has-text-centered">
        <template v-if="validatedObject.pickUp !== null">
          <router-link :to="'/pick-up-and-return-module/update/' + validatedObject.pickUp.id + '/0'">
            <button class="button is-success is-medium">{{$t("pick-up-and-return-edit")}}</button>
          </router-link>
        </template>
        <template v-else>
          <p>
            {{$t("pick-up-and-return-message")}}
          </p>
          <div class="buttons is-centered">
              <router-link :to="'/pick-up-and-return-module/create/' + validatedObject.id">
                <button class="button is-success is-medium">{{$t("simultaneous")}}</button>
              </router-link>
              <router-link :to="'/pick-up-and-return-module/create/' + validatedObject.id">
                <button class="button is-success is-medium">{{$t("deferred")}}</button>
              </router-link>
          </div>
        </template>
      </div>
      </template>
  </ValidationTemplate>
</template>
<script>
import apiClient from "@/client/client_logistics";
import ValidationMixin from '@/mixins/validationMixin'
import ValidationTemplate from '@/components/templates/ValidationTemplate'
export default {
    name: "ExpeditionValidation",
    mixins: [ValidationMixin],
    components: {ValidationTemplate},
    data() {
      return {
        errorKey: "expedition-retrieve-error",
      }
    },
    methods: {
      async getValidatedObject() {
        return apiClient.getExpedition(this.id)
      },
    }

}
</script>
