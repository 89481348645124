var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"expedition-button columns is-mobile is-multiline vcentered"},[_c('div',{staticClass:"column is-two-third"},[_c('h3',{staticClass:"is-size-6 has-text-primary has-text-weight-bold"},[_vm._v(_vm._s(_vm.expedition.reference)+" "),_c('span',{staticClass:"purchaser"},[_vm._v("("+_vm._s(_vm.enterpriseName)+")")])])]),_c('div',{staticClass:"column is-one-third has-text-right"},[_c('p',{staticClass:"has-text-grey-light is-size-7"},[_vm._v(_vm._s(_vm.expedition.deliveryDate))])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"media"},[_vm._m(0),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.expedition.recipientEntity.address.name))]),_c('p',[_vm._v(_vm._s(_vm.expedition.recipientEntity.address.zipCode)+" - "+_vm._s(_vm.expedition.recipientEntity.address.city))])])])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas",class:{
              'fa-truck': !_vm.expedition.isCanceled(),
              'fa-times': _vm.expedition.isCanceled(),
              'has-text-danger': !_vm.expedition.validation,
              'has-text-blue': _vm.expedition.validation && !_vm.expedition.isDelivered(),
              'has-text-green': _vm.expedition.validation && _vm.expedition.isDelivered()
            }})]),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.expedition.transporter))])])])]),_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"media"},[_vm._m(1),_c('div',{staticClass:"media-content has-text-grey"},[_c('p',[_vm._v(_vm._s(_vm.expedition.articleQuantity)+" "+_vm._s(_vm.$t("articles-label"))+" ")])])])]),_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"media"},[_vm._m(2),_c('div',{staticClass:"media-content has-text-grey"},[(_vm.expedition.pickUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("planned-pick-up-and-return"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("no-planned-pick-up-and-return"))+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas fa-user-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas fa-box-open"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-left"},[_c('i',{staticClass:"fas fa-exchange-alt"})])
}]

export { render, staticRenderFns }