<template>
  <div>
    <section class="section" v-if="!entityListLoading">
      <div class="container">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="columns is-vcentered">
              <div class="column is-1 has-text-primary">
                  <i class="fas fa-arrow-left fa-2x" v-if="currentSteps.value > 0" @click="previousStep"></i>
              </div>
              <div class="column is-10">
                <multiselect
                  class="has-text-primary has-text-weight-bold"
                  v-model="currentSteps"
                  :options="stepsAccess"
                  :searchable="false"
                  :allow-empty="false"
                  track-by="name"
                  label="name"
                >
                </multiselect>
              </div>
              <div class="column is-1 has-text-primary">
                <i class="fas fa-arrow-right fa-2x" v-if="currentSteps.value < validateStep" @click="nextStep"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="displayError && lastStepValid.errors[currentSteps.value].length > 0" class="notification is-danger">
          <span v-for="(error, index) in lastStepValid.errors[currentSteps.value]" :key="index">
            {{error}}
          </span>
        </div>
        <div v-if="errorsData && errorsData.nonFieldErrors">
          <h1 v-for="(error, index) in errorsData.nonFieldErrors" :key="index" class="has-text-centered error-message is-danger">
            {{error}}
          </h1>
        </div>
        <PickUpAndReturnStepsForm
          :value="value"
          :entityList="entityList"
          :catalogue="catalogue"
          :loading="loading"
          :currentSteps="currentSteps"
          :transporterList="transporterList"
          :contentAvailable="contentAvailable"
          :enterpriseList="enterpriseList"
          :entityListLoading="entityListLoading"
          :errorsData="errorsData"
          @cancel="$emit('cancel')"
          @input="update"
          @steps-finish="validationFunction"
          @step-finish="nextStep"
          @previous-step="previousStep"
          @change-step="changeStep">
        </PickUpAndReturnStepsForm>
      </div>
    </section>
    <section v-else class="section">
      <div class="container">
          <div class="loader-wrapper">
              <div class="loader is-loading"></div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import PickUpAndReturnStepsForm from '@/apps/pick_up_and_return/components/PickUpAndReturnStepsForm'
import expeditionFormMixin from '@/mixins/expeditionFormMixin'
import {checkAddress, checkContacts} from '@/utils/helpers'
import {Expedition} from '@/models/expedition'

export default{
  name: 'PickUpAndReturnForm',
  mixins: [expeditionFormMixin],
  components: {
    PickUpAndReturnStepsForm
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number
    },
    errorsData:{
      type: Object
    }
  },
  data: function(){
    return {
      contentAvailable: [],
      catalogue: [],
      validationFunction: () => this.$emit('validate'),

      steps: [
        { name: this.$t("pick-up-and-return-date-title"), value: 0, $isDisabled: true},
        { name: this.$t("delivery-title"), value: 1, $isDisabled: true},
        { name: this.$t("content-title"), value: 2, $isDisabled: true},
        { name: this.$t("insurance-title"), value: 3, $isDisabled: true},
        { name: this.$t("storage-type-title"), value: 4, $isDisabled: true},
        { name: this.$t("resume-title"), value: 5, $isDisabled: true},
      ],
      currentSteps: null,
      displayError: false,
      loadingError: false,
      validateStep: this.step || 0,
    }
  },
  computed: {
    lastStepValid () {
      // Each time we valid a step, lastStepValid is increased by one
      let value = 0 // Value of the last step valid to validate
      const errors = {} // All errors
      for (let i = 0; i < this.steps.length; i++) {
        errors[i] = []
      }
      // STEP 1
      // No special check to do verification is done by html (date)
      if(this.value.removalDate === null || this.value.removalDate === ""){
        errors[value].push(this.$t('pick-up-and-return-date-desired-label'))
        return {value, errors}
      }
      value += 1

      // STEP 2 check address
      const receiverSenderAddressErrors = checkAddress(
        this.value.recipientEntity.address
      )

      if(receiverSenderAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.deliveryDate === null || this.value.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }
      value += 1

      // STEP 3
      if(this.value.content.length === 0) {
        errors[value].push(this.$t('content-empty'))
        return {value, errors}
      }

      value += 4
      return {value, errors}
    },
  },
  methods: {
    nextStep () {
      window.scrollTo(0, 0)
      if (this.currentSteps.value === 1){
        if (this.value.recipientEntity.address.name.toLowerCase().includes('komaks') && (this.value.recipientEntity.id > 10 || this.value.recipientEntity.id === -1)) {
          window.console.log(this.currentSteps.name, this.steps)
          this.displayError = true
          return
        }
      }
      // Call the parent nextStep methode, way of overriting a mixin method
      expeditionFormMixin.methods.nextStep.call(this)
    },
    removeStorageTypesStep(){
      let index = this.steps.findIndex(step => step.value === 4)
      if(index !== -1){
        this.steps.splice(index, 1)
      }
    },
    addStorageTypesStep(){
      let index = this.steps.findIndex(step => step.value === 3)
      if(index !== -1 && this.steps.findIndex(step => step.value === 4) === -1){
        this.steps.splice(index + 1, 0, { name: this.$t("storage-type-title"), value: 4, $isDisabled: true})
      }
    },
    update(value) {
      this.$emit('input', new Expedition(value))
    }
  },
  beforeMount(){
    this.currentSteps = this.steps[0];

    if(this.step !== undefined){
      for(let i=0; i<this.step; i++){
        this.nextStep();
      }
      this.getKomaksCatalogue()
    }else{
      this.value.storageType = "BATCH"
      this.getKomaksCatalogue()
    }
  },
  watch: {
    'value.recipient': function(newRecipient){
      this.value.recipient = newRecipient;
      if(this.value.recipientEntity.enterprise !== null && this.value.recipientEntity.enterprise !== undefined && !this.value.recipientEntity.enterprise.isKomaks){
        this.removeStorageTypesStep()
      }else{
        this.addStorageTypesStep()
      }
    },
    errorsData: {
      handler: function(val){
          if(val !== null){
            if(val.senderEntity || val.removalDate){
              this.changeStep(0)
            }else if(val.recipient !== undefined || val.recipientEntity !== undefined || val.deliveryDate !== undefined){
              this.changeStep(1)
            }else if(val.content !== undefined){
              this.changeStep(2)
            }else if(val.insurance !== undefined || val.amountToEnsure !== undefined){
              this.changeStep(3)
            }else if(val.storageType !== undefined){
              this.changeStep(4)
            }
          }
      },
      deep: true
    }
  }
}

</script>
