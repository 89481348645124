<template>
  <div class="box container is-shadowless has-background-white-ter">
    <div v-if="errorsData && errorsData.content">
      <h1 v-for="(error, index) in errorsData.content" :key="index" class="has-text-centered error-message is-danger">
        {{error}}
      </h1>
    </div>
    <div v-if="editable" class="box container is-shadowless has-background-white-ter">
      <div class="has-text-centered">
        <div class="buttons is-centered">
          <button
            v-if="articles.length > 0"
            type="button"
            class="button is-link"
            @click="openNormalModal">
            {{$t("open-array-button")}}
          </button>
          <button
            v-if="catalogue.length > 0"
            type="button"
            class="button is-link"
            @click="openProductsModal">
            {{$t("open-array-product-button")}}
          </button>
          <button
            v-if="allConsumables.length > 0"
            type="button"
            class="button is-link"
            @click="openConsumableModal">
            {{$t("open-array-consumable-button")}}
          </button>
        </div>
      </div>
      <modal v-if="modalNormalOpen" @close="closeNormalModal" :extraCss="['big']">
        <template slot="header">
          <p class="modal-card-title">{{$t("content-title")}}</p>
          <button
            type="button"
            class="button is-pulled-right"
            aria-label="close"
            @click="closeNormalModal">
            <i class="fas fa-times"></i>
          </button>
        </template>
        <template slot="body">
          <DemoContent
            :actionValue="true"
            :contentList="articles"
            :haveSearch="true"
            :columns="columnsArticles"
            @validate="addItemToContent">
          </DemoContent>
        </template>
      </modal>
      <modal v-if="modalProductOpen" @close="closeConsumableOpen" :extraCss="['big']">
        <template slot="header">
          <p class="modal-card-title">{{$t("content-title")}}</p>
          <button
            type="button"
            class="button is-pulled-right"
            aria-label="close"
            @click="closeProductOpen">
            <i class="fas fa-times"></i>
          </button>
        </template>
        <template slot="body">
          <DemoContent
            :actionValue="true"
            :contentList="products"
            :haveSearch="true"
            :columns="columnsConsumables"
            @validate="addItemToContent">
          </DemoContent>
        </template>
      </modal>
      <modal v-if="modalConsumableOpen" @close="closeConsumableOpen" :extraCss="['big']">
        <template slot="header">
          <p class="modal-card-title">{{$t("content-title")}}</p>
          <button
            type="button"
            class="button is-pulled-right"
            aria-label="close"
            @click="closeConsumableOpen">
            <i class="fas fa-times"></i>
          </button>
        </template>
        <template slot="body">
          <DemoContent
            :actionValue="true"
            :contentList="consumables"
            :haveSearch="true"
            :columns="columnsConsumables"
            @validate="addItemToContent">
          </DemoContent>
        </template>
      </modal>
    </div>
    <div class="box">
      <DemoContent
        :contentList="value.content"
        :actionValue="false"
        :haveSearch="true"
        :columns="columnsArticles"
        @update-product="(product) => updateProduct(product)"
        @validate="removeItemFromContent"
        :editable="editable">
      </DemoContent>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import DemoContent from '@/apps/demo/components/DemoContent'
import vModelMixin from '@/mixins/vModelMixin'

import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ContentFieldDemo',
  mixins: [vModelMixin],
  components: {
    Modal,
    DemoContent,
  },
  props: {
    allArticles: { // All articles availables to create the demo
      type: Array,
      default: () => [],
    },
    allConsumables: { // All product from warehouses catalogue with categorie "MEDICAL CONSUMABLES"

      type: Array,
      default: () => [],
    },
    catalogue: { // All product from warehouses catalogue
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true
    },
    errorsData: {
      type: Object
    }
  },
  data () {
    return {
      modalNormalOpen: false,
      modalConsumableOpen: false,
      modalProductOpen: false,
      chooseArticle: true,
      columnsArticles: [
        {label: this.$t("qr-code-label"), field: "qrcode"},
        {label: this.$t("article-state-label"), field: "state"},
        {label: this.$t("article-denomination-label"), field: "denomination"},
        {label: this.$t("description-label"), field: "type"},
        {label: this.$t("serial-number-label"), field: "serialNumber"},
        {label: this.$t("note-label"), field: "note"},
        {label: this.$t("owner-label"), field: "owner"},
        {label: this.$t("status-label"), field: "status"},
        {label: this.$t("purchase-reference-label"), field: "purchaseReference"},
        {label: this.$t("quantity-label"), field: "quantity"},
        {label: "", field: "action", html: true, sortable: false}
      ],
      columnsConsumables: [
        {label: this.$t("article-denomination-label"), field: "denomination"},
        {label: this.$t("description-label"), field: "type"},
        {label: this.$t("serial-number-label"), field: "serial-number-button", html: true, sortable: false},
        {label: this.$t("quantity-label"), field: "quantity-button", html: true, sortable: false},
        {label: "", field: "action", html: true, sortable: false}
      ]
    }
  },

  computed: {
    articles(){
      return this.allArticles.filter(article => this.value.content.findIndex(art => art.qrcode === article.qrcode) === -1 )
    },
    consumables(){
      return this.allConsumables.filter(consu => this.value.content.findIndex(art => consu.id === art.id) === -1 )
    },
    products(){
      return this.catalogue.filter(consu => this.value.content.findIndex(art => consu.id === art.id) === -1 )
    }
  },
  methods: {
    closeNormalModal () {
      this.modalNormalOpen = false
    },

    closeConsumableOpen () {
      this.modalConsumableOpen = false
    },

    closeProductOpen () {
      this.modalProductOpen = false
    },

    openNormalModal () {
      this.modalNormalOpen = true
      this.modalConsumableOpen = false
      this.modalProductOpen = false
    },

    openConsumableModal () {
      this.modalNormalOpen = false
      this.modalConsumableOpen = true
      this.modalProductOpen = false
    },

    openProductsModal () {
      this.modalNormalOpen = false
      this.modalConsumableOpen = false
      this.modalProductOpen = true
    },

    addItemToContent (el) {
      if (el['quantity'] === undefined) {
        el['quantity'] = 1
      }
      this.value.content.push(el)
    },

    updateProduct (product) {
      const index = this.value.content.findIndex(productContent => product.id === productContent.id)
      const newProductsArray = cloneDeep(this.value.content)
      if (index !== -1) {
        newProductsArray[index] = product
      }
      this.update('content', newProductsArray)
    },
    removeItemFromContent (el, index) {
      const newProductsArray = cloneDeep(this.value.content)
      newProductsArray.splice(index, 1)
      this.update('content', newProductsArray)
    }
  }
}
</script>
