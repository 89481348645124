import ResumeTemplate from '@/components/templates/ResumeTemplate'

export default {
  props: {
    expedition: {
      type: Object
    },
    step: {
      type: Number,
      required: true
    },
    returnToStep: {
      type: Function,
      required: true,
    },
    extraCss: {
      type: Array,
      default: () => [],
    },
    locked: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ResumeTemplate
  },
  data: function (){
    return {
    }
  },
}
