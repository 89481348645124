import Vue from 'vue'
import { Modal } from '../models/modal'

export default {
  state: {
    isAuthenticated: false,
    token: null,
    user: null,
    modal: new Modal(),
  },
  getters: {
    modal: state => {
      return state.modal
    },
    user: state => {
      return state.user
    },
    isAdmin: state => {
      if(state.user && state.user.isAdministrator) return true
      return false
    }
  },
  mutations: {
    setAuthUser(state, { user, isAuthenticated }) {
      Vue.set(state, "user", user);
      Vue.set(state, "isAuthenticated", isAuthenticated)
    },
    updateToken(state, newToken) {
      localStorage.setItem("token", newToken)
      state.token = newToken
      state.isAuthenticated = true
    },
    updateUser(state, userData) {
      state.user = userData
      localStorage.setItem("user", JSON.stringify(state.user))
      if (state.user.token) {
        localStorage.setItem("token", state.user.token)
      }
      state.token = state.user.token
      state.isAuthenticated = true
    },
    removeToken(state) {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      state.token = null
      state.user = null
      state.isAuthenticated = false
    },
    setModalContent(state, modal) {
      state.modal = modal
    }
  },
  actions: {
    openModal({ commit, state }, message) {
      const modal = Modal.fromState(state.modal)
      modal.addContent(message)
      modal.isOpen = true
      commit("setModalContent", modal)
    },
    closeModal({ commit }) {
      commit("setModalContent", new Modal())
    },
    logout({ commit }) {
      commit("removeToken")
    },
  },
}