<template>
  <div class="box">
    <SectionTitle :title="$t('constraints-title')" :stairs="true"></SectionTitle>
    <div class="field">
      <label class="label"> {{ $t("floors-number-label")}}</label>
      <input
        id="floorNumber"
        class="input"
        :class="{'is-danger': errorOnValue('floorNumber')}"
        type="number"
        :value="value.floorNumber"
        @input="update('floorNumber', $event.target.value)" />
      <p
        v-if="errorOnValue('floorNumber')"
        class="help is-danger">
        {{errorsData.floorNumber[0]}}
      </p>
    </div>

    <div class="field">
      <label class="label"> {{ $t("stairs-number-label")}}</label>
      <input
        id="stairNumber"
        class="input"
        :class="{'is-danger': errorOnValue('stairNumber')}"
        type="number"
        min="0"
        :value="value.stairNumber"
        @input="update('stairNumber', $event.target.value)" />
      <p
        v-if="errorOnValue('stairNumber')"
        class="help is-danger">
        {{errorsData.stairNumber[0]}}
      </p>
    </div>

    <div v-if="value.stairNumber>0 || value.floorNumber>0" class="field">
      <div class="columns">
        <div class="column is-half">
          <label class="label"> {{ $t("elevator-label")}}{{$t("colon")}}</label>
        </div>
        <div class="column is-half">
          <input
            id="elevator"
            type="checkbox"
            :class="{'is-danger': errorOnValue('elevator')}"
            :checked="value.elevator"
            @change="update('elevator', !value.elevator)" />
        </div>
      </div>
      <p
        v-if="errorOnValue('elevator')"
        class="help is-danger">
        {{errorsData.elevator[0]}}
      </p>
    </div>

    <div v-if="value.stairNumber>0 || value.floorNumber>0" class="field">
      <div class="columns">
        <div class="column is-half">
          <label class="label">{{ $t("staircase-type-label")}}{{$t("colon")}}</label>
        </div>
        <div class="column is-half">
          <select
            v-if="staircaseTypes !== null"
            id="staircaseTypes"
            :value="value.staircaseType"
            :class="{'is-danger': errorOnValue('staircaseType')}"
            @change="update('staircaseType', $event.target.value)">
            <option v-for="(staircaseItem, staircaseKey, staircaseIndex) in staircaseTypes"
              v-bind:key="staircaseIndex"
              :value="staircaseKey">
              {{staircaseItem}}
            </option>
          </select>
        </div>
        <p
          v-if="errorOnValue('staircaseType')"
          class="help is-danger">
          {{errorsData.staircaseType[0]}}
        </p>
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $t("other-constraints-label")}}</label>
      <textarea
        id="otherInformations"
        class="textarea"
        :class="{'is-danger': errorOnValue('otherInformations')}"
        :value="value.otherInformations"
        @input="update('otherInformations', $event.target.value)">
        </textarea>
      <p
        v-if="errorOnValue('otherInformations')"
        class="help is-danger">
        {{errorsData.otherInformations[0]}}
      </p>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import vModelMixin from '@/mixins/vModelMixin'
  export default {
    name: 'ConstraintsField',
    mixins: [vModelMixin],
    props:{
      errorsData: {
        type: Object
      }
    },
    components: {
      SectionTitle
    },
    computed: {
      staircaseTypes(){
        return this.$store.getters['commonData/staircaseTypes']
      }
    },
    methods: {
      errorOnValue(nameField){
        if(this.errorsData && this.errorsData[nameField]){
          return true
        }
        return false
      }
    },
    watch: {
      // Remove staircaseType if there are not floors and stairs
      "value.stairNumber": function (val) {
        if(val == 0 && this.value.floorNumber == 0) {
          this.update('staircaseType', "NO")
        }
      },
      // Remove staircaseType if there are not floors and stairs
      "value.floorNumber": function (val) {
        if(val == 0 && this.value.stairNumber == 0) {
          this.update('staircaseType', "NO")
        }
      }
    }
  }
</script>
