<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-if="isTransporter && !isRemovalDatePassed && !isEditionClosed && !expedition.isHistory()" v-slot:details-update>
      <button v-if="!isUpdating" class="router-button button is-small is-text" @click="startUpdate()">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
        <span>{{$t("update-button")}}</span>
      </button>
    </template>
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <template v-if="!isUpdating">

          <div class="column is-one-third has-text-right">
            <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{expedition.transporter}}
          </div>

          <div class="column is-one-third has-text-right">
            <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <div v-for="(contact, index) in expedition.transporterContacts" v-bind:key="index">
              {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
            </div>
          </div>

          <div class="column is-one-third has-text-right">
            <strong>{{$t("immatriculation-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.transporterImmatriculation}}<br />
          </div>
        </template>
        <div v-else class="column is-full">
          <form @submit.prevent="validateUpdate">
            <ContactsField
              v-model="contacts"
              :errorsData="(errorsData && errorsData.transporterContacts) ? errorsData.transporterContacts : null">
            </ContactsField>
            <div class="field">
              <label class="label"> {{$t("immatriculation-label")}} *</label>
                <div class="controls">
                  <input class="input" type="text" v-model="immatriculation">
                </div>
            </div>
            <div class="buttons is-centered">
              <button class="button" type="button" @click="cancelUpdate()">{{$t("cancel-button")}}</button>
              <button class="button is-primary">{{$t("validate-button")}}</button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
import { isPassedDate, addDays, dateToString, deepCopy } from '@/utils/helpers'
import ContactsField from '@/components/fields/ContactsField'
import {ModalData} from '@/models/modal'
export default {
  name: 'detailsTransporter',
  mixins: [detailsStepMixin],
  components: {ContactsField},
  props: {
    isTransporter: {
      type: Boolean,
      required: true
    },
  },
  data () {
    return {
      isUpdating: false,
      contacts: [],
      immatriculation: '',
      errorsData: null
    }
  },
  computed: {
    isEditionClosed() {
      return isPassedDate(dateToString(addDays(this.expedition.deliveryDate, 2)))
    }
  },
  methods:{
    startUpdate(){
      this.contacts = deepCopy(this.expedition.transporterContacts);
      this.immatriculation = this.expedition.transporterImmatriculation;
      this.isUpdating = true;
    },
    cancelUpdate(){
      this.isUpdating = false;
    },
    validateUpdate(){
      const expeditionData = {
        'transporterContacts': this.contacts,
        'transporterImmatriculation': this.immatriculation
      }
      this.expedition.updateTransporterInfo(expeditionData).then(() => {
        this.expedition.transporterContacts = deepCopy(this.contacts)
        this.expedition.transporterImmatriculation = this.immatriculation
        this.isUpdating = false
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errorsData = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
      })

    }
  }
}
</script>
