<template>
  <div v-if="accessGranted">
    <progress v-if="loadingRetrive" class="progress is-small is-primary" max="100">15%</progress>
    <DemoForm v-else
      v-model="demo"
      :loading="loading"
      :step="step"
      :transporters="transporterList"
      @validate-demo="updateDemo"
      @cancel="cancel"
      :errorsData="errorsData">
    </DemoForm>
  </div>
</template>
<script>
import DemoForm from '@/apps/demo/components/DemoForm'
import apiClient from '@/client/client_logistics'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import updateFormMixin from '@/mixins/updateFormMixin.js'
import { Expedition } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'DemoUpdate',
  components: {
    DemoForm
  },
  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin,
    updateFormMixin,
  ],

  data (){
    return {
      demo: {
        expedition: null,
        reprise: null,
      },
      originalDemo: {
        expedition: null,
        reprise: null,
      },
      loading: false,
      loadingRetrive: true,
      isExpeditionCreated: false,
      finalRoutePath: '/demo-module/validate/:id/:idPickUpAndReturn',
      errorsData: {
        errorsExpedition: null,
        errorsReturn: null
      },
      transporterList: []
    }
  },
  props: {
   id: {
    type: Number,
    required: true,
   },
   idPickUpAndReturn: {
    type: Number,
    required: true,
   },
   step: {
    type: Number,
    required: true,
   },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveDemoAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
  },
  created() {
    this.getTransporterList()
    // Retrieving expedition
    apiClient.getLoan(this.id).then(res => {
      const expedition = res.data
      const promises = []
      promises.push(this.getEnterprise(expedition.purchaser))
      if (expedition.sender !== null) {
        promises.push(this.getEnterprise(expedition.sender))
      }
      Promise.all(promises).then(datas => {
        this.demo.expedition = new Expedition(expedition)
        this.originalDemo.expedition = new Expedition(expedition)

        this.demo.expedition.purchaserEntity.enterprise = datas[0]
        delete this.demo.expedition.purchaserEntity.enterprise.entities

        this.originalDemo.expedition.purchaserEntity.enterprise = datas[0]
        delete this.originalDemo.expedition.purchaserEntity.enterprise.entities

        if (this.demo.expedition.sender !== null) {
          this.demo.expedition.senderEntity.enterprise = datas[1]
          delete this.demo.expedition.senderEntity.enterprise.entities

          this.originalDemo.expedition.senderEntity.enterprise = datas[1]
          delete this.originalDemo.expedition.senderEntity.enterprise.entities
        }

        if(this.demo.reprise !== null && this.accessGranted) {
          this.loadingRetrive = false
          this.redirectIfContentCantBeUpdated()
          this.initTransporter()
        }
      }).catch(() => this.trowErrorLoading())
    }).catch(() => {
      this.trowErrorLoading()
    })
    // Retriveving PickUp
    apiClient.getPickUpAndReturn(this.idPickUpAndReturn).then(res => {
      const recovery = res.data
      this.getEnterprise(recovery.purchaser).then(data => {
        this.demo.reprise = new Expedition(recovery)
        this.demo.reprise.removalDate = recovery['removalDate']
        this.demo.reprise.purchaserEntity.enterprise = data
        delete this.demo.reprise.purchaserEntity.enterprise.entities

        this.originalDemo.reprise = new Expedition(recovery)
        this.originalDemo.reprise.removalDate = recovery['removalDate']
        this.originalDemo.reprise.purchaserEntity.enterprise = data
        delete this.originalDemo.reprise.purchaserEntity.enterprise.entities

        if(this.demo.expedition !== null && this.accessGranted) {
          this.loadingRetrive = false
          this.redirectIfContentCantBeUpdated()
          this.initTransporter()
        }
      })
    }).catch(() => {
      this.trowErrorLoading()
    })
  },

  methods: {
    getTransporterList(){
      apiClient.getListTransporters().then((response) => {
        this.transporterList = [...new Set(response.data.results)]
        if (this.demo.expedition !== null && this.demo.reprise !== null) {
          this.initTransporter()
        }
      }).catch(() => {
        this.trowErrorLoading()
      })
    },

    initTransporter() {
        if (this.transporterList.length !== 0) {
          const index = this.transporterList.findIndex(element => element.businessName === this.demo.expedition.transporter)
          if (index !== -1) {
            this.demo.expedition.transporterId = this.transporterList[index].id
            this.originalDemo.expedition.transporterId = this.transporterList[index].id
            this.demo.reprise.transporterId = this.transporterList[index].id
            this.originalDemo.reprise.transporterId = this.transporterList[index].id
          }
        }
    },
    // Redirect with an error if loan's pickup can't be updated
    redirectIfContentCantBeUpdated() {
      if(!this.demo.expedition.canBeUpdated() && !this.demo.reprise.canBeUpdated()) {
        this.forceChangeRoute = true;
        // If there is a demo with removal date passed and not delivered
        // we change the default message
        if (
          !this.demo.expedition.canBeUpdated() &&
          this.demo.reprise.isRemovalDatePassed() &&
          !this.demo.reprise.isDeliveryDatePassed()
        ) {
          this.cannotEditError(
            this.demo.expedition,
            this.$t('cant-edit-after-loan-return-removal-date')
          )
        }
        else this.cannotEditError(this.demo.expedition)
        return;
      }
    },
    async getEnterprise(enterpriseId){
      return apiClient.getEnterprise(enterpriseId).then((response) => {
        return response.data
      })
    },
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push(`/demo-module/list/${this.id}`)
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    async updateDemo() {
      this.loading = true
      this.updateExpedition()
    },
    async updateExpedition() {
      if (!this.demo.expedition.canBeUpdated()) {
        this.updatePickUp()
      } else {

        if(this.demo.expedition.transporterId === null) {
          let transporter = {
            businessName: this.demo.expedition.transporter,
            contactsList: this.demo.expedition.transporterContacts
          }
          const [transporterResp, transporterError] = await handlePromise(
            this.createTransporter(transporter)
          )
          if (transporterError) { // createTransporter have an issue
            this.loading = false
            this.throwTransporterError()
            return // Stop here, block the rest of the creation
          } else {
            this.demo.expedition.transporterId = transporterResp.id
          }
        }

        if(this.demo.expedition.purchaserEntity.id === this.originalDemo.expedition.purchaserEntity.id) {
          this.originalDemo.expedition.purchaserEntity.entityName = this.demo.expedition.purchaserEntity.entityName
        }

        if(this.demo.expedition.senderEntity.id === this.originalDemo.expedition.senderEntity.id) {
          this.originalDemo.expedition.senderEntity.entityName = this.demo.expedition.senderEntity.entityName
        }

        const dataFromExpedition = this.demo.expedition.getFormData(this.user.isAdministrator)
        const dataToSendFromOriginalExpedition = this.originalDemo.expedition.getFormData(
          this.user.isAdministrator
        )
        const dataSended = this.getDifferenceWithOriginal(dataToSendFromOriginalExpedition, dataFromExpedition)

        // Nothing to update
        if (Object.keys(dataSended).length === 0) {
          this.updatePickUp()
          return
        }
        return apiClient.partialUpdateLoan(
          dataSended,
          this.demo.expedition.id
        ).then(() => {
          this.updatePickUp()
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errorsData.errorsExpedition= e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
      }
    },
    trowErrorLoading() {
      if(this.loadingError) {
        return
      }
      this.loadingError = true
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
            text: this.$t('data-receive-error'),
          }],
          {
            refresh: {
              active: true,
              css: 'is-danger',
              icon: 'fa-redo',
              onClick: () => {
                  this.$store.dispatch('closeModal')
                  this.$router.go()
              }
            },
            home: {
              active: true,
              css: 'is-info',
              icon: 'fa-home',
              onClick: () => {
                this.$store.dispatch('closeModal')
                this.$router.push('/')
              }
            }
          }
        )
      )
    },
    async updatePickUp() {
      const dataFromPickup = this.demo.reprise.getFormData(this.user.isAdministrator)
      const dataToSendFromOriginalExpedition = this.originalDemo.reprise.getFormData(
        this.user.isAdministrator
      )
      const dataSended = this.getDifferenceWithOriginal(dataToSendFromOriginalExpedition, dataFromPickup)

      // Nothing to update
      if (Object.keys(dataSended).length === 0) {
        this.$router.push(`/demo-module/validate/${this.demo.expedition.id}/${this.demo.reprise.id}`)
        return
      }
      return apiClient
        .updatePickUpAndReturn(dataSended, this.demo.reprise.id)
        .then((response) => {
          this.demo.reprise.pdfUrl = response.data.pdfUrl
          this.demo.reprise.reference = response.data.reference
          this.demo.reprise.id = response.data.id
          this.loading = false;
          this.$router.push(`/demo-module/validate/${this.demo.expedition.id}/${this.demo.reprise.id}`)
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errorsData.errorsReturn= e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('pick-up-and-return-loan-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        });
    },
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
