<template>
  <div :class="extraCss">
    <div class="card resume-card">
      <header class="card-header">
        <h1 class="card-header-title">
          {{step}}. {{$t(title)}}
        </h1>
        <div v-if="locked" align="right" class="card-header-action">
          <i class="fas fa-lock"></i>
        </div>
        <div v-else align="right" class="card-header-action" @click="returnToStep(step)">
          <span class="card-header-action-name">{{ $t("update-button")}} </span>
          <i class="fas fa-edit"></i>
        </div>
      </header>
      <div class="card-content">
        <slot name="resume-content">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ResumeStepTemplate',
  props: {
    step: {
      type: Number,
      required: true,
    },
    extraCss: {
      type: Array,
      default: () => [],
    },
    locked: {
      type: Boolean,
      default: false,
    },
    returnToStep: {
      type: Function
    },
    title: {
      type: String,
    }
  }
}
</script>
