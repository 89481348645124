import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {path: '*', component: NotFound}
];


import expeditionRoutes from '@/apps/expedition/router.js'
routes = routes.concat(expeditionRoutes)
import pickUpAndReturnRoutes from '@/apps/pick_up_and_return/router.js'
routes = routes.concat(pickUpAndReturnRoutes)

import demoRoutes from '@/apps/demo/router.js'
routes = routes.concat(demoRoutes)

import stockRoutes from '@/apps/stock/router.js'
routes = routes.concat(stockRoutes)

import transferRoutes from '@/apps/transfer/router.js'
routes = routes.concat(transferRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
