<template>
  <div>
    <div class="card-content">
      <div v-for="(message, index) in messages" v-bind:key="index">
        <div :class="[{'is-primary': index % 2 === 0}, 'notification', 'm-b-xs']">
          <p class="is-size-7 has-text-weight-bold">{{ message.creator }} - {{ formatDate(message.created) }} : </p>
          <p class="line-returns">{{message.content}}</p>
        </div>
      </div>
      <p v-if="messages.length === 0" class="has-text-info">
        {{ $t('no-message-chat') }}
      </p>
    </div>

    <div class="card-content">
      <div class="field">
        <textarea class="textarea" v-model="content" :placeholder="$t('your-message-placeholder')"></textarea>
      </div>
      <button
        type="button"
        :disabled="content.length === 0 || loading"
        class="button is-primary"
        :class="{'is-loading': loading}"
        @click="postMessage">
        {{ $t('send-button') }}
      </button>
    </div>
  </div>
</template>

<script>
import apiClient from '@/client/client_logistics'
import { ModalData } from '@/models/modal'

export default {
  name: 'Chat',
  props: {
    value: {
      type: Object
    },
    objectType: {
      type: String
    }
  },
  data: function() {
    return{
      messages: [],
      nbrMessages: 0,
      loading: false,
      newMessages: 0,
      content: ''
    }
  },
  beforeMount(){
    if(this.value.chatPk !== -1){
      this.getMessages()
    }else{
      this.messages = []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    formatDate(dateData) {
      return dateData.toLocaleString("fr-FR")
    },
    getMessages() {
      if(this.value.chatPk !== -1) {
        apiClient.getChatMessages(this.value.chatPk).then((response) => {
          this.messages = []
          response.data.messages.forEach(message => {
            const messageObject = message;
            messageObject.created = new Date(messageObject.created)
            this.messages.push(messageObject)
          })
          this.nbrMessages = this.messages.length
        }).catch(() => {
          this.$store.dispatch(
            "openModal",
            new ModalData(
              {
                text: this.$t("error-title"),
                css: "is-danger"
              },
              [{
                text: this.$t("chat-receive-messages-error"),
              }],
              {
                cancel: {
                  active: true,
                  css: "is-danger",
                  onClick: () => {
                    this.$store.dispatch("closeModal");
                  }
                },
              }
            )
          )
        })
      }
    },
    postMessage(){
      this.loading = true
      if(this.value.chatPk === -1) {
        const postData = {
          "objectId": this.value.id,
          "typeOfContent": this.objectType
        }

        apiClient.createChat(postData).then((response) => {
          const res = response.data
          this.value.chatPk = res.pk
          this.postMessage()
        }).catch(() => {
          this.loading = false
          this.$store.dispatch(
            "openModal",
            new ModalData(
              {
                text: this.$t("error-title"),
                css: "is-danger"
              },
              [{
                text: this.$t("chat-create-error"),
              }],
              {
                cancel: {
                  active: true,
                  css: "is-danger",
                  onClick: () => {
                    this.$store.dispatch("closeModal");
                  }
                },
              }
            )
          )
        })
      } else {
        const messageData = {
          "content": this.content
        }
        let newMessage = {
          'content': this.content,
          'creator': this.user.username,
          'created': new Date(Date.now()),
          'pk': -1
        }
        this.messages.push(newMessage)
        this.nbrMessages = this.messages.length
        apiClient.postMessage(this.value.chatPk, messageData).then((response) => {
          const messages = this.messages
          const message = response.data
          message.created = new Date(message.created)
          this.messages.pop()
          messages.push(message)
          this.messages = messages
          this.nbrMessages = this.messages.length
          this.content = ''
          this.loading = false
        }).catch(() => {
          this.messages.pop()
          this.nbrMessages = this.messages.length
          this.loading = false
          this.$store.dispatch(
            "openModal",
            new ModalData(
              {
                text: this.$t("error-title"),
                css: "is-danger"
              },
              [{
                text: this.$t("chat-post-error"),
              }],
              {
                cancel: {
                  active: true,
                  css: "is-danger",
                  onClick: () => {
                    this.$store.dispatch("closeModal");
                  }
                },
              }
            )
          )
        })
      }
    }
  }
}
</script>

<style scoped>
  .chat-body-container {
    max-height: 200px;
    overflow-y: scroll;
  }
</style>
