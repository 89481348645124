<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-third has-text-right">
          <strong> {{$t("name-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
           {{enterpriseName}}<br />
        </div>
        <div class="column is-one-third has-text-right">
          <strong> {{$t("address-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span>
            {{ expedition.purchaserEntity.address.line }} - <em>{{ expedition.purchaserEntity.address.lineAdditionalInfo }}</em><br />
            {{ expedition.purchaserEntity.address.zipCode}} {{ expedition.purchaserEntity.addresscity }} ({{expedition.purchaserEntity.address.getCountryName()}})
          </span>
        </div>

        <div class="column is-one-third has-text-right">
          <strong> {{$t("contact-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <div v-for="(contact, index) in expedition.purchaserEntity.contacts" v-bind:key="index">
            {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
          </div>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
export default {
  name: 'detailsPurchaser',
  mixins: [detailsStepMixin],
  computed: {
    enterpriseName () {
      return this.expedition.purchaserEntity.address.name
    },
  }
}
</script>
