<template>
  <div>
    <ExpeditionStepsForm
      :value="value.expedition"
      @input="updateExpedition(false, $event)"
      :loading="loading"
      :currentSteps="currentSteps"
      :steps="['purchaser', 'sender', 'transporter', 'recipient', 'content']"
      :transporterList="transporterList"
      :contentAvailable="contentAvailable"
      :enterpriseList="enterpriseList"
      :entityList="entityList"
      :entityListLoading="entityListLoading"
      :errorsData="errorsData.errorsExpedition"
      @cancel="$emit('cancel')"
      @steps-finish="$emit('next-step-expedition-demo')"
      @step-finish="$emit('next-step-expedition-demo')"
      @change-step="(step) => $emit('change-step', step)"
      @previous-step="$emit('previous-step')">
      <template v-slot:content-step>
        <section>
          <div v-if="isExpeditionRemovalDatePassed" class="box">
            <div class="notification is-warning">
              {{$t('content-after-removal-date')}}
            </div>
          </div>
          <ContentFieldDemo
            :value="value.expedition"
            @input="updateExpedition(false, $event)"
            :allArticles="allArticles"
            :allConsumables="allConsumables"
            :catalogue="catalogue"
            :errorsData="errorsData.errorsExpedition"
            :editable="!isExpeditionRemovalDatePassed"
          >
          </ContentFieldDemo>
        </section>
      </template>
    </ExpeditionStepsForm>

    <PickUpAndReturnStepsForm
      :value="value.reprise"
      @input="updateExpedition(true, $event)"
      :loading="loading"
      :stepsStart="5"
      :entityList="entityList"
      :catalogue="catalogue"
      :currentSteps="currentSteps"
      :transporterList="transporterList"
      :contentAvailable="contentAvailable"
      :enterpriseList="enterpriseList"
      :entityListLoading="entityListLoading"
      :errorsData="errorsData.errorsPickUp"
      :isForDemo="true"
      :steps="['date', 'recipient']"
      @cancel="$emit('cancel')"
      @steps-finish="$emit('next-step')"
      @step-finish="$emit('next-step')"
      @change-step="(step) => $emit('change-step', step)"
      @previous-step="$emit('previous-step')">
    </PickUpAndReturnStepsForm>

    <ExpeditionStepFormTemplate v-if="currentSteps.value == 7" @step-finish="$emit('validate-demo')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
      <!-- Resume Demo -->
        <div class="resume-expedition columns is-multiline">
          <ResumePurchaser
            :expedition="value.expedition"
            :step="1"
            :returnToStep="() => returnToStep(0)"
            :extraCss="['column', 'is-half']"
          />
          <ResumeTransporter
            :expedition="value.expedition"
            :step="2"
            :returnToStep="() => returnToStep(2)"
            :extraCss="['column', 'is-half']"
          />
          <ResumeRemoval
            :expedition="value.expedition"
            :step="3"
            :returnToStep="() => returnToStep(1)"
            :extraCss="['column', 'is-half']"
          />
          <ResumeRecipient
            :expedition="value.expedition"
            :step="4"
            :returnToStep="() => returnToStep(3)"
            :extraCss="['column', 'is-half']"
          />
          <ResumeTemplate
            :extraCss="['column', 'is-half']"
            :step="5"
            :title="'pick-up-and-return-delivery-date-title'"
            :returnToStep="() => returnToStep(5)"
          >
             <template v-slot:resume-content>
              <div class="card-content">
                <div class="columns is-mobile">
                  <div class="column is-one-third has-text-right">
                    <strong>{{$t("pick-up-and-return-delivery-date-title")}}{{$t("colon")}} </strong>
                  </div>
                  <div class="column is-two-thirds has-text-primary has-text-weight-bold">
                    {{ value.reprise.removalDate }}
                  </div>
                </div>
                <div v-if="value.reprise.senderEntity.haveAmSchedule || value.reprise.senderEntity.havePmSchedule" class="columns is-mobile">
                  <div class="column is-one-third has-text-right">
                    <strong>{{$t("time-slot-label")}}{{$t("colon")}} </strong>
                  </div>
                  <div class="column is-two-thirds has-text-weight-bold">
                    <strong v-if="value.reprise.senderEntity.haveAmSchedule">{{value.reprise.senderEntity.amOpenStart}} - {{value.reprise.senderEntity.amOpenEnd}}</strong>
                    <strong v-if="value.reprise.senderEntity.haveAmSchedule && value.reprise.senderEntity.havePmSchedule">/</strong>
                    <strong v-if="value.reprise.senderEntity.havePmSchedule">{{value.reprise.senderEntity.pmOpenStart}} - {{value.reprise.senderEntity.pmOpenEnd}}</strong>
                  </div>
                </div>
              </div>
            </template>
          </ResumeTemplate>
          <ResumeRecipient
            :expedition="value.reprise"
            :title="'pick-up-and-return-delivery-title'"
            :step="6"
            :returnToStep="() =>  returnToStep(6)"
            :extraCss="['column', 'is-half']"
          />
          <ResumeContent
            v-if="value.expedition.content.length > 0"
            :expedition="value.expedition"
            :step="7"
            :returnToStep="() => returnToStep(4)"
            :extra-css="['column', 'is-full']"
          />
        </div>
      <!-- End Resume Demo-->
      </template>
      <template v-slot:buttons>
        <div class="buttons is-centered">
          <div class="button is-warning" @click="$emit('previous-step')">
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
            <span>{{$t("previous-button")}}</span>
          </div>
          <button class="button is-primary" :disabled="loading" :class="{'is-loading': loading}">
            <span>{{$t("validate-button")}}</span>
          </button>
        </div>
        <div class="buttons is-centered">
          <div class="button is-danger" @click="$emit('cancel')">
            {{$t('cancel-button')}}
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>
  </div>
</template>
<script>
import ExpeditionStepsForm from '@/apps/expedition/components/ExpeditionStepsForm'
import PickUpAndReturnStepsForm from '@/apps/pick_up_and_return/components/PickUpAndReturnStepsForm'
import ExpeditionStepFormTemplate from '@/components/templates/ExpeditionStepFormTemplate'
import ContentFieldDemo from '@/apps/demo/components/ContentFieldDemo'

import ResumePurchaser from '@/components/resume/ResumePurchaser'
import ResumeTransporter from '@/components/resume/ResumeTransporter'
import ResumeRemoval from '@/components/resume/ResumeRemoval'
import ResumeRecipient from '@/components/resume/ResumeRecipient'
import ResumeContent from '@/components/resume/ResumeContent'
import ResumeTemplate from '@/components/templates/ResumeTemplate'

import { deepCopy } from '@/utils/helpers'
import vModelMixin from '@/mixins/vModelMixin'
import { Expedition } from '@/models/expedition'
import { mapGetters } from 'vuex'

export default {
  name: "DemoStepsForm",
  mixins: [vModelMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    currentSteps: {
      type: Object,
      required: true,
    },
    transporterList: {
      type: Array,
      default: () => []
    },
    contentAvailable: {
      type: Array,
      default: () => []
    },
    enterpriseList: {
      type: Array,
      default: () => []
    },
    enterpriseListLoading: {
      type: Boolean,
      default: false,
    },
    entityList: {
      type: Array,
      default: () => []
    },
    entityListLoading: {
      type: Boolean,
      default: false
    },
    allArticles: {
      type: Array,
      default: () => []
    },
    allConsumables: {
      type: Array,
      default: () => []
    },
    catalogue: {
      type: Array,
      default: () => []
    },
    errorsData: {
      type: Object
    }
  },
  components: {
    ContentFieldDemo,
    ExpeditionStepsForm,
    ExpeditionStepFormTemplate,
    PickUpAndReturnStepsForm,
    ResumePurchaser,
    ResumeTransporter,
    ResumeRemoval,
    ResumeRecipient,
    ResumeContent,
    ResumeTemplate,
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isExpeditionRemovalDatePassed () {
      if(this.isAdmin) return false
      return this.value.expedition.isRemovalDatePassed()
    },
  },
  methods: {
    returnToStep(step){
      this.$emit('change-step', step)
    },
    updateExpedition(isPickUp, newExpeditionData) {
      if (!isPickUp) {
        // We must copy somes data from expedition to pickup datas
        const pickup = this.value.reprise

        // Copy purchaser / purchaserEntity
        pickup.purchaserEntity = deepCopy(newExpeditionData.purchaserEntity)
        pickup.purchaser = newExpeditionData.purchaser

        // Copy recipient expedition to sender for pickup
        // if there is already times datas we don't want to overide it
        let haveAmSchedule = false, havePmSchedule = false, amOpenStart = '', amOpenEnd = '', pmOpenStart = '', pmOpenEnd = '', haveTimePreferences = true
        if(this.value.reprise.senderEntity.address.name !== undefined && this.value.reprise.senderEntity.address.name !== "" && this.value.reprise.senderEntity.address.name !== null){
          // Store data before reasign
          haveAmSchedule = this.value.reprise.senderEntity.haveAmSchedule
          havePmSchedule = this.value.reprise.senderEntity.havePmSchedule
          amOpenStart = this.value.reprise.senderEntity.amOpenStart
          amOpenEnd = this.value.reprise.senderEntity.amOpenEnd
          pmOpenStart = this.value.reprise.senderEntity.pmOpenStart
          pmOpenEnd = this.value.reprise.senderEntity.pmOpenEnd
          haveTimePreferences = this.value.reprise.senderEntity.haveTimePreferences
        }

        const senderEntity = deepCopy(newExpeditionData.recipientEntity)
        // Replace schedule data
        senderEntity.haveAmSchedule = haveAmSchedule
        senderEntity.havePmSchedule = havePmSchedule
        senderEntity.amOpenStart = amOpenStart
        senderEntity.pmOpenStart = pmOpenStart
        senderEntity.amOpenEnd = amOpenEnd
        senderEntity.pmOpenEnd = pmOpenEnd

        // Assign sender (recipient from expedition)
        pickup.senderEntity = senderEntity
        if(newExpeditionData.recipient) {
          pickup.sender = newExpeditionData.recipient
        }
        pickup.haveTimePreferences = haveTimePreferences

        // /!\ Work only the first time /!\
        if(this.value.reprise.recipientEntity.address.name === undefined ||
            this.value.reprise.recipientEntity.address.name === "" || this.value.reprise.recipientEntity.address.name === null){
          pickup.recipientEntity = deepCopy(newExpeditionData.senderEntity)
        }

        if(this.value.reprise.recipient === null){
          pickup.recipient = newExpeditionData.sender
        }

        // copy transporter
        pickup.transporter = newExpeditionData.transporter
        pickup.transporterContacts = newExpeditionData.transporterContacts
        pickup.transporterId = newExpeditionData.transporterId
        pickup.transporterImmatriculation = newExpeditionData.transporterImmatriculation

        const newDemoInstance = {
          expedition: new Expedition(newExpeditionData),
          reprise: new Expedition(pickup)
        }
        // newExpeditionData.reprise = new Expedition(pickup)
        // newExpeditionData.demo = new Expedition(newExpeditionData.demo)
        this.updateFromObject(newDemoInstance)

      } else {
        this.update('reprise', new Expedition(newExpeditionData))
      }
    }
  }
}
</script>
