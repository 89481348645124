<template>
  <div class="container">
    <div class="box">
      <SectionTitle :title="contactsTitle" imgName="fas fa-user"></SectionTitle>
      <div>
        <div v-if="value.length>1" class="columns has-text-centered is-centered">
          <div class="column is-one-fifth">
            <button v-if="currentIndex!=0" type="button" class="button is-pulled-left" @click="previousContact">
              <span class="icon">
               <i class="fas fa-arrow-circle-left"></i>
             </span>
            </button>
          </div>
          <div class="column is-one-third">
            <select
              class="select"
              :value="currentIndex"
              @change="selectContact">
              <option v-for="(contact, key) in value" v-bind:key="key" v-bind:value="key">{{contact.name}}</option>
            </select>
          </div>
          <div class="column is-one-fifth">
            <button v-if="currentIndex!=value.length-1" type="button" class="button is-pulled-right" @click="nextContact">
              <span class="icon">
               <i class="fas fa-arrow-circle-right"></i>
             </span>
            </button>
          </div>
        </div>
      </div>
      <br />
      <div align="right">
        <button type="button" v-if="value.length>1 && editable" class="button is-small is-danger" @click="removeContact(currentIndex)">
            <i class="fas fa-times-circle"></i>
        </button>
      </div>

      <div class="field">
        <label class="label"> {{ $t("phone-number-label") }} </label>
        <VuePhoneNumberInput
          v-model="phoneNumbers[currentIndex]"
          :class="{'is-danger': errorOnValue(currentIndex, 'phone')}"
          default-country-code="FR"
          :disabled="!editable"
          :translations="translations"
          @update="update(`${currentIndex}.phone`, $event.phoneNumber)">
        </VuePhoneNumberInput>
        <p
          v-if="errorOnValue(currentIndex, 'phone')"
          class="help is-danger">
          {{errorsData[currentIndex].phone[0]}}
        </p>
      </div>
      <div class="field">
        <label class="label"> {{ $t("cellphone-number-label") }} </label>
        <VuePhoneNumberInput
          v-model="cellphoneNumbers[currentIndex]"
          :class="{'is-danger': errorOnValue(currentIndex, 'cellphone')}"
          default-country-code="FR"
          :disabled="!editable"
          :translations="translations"
          @update="update(`${currentIndex}.cellphone`, $event.phoneNumber)">
        </VuePhoneNumberInput>
        <p
          v-if="errorOnValue(currentIndex, 'cellphone')"
          class="help is-danger">
          {{errorsData[currentIndex].cellphone[0]}}
        </p>
      </div>
      <div class="field">
        <div class="columns">
          <div class="column is-one-quarter">
            <label class="label"> {{ $t("civility-label")}} </label>
            <select
              :value="value[currentIndex].civility"
              :class="{'is-danger': errorOnValue(currentIndex, 'civility')}"
              :required="editable"
              :disabled="!editable"
              @change="update(`${currentIndex}.civility`, $event.target.value)">
              <option value="MR" selected="selected">{{$t("MR")}}</option>
              <option value="MS">{{$t("MS")}}</option>
            </select>
            <p
              v-if="errorOnValue(currentIndex, 'civility')"
              class="help is-danger">
              {{errorsData[currentIndex].civility[0]}}
            </p>
          </div>
          <div class="column is-three-quarters">
            <label class="label"> {{ $t("name-label")}} </label>
            <input
              type="text"
              class="input"
              :class="{'is-danger': errorOnValue(currentIndex, 'name')}"
              :value="value[currentIndex].name"
              @change="update(`${currentIndex}.name`, $event.target.value)"
              :required="editable"
              :disabled="!editable">
            <p
              v-if="errorOnValue(currentIndex, 'name')"
              class="help is-danger">
              {{errorsData[currentIndex].name[0]}}
            </p>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label"> {{ $t("email-label")}} </label>
        <input
          type="email"
          class="input"
          :class="{'is-danger': errorOnValue(currentIndex, 'email')}"
          :value="value[currentIndex].email"
          @change="update(`${currentIndex}.email`, $event.target.value)"
          :disabled="!editable">
        <p
          v-if="errorOnValue(currentIndex, 'email')"
          class="help is-danger">
          {{errorsData[currentIndex].email[0]}}
        </p>
      </div>
    </div>

    <div v-if="editable" class="has-text-centered">
      <button type="button" class="button" @click="addContact">
        <span class="icon">
         <i class="fas fa-plus-circle"></i>
       </span>
       <span>{{$t("add-contact-button")}}</span>
      </button>
    </div>
  </div>
</template>
<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import SectionTitle from '@/components/SectionTitle'
import vModelMixin from '@/mixins/vModelMixin'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ContactsField',
  mixins: [vModelMixin],
  components: {
    VuePhoneNumberInput, SectionTitle
  },
  props: {
    errorsData: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true,
    }
  },
  data: function(){
    return {
      translations: {
        countrySelectorLabel: this.$t("country-selector-label"),
        countrySelectorError: this.$t("country-selector-error"),
        phoneLabel: this.$t("phone-number-label"),
        example: this.$t("example-label")
      },
      currentIndex: 0,
    }
  },
  computed: {
    phoneNumbers() {
      return this.value.map(el => el.phone)
    },
    cellphoneNumbers() {
        return this.value.map(el => el.cellphone)
    },
    contactsTitle() {
      if(this.value.length >1){
        let index = this.currentIndex + 1
        return this.$t("contact-title") + " " + index + "/" + this.value.length
      }
      return this.$t("contact-title")
    }
  },
  methods: {
    addContact() {
      const newValue = cloneDeep(this.value)
      newValue.push([])
      this.$emit('input', newValue)
      this.currentIndex = newValue.length - 1
    },
    removeContact(index) {
      const newValue = cloneDeep(this.value)
      newValue.splice(index, 1)
      this.currentIndex = index == 0 ? index : index - 1
      this.$emit('input', newValue)
    },
    errorOnValue(index, nameField){
      if(this.errorsData && this.errorsData[index] && this.errorsData[index][nameField]){
        return true
      }
      return false
    },
    nextContact(){
      if(this.currentIndex != this.value.length -1){
        this.currentIndex ++
      }
    },
    previousContact(){
      if(this.currentIndex != 0){
        this.currentIndex --
      }
    },
    selectContact(event){
      this.currentIndex = parseInt(event.target.value)
    }
  }
}
</script>
