import Vue from 'vue'
import Vuex from 'vuex'

const expeditionStore = require('@/apps/expedition/store')
const pickUpAndReturnStore = require('@/apps/pick_up_and_return/store')
const demoStore = require('@/apps/demo/store')
const commonData = require('@/store/common_data')

const defaultStore = require('@/store/default_store').default

Vue.use(Vuex)

// getters are functions
const store = new Vuex.Store({
   ...defaultStore,
  modules: {
    expeditionModule: expeditionStore.default,
    pickUpAndReturnModule: pickUpAndReturnStore.default,
    demoModule: demoStore.default,
    commonData: commonData.default,
  }
})

export default store
