<template>
  <div>
    <div class="card-content">
      <div class="field">
        <label class="label" for="outer_file">{{$t("choose-file")}}{{$t("colon")}}</label>
        <div class="controls">
          <input id="outer_file" type="file" multiple @change="postDocument" />
        </div>
      </div>
      <table class=" table is-bordered is-striped is-narrow is-fullwidth">
        <tr v-if="getPVName() !== undefined">
          <td>
            <span><a class="has-text-black" :href="expeditionPV" target="_blank">{{ getPVName() }}</a></span>
          </td>
        </tr>
        <tr>
          <td>
            <a class="has-text-black" @click="getResumePDF">{{$t('resume-title')}}-{{expeditionReference}}</a>
          </td>
        </tr>
        <tr v-if="isDemo">
          <td>
            <a class="has-text-black" @click="getPvPDF">PV {{ expeditionReference }}</a>
          </td>
        </tr>
        <tr v-for="(docu, index) in documents" v-bind:key="index">
          <td>
            <span><a class="has-text-black" :href="url + docu.url" target="_blank">{{ docu.title }}</a></span>
            <button class="button is-small is-danger" @click="removeDocument(index)" style="float: right;">
                <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped>
  table{
    margin-top: 2rem;
  }
</style>

<script>
import apiClient from '@/client/client_logistics'
import {downloadPdf} from  '@/utils/helpers'

export default {
  name: 'FilesExpedition',
  props: {
    expeditionId: {
      type: Number
    },
    expeditionReference: {
      type: String
    },
    expeditionPV: {
      type: String
    },
    isDemo: {
      type: Boolean,
      default: false
    },
    isKomaks: {
      type: Boolean,
      default: false
    }
  },
  data: function(){
    return{
      documents: [],
      url: apiClient.baseURL
    }
  },
  beforeMount(){
    this.getDocuments()
  },
  methods: {
    getPVName(){
      if(this.expeditionPV !== undefined && this.expeditionPV !== ""){
        let parts = this.expeditionPV.split('/')
        return parts[parts.length -1]
      }
      return undefined
    },
    getResumePDF () {
      apiClient.getResumePDF(this.expeditionReference).then(
        response => {
          downloadPdf(
            response.request.response,
            `resume_expedition_${this.expeditionReference}.pdf`,
            'application/pdf'
          )
        })
    },
    getPvPDF () {
      if(this.isKomaks){
        apiClient.getPvPDF(this.expeditionReference).then(
          response => {
            downloadPdf(
              response.request.response,
              `PV_${this.expeditionReference}`,
              'application/pdf'
            )
          })
      }else{
        apiClient.getPvPDFWithoutShipment(this.expeditionReference).then(
          response => {
            downloadPdf(
              response.request.response,
              `PV_${this.expeditionReference}`,
              'application/pdf'
            )
          })
      }
    },
    getDocuments(){
      this.documents = []
      apiClient.getDocumentsExpedition(this.expeditionId).then((response) => {
        if(response.data.documents !== null && response.data.documents !== undefined){
          response.data.documents.forEach(document => this.documents.push(document));
        }
      })
    },
    postDocument(event){
      if(event.target.files.length > 0){
        let formData = new FormData()
        Array.from(event.target.files).forEach(doc => {
            formData.append("documents", doc)
        })
        formData.append("expedition", this.expeditionId)

        apiClient.postDocumentExpedition(formData).then((response) => {
            if(response.data.documents !== null && response.data.documents !== undefined){
              this.documents = []
              response.data.documents.forEach(document => this.documents.push(document));
            }
        })
      }
    },
    removeDocument(index){
      if(index >= 0 && index < this.documents.length){
        apiClient.deleteDocumentExpedition(this.documents[index].id).then(() => {
          this.documents.splice(index, 1)
        })
      }
    }
  }
}

</script>
