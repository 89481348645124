<template>
  <div class="columns is-centered">
    <div class="column is-full">
      <div v-if="editable">
        <label class="label">{{$t("product-name-label")}} *</label>
        <Autocomplete
          id="item"
          name="item"
          :suggestions="contentAvailable"
          :addCurrentInputToSuggestion="true"
          :placeholder="$t('product-label')"
          :minLengthBeforeDisplaySuggestion="1"
          @select-value="addProduct"
          :removeValueOnSelect="true">
        </Autocomplete>
        <br />
      </div>
      <div v-for="(item, index) in value" v-bind:key="index">
        <div class="box">
          <div class="columns">
            <div class="column is-5">
              {{ item.name }}
            </div>
            <div class="column is-2">
              {{ $t("serial-number-label")}}{{$t("colon")}}
            </div>
            <div class="column is-2">
              <input v-if="item.attributeQuality"
                :value="item.attributeQuality.serialNumber"
                @change="update(`${index}.attributeQuality.serialNumber`, $event.target.value)"
                :disabled="!editable"
                class="input"
                type="text"/>
            </div>
            <div class="column is-1">
              {{ $t("quantity-label")}}{{$t("colon")}}
            </div>
            <div class="column is-1">
              <input :value="item.quantity"
                @change="update(`${index}.quantity`, $event.target.value)"
                :disabled="!editable"
                class="input"
                type="number"
                min="1" />
            </div>
            <div class="column is-1">
              <button
                :disabled="!editable"
                @click="removeProduct(index)"
                class="button is-danger"
                type="button">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@/components/utils/Autocomplete'
import vModelMixin from '@/mixins/vModelMixin'

export default{
  name: 'CatalogueContent',
  mixins: [vModelMixin],
  components: { Autocomplete },
  props: {
    contentAvailable: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    addProduct(value){
      let product = {
        name: value,
        quantity: 1,
        attributeQuality: {
          serialNumber: ""
        }
      }
      const content = this.deepCopy(this.value)
      content.push(product)
      this.$emit('input', content)
    },
    removeProduct(index) {
      const content = this.deepCopy(this.value)
      content.splice(index, 1)
      this.$emit('input', content)
    }
  }
}

</script>
