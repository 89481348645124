<template>
  <div class="resume-expedition columns is-multiline">
    <ResumePurchaser
      :expedition="expedition"
      :step="1"
      :returnToStep="() => returnToStep(0)"
      :extraCss="['column', 'is-half']"
    />
    <ResumeTransporter
      :expedition="expedition"
      :step="2"
      :returnToStep="() => returnToStep(2)"
      :extraCss="['column', 'is-half']"
    />

    <ResumeRemoval
      :expedition="expedition"
      :step="3"
      :returnToStep="() => (expedition.kind === 'PICK_UP_AND_RETURN') ? returnToStep(0) : returnToStep(1)"
      :extraCss="['column', 'is-half']"
    />

    <ResumeRecipient
      :expedition="expedition"
      :step="4"
      :returnToStep="() => (expedition.kind === 'PICK_UP_AND_RETURN') ? returnToStep(1) : returnToStep(3)"
      :extraCss="['column', 'is-half']"
    />

    <ResumePickUpAndReturn
      :expedition="expedition"
      :step="5"
      :returnToStep="() => (expedition.kind === 'PICK_UP_AND_RETURN') ? returnToStep(4) : returnToStep(6)"
      :extra-css="['column', 'is-half']"
    />

    <ResumeInsurance
      :expedition="expedition"
      :step="6"
      :returnToStep="() => (expedition.kind === 'PICK_UP_AND_RETURN') ? returnToStep(3) : returnToStep(5)"
      :extra-css="['column', 'is-half']"
    />

    <ResumeContent
      v-if="expedition.content.length > 0"
      :expedition="expedition"
      :step="7"
      :returnToStep="() => (expedition.kind === 'PICK_UP_AND_RETURN') ? returnToStep(2) : returnToStep(4)"
      :extra-css="['column', 'is-full']"
    />
  </div>
</template>
<script>
import ResumePurchaser from '@/components/resume/ResumePurchaser'
import ResumeTransporter from '@/components/resume/ResumeTransporter'
import ResumeRemoval from '@/components/resume/ResumeRemoval'
import ResumeRecipient from '@/components/resume/ResumeRecipient'
import ResumePickUpAndReturn from '@/components/resume/ResumePickUpAndReturn'
import ResumeInsurance from '@/components/resume/ResumeInsurance'
import ResumeContent from '@/components/resume/ResumeContent'

export default {
  name: 'ResumeExpedition',
  components: {
    ResumePurchaser,
    ResumeTransporter,
    ResumeRemoval,
    ResumeRecipient,
    ResumePickUpAndReturn,
    ResumeInsurance,
    ResumeContent,
  },
  props: {
    storageTypes: {
      type: Object,
      default: () => {}
    },
    expedition: {
      type: Object
    }
  },
  methods: {
    returnToStep(step){
      this.$emit('change-step', step)
    }
  }
}
</script>
