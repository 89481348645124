<template>
  <div>
    <section class="section" v-if="!entityListLoading">
      <div class="container">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="columns is-vcentered">
              <div class="column is-1 has-text-primary">
                  <i class="fas fa-arrow-left fa-2x" v-if="currentSteps.value > 0" @click="previousStep"></i>
              </div>
              <div class="column is-10">
                <multiselect
                  class="has-text-primary has-text-weight-bold"
                  v-model="currentSteps"
                  :options="stepsAccess"
                  :searchable="false"
                  :allow-empty="false"
                  track-by="name"
                  label="name"
                >
                </multiselect>
              </div>
              <div class="column is-1 has-text-primary">
                <i class="fas fa-arrow-right fa-2x" v-if="currentSteps.value < validateStep" @click="nextStep"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-if="displayError && lastStepValid.errors[currentSteps.value].length > 0" class="notification is-danger">
          <span v-for="(error, index) in lastStepValid.errors[currentSteps.value]" :key="index">
            {{error}}
          </span>
        </div>
        <div v-if="errorsData && errorsData.nonFieldErrors">
          <h1 v-for="(error, index) in errorsData.nonFieldErrors" :key="index" class="has-text-centered error-message is-danger">
            {{error}}
          </h1>
        </div>

        <ExpeditionStepsForm
          :value="value"
          :loading="loading"
          :currentSteps="currentSteps"
          :transporterList="transporterList"
          :contentAvailable="contentAvailable"
          :enterpriseList="enterpriseList"
          :entityList="entityList"
          :entityListLoading="entityListLoading"
          :errorsData="errorsData"
          @cancel="$emit('cancel')"
          @steps-finish="validationFunction"
          @step-finish="nextStep"
          @previous-step="previousStep"
          @change-step="changeStep"
          @prepare-get-stock-list-expedition="prepareGetStockList"
          @input="update($event)">
        </ExpeditionStepsForm>
      </div>
    </section>
    <section v-else class="section">
      <div class="container">
          <div class="loader-wrapper">
              <div class="loader is-loading"></div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import { deepCopy } from '@/utils/helpers'
import ExpeditionStepsForm from '@/apps/expedition/components/ExpeditionStepsForm'
import expeditionFormMixin from '@/mixins/expeditionFormMixin'
import {checkAddress, checkContacts} from '@/utils/helpers'

export default {
  name: "ExpeditionForm",
  mixins: [expeditionFormMixin],
  components: {ExpeditionStepsForm},
  props: {
    errorsData:{
      type: Object
    }
  },
  data() {
    return {
      steps: [
        { name: this.$t("purchaser-title"), value: 0, $isDisabled: false},
        { name: this.$t("removal-title"), value: 1, $isDisabled: true},
        { name: this.$t("transporter-title"), value: 2, $isDisabled: true},
        { name: this.$t("delivery-title"), value: 3, $isDisabled: true},
        { name: this.$t("content-title"), value: 4, $isDisabled: true},
        { name: this.$t("insurance-title"), value: 5, $isDisabled: true},
        { name: this.$t("pick-up-and-return-title"), value: 6, $isDisabled: true},
        { name: this.$t("resume-title"), value: 7, $isDisabled: true},
      ],
    }
  },
  beforeMount(){
    this.getEnterpriseList()
    this.getTransporterList()
    this.prepareGetStockList()

    this.currentSteps = this.steps[0];

    if(this.step !== undefined){
      for(let i=0; i<this.step; i++){
        this.nextStep();
      }
    }
  },
  computed: {
    lastStepValid () {
      // Each time we valid a step, lastStepValid is increased by one
      let value = 0 // Value of the last step valid to validate
      const errors = {} // All errors
      for (let i = 0; i < this.steps.length; i++) {
        errors[i] = []
      }

      // STEP 1 purchaser
      if(this.value.purchaserEntity.id === -1 || this.value.purchaserEntity.id === undefined || this.value.purchaserEntity.id === null) {
        errors[value].push(this.$t('purchaser-entity-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.purchaserEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 2 sender
      if(this.value.senderEntity.id === -1 || this.value.senderEntity.id === undefined || this.value.senderEntity.id === null) {
        errors[value].push(this.$t('sender-entity-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.senderEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 3 transporter
      if(this.value.transporter === null ||
        this.value.transporter === "") {
        errors[value].push(this.$t('transporter-empty'))
        return {value, errors}
      }
      if(!checkContacts(this.value.transporterContacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      value += 1

      // STEP 4
      // No special check to do
      const receiverSenderAddressErrors = checkAddress(
        this.value.recipientEntity.address
      )
      if(receiverSenderAddressErrors.length !== 0) {
        errors[value].push(this.$t('address-incomplete'))
        return {value, errors}
      }
      if(!checkContacts(this.value.recipientEntity.contacts)){
        errors[value].push(this.$t('contact-name-empty'))
        return {value, errors}
      }
      if(this.value.deliveryDate === null || this.value.deliveryDate === ""){
        errors[value].push(this.$t('expedition-date-desired-label'))
        return {value, errors}
      }
      value += 1

      // STEP 5
      if(this.value.content.length === 0) {
        errors[value].push(this.$t('content-empty'))
        return {value, errors}
      }
      // STEP 6 to 9 nothing to do
      value += 5
      return {value, errors}
    }
  },
  watch: {
    entityList() {
      if (this.entityList.length == 1) {
        this.value.senderEntity = deepCopy(this.entityList[0])
        this.value.sender = this.value.senderEntity.enterprise.id
        this.getKomaksCatalogue()
      }
    },
    'value.senderEntity'(newVal, oldValue){
      this.value.senderEntity = newVal
      // Avoid re-request if the value change and the id is still the same
      // This can appen if value.senderEntity is reassigned with a deepCopy of himself
      // Vue will detect this operation as a changement but we don't want to reload all stocks
      if(oldValue.id !== newVal.id) {
        this.prepareGetStockList()
      }
    },
    errorsData: {
      handler: function(val){
        if(val !== null){
          if(val.purchaserEntity !== undefined || val.purchaser !== undefined){
            this.changeStep(0)
          }else if(val.senderEntity !== undefined || val.sender !== undefined || val.removalDate !== undefined){
            this.changeStep(1)
          }else if(val.transporter !== undefined){
            this.changeStep(2)
          }else if(val.recipient !== undefined || val.recipientEntity !== undefined || val.deliveryDate !== undefined){
            this.changeStep(3)
          }else if(val.content !== undefined){
            this.changeStep(4)
          }else if(val.insurance !== undefined || val.amountToEnsure !== undefined){
            this.changeStep(5)
          }else if(val.expeditionReturn !== undefined){
            this.changeStep(6)
          }
        }
      },
      deep: true
    }
  },
}
</script>
