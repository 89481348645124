import {ModalData} from "@/models/modal";
import {downloadPdf} from  '@/utils/helpers'
import apiClient from "@/client/client_logistics";
export default {
    name: "ValidationMixin", // To override in childs
    props: {
        id: {
            type: String,
            required: true
        },
    },
    data() {
        return {
          validatedObject: null,
          errorKey: '',
        };
    },
    methods: {
      // To override in childs
      async getValidatedObject() {
      },
      // To override in DEMO
      getResumePDF () {
        apiClient.getResumePDF(this.validatedObject.reference).then(
          response => {
            downloadPdf(
              response.request.response,
              `resume_expedition_${this.validatedObject.reference}.pdf`,
              'application/pdf'
            )
          })
      },
    },
    created() {
      this.getValidatedObject()
        .then(response => this.validatedObject = response.data)
        .catch(() => {
            this.$store.dispatch(
              "openModal",
              new ModalData(
                {
                  text: this.$t("error-title"),
                  css: "is-danger"
                },
                [{
                  text: this.$t(this.errorKey),
                }],
                {
                  refresh: {
                    active: true,
                    css: "is-danger",
                    icon: "fa-redo",
                    onClick: () => {
                      this.$router.go();
                      this.$store.dispatch("closeModal");
                    }
                  },
                  home: {
                    active: true,
                    css: "is-success",
                    icon: "fa-home",
                    onClick: () => {
                      this.$router.push("/");
                      this.$store.dispatch("closeModal");
                    }
                  }
                }
              )
          );
      });
    }
};
