const api = {
  createExpedition(expedition){
    return this.apiClient().post('expedition/expeditions/', expedition)
  },
  updateExpedition(expedition, id){
    return this.apiClient().put('expedition/expeditions/' + id + '/', expedition)
  },
  partialUpdateExpedition(expedition, id){
    return this.apiClient().patch('expedition/expeditions/' + id + '/', expedition)
  },
  getExpeditions(){
    return this.apiClient().get('expedition/expeditions/')
  },
  getExpedition(id){
    return this.apiClient().get('expedition/expeditions/' + id + '/')
  },
  getUserEnterprise(){
    return this.apiClient().get('enterprises/user-enterprises/')
  },
  getStorageTypes(){
    return this.apiClient().get('expedition/storage-types/')
  },
  getStaircaseTypes(){
    return this.apiClient().get('enterprises/staircase-types/')
  },

  getResumePDF (expeditionReference) {
    return this.apiClient().get(
      `expedition/expedition-resume-pdf/${expeditionReference}/`,
      {responseType: 'arraybuffer'}
    )
  },

  getPvPDF (expeditionReference) {
    return this.apiClient().get(
      `expedition/shipment-pv-pdf/${expeditionReference}/`,
      {responseType: 'arraybuffer'}
    )
  },

  deliver (id) {
    return this.apiClient().put(`expedition/expedition-deliver/${id}/`)
  },

  validate (expedition) {
    if (expedition.isLoan()) {
      return this.apiClient().patch(
        'expedition/loans/' + expedition.id + '/',
        { validation: true }
      )
    }else if(expedition.isPickUp()){
      return this.apiClient().patch(
        'expedition/pick-up-and-returns/' + expedition.id + '/',
        { validation: true }
      )
    }else{
      return this.apiClient().patch(
        'expedition/expeditions/' + expedition.id + '/',
        { validation: true }
      )
    }
  },
};

export default api;
