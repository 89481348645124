<template>
  <div id="app">
    <div v-if="logged">
      <Navbar @logout="logout"></Navbar>
      <router-view/>
    </div>
    <div v-else>
      <Login v-model="logged"></Login>
    </div>
    <transition name="fade">
      <!-- Modal error -->
      <Modal v-if="modal.isOpen" :modalData="modal">
        <template v-slot:header>
          <h1>
            {{modal.title.text}}
          </h1>
        </template>
        <template v-slot:body>
          <div v-for="(content, index) in modal.content" :key="index" class="is-light">
            <template v-if="Array.isArray(content.text)">
              <div v-for="t in content.text" :key="t">
                {{t}}
              </div>
            </template>
            <span v-else>
            {{content.text}}
            </span>
          </div>
        </template>
        <template v-slot:footer>
          <template v-for="(button, buttonName) in modal.buttons">
            <button v-if="button.active"
              :key="buttonName"
              :class="['button', button.css]"
              @click="() => {if(button.onClick !== null) {button.onClick()}}">
              <span v-if="button.icon" class="icon is-small">
                <i class="fa" :class="button.icon" aria-hidden="true"></i>
              </span>
              <span>
                {{$t(`${buttonName}-button`)}}
              </span>
            </button>
          </template>
        </template>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Login from '@/components/Login'
import Modal from '@/components/Modal'
import Navbar from '@/views/Navbar'
//import SideMenu from '@/components/SideMenu';
import { mapGetters, mapActions } from 'vuex'
import apiClient from '@/client/client_logistics'

export default {
  name: 'KomaksLogistics',
  components: {
    Login, //SideMenu
    Modal,
    Navbar
  },
  data: function () {
    return {
      showLoginModal: false,
    }
  },
  beforeMount: function(){
    this.isAuthenticated();
  },
 computed: {
   logged() {
     return this.user !== null
   },
   ...mapGetters(['modal', 'user']),
  },
  methods: {
    ...mapActions({
      getAllEntitiesCollection: 'commonData/getAllEntitiesCollection',
      getExpeditionEntitiesCollection: 'commonData/getExpeditionEntitiesCollection'
    }),
    isAuthenticated(){
      const rawUserData = localStorage.getItem("user")
      if (rawUserData === null) {
        this.logout()
      }
      if (rawUserData !== null && this.$store.user === undefined) {
        const userData = JSON.parse(rawUserData);
        if (userData.id === undefined) return false;
        this.refreshUserData(userData.id)
      }
      return rawUserData !== null;
    },
    logout() {
      apiClient.logout();
    },
    refreshUserData(id) {
      // Get data from server (Refresh) remove token if the server throw error
      apiClient.refreshUserData(id).then(
        () => {
          this.getAllEntitiesCollection()
          this.getExpeditionEntitiesCollection()
        }).catch(() => this.logout())
    }
  }
}
</script>
<style lang="scss">
  @charset "utf-8";
  // $body-background-color: #eee;
  $navbar-padding-horizontal: 32rem;
  $primary: #93c123;
  $link: $primary;

  $tabs-link-active-color: $primary;
  @import "scss/app.scss";
  @import "static/fontawesome.min.css";
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  @import "~vue-phone-number-input/dist/vue-phone-number-input.css";
</style>
