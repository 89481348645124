const api = {
  getUserEnterprise(){
    return this.apiClient().get('enterprises/user-enterprises/')
  },
  getUserStocksEnterprise(){
    return this.apiClient().get('enterprises/user-stocks-enterprises/')
  },
  getUserEntity(filter=false){
    const params = (filter) ? filter : {}
    return this.apiClient().get('enterprises/user-entities/', {params})
  },
  getEnterprise(id){
    return this.apiClient().get('enterprises/enterprises/' + id + '/')
  },
  getListTransporters(){
    return this.apiClient().get('enterprises/transporters/')
  },
  createTransporter(transporter){
    return this.apiClient().post('enterprises/transporters/', transporter)
  },
};

export default api;
