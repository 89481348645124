import DemoModule from '@/apps/demo/demo/module'
import DemoCreate from '@/apps/demo/demo/create'
import DemoValidation from '@/apps/demo/demo/validation'
import DemoUpdate from '@/apps/demo/demo/update'
import DemoList from '@/apps/demo/demo/list'

const basePath = '/demo-module/'

const routes = [
  {
    path: basePath,
    name: 'demo-module',
    component: DemoModule,
  },
  {
    path: basePath + 'create',
    name: 'create-loan',
    component: DemoCreate,
  },
  {
    path: basePath + 'list/:id?',
    component: DemoList,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: basePath + 'validate/:id/:idPickUpAndReturn',
    component: DemoValidation,
    props: true
  },
  {
    path: basePath +  'update/:id/:idPickUpAndReturn/:step/',
    component: DemoUpdate,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      props.idPickUpAndReturn = +props.idPickUpAndReturn
      props.step = +props.step
      return props
    },
  },
];

export default routes;
