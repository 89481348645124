import {mapGetters} from 'vuex'
import {Expedition} from '@/models/expedition'
export default{
  props: {
    value: { // Expe or reprise
      type: Expedition
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentSteps: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      /* This is data and not computed for the following reason:
       If the user update the removal date or delivery date of the expedition
       computed will be re-calculate, this can have as side effect the blocking
       of the differents forms */
      isRemovalDatePassed:false,
      isEditionClosed: false
    }
  },
  computed:{
    ...mapGetters(['isAdmin'])
  },
  beforeMount() {
    // First assignation on the component
    if(this.isAdmin) {
      this.isRemovalDatePassed = false
    } else {
      this.isRemovalDatePassed = this.value.isRemovalDatePassed()
    }
    this.isEditionClosed = (this.value.id === null) ? false : !this.value.canBeUpdated()
  }
}
