<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div class="container">
        <div class="field">
          <label class="label">{{$t("transporter-name-label")}} *</label>
          <div v-if="!editable" class="notification is-warning">
              {{$t('content-after-removal-date')}}
          </div>
          <Autocomplete
            :initialValue="(value.transporter) ? value.transporter : ''"
            :suggestions="transporterList.map(e=> e.businessName)"
            :loading="false"
            :placeholder="$t('transporter-name-label')"
            :freeTyping="true"
            :addCurrentInputToSuggestion="true"
            :disabled="!editable"
            @select-value="chooseSelection"
            required>
          </Autocomplete>
          <p
            v-if="errorsData && errorsData.transporter"
            class="help is-danger">
            {{errorsData.transporter[0]}}
          </p>
        </div>
        <div>
          <ContactsField
            :editable="editable"
            :value="value.transporterContacts"
            @input="update('transporterContacts', $event)"
            :errorsData="(errorsData && errorsData.transporterContacts) ? errorsData.transporterContacts : null">
          </ContactsField>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import ContactsField from '@/components/fields/ContactsField'
import Autocomplete from '@/components/utils/Autocomplete'
import {deepCopy} from '@/utils/helpers'
import vModelMixin from '@/mixins/vModelMixin'

export default {
  name: 'TransporterFields',
  mixins: [vModelMixin],
  components: { ContactsField, Autocomplete },
  props: {
    transporterList: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true
    },
    errorsData: {
      type: Object
    }
  },
  data: function () {
    return {
      haveSearchValue: false,
      transporter: {
        businessName: '',
        contacts: [{}]
      }
    }
  },
  computed: {
    transporters () {
      const transporters = {}
      this.transporterList.forEach(transporter => {
          transporters[transporter.businessName] = transporter
      })
      return transporters
    }
  },
  methods: {
    chooseSelection(selection){
      if (this.transporters[selection] === undefined) {
        this.updateFromObject({
          transporter: selection,
          transporterContacts: [{}],
          transporterId: null
        })
      } else {
        this.updateFromObject({
          transporter: this.transporters[selection].businessName,
          transporterContacts: deepCopy(this.transporters[selection].contacts),
          transporterId: this.transporters[selection].id
        })
      }
    }
  }
}
</script>
