<template>
    <section class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-multiline">
            <router-link to="/pick-up-and-return-module/list" class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div>
                <img class="img-dashboard" src="@/assets/icons/Eye_purple_icon.png" >
                <p align="center">{{$t("tracking-pick-up-and-return-title")}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
</template>

<style>
.img-dashboard {
  display : block;
  margin: auto;
  width: 128px;
  height: 128px;
}
</style>
<script>

import deniedAccessMixin from '@/mixins/deniedAccessMixin'

export default {
  name: 'PickUpAndReturnModule',
  mixins: [deniedAccessMixin],
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.haveExpeditionAccess){
          return true
        }else{
          this.accessDeniedError()
          return false
        }
      }
    }
  },
  methods: {
    isTransporter(){
      return localStorage.getItem("transporter") !== null;
    }
  }
}
</script>
