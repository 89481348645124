<template>
  <div class="has-text-centered">
    <div v-html="message"></div>
    <button
      class="button"
      @click="cancel()">
      {{ $t('cancel-button') }}
    </button>
    <button
      class="button is-danger"
      @click="confirm()">
      {{ $t('confirm-button') }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}

</script>
