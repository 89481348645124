<template>
  <div class="columns">
    <p
      id="errorsDataSender"
      v-if="errorsData && errorsData.sender"
      class="help is-danger has-text-centered">
      {{errorsData.sender[0]}}
    </p>
    <div class="column is-6">
      <div>
        <div class="box">
            <div class="has-text-centered section-title">
              <label>{{ $t('sender-name-label') }}</label>
            </div>
            <div id="divSenderNotEditable" v-if="!editable" class="notification is-warning">
                {{$t('content-after-removal-date')}}
            </div>
            <div v-if="!value.isLoan()">
              <div class="field">
                <label class="label"> {{$t("name-label")}}</label>
                <multiselect
                  v-if="choiceList.length>1"
                  label="entityName"
                  :class="{'is-danger': errorOnAddress('name')}"
                  v-model="selectedEntity"
                  :disabled="!editable"
                  @select="updateInfo"
                  @remove="resetInfo"
                  :options="choiceListWithNames">
                </multiselect>
                <input
                  v-else
                  id="inputSender"
                  class="input"
                  :class="{'is-danger': errorOnAddress('name')}"
                  type="text"
                  :value="value.senderEntity.address.name"
                  @change="update('senderEntity.address.name', $event.target.value)"
                  required
                  :disabled="disableFields || !editable">
                <p
                  id="errorsDataSenderAddressName"
                  v-if="errorOnAddress('name')"
                  class="help is-danger">
                  {{errorsData.senderEntity.address.name[0]}}
                </p>
              </div>
            </div>
            <div v-else>
              <div class="field">
                <label class="label">{{$t("name-label")}} *</label>
                <Autocomplete
                  :initialValue="value.senderEntity"
                  :suggestions="choiceList"
                  :track-by="'address.name'"
                  :loading="false"
                  :class="{'is-danger': errorOnAddress('name')}"
                  :placeholder="$t('sender-name-label')"
                  :freeTyping="true"
                  :addCurrentInputToSuggestion="true"
                  :disabled="!editable"
                  @select-value="chooseSelection"
                  required>
                </Autocomplete>
                <p
                  id="errorsDataSenderAddressName"
                  v-if="errorOnAddress('name')"
                  class="help is-danger">
                  {{errorsData.senderEntity.address.name[0]}}
                </p>
              </div>
            </div>
            <div class="field">
              <label class="label"> {{$t("name-additional-info-label")}} </label>
              <input
                class="input"
                :class="{'is-danger': errorOnAddress('nameAdditionalInfo')}"
                type="text"
                :value="value.senderEntity.address.nameAdditionalInfo"
                :disabled="!editable"
                @change="update('senderEntity.address.nameAdditionalInfo', $event.target.value)">
              <p
                id="errorsDataSenderAddressNameAdditionalInfo"
                v-if="errorOnAddress('nameAdditionalInfo')"
                class="help is-danger">
                {{errorsData.senderEntity.address.nameAdditionalInfo[0]}}
              </p>
            </div>
          </div>
          <div>
            <div class="box">
              <AddressField
                :value="value.senderEntity.address"
                :title="$t('sender-address-label')"
                :autoComplete="disableFields"
                @input="update('senderEntity.address', $event)"
                :errorsData="(errorsData && errorsData.senderEntity) ? errorsData.senderEntity.address : null">
              </AddressField>
            </div>
            <div class="box">
              <SchedulesField
                :value="value.senderEntity"
                :autoComplete="!editable"
                :toggleTimePreferences="true"
                @input="update('senderEntity', $event)"
                :errorsData="(errorsData) ? errorsData.senderEntity : null">
              </SchedulesField>
            </div>
          </div>
      </div>
    </div>
    <div class="column is-6">
      <ConstraintsField
        v-if="editable"
        :value="value.senderEntity"
        @input="update('senderEntity', $event)"
        :errorsData="(errorsData) ? errorsData.senderEntity : null">
      </ConstraintsField>
      <div id="divRemovalDate" class="box content-highlight" v-if="isAdmin && value.isExpedition()">
        <SectionTitle
          imgName="fas fa-calendar-alt"
          :title="$t('removal-date-label')"
        />
        <div class="field">
          <label class="label">{{$t("expedition-removal-date-desired-label")}}</label>
          <input
            id="inputRemovalDate"
            :value="value.removalDate"
            class="input"
            :class="{'is-danger': errorOnValue('removalDate')}"
            type="date"
            :disabled="!editable"
            @input="update('removalDate', $event.target.value)"
          />
          <p
            id="errorsDataRemovalDate"
            v-if="errorOnValue('removalDate')"
            class="help is-danger">
            {{errorsData.removalDate[0]}}
          </p>
        </div>
      </div>
      <ContactsField
        v-model="value.senderEntity.contacts"
        :editable="editable"
        @input="update('senderEntity.contacts', $event)"
        :errorsData="(errorsData && errorsData.senderEntity) ? errorsData.senderEntity.contacts : null">
      </ContactsField>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import AddressField from '@/components/fields/AddressField'
import SchedulesField from '@/components/fields/SchedulesField'
import ContactsField from '@/components/fields/ContactsField'
import ConstraintsField from '@/components/fields/ConstraintsField'
import { Entity } from '@/models/expedition'
import Autocomplete from '@/components/utils/Autocomplete'
import SectionTitle from '@/components/SectionTitle'
import vModelMixin from '@/mixins/vModelMixin'

import {mapGetters} from 'vuex'

export default {
  name: 'SenderField',
  mixins: [vModelMixin],
  components: {
    Multiselect, AddressField, SchedulesField, ContactsField, ConstraintsField, Autocomplete, SectionTitle
  },
  props: {
    choiceList: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    errorsData: {
      type: Object
    }
  },
  data: function(){
    return {
      selectedEntity: {
        name: ''
      },
      previousIndexValue: -1,
      disableFields: true
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    choiceListWithNames () {
      return this.choiceList.filter(
        el => {
          return this.value.isNormalExpedition() ? el.enterprise !== null : true
        }
      ).map(el => {
        const copyWithName = Object.assign({}, el)
        copyWithName.entityName = el.address.name
        return copyWithName
      })
    }
  },
  watch:{
    choiceList() {
      this.previousIndexValue = this.choiceList.findIndex((entity) => entity.address.name === this.value.senderEntity.address.name)
    }
  },
  beforeMount(){
    this.selectedEntity = Object.assign({}, this.value.senderEntity);
    this.selectedEntity.entityName = this.value.senderEntity.address.name
    if(this.value.senderEntity.id === -1){
      this.disableFields = false
    } else {
      this.disableFields = this.value.senderEntity.enterprise ? true : false
    }
  },
  methods: {
    updateInfo(event){
      if(event){
        this.selectedEntity = event
          this.updateFromObject(
            {
              senderEntity: Object.assign({}, event),
              sender: event.enterprise ? event.enterprise.id: null,
              content: [],
            }
        )
        this.disableFields = event.enterprise ? true : false
        this.$emit("getStocks")
      }
    },
    resetInfo(){
      this.value.senderEntity = new Entity()
      this.value.sender= null
      this.selectedEntity = {
        name: ''
      }
    },
    chooseSelection(selection){
      let index = this.choiceList.findIndex((entity) => entity.address.name === selection.address.name)
      if(index === -1){
        // if this previousIndexValue === -1 we have to keep data in address, in the other case we wipe everythings
        const entity =  new Entity(this.previousIndexValue === -1 ? this.value.senderEntity : null)
        entity.address.name = selection.address.name
        entity.entityName = entity.address.name
        entity.id = -1
        // Update the recipient to remove it from data (set null)
        this.updateFromObject({senderEntity: entity, sender: null})
        this.disableFields = false
        this.previousIndexValue = -1
      }else{
        this.updateFromObject({senderEntity: selection, sender: selection.enterprise ? selection.enterprise.id : null})
        // Disable completion if the selected entity doesn't have linked enterprise
        this.disableFields = selection.enterprise ? true : false
        this.previousIndexValue = index
      }
    },
    errorOnAddress(nameField){
      if(this.errorsData){
        if(this.errorsData.senderEntity && this.errorsData.senderEntity.address && this.errorsData.senderEntity.address[nameField]){
          return true
        }
      }
      return false
    },
    errorOnValue(nameField){
      if(this.errorsData && this.errorsData[nameField]){
        return true
      }
      return false
    }
  }
}

</script>
