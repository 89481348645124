export default{
  props: {
    id: {
      type: Number
    }
  },
  data: function() {
    return {
      filterValue: "0", // 0: new Expedition, 1 : validated expedition 2, delivered Expedition 3 :  all,
      search: '',
      error: false,
      selected: undefined,
      // TO OVERIDE
      collectionStorePath: '' // The path to the getter of the collection
    }
  },
  computed: {
    list () {
      const list = this.sort(this.collection)
      if(this.filterValue === "0") {
        list.validated = {}
        list.delivered = {}
        list.canceled = {}
      } else if (this.filterValue === "1") {
        list.notValidated = {}
        list.delivered = {}
        list.canceled = {}
      } else if (this.filterValue === "2") {
        list.notValidated = {}
        list.validated = {}
        list.canceled = {}
      }
      return list
    },
    collection() {
      return this.$store.getters[this.collectionStorePath].collection
    },
    loading() {
      return this.$store.getters[this.collectionStorePath].loading
    },
    currentChoice() {
      this.selected = this.collection.find(el => el.id === this.id)
      if (this.selected === undefined) {
        return { id:  -1}
      }
      return this.selected
    },
    user() {
      return this.$store.state.user
    }
  },
  beforeMount: function(){
    this.$store.dispatch('commonData/getStaircaseTypes')
  },
  methods: {
    async get() {
      // To OVERIDE in subcomponents
    },
    unselect() {
      this.selected = null
    },
    cancelElement(){
      this.$store.dispatch(this.selectStoreDispatch, null)
      this.get()
    },
    setFilter(value){
      this.filterValue = value
    },

    isInSearch(expedition) {
      let filters = this.search.split(" ")
      if (expedition['SEARCH'] !== undefined && this.search !== '') {
          let filterInSEARCH = true
          filters.forEach(filter => {
            if(!expedition["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
              filterInSEARCH = false
            }
          })
          if (!filterInSEARCH) return false
      }
      return true
    },
    sort(collection){
      const notValidated = {}
      const validated = {}
      const delivered = {}
      const canceled = {}
      if(this.search !== ''){
        this.filterValue = "3"
      }
      collection.forEach(expedition => {
        if (!this.isInSearch(expedition)) return
        let dateString = expedition.deliveryDate
        if(expedition.isCanceled()) {
          if(!canceled[dateString]){
            canceled[dateString] = []
          }
          canceled[dateString].push(expedition)
        }
        else if(expedition.isDelivered()) {
          if(!delivered[dateString]){
            delivered[dateString] = []
          }
          delivered[dateString].push(expedition)
        } else if (expedition.isValidate()) {
            if(!validated[dateString]){
              validated[dateString] = []
            }
            validated[dateString].push(expedition)
        } else {
          if (!notValidated[dateString]) {
            notValidated[dateString] = []
          }
          notValidated[dateString].push(expedition)
        }
      })
      return {
        notValidated,
        validated,
        delivered,
        canceled,
      }
    },
    filterExpeditions(){
      let filters = this.filterValue.split(" ")
      let listIntermediaire = [];
      this.expeditions.forEach(element => {
        let filterInSEARCH = true
        // property is equivalent to stock headers
        filters.forEach(filter => {
          if(!element["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
            filterInSEARCH = false
          }
        })
        if(filterInSEARCH){
          listIntermediaire.push(element);
        }
      })
      this.sortExpeditions(listIntermediaire)
    }
  },
}
