<template>
  <div>
    <div class="expedition-button columns is-mobile is-multiline vcentered">
      <div class="column is-two-third">
        <h3 class="is-size-6 has-text-primary has-text-weight-bold">{{ pickUpAndReturn.reference }} <span class="purchaser">({{ enterpriseName }})</span></h3>
      </div>
      <div class="column is-one-third has-text-right">
        <p class="has-text-grey-light is-size-7">{{ pickUpAndReturn.removalDate }}</p>
      </div>
      <div class="column is-full">
        <div class="media">
          <div class="media-left">
            <i class="fas fa-user-circle"></i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{pickUpAndReturn.senderEntity.address.name}}</p>
            <p>{{pickUpAndReturn.senderEntity.address.zipCode}} - {{pickUpAndReturn.senderEntity.address.city }}</p>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <div class="media">
          <div class="media-left">
            <i class="fas"
              :class="{
                'fa-truck': !pickUpAndReturn.isCanceled(),
                'fa-times': pickUpAndReturn.isCanceled(),
                'has-text-danger': !pickUpAndReturn.validation,
                'has-text-blue': pickUpAndReturn.validation && !pickUpAndReturn.isDelivered(),
                'has-text-green': pickUpAndReturn.validation && pickUpAndReturn.isDelivered()
              }">
            </i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{pickUpAndReturn.transporter}}</p>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="media">
          <div class="media-left">
            <i class="fas fa-box-open"></i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{ pickUpAndReturn.articleQuantity }} {{$t("articles-label")}} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name: 'PickUpAndReturnButton',
  props: {
    pickUpAndReturn: {
      type: Object
    }
  },
  computed: {
    enterpriseName () {
      return this.pickUpAndReturn.purchaserEntity.address.name
    },
  }
}
</script>
<style lang="scss" scoped>
  .expedition-button{
    font-size: .85rem;
  }
  .media + .media {
    border-top: transparent;
    margin-top: 0;
    padding-top: 0;
  }
  .media-left{
    margin-right: .5em;
  }
  .expedition-item{
    .column{
      padding: .25em .5em;
    }
  }
  .purchaser{
    color: grey;
    font-size: 0.7em;
  }
</style>
