<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-slot:details-content>
      <strong> {{$t("articles-label")}}{{$t("colon")}}</strong>
      <br>
      <div class="scroll-on-overflow" v-if="expedition.content.length>0">
        <table v-if="isKomaks || expedition.isLoan()" class="table is-striped is-bordered">
          <thead>
            <tr>
              <th>
                {{ $t("qr-code-label") }}
              </th>
              <th>
                {{ $t("article-denomination-label") }}
              </th>
              <th>
                {{ $t("description-label") }}
              </th>
              <th>
                {{ $t("serial-number-label") }}
              </th>
              <th>
                {{ $t("note-label") }}
              </th>
              <th>
                {{ $t("owner-label") }}
              </th>
              <th>
                {{ $t("purchase-reference-label") }}
              </th>
              <th v-if="expedition.isLoan()">
                {{$t("quantity-label")}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in expedition.content" v-bind:key="index">
              <td>
                {{item.qrcode}}
              </td>
              <td>
                {{item.denomination}}
              </td>
              <td>
                {{item.type}}
              </td>
              <td>
                {{ item['serialNumber'] }}
              </td>
              <td>
                {{ item.note }}
              </td>
              <td>
                {{item.owner}}
              </td>
              <td>
                {{ item.purchaseReference }}
              </td>
              <td v-if="expedition.isLoan()">
                {{ item.quantity}}
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else class="table is-striped is-bordered">
          <thead>
            <tr>
              <th>
                {{$t("product-label")}}
              </th>
              <th>
                {{$t("serial-number-label")}}
              </th>
              <th>
                {{$t("quantity-label")}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in expedition.content" :key="index">
              <td>
                {{item.name}}
              </td>
              <td>
                <span v-if="item.attributeQuality">
                  {{item.attributeQuality.serialNumber}}
                </span>
              </td>
              <td>
                {{item.quantity}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <strong> {{$t("total-articles-label")}}{{$t("colon")}} {{expedition.content.length}}</strong><br />
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
export default {
  name: 'detailsContent',
  mixins: [detailsStepMixin],
  props: {
    isKomaks: {
      type: Boolean,
      required: true,
    }
  }

}
</script>
<style scroped lang="scss">
.scroll-on-overflow {
  overflow-x: auto;
}
</style>
