<template>
  <div v-if="pickUpAndReturn !== null && accessGranted">
    <PickUpAndReturnForm v-if="!isLoadingPurchaser"
      v-model="pickUpAndReturn"
      :step="step"
      :loading="loading"
      :errorsData="errorsData"
      @cancel="cancel"
      @validate="updatePickUpAndReturn">
    </PickUpAndReturnForm>
  </div>
</template>

<script>
import apiClient from '@/client/client_logistics'
import PickUpAndReturnForm from '@/apps/pick_up_and_return/components/PickUpAndReturnForm'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import updateFormMixin from '@/mixins/updateFormMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'

import isEqual from 'lodash/isEqual'

export default {
  name: "PickUpAndReturnUpdate",
  components: { PickUpAndReturnForm },
  mixins: [confirmationCancelMixin, updateFormMixin, deniedAccessMixin],
  props: {
    id: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    }
  },
  data: function(){
    return {
      isLoadingPurchaser: true,
      loading: false,
      error: false,
      finalRoutePath: '/pick-up-and-return-module/validate/:id',
      pickUpAndReturn: {},
      originalPickUpAndReturn: {},
      errors: null,
      dataSended: {},
    }
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.havePickUpAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },

    errorsData() {
      if (this.errors === null) return this.errors
      const errorsData = {}
      Object.keys(this.errors).forEach(key => {
        // Special case for Entity
        if (this.pickUpAndReturn[key] instanceof Entity) {
          // Recreate Entity with data to avoid extra data added by vue
          // then compare the two "new" entities to have a correct comparaison
          if (isEqual(new Entity(this.dataSended[key]), new Entity(this.pickUpAndReturn[key]))) {
            errorsData[key] = this.errors[key]
          }
        }
        // Change each time expedition error field is changed
        else {
          // Change each time expedition error field is changed
          if (isEqual(this.dataSended[key], this.pickUpAndReturn[key])) {
            errorsData[key] = this.errors[key]
          }
        }
      })
      return errorsData
    },
  },
  created(){
    this.getPickUpAndReturn()
  },
  methods: {
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
            text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/pick-up-and-return-module/list/' + this.id)
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    getPickUpAndReturn(){
      apiClient.getPickUpAndReturn(this.id).then((response) => {
        this.pickUpAndReturn = new Expedition(response.data);
        this.pickUpAndReturn.removalDate = response.data['removalDate']
        this.pickUpAndReturn.senderEntity.enterprise = {
          isKomaks: false
        }

        this.originalPickUpAndReturn = new Expedition(response.data);
        this.originalPickUpAndReturn.removalDate = response.data['removalDate']
        this.originalPickUpAndReturn.senderEntity.enterprise = {
          isKomaks: false
        }

        // 48h after the deliveryDate: We can't edit anymore
        if (!this.pickUpAndReturn.canBeUpdated() && this.accessGranted) {
          this.forceChangeRoute = true;
          this.cannotEditError(this.pickUpAndReturn)
          return;
        }
        this.getPurchaserName(this.pickUpAndReturn.purchaser)
      }).catch(() => {
          this.$store.dispatch (
              'openModal',
              new ModalData(
              {
                text: this.$t('error-title'),
                css: 'is-danger'
              },
              [{
                text: this.$t('data-receive-error'),
              }],
              {
                refresh: {
                  active: true,
                  css: 'is-danger',
                  icon: 'fa-redo',
                  onClick: () => {
                    this.$router.go()
                    this.$store.dispatch('closeModal');
                  }
                },
                home: {
                  active: true,
                  css: 'is-success',
                  icon: 'fa-home',
                  onClick: () => {
                    this.$router.push('/')
                    this.$store.dispatch('closeModal');
                  }
                },
              }
            )
          )
      })
    },
    getPurchaserName(purchaserId){
      apiClient.getEnterprise(purchaserId).then((response) => {
        this.pickUpAndReturn.purchaserEntity.enterpriseName = response.data.businessName
        this.pickUpAndReturn.purchaserEntity.enterprise = response.data
        delete this.pickUpAndReturn.purchaserEntity.enterprise.entities

        this.originalPickUpAndReturn.purchaserEntity.enterpriseName = response.data.businessName
        this.originalPickUpAndReturn.purchaserEntity.enterprise = response.data
        delete this.originalPickUpAndReturn.purchaserEntity.enterprise.entities

        this.isLoadingPurchaser = false;
      })
    },
    async updatePickUpAndReturn(){
      this.loading = true
      this.errors = null

      // This is ugly, transporter should be a FK on the model to avoid this
      if(this.pickUpAndReturn.transporter === this.originalPickUpAndReturn.transporter) {
        this.originalPickUpAndReturn.transporterId = this.pickUpAndReturn.transporterId;
      }
      const dataToSend = this.pickUpAndReturn.getFormData(this.user.isAdministrator)
      const dataToSendFromOriginalPickUpAndReturn = this.originalPickUpAndReturn.getFormData(this.user.isAdministrator)
      this.dataSended = this.getDifferenceWithOriginal(
        dataToSendFromOriginalPickUpAndReturn,
        dataToSend
      )

      // Nothing to update
      if (Object.keys(this.dataSended).length === 0) {
          this.$router.push(`/pick-up-and-return-module/validate/${this.pickUpAndReturn.id}`)
          return
      }

      return await apiClient.updatePickUpAndReturn(this.dataSended, this.pickUpAndReturn.id).then(() => {
          this.$router.push(`/pick-up-and-return-module/validate/${this.pickUpAndReturn.id}`)
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('pick-up-and-return-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
        })
    },
  }
}

</script>
