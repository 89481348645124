const api = {
  getDocumentsExpedition(id){
    return this.apiClient().get('expedition/expedition-documents/' + id + '/')
  },
  postDocumentExpedition(formData){
    return this.apiClient().post('expedition/expedition-document/', formData, {headers: {
      'Content-Type': 'multipart/form-data'
    }})
  },
  deleteDocumentExpedition(id){
    return this.apiClient().delete('expedition/expedition-document/' + id + '/')
  },
  getResumePDF (expeditionReference) {
    // window.open(`expedition/expedition-resume-pdf/${expeditionReference}/`)
    return this.apiClient().get(
      `expedition/expedition-resume-pdf/${expeditionReference}/`,
      {responseType: 'arraybuffer'}
    )
  },
  getPvPDF (expeditionReference) {
    return this.apiClient().get(
      `expedition/shipment-pv-pdf/${expeditionReference}/`,
      {responseType: 'arraybuffer'}
    )
  },
  getPvPDFWithoutShipment (expeditionReference) {
    return this.apiClient().get(
      `expedition/expedition-pv-pdf/${expeditionReference}/`,
      {responseType: 'arraybuffer'}
    )
  },
};

export default api;
