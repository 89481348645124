<template>
  <div class="hero-body">
    <h1 class="title has-text-primary has-text-centered">
      {{$t("page-not-found")}}
    </h1>
    <div class="has-text-centered">
      {{$t("return-to-dashboard-message")}}
      <router-link to="/">
        {{$t("here")}}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
  computed: {
    user() {
      return this.$store.state.user
    }
  }
};
</script>
