<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-if="isTransporter && !isRemovalDatePassed && !isEditionClosed" v-slot:details-update>
      <button v-if="!isUpdating" class="router-button button is-small is-text" @click="startUpdate()">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
        <span>{{$t("update-button")}}</span>
      </button>
    </template>
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <template v-if="!isUpdating">
          <div class="column is-one-third has-text-right">
            <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.senderEntity.address.name}}<br />
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("address-title")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <span>
              {{expedition.senderEntity.address.line}} - <em>{{ expedition.senderEntity.address.lineAdditionalInfo}}</em><br />
              {{ expedition.senderEntity.address.zipCode}} {{ expedition.senderEntity.address.city }} ({{expedition.senderEntity.address.getCountryName()}})
            </span>
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("date-label")}}{{$t("colon")}}</strong><br />
          </div>
          <div class="column is-two-thirds has-text-danger">
           {{ expedition.removalDate }}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("time-slot-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <span v-if="expedition.senderEntity.haveAmSchedule">
              {{ expedition.senderEntity.amOpenStart}} - {{ expedition.senderEntity.amOpenEnd}}
            </span>
            <span v-if="expedition.senderEntity.haveAmSchedule && expedition.senderEntity.havePmSchedule">
              /
            </span>
            <span v-if="expedition.senderEntity.havePmSchedule">
              {{ expedition.senderEntity.pmOpenStart}} - {{ expedition.senderEntity.pmOpenEnd}}
            </span>
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <span v-for="(contact, index) in expedition.senderEntity.contacts" v-bind:key="index">
              {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
            </span>
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("floors-number-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.senderEntity.floorNumber }}<br />
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("stairs-number-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.senderEntity.stairNumber }} <br />
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("staircase-type-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <span v-if="staircaseTypes !== null">
              {{ staircaseTypes[expedition.senderEntity.staircaseType] }}
            </span>
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("elevator-label")}}{{$t("colon")}}</strong>
          </div>
          <div v-if="expedition.senderEntity.elevator" class="column is-two-thirds">
            {{$t('yes')}}
          </div>
          <div v-else class="column is-two-thirds">
            {{$t('no')}}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("other-constraints-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds line-returns">
            {{ expedition.senderEntity.otherInformations }} <br />
          </div>
        </template>
        <div v-else class="column is-full">
          <form @submit.prevent="validateUpdate">
            <ConstraintsField
              v-model="senderEntity"
              staircaseTypes="staircaseTypes"
              :errorsData="(errorsData) ? errorsData.senderEntity : null">
            </ConstraintsField>
            <div class="buttons is-centered">
              <button class="button" type="button" @click="cancelUpdate()">{{$t("cancel-button")}}</button>
              <button class="button is-primary">{{$t("validate-button")}}</button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
import ConstraintsField from '@/components/fields/ConstraintsField'
import {deepCopy} from '@/utils/helpers'
import { isPassedDate, addDays, dateToString } from '@/utils/helpers'
import {ModalData} from '@/models/modal'

export default {
  name: 'detailsSender',
  mixins: [detailsStepMixin],
  components: {ConstraintsField},
  data () {
    return {
      isUpdating: false,
      senderEntity: null,
      errorsData: null
    }
  },
  computed: {
    isTransporter () {
      const user  = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.isTransporter
      }
      return false;
    },
    staircaseTypes(){
      return this.$store.getters['commonData/staircaseTypes']
    },
    isRemovalDatePassed() {
      return this.expedition.isRemovalDatePassed()
    },
    isEditionClosed() {
      return isPassedDate(dateToString(addDays(this.expedition.deliveryDate, 2))) || this.expedition.isCanceled()
    }
  },
  methods: {
    startUpdate(){
      this.senderEntity = deepCopy(this.expedition.senderEntity);
      this.isUpdating = true;
    },
    cancelUpdate(){
      this.isUpdating = false;
    },
    validateUpdate(){
      const expeditionData = {
        'senderEntity': this.senderEntity,
      }
      this.expedition.updateTransporterInfo(expeditionData).then(() => {
        this.expedition.senderEntity = deepCopy(this.senderEntity)
        this.isUpdating = false
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errorsData = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
      })
    }
  }
}
</script>
