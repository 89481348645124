<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :locked="locked" :title="title" :returnToStep="returnToStep">
     <template v-slot:resume-content>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-primary has-text-weight-bold">
          {{ expedition.recipientEntity.address.name }}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("address-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.recipientEntity.address.line}}<br />
          {{expedition.recipientEntity.address.lineAdditionalInfo}}
          <br />{{expedition.recipientEntity.address.zipCode}} - {{expedition.recipientEntity.address.city}} ({{expedition.recipientEntity.address.getCountryName()}})
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("date-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-weight-bold">
          {{ expedition.deliveryDate }}
        </div>
      </div>
      <div v-if="expedition.recipientEntity.haveAmSchedule || expedition.recipientEntity.havePmSchedule" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("time-slot-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-weight-bold">
          <strong v-if="expedition.recipientEntity.haveAmSchedule">{{expedition.recipientEntity.amOpenStart}} - {{expedition.recipientEntity.amOpenEnd}}</strong>
          <strong v-if="expedition.recipientEntity.haveAmSchedule && expedition.recipientEntity.havePmSchedule">/</strong>
          <strong v-if="expedition.recipientEntity.havePmSchedule">{{expedition.recipientEntity.pmOpenStart}} - {{expedition.recipientEntity.pmOpenEnd}}</strong>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <ul>
            <li v-for="(contact, index) in expedition.recipientEntity.contacts" v-bind:key="index">
              {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="expedition.recipientEntity.floorNumber != 0" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("floors-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.recipientEntity.floorNumber}}
        </div>
      </div>
      <div v-if="expedition.recipientEntity.stairNumber != 0" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("stairs-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.recipientEntity.stairNumber}}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("staircase-type-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="staircaseTypes !== null">
            {{ staircaseTypes[expedition.recipientEntity.staircaseType] }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("elevator-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="expedition.recipientEntity.elevator">
            {{$t('yes')}}
          </span>
          <span v-else>
            {{$t('no')}}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("other-constraints-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-danger line-returns">
          {{expedition.recipientEntity.otherInformations}}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("observations-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-danger line-returns">
          {{expedition.observations}}
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumeRecipient',
  mixins: [resumeStepsMixin],
  props: {
    title: {
      type: String,
      default: 'delivery-title'
    }
  },
  computed: {
    staircaseTypes(){
      return this.$store.getters['commonData/staircaseTypes']
    },
  }
}
</script>
