import { ModalData } from '@/models/modal'
import { difference } from '@/utils/helpers'

export default{
  methods:{
    getDifferenceWithOriginal(originalExpedition, expedition) {
      // Get the difference beetwen the two expedition
      const diffs = difference(originalExpedition, expedition)
      const reverseDiffs = difference(expedition, originalExpedition)
      const differentsData = {}

      // For each key of the differences : Copy all the new data
      // This is mandatory to avoid to send just a part of a JSON FIELD
      // For example if an entity (JSON) has its contacts changed:
      // we have to send the whole entity to the server and not only the changed contact.
      Object.keys(diffs).forEach(key => {
        if (
          (
            key === 'recipientEntity' ||
            key === 'senderEntity' ||
            key === 'purchaserEntity'
          ) &&
          (
            !(typeof diffs[key] === 'object' && diffs[key] !== null) ||
            Object.keys(diffs[key]).length > 0
          )
        ) {
          // if the ID is present : this is a new entity, we have to put ALL the data
          if (reverseDiffs[key]['id']) {
            differentsData[key] = expedition[key]
          } else {
            // We have to put back the id to allow update in back
            differentsData[key] = reverseDiffs[key]
            differentsData[key]['id'] = expedition[key]['id']

            // We dont send enterprise here, in back enterprise are not inclueded in the expedition
            // serializer
            delete differentsData[key]['enterprise']

            // Special case for address: If the address is modified: We need to re-add all the data
            if (reverseDiffs[key]['address'] !== undefined) {
              differentsData[key]['address'] = expedition[key]['address']
            }

            if (reverseDiffs[key]['contacts'] !== undefined) {
              differentsData[key]['contacts'] = expedition[key]['contacts']
            }
          }
          // Only id case
          if (Object.keys(differentsData[key]).length === 1) {
            delete differentsData[key]
          }
        }
        // If it's not an object or there is at least on key
        else if(
          Array.isArray(diffs[key]) || // if it's an array or
          (!(typeof diffs[key] === 'object' && diffs[key] !== null) || Object.keys(diffs[key]).length > 0)
        )  {
          differentsData[key] = expedition[key]
        }
      })
      return differentsData
    },
    cannotEditError(expedition, message) {
      let text = ""
      if (message) {
        text = message
      } else {
        text = expedition.isCanceled() ? this.$t('cant-edit-cancel') : this.$t('cant-edit-after-delivery-date')
      }
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
            text
          }],
          {
            "home": {
              active: true,
              css: 'is-danger',
              onClick: () => {
                this.$store.dispatch('closeModal')
                this.$router.push('/')
              }
            }
          }
        )
      )
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
