import { ModalData } from '@/models/modal'
export default{
  methods:{
    accessDeniedError() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
            text: this.$t('access-denied'),
          }],
          {
            "home": {
              active: true,
              css: 'is-danger',
              onClick: () => {
                this.$router.push('/')
                this.$store.dispatch('closeModal')
              }
            }
          }
        )
      )
    }
  }
}
