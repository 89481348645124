<template>
    <section class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container" v-if="accessGranted">
          <div class="columns is-centered is-multiline">
            <router-link to="/expedition-module/list" class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div>
                <img class="img-dashboard" src="@/assets/icons/Eye_yellowgradient_icon.png" >
                <p align="center">{{$t("tracking-control-title")}}</p>
              </div>
            </router-link>

            <router-link v-if="!isTransporter && haveStockAccess" to="/expedition-module/create" class="column is-5-tablet is-4-desktop is-3-widescreen">
              <div>
                  <img class="img-dashboard" src="@/assets/icons/Add_yellowgradient_icon.png">
                  <p align="center">{{$t("expedition-order-title")}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
</template>

<style>
.img-dashboard {
  display : block;
  margin: auto;
  width: 128px;
  height: 128px;
}
</style>
<script>

import deniedAccessMixin from '@/mixins/deniedAccessMixin'

export default {
  name: 'ExpeditionModule',
  mixins: [deniedAccessMixin],
  computed: {
    isTransporter () {
      if(this.user !== null) {
        return this.user.isTransporter
      }
      return false;
    },
    haveStockAccess(){
      if(this.user !== null) {
        return this.user.haveStockAccess
      }
      return false;
    },
    accessGranted(){
      if (this.user === null) {
        return false
      } else {
        if(this.user.haveExpeditionAccess){
          return true
        }else{
          this.accessDeniedError()
          return false
        }
      }
    },
    user() {
      return this.$store.state.user
    }
  }
}
</script>
