<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <template v-if="expedition.insurance">
          <div class="column is-one-third has-text-right">
            <strong> {{$t("amount-to-ensure-label")}}{{$t("colon")}}</strong><br />
          </div>
          <div class="column is-two-thirds">
            {{expedition.amountToEnsure}}
          </div>
        </template>
        <template v-else>
          <div class="column is-one-third has-text-right">
            <strong> {{$t("insurance-content-label")}}{{$t("colon")}}</strong><br />
          </div>
          <div class="column is-two-thirds">
            {{$t("no")}}
          </div>
        </template>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
export default {
  name: 'detailsPurchaser',
  mixins: [detailsStepMixin]
}
</script>
