<template>
  <div class="container">
    <div v-if="!actionValue" class="field has-text-centered">
      {{$t("help-text-selected-articles")}}
    </div>
    <div class="field">
      <p class="control has-icons-left">
        <input :placeholder="$t('research-label')" class="input" type="search" v-model="searchValue" />
        <span class="icon is-small is-left">
          <i class="fas fa-search"></i>
        </span>
      </p>
    </div>
    <transition name="slide-fade">
      <div v-if="notif" class="is-danger notification article-notif">
        {{$t("successfully-remove-article")}}
        <!-- (actionValue) ? $t("successfully-add-article") : -->
      </div>
    </transition>
    <vue-good-table
      :columns="columns"
      :rows="value"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        dropdownAllowAll: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button :disabled="notif || !editable" type="button" class="button is-danger" @click="removeArticle(props)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </span>
        <span v-else-if="props.column.field == 'status'">
          {{$t(props.formattedRow[props.column.field])}}
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import vModelMixin from '@/mixins/vModelMixin';

export default {
  name: 'ExpeditionContent',
  mixins: [vModelMixin],
  components: {
    VueGoodTable
  },
  props: {
    // value : Content of the expedition
    actionValue: {
      type: Boolean
    },
    haveSearch: {
      type: Boolean
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data: function(){
    return {
      notif: false,
      searchValue: "",
      previousSearchValue: "",
      selectedIndex: -1,
      timer: 0,
      columns: [
        {label: this.$t("qr-code-label"), field: "qrcode"},
        {label: this.$t("article-denomination-label"), field: "denomination"},
        {label: this.$t("description-label"), field: "type"},
        {label: this.$t("serial-number-label"), field: "serialNumber"},
        {label: this.$t("note-label"), field: "note"},
        {label: this.$t("owner-label"), field: "owner"},
        {label: this.$t("status-label"), field: "status"},
        {label: this.$t("purchase-reference-label"), field: "purchaseReference"},
        {label: "", field: "action", html: true, sortable: false}
      ]
    }
  },
  methods: {
    openNotification(){
      this.notif = true
      setTimeout(() => {this.notif = false}, 800)
    },
    removeArticle(lineProps){
      const finalContent = this.deepCopy(this.value)
      finalContent.splice(lineProps.index, 1)
      this.openNotification()
      this.$emit('input', finalContent)
    },
    searchNewItems(value){
      let filters = value.split(" ")
      // this.listIntermediaire = [];
      this.contentChoosen.forEach((element, index) => {
        let filterInSEARCH = true
        // property is equivalent to stock headers
        filters.forEach(filter => {
          if(!element["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
            filterInSEARCH = false
          }
        })
        if(filterInSEARCH){
          element['indexBase'] = index;
          // this.listIntermediaire.push(element);
        }
      })
      this.previousSearchValue = value
    },
  },
}
</script>

<style lang="scss" scoped>
  @import 'vue-good-table/dist/vue-good-table';

  table.vgt-table {
    thead th{
      vertical-align: middle;
    }

    tr td{
      &:last-child{
        background-color: hsl(0, 0%, 98%);
        box-shadow: 0 0 0 1px #dcdfe6;
        position: sticky;
        right: 0;
        transform: translateY(.5px);
      }
    }
  }
  .article-notif{
    position: sticky;
    top: 5%;
    z-index: 1000;
    width: 100%;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
  }
  .slide-fade-enter{
    opacity: 1;
  }
  .slide-fade-leave{
    opacity: 1;
  }
  .slide-fade-leave-active{
    transition: opacity 0.3s;
    opacity: 0;
  }
</style>
