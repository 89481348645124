<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :locked="locked" title="pick-up-and-return-title" :returnToStep="returnToStep">
    <template v-slot:resume-content>
      <div class="columns is-mobile" v-if="expedition.kind === 'PICK_UP_AND_RETURN'">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("storage-type-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="storageTypes !== null">
            {{ storageTypes[expedition.storageType] }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile" v-else>
        <div class="column is-one-third has-text-right">
          <strong>{{$t("pick-up-and-return-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="expedition.expeditionReturn">{{$t("yes")}}</span>
          <span v-else>{{$t("no")}}</span>
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumePickUpAndReturn',
  mixins: [resumeStepsMixin],
  computed: {
    storageTypes(){
      return this.$store.getters['commonData/storageTypes']
    }
  }
}

</script>
