<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :locked="locked" title="expedition-content-title" :returnToStep="returnToStep">
    <template v-slot:resume-content>
      <div class="card-content">
        <div class="table-container">
          <table class="table" v-if="!expedition.isPickUp() && (expedition.isLoan() || expedition.isSenderKomaks())">
          <thead>
            <tr>
              <th>
                {{ $t("qr-code-label") }}
              </th>
              <th>
                {{ $t("article-denomination-label") }}
              </th>
              <th>
                {{ $t("description-label") }}
              </th>
              <th>
                {{ $t("serial-number-label") }}
              </th>
              <th>
                {{ $t("note-label") }}
              </th>
              <th>
                {{ $t("owner-label") }}
              </th>
              <th>
                {{ $t("status-label") }}
              </th>
              <th v-if="!expedition.isLoan()">
                {{ $t("purchase-reference-label") }}
              </th>
              <th v-else>
                {{ $t("quantity-label") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in expedition.content" v-bind:key="index">
              <td>
                {{item.qrcode}}
              </td>
              <td>
                {{item.denomination}}
              </td>
              <td>
                {{item.type}}
              </td>
              <td>
                {{ item['serialNumber'] }}
              </td>
              <td>
                {{ item.note }}
              </td>
              <td>
                {{item.owner}}
              </td>
              <td>
                {{$t(item.status)}}
              </td>
              <td v-if="!expedition.isLoan()">
                {{ item.purchaseReference }}
              </td>
              <td v-else>
                {{ item.quantity}}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table" v-else>
          <thead>
            <tr>
              <th>
                {{ $t("description-label") }}
              </th>
              <th>
                {{ $t("serial-number-label") }}
              </th>
              <th>
                {{ $t("quantity-label") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in expedition.content" v-bind:key="index">
              <td>
                {{item.name}}
              </td>
              <td>
                <span v-if="item.attributeQuality">
                  {{item.attributeQuality.serialNumber}}
                </span>
              </td>
              <td>
                {{item.quantity}}
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumeContent',
  mixins: [resumeStepsMixin],
}
</script>
