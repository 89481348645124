const api = {
  createPickUpAndReturn(pickUpAndReturn){
    return this.apiClient().post('expedition/pick-up-and-returns/', pickUpAndReturn)
  },
  getPickUpAndReturns(params){
    return this.apiClient().get('expedition/pick-up-and-returns/', (params) ? {params} : {})
  },
  getPickUpAndReturn(id){
    return this.apiClient().get('expedition/pick-up-and-returns/' + id + '/')
  },
  updatePickUpAndReturn(pickUpAndReturn, id){
    return this.apiClient().patch('expedition/pick-up-and-returns/' + id + '/', pickUpAndReturn)
  },
}

export default api;
