<template>
  <div class="has-text-centered section-title">
    <span v-if="imgName" class="section-title--icon">
      <i :class="imgName"></i>
    </span>
    <span v-else-if="stairs">
      <img src="../assets/icons/Stairs_icon.png" alt="stairs logo">
    </span>
    {{ title }}
  </div>
</template>

<script>

export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String
    },
    imgName: {
      type: String
    },
    stairs: {
      type: Boolean
    }
  }
}

</script>

<style lang="scss" scoped>
  img{
    height: 15px;
  }
</style>
