import apiClient from '@/client/client_logistics'
import { Expedition } from '@/models/expedition.js'

export default {
  namespaced: true,
  state: {
    demos: {
      loading: true,
      collection: []
    }
  },
  getters: {
    demos: state => {
      return state.demos
    },
  },
  mutations: {
    setDemos(state, demos) {
      state.demos = {
        loading: false,
        collection: demos
      }
    },
    // Set the loading to true, remove the current collections
    loadDemos(state) {
      state.demos = {
        loading: true,
        collection: []
      }
    },
    updateDemo(state, demo) {
      const demos = state.demos
      const index = demos.collection.findIndex((exp) => exp.id === demo.id)
      if (index !== -1) {
        demos.collection.splice(index, 1, demo)
      }
      state.demos = Object.assign({}, demos) // Copy to force update !
    },
    removeDemo(state, demoId) {
      const demos = state.demos
      const index = demos.collection.findIndex((exp) => demoId === exp.id)
      if (index !== -1) {
        demos.collection.splice(index, 1)
      }
      state.selectedDemo = demos
    },
  },
  actions: {
    async getDemos({ commit }) {
      commit('loadDemos') // Set global demos loadings
      return apiClient.getLoans().then((response) => {
        this.loading = false
        commit('setDemos', response.data.map(demo => {
          let searchKey = demo.recipientEntity.address.name
          searchKey += " " + demo.recipientEntity.address.nameAdditionalInfo
          searchKey += " " + demo.recipientEntity.address.line
          searchKey += " " + demo.recipientEntity.address.lineAdditionalInfo
          searchKey += " " + demo.recipientEntity.address.zipCode
          searchKey += " " + demo.recipientEntity.address.city
          searchKey += " " + demo.senderEntity.address.name
          searchKey += " " + demo.senderEntity.address.nameAdditionalInfo
          searchKey += " " + demo.senderEntity.address.line
          searchKey += " " + demo.senderEntity.address.lineAdditionalInfo
          searchKey += " " + demo.senderEntity.address.zipCode
          searchKey += " " + demo.senderEntity.address.city
          searchKey += " " + demo.purchaserEntity.address.name
          searchKey += " " + demo.purchaserEntity.address.nameAdditionalInfo
          searchKey += " " + demo.purchaserEntity.address.line
          searchKey += " " + demo.purchaserEntity.address.lineAdditionalInfo
          searchKey += " " + demo.purchaserEntity.address.zipCode
          searchKey += " " + demo.purchaserEntity.address.city

          searchKey = searchKey + " " + demo.reference
          if (demo.pickUp !== null) {
            searchKey = demo.pickUp.recipientEntity.address.name
            searchKey += " " + demo.pickUp.recipientEntity.address.nameAdditionalInfo
            searchKey += " " + demo.pickUp.recipientEntity.address.line
            searchKey += " " + demo.pickUp.recipientEntity.address.lineAdditionalInfo
            searchKey += " " + demo.pickUp.recipientEntity.address.zipCode
            searchKey += " " + demo.pickUp.recipientEntity.address.city
            if (demo.pickUp.senderEntity) {
              searchKey += " " + demo.pickUp.senderEntity.address.name
              searchKey += " " + demo.pickUp.senderEntity.address.nameAdditionalInfo
              searchKey += " " + demo.pickUp.senderEntity.address.line
              searchKey += " " + demo.pickUp.senderEntity.address.lineAdditionalInfo
              searchKey += " " + demo.pickUp.senderEntity.address.zipCode
              searchKey += " " + demo.pickUp.senderEntity.address.city
            }
            if (demo.pickUp.purchaserEntity) {
              searchKey += " " + demo.pickUp.purchaserEntity.address.name
              searchKey += " " + demo.pickUp.purchaserEntity.address.nameAdditionalInfo
              searchKey += " " + demo.pickUp.purchaserEntity.address.line
              searchKey += " " + demo.pickUp.purchaserEntity.address.lineAdditionalInfo
              searchKey += " " + demo.pickUp.purchaserEntity.address.zipCode
              searchKey += " " + demo.pickUp.purchaserEntity.address.city
            }
            searchKey = searchKey + " " + demo.pickUp.reference
          }

          demo.content.forEach(item => {
            if(item.attributeQuality !== undefined){
              searchKey = searchKey + " " + item.attributeQuality.serialNumber
            }
            if(item.serialNumber !== undefined && item.serialNumber !== null){
              searchKey = searchKey + " " + item.serialNumber
            }
            if (item.purchaseReference) {
              searchKey += " " + item.purchaseReference
            }
            if (item.qrcode) {
              searchKey += " " + item.qrcode
            }
          })
          const demoObject = new Expedition(demo)
          demoObject['kind'] = 'LOAN'
          demoObject["SEARCH"] = searchKey
          return demoObject
        }))
      })
    },
    updateDemo({ commit }, demo) {
      commit('updateDemo', demo) // Update the demo
    },
    async switchWithTransfer({commit}, idDemoAndTransfer) {
      return apiClient.switchTransfer(idDemoAndTransfer).then(response => {
        if(response.data && response.data.updatedExpeditions) {
          response.data.updatedExpeditions.forEach(demo => {
            commit('updateDemo', new Expedition(demo))
          })
        }
      })
    },
    async cancelDemo({ commit }, demoId) {
      return apiClient.partialUpdateLoan({status: 'CANCELED'}, demoId).then((response) => {
        commit('updateDemo', new Expedition(response.data))
      })
    },
  },
}
