const api = {
  getChatMessages(chatId) {
    return this.apiClient().get(
      'chats/' + chatId + '/messages/'
    )
  },
  postMessage(chatId, message){
    return this.apiClient().post(
      'chats/' + chatId + '/messages/',
      message
    )
  },
  createChat(chatData){
    return this.apiClient().post(
      'chats/',
      chatData
    )
  }
};

export default api;
