<template>
  <DetailsTemplate :expedition="expedition" :isEditionClosed="isEditionClosed" :isKomaks="isKomaks">
    <template v-slot:extra-actions>
      <template v-if="!isTransporter && !expedition.isCanceled()">
        <template v-if="user && user.havePickUpAccess && expedition.pickUp === null">
          <router-link :to="'/pick-up-and-return-module/create/' + expedition.id">
              {{ $t("create-pick-up-and-return") }}
          </router-link>
        </template>
        <!-- Expedition cancel -->
        <div v-if="!loadingCancel">
          <a
            v-if="(isAdmin || !isEditionClosed) && !loadingCancel"
            class="link"
            @click="confirmeCancel = true">
            {{ $t("cancel-expedition-button") }}
          </a>
        </div>
        <template v-if="user && user.haveExpeditionAccess">
          <router-link :to="{
                            name: 'create-expedition',
                            query: {
                              purchaser: expedition.purchaser,
                              purchaserEntity: JSON.stringify(expedition.purchaserEntity),
                              recipient: expedition.recipient,
                              recipientEntity: JSON.stringify(expedition.recipientEntity),
                              sender: expedition.sender,
                              senderEntity: JSON.stringify(expedition.senderEntity),
                            }}">
              {{ $t("duplicate-expedition-button") }}
          </router-link>
        </template>
        <div class="has-text-warning" v-if="loadingCancel">
          {{$t("loading")}}
        </div>
        <modal v-if="confirmeCancel && (isAdmin || !isEditionClosed)" @close="confirmeCancel = false">
          <template slot="header">
            <p class="modal-card-title has-text-centered">{{$t("cancel-confirmation-button")}}</p>
            <button
              type="button"
              class="button is-pulled-right"
              :disabled="loadingCancel"
              aria-label="close"
              @click="confirmeCancel = false">
              <i class="fas fa-times"></i>
            </button>
          </template>
          <!-- actionValue to true if we add article, false otherwise -->
          <div slot="body">
            <Confirmation
              v-if="!loadingCancel"
              :message="$t('cancel-expedition-confirmation-message')"
              @cancel="confirmeCancel=false"
              @confirm="cancel()">
            </Confirmation>
            <progress v-else class="progress is-small is-primary" max="100">15%</progress>
          </div>
        </modal>
      </template>
    </template>
    <template v-slot:detail-content>
      <DetailsPurchaser
        :expedition="expedition"
        :step="'1'"
        :title="'purchaser-title'"
        :getUrl="() => expedition.getHrefUpdate(0)"
        :updateCondition="isAdmin || (!isTransporter && !isRemovalDatePassed && !isEditionClosed)">
      </DetailsPurchaser>

      <DetailsTransporter
        :expedition="expedition"
        :step="'2'"
        :title="'transporter-title'"
        :getUrl="() => expedition.getHrefUpdate(2)"
        :updateCondition="isAdmin || (!isTransporter && !isRemovalDatePassed && !isEditionClosed)"
        :isTransporter="isTransporter"
        >
      </DetailsTransporter>

      <DetailsSender
        :expedition="expedition"
        :step="'3'"
        :title="'removal-title'"
        :getUrl="() => expedition.getHrefUpdate(1)"
        :updateCondition="isAdmin || (!isTransporter && !isRemovalDatePassed && !isEditionClosed)">
      </DetailsSender>

      <DetailsRecipient
        :expedition="expedition"
        :step="'4'"
        :title="'delivery-title'"
        :getUrl="() => expedition.getHrefUpdate(3)"
        :updateCondition="isAdmin || (!isTransporter && !isEditionClosed)">
      </DetailsRecipient>

      <DetailsContent
        :expedition="expedition"
        :step="'5'"
        :title="'content-title'"
        :getUrl="() => expedition.getHrefUpdate(4)"
        :updateCondition="isAdmin || (!isTransporter && !isRemovalDatePassed && !isEditionClosed)"
        :isKomaks="isKomaks">
      </DetailsContent>

      <DetailsInsurance
        :expedition="expedition"
        :step="'5.1'"
        :title="'insurance-title'"
        :getUrl="() => expedition.getHrefUpdate(5)"
        :updateCondition="isAdmin || (!isTransporter && !isEditionClosed)">
      </DetailsInsurance>

      <DetailsPickUpAndReturn
        :expedition="expedition"
        :step="'6'"
        :title="'pick-up-and-return-title'"
        :getUrl="() => expedition.getHrefUpdate(6)"
        :updateCondition="isAdmin || (!isTransporter && !isEditionClosed)">
      </DetailsPickUpAndReturn>

      <div class="buttons is-centered">
        <router-link v-if="user && user.havePickUpAccess && expedition.pickUp !== null" :to="'/pick-up-and-return-module/list/' + expedition.pickUp.id">
          <button class="button is-info">
            {{ $t("pick-up-and-return-tracking") }}
          </button>
        </router-link>
      </div>
    </template>
  </DetailsTemplate>
</template>
<script>
import DetailsMixin from '@/mixins/detailsMixin'
export default {
  name: 'ExpeditionDetails',
  mixins: [DetailsMixin],
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  data () {
    return {
      cancelStorePath: 'expeditionModule/cancelExpedition'
    }
  }
}
</script>
