<template>
  <div class="columns">
    <p
      v-if="errorsData && errorsData.purchaser"
      class="help is-danger has-text-centered">
      {{errorsData.purchaser[0]}}
    </p>
    <div class="column is-6">
      <div>
        <div class="box">
            <div class="has-text-centered section-title">
              <label>{{ $t('purchaser-name-label') }}</label>
            </div>
            <div v-if="!editable" class="notification is-warning">
                {{$t('content-after-removal-date')}}
            </div>
            <div class="field">
              <label class="label"> {{$t("name-label")}}</label>
              <Multiselect
                id="purchaserMultiselect"
                v-if="choiceList.length > 1"
                label="entityName"
                v-model="selectedEntity"
                :class="{'is-danger': errorOnAddress('name')}"
                :loading="loading"
                :disabled="!editable"
                @select="updateEntity"
                @remove="resetEntity"
                :options="choiceListWithNames">
              </Multiselect>
              <input
                v-else
                id="purchaserInput"
                class="input has-text-primary has-text-weight-bold"
                :class="{'is-danger': errorOnAddress('name')}"
                type="text"
                :value="enterpriseName"
                required
                disabled>
              <p
                v-if="errorOnAddress('name')"
                class="help is-danger">
                {{errorsData.purchaserEntity.address.name[0]}}
              </p>
            </div>
            <div class="field">
              <label class="label"> {{$t("name-additional-info-label")}} </label>
              <input
                class="input"
                :class="{'is-danger': errorOnAddress('nameAdditionalInfo')}"
                type="text"
                :value="value.purchaserEntity.address.nameAdditionalInfo"
                disabled>
              <p
                v-if="errorOnAddress('nameAdditionalInfo')"
                class="help is-danger">
                {{errorsData.purchaserEntity.address.nameAdditionalInfo[0]}}
              </p>
            </div>
          </div>
          <div>
            <div class="box">
              <AddressField
                :value="value.purchaserEntity.address"
                :title="$t('purchaser-address-label')"
                :autoComplete="true"
                :errorsData="(errorsData && errorsData.purchaserEntity) ? errorsData.purchaserEntity.address : null"
              >
              </AddressField>
            </div>
            <div class="box">
              <SchedulesField
                :value="value.purchaserEntity"
                :autoComplete="true"
                :errorsData="(errorsData) ? errorsData.purchaserEntity : null"
              >
              </SchedulesField>
            </div>
          </div>
      </div>
    </div>
    <div class="column is-6">
      <ContactsField
        :editable="editable"
        :value="value.purchaserEntity.contacts"
        :errorsData="(errorsData && errorsData.purchaserEntity) ? errorsData.purchaserEntity.contacts : null"
        @input="update('purchaserEntity.contacts', $event)">
      </ContactsField>
    </div>
  </div>
</template>


<script>

import Multiselect from 'vue-multiselect'
import AddressField from '@/components/fields/AddressField'
import SchedulesField from '@/components/fields/SchedulesField'
import ContactsField from '@/components/fields/ContactsField'
import { Entity } from '@/models/expedition'
import vModelMixin from '@/mixins/vModelMixin'


export default {
  name: 'PurchaserField',
  mixins: [vModelMixin],
  components: {
    Multiselect, AddressField, SchedulesField, ContactsField
  },
  props: {
    choiceList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true,
    },
    errorsData: {
      type: Object
    }
  },
  data: function(){
    return {
      selectedEntity: {
        name: ''
      },
    }
  },
  beforeMount(){
    this.selectedEntity = Object.assign({}, this.value.purchaserEntity)
    this.selectedEntity.entityName = this.value.purchaserEntity.enterprise ? this.value.purchaserEntity.enterprise.businessName : this.value.purchaserEntity.address.name
  },
  computed: {
    enterpriseName () {
      if (!this.value.purchaserEntity) return ''
      else {
        return this.value.purchaserEntity.enterprise ? this.value.purchaserEntity.enterprise.businessName : this.value.purchaserEntity.address.name
      }
    },
    choiceListWithNames () {
      return this.choiceList.map(el => {
        const copyWithName = Object.assign({}, el)
        copyWithName.entityName = el.enterprise.businessName
        return copyWithName
      })
    }
  },
  methods: {
    updateEntity(entity){
      if(entity){
        const cleanEntity = new Entity(entity)
        const purchaser = cleanEntity.enterprise.id
        this.selectedEntity = cleanEntity
        const purchaserEntity = cleanEntity
        this.updateFromObject({purchaserEntity, purchaser})
      }
    },
    resetEntity(){
      this.selectedEntity = {
        name: ''
      }
      this.updateFromObject({purchaserEntity: new Entity(), purchaser: null})
    },
    errorOnAddress(nameField){
      if(this.errorsData && this.errorsData.purchaserEntity && this.errorsData.purchaserEntity.address && this.errorsData.purchaserEntity.address[nameField]){
        return true
      }
      return false;
    }
  }
}

</script>
