<template>
  <DetailsTemplate :expedition="expedition" :isKomaks="isKomaks">
    <template v-slot:extra-actions>
        <template v-if="!isTransporter">
          <router-link :to="{
                            name: 'create-loan',
                            query: {
                              purchaser: expedition.purchaser,
                              purchaserEntity: JSON.stringify(expedition.purchaserEntity),
                              recipient: expedition.recipient,
                              recipientEntity: JSON.stringify(expedition.recipientEntity),
                              sender: expedition.sender,
                              senderEntity: JSON.stringify(expedition.senderEntity),

                              pickUpRecipient: expedition.pickUp.recipient,
                              pickUpRecipientEntity: JSON.stringify(expedition.pickUp.recipientEntity),
                              pickUpSender: expedition.pickUp.sender,
                              pickUpSenderEntity: JSON.stringify(expedition.pickUp.senderEntity),
                            }}">
              {{ $t("duplicate-expedition-button") }}
          </router-link>
        </template>
        <template v-if="!isTransporter && !expedition.isCanceled()">
          <template v-if="expedition.isLoan() && !expedition.pickUp.isRemovalDatePassed()">
            <div v-if="expedition.transfers.length !== 0">
              <a @click="confirmeSwitch = true">
                {{$t('switch-transfer-button-label')}}
              </a>
            </div>
            <router-link
              v-if="expedition.transfers.length === 0"
              :to="`/transfer-module/create/${expedition.id}`">
                {{ $t("create-transfer-button") }}
            </router-link>
            <div v-if="!loadingCancel">
              <a class="has-text-danger" @click="confirmeCancel = true">
                {{ $t("cancel-demo-button") }}
              </a>
            </div>
            <div class="has-text-warning" v-else>
              {{$t("loading")}}
            </div>
          </template>

          <!-- Cancel modal -->
          <modal v-if="confirmeCancel" @close="confirmeCancel = false">
            <template slot="header">
              <p class="modal-card-title has-text-centered">{{$t("cancel-confirmation-button")}}</p>
              <button
                type="button"
                class="button is-pulled-right"
                :disabled="loadingCancel"
                aria-label="close"
                @click="confirmeCancel = false">
                <i class="fas fa-times"></i>
              </button>
            </template>
            <!-- actionValue to true if we add article, false otherwise -->
            <div slot="body">
              <Confirmation
                v-if="!loadingCancel"
                :message="getCancelMessage()"
                @cancel="confirmeCancel=false"
                @confirm="cancel()">
              </Confirmation>
              <progress v-else class="progress is-small is-primary" max="100">15%</progress>
            </div>
          </modal>

          <!-- Switch modal -->
          <modal v-if="confirmeSwitch" @close="confirmeSwitch = false">
            <template slot="header">
              <p class="modal-card-title has-text-centered">{{$t("switch-transfer-confirmation-button")}}</p>
              <button
                type="button"
                class="button is-pulled-right"
                :disabled="loadingSwitch"
                aria-label="close"
                @click="confirmeSwitch = false">
                <i class="fas fa-times"></i>
              </button>
            </template>
            <div slot="body">
              <div class="notification is-danger" v-if="errorSwitch !== null">{{errorSwitch}}</div>
              <Confirmation
                v-if="!loadingSwitch"
                :message="$t('switch-transfer-sentence-confirmation')"
                @cancel="confirmeSwitch = false"
                @confirm="launchSwitchProcess">
              </Confirmation>
              <progress v-else class="progress is-small is-primary" max="100">15%</progress>
            </div>
          </modal>
        </template>
    </template>
    <template v-slot:detail-content>
      <DetailsPurchaser
        :expedition="expedition"
        :step="'1'"
        :title="'purchaser-title'"
        :getUrl="() => expedition.getHrefUpdate(0)"
        :updateCondition="!expedition.isTransfer() && !isTransporter && !isRemovalDatePassed && !isEditionClosed">
      </DetailsPurchaser>

      <DetailsSender
        :expedition="expedition"
        :step="'2'"
        :title="'removal-title'"
        :getUrl="() => expedition.getHrefUpdate(1)"
        :updateCondition="!expedition.isTransfer() && !isTransporter && !isRemovalDatePassed && !isEditionClosed">
      </DetailsSender>

      <DetailsTransporter
        :expedition="expedition"
        :step="'3'"
        :title="'transporter-title'"
        :getUrl="() => expedition.isTransfer() ? expedition.getHrefUpdate(0) : expedition.getHrefUpdate(2)"
        :updateCondition="!isTransporter && !isRemovalDatePassed && !isEditionClosed"
        :isTransporter="isTransporter"
        >
      </DetailsTransporter>

      <DetailsRecipient
        :expedition="expedition"
        :step="'4'"
        :title="'delivery-title'"
        :getUrl="() => expedition.isTransfer() ?expedition.getHrefUpdate(1) : expedition.getHrefUpdate(3)"
        :updateCondition="!isTransporter && !isEditionClosed">
      </DetailsRecipient>

      <DetailsContent
        :expedition="expedition"
        :step="'5'"
        :title="'content-title'"
        :getUrl="() => expedition.getHrefUpdate(4)"
        :updateCondition="!expedition.isTransfer() && !isTransporter && !isRemovalDatePassed && !isEditionClosed"
        :isKomaks="isKomaks">
      </DetailsContent>
      <template v-if="expedition.pickUp !== null">
        <div class="columns">
          <div class="column">
            <div
                class="expedition-validation-indicator"
                :class="{
                  'has-text-danger': !expedition.pickUp.validation || expedition.pickUp.isCanceled(),
                  'has-text-blue': expedition.pickUp.validation && !expedition.pickUp.isDelivered(),
                  'has-text-green': expedition.pickUp.validation && expedition.pickUp.isDelivered()
                }"
              >
                <i class="fas fa-truck fa-2x m-r-md"></i>
                <b v-if="expedition.pickUp.isCanceled()" class="is-uppercase">
                  {{$t('expedition-canceled')}}
                </b>
                <span v-else-if="!expedition.pickUp.validation">
                  {{$t('not-fixed-appointment')}}
                </span>
                <span v-else-if="expedition.pickUp.validation && !expedition.pickUp.isDelivered()">
                  {{$t('fixed-appointment')}}
                </span>
                <span v-else>
                  {{$t('expedition-delivered')}}
                </span>
              </div>
            </div>
          <div class="column has-text-centered">
            <h1 class="is-size-4 has-text-weight-bold">{{$t('pick-up-and-return-title')}}</h1>
          </div>
          <div class="column has-text-right">
            <p class="has-text-grey-light is-size-7">{{ expedition.expeditionDate }}</p>
          </div>
        </div>

        <DetailsDateManagement :expedition="expedition.pickUp" v-if="!expedition.pickUp.isCanceled() && (isTransporter || isAdmin)"/>
      </template>

      <DetailsSender
        :expedition="expedition.pickUp"
        :step="'6'"
        :title="'removal-pick-up-and-return-title'"
        :getUrl="() => expedition.isTransfer() ? expedition.getHrefUpdate(2) : expedition.getHrefUpdate(5)"
        :updateCondition="!isTransporter && !expedition.pickUp.isRemovalDatePassed() && !expedition.pickUp.isCanceled()">
      </DetailsSender>

      <DetailsRecipient
        :expedition="expedition.pickUp"
        :step="'7'"
        :title="'pick-up-and-return-delivery-title'"
        :getUrl="() => expedition.isTransfer() ? expedition.getHrefUpdate(3) : expedition.getHrefUpdate(6)"
        :updateCondition="!isTransporter && !expedition.pickUp.canBeUpdated() && !expedition.pickUp.isCanceled()">
      </DetailsRecipient>

      <div class="buttons is-centered">
        <template v-if="expedition.transferedFrom !== null && (isAdmin || (!isAdmin && !isTransporter) || (isTransporter && isTransporterFor(expedition.transferedFrom)))">
          <router-link :to="`/demo-module/list/${expedition.transferedFrom.id}`">
            <button class="button is-success">{{$t("see-original-demo-button")}}</button>
          </router-link>
        </template>
        <template v-if="expedition.transfers.length !== 0 && (isAdmin || (!isAdmin && !isTransporter) || (isTransporter && isTransporterFor(expedition.transfers[0])))">
          <router-link :to="`/demo-module/list/${expedition.transfers[0].id}`">
            <button class="button is-success">{{$t("see-transfer-button")}}</button>
          </router-link>
        </template>
      </div>
    </template>
  </DetailsTemplate>
</template>
<script>
import DetailsDateManagement from '@/components/details/detailsDateManagement'
import DetailsMixin from '@/mixins/detailsMixin'
import {mapActions} from 'vuex'
export default {
  name: 'DemoDetails',
  mixins: [DetailsMixin],
  components: {
    DetailsDateManagement
  },
  data () {
    return {
      cancelStorePath: 'demoModule/cancelDemo',

      // Switch process datas
      confirmeSwitch: false,
      loadingSwitch: false,
      errorSwitch: null
    }
  },
  computed: {
    demos() {
      return this.$store.getters['demoModule/demos'].collection
    },
  },
  methods: {
    ...mapActions({switchWithTransfer: 'demoModule/switchWithTransfer'}),
    // This methods create the payload to pass to switchWithTransfer store function
    launchSwitchProcess() {
      this.loadingSwitch = true
      this.switchWithTransfer(
          {
            originalExpedition: this.expedition.id,
            transfer: this.expedition.transfers[0].id
          }
      )
      .then(() => {
        this.loadingSwitch = false
        this.confirmeSwitch = false
        this.errorSwitch = null
      })
      .catch((error) => {
        if(typeof error.response.data !== 'string'){
          Object.keys(error.response.data).forEach(key => {
            this.errorSwitch = `${key}: ${error.response.data[key]} \n`
          })
        } else {
        this.errorSwitch = error
        }
        this.loadingSwitch = false
      })

    },
    // Return all demo (Reference) inpacted for the annulation of the current demo
    getTransfersImpacted(currentReference, alreadyChecked=[]) {
      if (alreadyChecked.indexOf(currentReference) !== -1) {
        return
      }
      alreadyChecked.push(currentReference)
      const demo = this.demos.find(el =>  el.reference === currentReference)
      if (demo) {
        demo.transfers.forEach(transfer => {
          this.getTransfersImpacted(transfer.reference, alreadyChecked)
        })
      }
      return alreadyChecked
    },
    getCancelMessage() {
      if(this.expedition.transfers.length == 0) {
        return  this.$t('cancel-demo-confirmation-message')
      } else {
        let finalHtml = `
          <p>${this.$t('cancel-demo-confirmation-message')}</p>
          ${this.$t('also-cancel-following-transfer')}
          <ul>`
        this.getTransfersImpacted(this.expedition.reference).forEach((transfered) => {
          // All expected self
          if(transfered !== this.expedition.reference) finalHtml += `<li>${transfered}</li>`
        })
        finalHtml += '</ul>'
        return finalHtml
      }
    },
    isTransporterFor (expedition) {
      const user = this.$store.state.user
      if(user !== null) {
        const index = user.isTransporterFor.findIndex(el => el === expedition.transporter)
        return index !== -1
      }
      return false
    },
  }
}
</script>
