var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(!_vm.actionValue)?_c('div',{staticClass:"field has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t("help-text-selected-articles"))+" ")]):_vm._e(),_c('div',{staticClass:"field"},[_c('p',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"input",attrs:{"placeholder":_vm.$t('research-label'),"type":"search"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}}),_vm._m(0)])]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.notif)?_c('div',{staticClass:"is-danger notification article-notif"},[_vm._v(" "+_vm._s(_vm.$t("successfully-remove-article"))+" ")]):_vm._e()]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.value,"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPage: 100,
      dropdownAllowAll: false,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('button',{staticClass:"button is-danger",attrs:{"disabled":_vm.notif || !_vm.editable,"type":"button"},on:{"click":function($event){return _vm.removeArticle(props)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])]):(props.column.field == 'status')?_c('span',[_vm._v(" "+_vm._s(_vm.$t(props.formattedRow[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-left"},[_c('i',{staticClass:"fas fa-search"})])
}]

export { render, staticRenderFns }