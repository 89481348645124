<template>
  <div class="container">
    <div v-if="!actionValue" class="field has-text-centered">
      {{$t("help-text-selected-articles")}}
    </div>
    <div class="field">
      <p class="control has-icons-left">
        <input :placeholder="$t('research-label')" class="input" type="search" v-model="searchValue" />
        <span class="icon is-small is-left">
          <i class="fas fa-search"></i>
        </span>
      </p>
    </div>
    <transition name="slide-fade">
      <div v-if="notif" :class="[ actionValue ? 'is-success' : 'is-danger', 'notification', 'article-notif']">
        {{(actionValue) ? $t("successfully-add-article") : $t("successfully-remove-article")}}
      </div>
    </transition>
    <vue-good-table
      :columns="columns"
      :rows="filteredContent"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        dropdownAllowAll: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button type="button" v-if="actionValue" :disabled="!editable" class="button is-success" @click="(e) => validate(props.index, props.row)">
            <i class="fas fa-plus-circle"></i>
          </button>
          <button type="button" class="button is-danger" :disabled="!editable" v-else @click="(e) => validate(props.index)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </span>
        <span v-if="props.column.field == 'quantity-button'">
          <input v-if="actionValue" v-model="props.row.quantity" :disabled="!editable" type="number" min="1" class="input">
        </span>
        <span v-if="props.column.field == 'serial-number-button'">
          <input v-if="actionValue" v-model="props.row['serialNumber']" type="text" :disabled="!editable" class="input">
        </span>
        <span v-else-if="props.column.field == 'status'">
          {{$t(props.formattedRow[props.column.field])}}
        </span>
        <span v-else-if="props.column.field == 'quantity'">
          <span v-if="props.formattedRow['quantity'] && props.formattedRow['qrcode'] === ''">
            <input class="input" v-model="props.row.quantity" type="number" min="1" :disabled="!editable" @change="$emit('update-product', props.row)">
          </span>
          <div class="has-text-centered" v-else>
            1
          </div>
        </span>
        <span v-else-if="props.column.field == 'serialNumber' && props.row.qrcode === undefined">
            <input class="input" v-model="props.row['serialNumber']" type="text" :disabled="!editable" @change="$emit('update-product', props.row)">
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
  name: 'DemoContent',
  components: {
    VueGoodTable
  },
  props: {
    // list of all article at the beginning. Liste d'articles présents dans le tableau de filtre
    contentList: {
      type: Array
    },
    // the final list of articles. LIste d'articles selectionnés
    actionValue: {
      type: Boolean
    },
    haveSearch: {
      type: Boolean
    },
    columns: {
      type: Array
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data (){
    return {
      notif: false,
      searchValue: "",
      // liste des index du tableau d'article à mettre dans contentList
      timer: 0,
    }
  },
  computed: {
    filteredContent() {
      if (this.searchValue === "") return this.contentList
      let filters = this.searchValue.split(" ")
      const filteredContent = [];
      this.contentList.forEach((element) => {
        let filterInSEARCH = true
        // property is equivalent to stock headers
        filters.forEach(filter => {
          if(!element["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
            filterInSEARCH = false
          }
        })
        if (filterInSEARCH) {
          filteredContent.push(element)
        }
      })
      return filteredContent
    }
  },
  methods: {
    openSuccessNotification(){
      this.notif = true
      setTimeout(() => {this.notif = false}, 800)
    },

    validate(index, row) {
      this.openSuccessNotification()
      this.$emit('validate', row, index)
    }
  },
}
</script>

<style lang="scss">
  @import 'vue-good-table/dist/vue-good-table';

  table.vgt-table {
    thead th{
      vertical-align: middle;
    }

    tr td{
      &:last-child{
        background-color: hsl(0, 0%, 98%);
        box-shadow: 0 0 0 1px #dcdfe6;
        position: sticky;
        right: 0;
        transform: translateY(.5px);
      }
    }
  }
  .article-notif{
    position: sticky;
    top: 5%;
    z-index: 1000;
    width: 100%;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
  }
  .slide-fade-enter{
    opacity: 1;
  }
  .slide-fade-leave{
    opacity: 1;
  }
  .slide-fade-leave-active{
    transition: opacity 0.3s;
    opacity: 0;
  }
</style>
