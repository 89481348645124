import apiClient from '@/client/client_logistics'

import {mapGetters} from 'vuex'

import DetailsTemplate from '@/components/templates/detailsTemplate'
import DetailsPurchaser from '@/components/details/detailsPurchaser'
import DetailsTransporter from '@/components/details/detailsTransporter'
import DetailsSender from '@/components/details/detailsSender'
import DetailsRecipient from '@/components/details/detailsRecipient'
import DetailsContent from '@/components/details/detailsContent'
import DetailsPickUpAndReturn from '@/components/details/detailsPickUpAndReturn'
import DetailsStorage from '@/components/details/detailsStorage'
import DetailsInsurance from '@/components/details/detailsInsurance'

import Confirmation from '@/components/Confirmation'

import Modal from '@/components/Modal'
import { ModalData } from '@/models/modal'
import { isPassedDate, addDays, dateToString } from '@/utils/helpers'

export default {
  components: {
    DetailsTemplate,
    DetailsPurchaser,
    DetailsTransporter,
    DetailsSender,
    DetailsRecipient,
    DetailsContent,
    DetailsPickUpAndReturn,
    DetailsInsurance,
    DetailsStorage,
    Modal,
    Confirmation,
  },
  props: {
    expedition: {
      type: Object
    }
  },
  data: function() {
    return {
      updateInfoTransporter: false,
      updateContactsTransporter: [],
      updateImmatriculationTransporter: "",
      isKomaks: false,
      confirmeCancel: false,
      loadingCancel: false,
      // TO OVERIDE
      cancelStorePath: '',
    }
  },
  beforeMount(){
    this.senderIsKomaks()
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isTransporter () {
      const user = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.isTransporter
      }
      return false
    },
    isRemovalDatePassed() {
      return this.expedition.isRemovalDatePassed()
    },
    isEditionClosed() {
      return isPassedDate(dateToString(addDays(this.expedition.deliveryDate, 2))) ||
        this.expedition.isCanceled()
    }
  },
  methods: {
    senderIsKomaks(){
      if(this.expedition.sender === null) return
      apiClient.getEnterprise(this.expedition.sender).then((response) => {
        this.isKomaks = response.data.isKomaks
      })
    },
    cancel(){
      this.loadingCancel = true
      this.$store.dispatch(this.cancelStorePath, this.expedition.id).then(() => {
        this.confirmeCancel = false
        this.loadingCancel = false
      }).catch(() => {
        this.loadingCancel = false
        this.$store.dispatch (
          'openModal',
          new ModalData(
            {
              text: this.$t('error-title'),
              css: 'is-danger'
            },
            [{
              text: this.$t('expedition-update-error'),
            }],
            {
              cancel: {
                active: true,
                css: 'is-danger',
                onClick: () => {
                    this.$store.dispatch('closeModal')
                }
              }
            }
          )
        )
      })
    },
  }
}
