import Stock from '@/apps/stock/pages/Stock'
import ArticleDetail from '@/apps/stock/pages/ArticleDetail'
import ArticleQualityHistory from '@/apps/stock/pages/ArticleQualityHistory'
const basePath = '/stock-module/'
const routes = [
  {
    path: basePath,
    name: 'stock-module',
    component: Stock,
  },
  {
    path: basePath + 'articles/:id',
    component: ArticleDetail,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
  {
    path: basePath + 'articles/:id/history',
    component: ArticleQualityHistory,
    props(route) {
      const props = { ...route.params }
      props.id = +props.id
      return props
    },
  },
];

export default routes;
