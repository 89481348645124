<template>
  <div>
    <!-- Purchaser Step -->
    <ExpeditionStepFormTemplate
      v-if="currentSteps.value == steps.indexOf('purchaser')"
      :isFirstStep="true"
      @step-finish="finishStep('purchaser')"
      @step-cancel="$emit('cancel')">
      <template v-slot:content>
        <PurchaserField
          :value="value"
          :choiceList="enterpriseList"
          :loading="enterpriseListLoading"
          :editable="!isRemovalDatePassed"
          :errorsData="errorsData"
          @input="updateExpedition($event)"
          >
        </PurchaserField>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Sender Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('sender')" @step-finish="finishStep('sender')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <SenderField
          :value="value"
          :choiceList="entityList"
          :loading="entityListLoading"
          :editable="!isRemovalDatePassed"
          :errorsData="errorsData"
          @input="updateExpedition($event)"
        >
        </SenderField>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Transporter Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('transporter')" @step-finish="finishStep('transporter')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <TransporterFields
          :value="value"
          :transporterList="transporterList"
          :editable="!isRemovalDatePassed"
          :errorsData="errorsData"
          @input="updateExpedition($event)">
        </TransporterFields>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Recipient Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('recipient')" @step-finish="finishStep('recipient')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
    @step-finish="finishStep('step-finish')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <RecipientField
          :value="value"
          :entityList="entityList"
          :editable="!isEditionClosed"
          :errorsData="errorsData"
          @input="updateExpedition($event)">
        </RecipientField>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Content Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('content')" @step-finish="finishStep('content')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
        <template v-slot:content>
          <slot name="content-step">
            <div v-if="isRemovalDatePassed" class="box">
              <div class="notification is-warning">
                {{$t('content-after-removal-date')}}
              </div>
              <ExpeditionContent
                :editable="false"
                :value="value.content"
                :actionValue="false"
                :errorsData="errorsData">
              </ExpeditionContent>
            </div>
            <div v-else>
              <div v-if="value.isSenderKomaks()" class="box container is-shadowless has-background-white-ter">
                <p
                  v-if="errorsData && errorsData.content"
                  class="help is-danger">
                  {{errorsData.content[0]}}
                </p>
                <ContentField
                  :contentList="contentAvailable"
                  :value="value.content"
                  @input="update('content', $event)">
                </ContentField>
                <!-- actionValue to true if we add article, false otherwise -->
                <div class="box">
                  <ExpeditionContent
                    :value="value.content"
                    :actionValue="false"
                    @input="update('content', $event)">
                  </ExpeditionContent>
                </div>
              </div>
              <div v-else>
                <p
                  v-if="errorsData && errorsData.content"
                  class="help is-danger">
                  {{errorsData.content[0]}}
                </p>
                <CatalogueContent :value="value.content" @input="update('content', $event)" :contentAvailable="contentAvailable"></CatalogueContent>
              </div>
            </div>
          </slot>
        </template>
    </ExpeditionStepFormTemplate>

    <!-- Insurance Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('insurance')" @step-finish="finishStep('insurance')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box container">
              <div class="field">
                <label class="label">{{$t("insurance-content-label")}}</label>
                <div class="buttons control">
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="value.insurance" @change="update('insurance', true)">
                     {{$t("yes")}}
                  </label>
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="!value.insurance" @change="update('insurance', false)">
                     {{$t("no")}}
                  </label>
                </div>
              </div>
              <div class="field">
                <label class="label">{{$t("amount-to-ensure-label")}}</label>
                <p class="control has-icons-right">
                  <input
                    type="text"
                    class="input"
                    :value="value.amountToEnsure"
                    :disabled="!value.insurance"
                    @change="update('amountToEnsure', $event.target.value)" />
                  <span class="icon is-right">
                    <i class="fas fa-euro-sign"></i>
                  </span>
                </p>
                <p
                  v-if="errorsData && errorsData.amountToEnsure"
                  class="help is-danger">
                  {{errorsData.amountToEnsure[0]}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Reprise Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('reprise')" @step-finish="finishStep('reprise')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="box container">
              <div class="field">
                <label class="label">{{$t("simultaneous-pick-up-and-return-label")}}</label>
                <div class="buttons control">
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="value.expeditionReturn" @change="update('expeditionReturn', true)">
                     {{$t("yes")}}
                  </label>
                  <label class="radio button is-rounded">
                    <input type="radio" name="answer" :checked="!value.expeditionReturn" @change="update('expeditionReturn', false)">
                     {{$t("no")}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>

    <!-- Resume Step -->
    <ExpeditionStepFormTemplate v-if="currentSteps.value == steps.indexOf('resume')" @step-finish="finishStep('resume')" @step-cancel="$emit('cancel')" @previous-step="$emit('previous-step')">
      <template v-slot:content>
        <ResumeExpedition
          :expedition="value"
          @change-step="(step) => $emit('change-step', step)">
        </ResumeExpedition>
      </template>
      <template v-slot:buttons>
        <div class="buttons is-centered">
          <div class="button is-warning" @click="$emit('previous-step')">
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
            <span>{{$t("previous-button")}}</span>
          </div>
          <button class="button is-primary" :disabled="loading" :class="{'is-loading': loading}">
            <span>{{$t("validate-button")}}</span>
          </button>
        </div>
        <div class="buttons is-centered">
          <div class="button is-danger" @click="$emit('cancel')">
            {{$t('cancel-button')}}
          </div>
        </div>
      </template>
    </ExpeditionStepFormTemplate>
  </div>
</template>
<script>
import ExpeditionStepFormTemplate from '@/components/templates/ExpeditionStepFormTemplate'
import PurchaserField from '@/components/fields/PurchaserField'
import SenderField from '@/components/fields/SenderField'
import TransporterFields from '@/components/fields/TransporterFields'
import RecipientField from '@/components/fields/RecipientField'
import ContentField from '@/components/fields/ContentField'
import CatalogueContent from '@/components/fields/CatalogueContentField'
import ExpeditionContent from '@/components/fields/ExpeditionContent'

import ResumeExpedition from '@/apps/expedition/components/ResumeExpedition'
import StepsFormMixin from '@/mixins/StepsFormMixin'
import vModelMixin from '@/mixins/vModelMixin'
export default {
  name: 'ExpeditionStepsForm',
  mixins: [StepsFormMixin, vModelMixin],
  components: {
    ExpeditionStepFormTemplate,
    PurchaserField,
    SenderField,
    TransporterFields,
    RecipientField,
    ContentField,
    ExpeditionContent,
    CatalogueContent,
    ResumeExpedition,
  },
  props: {
    startStep: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => [
        'purchaser', 'sender', 'transporter', 'recipient', 'content', 'insurance', 'reprise',
        'resume'
      ]
    },
    transporterList: {
      type: Array,
      default: () => []
    },
    contentAvailable: {
      type: Array,
      default: () => []
    },
    enterpriseList: {
      type: Array,
      default: () => []
    },
    enterpriseListLoading: {
      type: Boolean,
      default: false,
    },
    entityList: {
      type: Array,
      default: () => []
    },
    entityListLoading: {
      type: Boolean,
      default: false
    },
    errorsData: {
      type: Object
    }
  },

  methods: {
    finishStep(step) {
      // If this is the last step: emit "steps-finish" else : "step-finish"
      this.steps.indexOf(step) === this.steps.length -1 ? this.$emit('steps-finish'): this.$emit('step-finish')
    },
    updateExpedition(value) {
      this.$emit('input', value)
    },
  }
}
</script>
