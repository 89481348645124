<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-if="isTransporter && !isRemovalDatePassed && !isEditionClosed && expedition.kind === 'NORMAL'" v-slot:details-update>
      <button v-if="!isUpdating" class="router-button button is-small is-text" @click="startUpdate()">
        <span class="icon">
          <i class="fas fa-edit"></i>
        </span>
        <span>{{$t("update-button")}}</span>
      </button>
    </template>
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <template v-if="!isUpdating">
          <div class="column is-one-third has-text-right">
            <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{ expedition.recipientEntity.address.name }}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("address-title")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{expedition.recipientEntity.address.line}}<br />
            {{expedition.recipientEntity.address.lineAdditionalInfo}}
            <br />{{expedition.recipientEntity.address.zipCode}} - {{expedition.recipientEntity.address.city}} ({{expedition.recipientEntity.address.getCountryName()}})
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("date-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds has-text-danger">
            {{ expedition.deliveryDate }}
          </div>

          <template v-if="expedition.recipientEntity.haveAmSchedule || expedition.recipientEntity.havePmSchedule">
            <div class="column is-one-third has-text-right">
              <strong>{{$t("time-slot-label")}}{{$t("colon")}}</strong>
            </div>
            <div class="column is-two-thirds">
              <strong v-if="expedition.recipientEntity.haveAmSchedule">{{expedition.recipientEntity.amOpenStart}} - {{expedition.recipientEntity.amOpenEnd}}</strong>
              <strong v-if="expedition.recipientEntity.haveAmSchedule && expedition.recipientEntity.havePmSchedule">/</strong>
              <strong v-if="expedition.recipientEntity.havePmSchedule">{{expedition.recipientEntity.pmOpenStart}} - {{expedition.recipientEntity.pmOpenEnd}}</strong>
            </div>
          </template>

          <div class="column is-one-third has-text-right">
            <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <ul>
              <li v-for="(contact, index) in expedition.recipientEntity.contacts" v-bind:key="index">
                {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
              </li>
            </ul>
          </div>

          <div class="column is-one-third has-text-right">
            <strong>{{$t("floors-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{expedition.recipientEntity.floorNumber}}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("stairs-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            {{expedition.recipientEntity.stairNumber}}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("staircase-type-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds">
            <span v-if="staircaseTypes !== null">
              {{ staircaseTypes[expedition.recipientEntity.staircaseType] }}
            </span>
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("elevator-label")}}{{$t("colon")}}</strong>
          </div>
          <div v-if="expedition.recipientEntity.elevator" class="column is-two-thirds">
            {{$t('yes')}}
          </div>
          <div v-else class="column is-two-thirds">
            {{$t('no')}}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("other-constraints-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds line-returns">
            {{expedition.recipientEntity.otherInformations}}
          </div>
          <div class="column is-one-third has-text-right">
            <strong>{{$t("observations-label")}}{{$t("colon")}}</strong>
          </div>
          <div class="column is-two-thirds line-returns">
            {{expedition.observations}}
          </div>
        </template>
        <div v-else class="column is-full">
          <form @submit.prevent="validateUpdate">
            <RecipientField
              v-model="expeditionCopy"
              :entityList="[]"
              :editable="!isRemovalDatePassed && !isEditionClosed"
              :updateAsTransporter="true"
              :errorsData="errorsData">
            </RecipientField>
            <div class="buttons is-centered">
              <button class="button" type="button" @click="cancelUpdate()">{{$t("cancel-button")}}</button>
              <button class="button is-primary" @click="validateUpdate()">{{$t("validate-button")}}</button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
import RecipientField from '@/components/fields/RecipientField'
import {deepCopy} from '@/utils/helpers'
import { isPassedDate, addDays, dateToString } from '@/utils/helpers'
import {ModalData} from '@/models/modal'
export default {
  name: 'detailsRecipient',
  mixins: [detailsStepMixin],
  components: {RecipientField},
  data () {
    return {
      isUpdating: false,
      expeditionCopy: null,
      errorsData: null
    }
  },
  computed: {
    isTransporter () {
      const user  = this.$store.state.user
      if(user !== null) {
        return this.$store.state.user.isTransporter
      }
      return false;
    },
    staircaseTypes(){
      return this.$store.getters['commonData/staircaseTypes']
    },
    isEditionClosed() {
      return isPassedDate(dateToString(addDays(this.expedition.deliveryDate, 2))) || this.expedition.isCanceled()
    }
  },
  methods: {
    startUpdate(){
      this.expeditionCopy = deepCopy(this.expedition);
      this.isUpdating = true;
    },
    cancelUpdate(){
      this.isUpdating = false;
    },
    validateUpdate(){
      const expeditionData = {
        'recipientEntity': this.expeditionCopy.recipientEntity,
        'observations': this.expeditionCopy.observations
      }
      this.expedition.updateTransporterInfo(expeditionData).then(() => {
        this.expedition.recipientEntity = deepCopy(this.expeditionCopy.recipientEntity)
        this.expedition.observations = this.expeditionCopy.observations
        this.isUpdating = false
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errorsData = e.response.data
            window.scrollTo(0, 0)
          }else{
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-update-error').concat(this.$t('colon'), ' ' ,this.$t('server-error'))],
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }
      })
    }
  }
}
</script>
