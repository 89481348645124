<template>
  <ResumeTemplate :extraCss="extraCss" :step="step" :locked="locked" title="removal-title" :returnToStep="returnToStep">
     <template v-slot:resume-content>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("name-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-primary has-text-weight-bold">
          {{ expedition.senderEntity.address.name }}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("address-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.senderEntity.address.line}}<br />{{expedition.senderEntity.address.lineAdditionalInfo}}
          <br />{{expedition.senderEntity.address.zipCode}} - {{expedition.senderEntity.address.city}} ({{expedition.senderEntity.address.getCountryName()}})
        </div>
      </div>
      <div v-if="expedition.removalDate" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("date-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-weight-bold">
          {{ expedition.removalDate }}
        </div>
      </div>
      <div  v-if="expedition.senderEntity.haveAmSchedule || expedition.senderEntity.havePmSchedule" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("time-slot-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-weight-bold">
          <strong v-if="expedition.senderEntity.haveAmSchedule">{{expedition.senderEntity.amOpenStart}} - {{expedition.senderEntity.amOpenEnd}}</strong>
          <strong v-if="expedition.senderEntity.haveAmSchedule && expedition.senderEntity.havePmSchedule">/</strong>
          <strong v-if="expedition.senderEntity.havePmSchedule">{{expedition.senderEntity.pmOpenStart}} - {{expedition.senderEntity.pmOpenEnd}}</strong>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("contact-title")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <ul>
            <li v-for="(contact, index) in expedition.senderEntity.contacts" v-bind:key="index">
              {{ contact.phone }} <span v-if="contact.phone && contact.cellphone">-</span> {{ contact.cellphone }} <em>({{$t(contact.civility)}} {{contact.name}})</em> {{contact.email}}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="expedition.senderEntity.floorNumber != 0" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("floors-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.senderEntity.floorNumber}}
        </div>
      </div>
      <div v-if="expedition.senderEntity.stairNumber != 0" class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("stairs-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          {{expedition.senderEntity.stairNumber}}
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("staircase-type-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="staircaseTypes !== null">
            {{ staircaseTypes[expedition.senderEntity.staircaseType] }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("elevator-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds">
          <span v-if="expedition.senderEntity.elevator">
            {{$t('yes')}}
          </span>
          <span v-else>
            {{$t('no')}}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("other-constraints-label")}}{{$t("colon")}}</strong>
        </div>
        <div class="column is-two-thirds has-text-danger line-returns">
          {{expedition.senderEntity.otherInformations}}
        </div>
      </div>
    </template>
  </ResumeTemplate>
</template>
<script>
import resumeStepsMixin from '@/mixins/resumeStepsMixin'
export default {
  name: 'ResumeRemoval',
  mixins: [resumeStepsMixin],
  computed: {
    staircaseTypes(){
      return this.$store.getters['commonData/staircaseTypes']
    }
  }

}
</script>
