import store from '@/store/index';

const api = {
  authenticate(username, password) {
    return this.apiClient()
      .post('/user/auth/obtain-token/', {
        username,
        password,
      })
      .then((resp) => {
        store.commit('updateUser', resp.data);
      })
  },
  refreshUserData(id) {
    // This function will fail if there is not token
    return this.apiClient()
      .get(`/user/users-logistics/${id}/`)
      .then((resp) => {
        store.commit('updateUser', resp.data);
      })
  },
  logout() {
    store.dispatch('logout')
  },
};

export default api;
