import apiClient from '@/client/client_logistics'
import { ModalData } from '@/models/modal'

export default {
    methods: {
      createTransporter(transporter) {
        return apiClient.createTransporter(transporter)
      },
      throwTransporterError() {
        this.$store.dispatch (
          'openModal',
          new ModalData(
            {
              text: this.$t('error-title'),
              css: 'is-danger'
            },
            [{
              text: [this.$t('transporter-create-error')],
            }],
            {
              cancel: {
                active: true,
                css: '',
                onClick: () => {
                  this.$store.dispatch('closeModal');
                }
              }
            }
          )
        )
      }
    }
}
