<template>
  <ListingTemplate
  v-if="accessGranted"
    :activeKey="filterValue"
    :list="list"
    :loading="loading"
    :title="'pickup-tracking-title'"
    :currentChoice="currentChoice"
    @set-filter="(val) => setFilter(val)"
    @select="(expedition) => setCurrentChoice(expedition)"
    @unselect="unselect"
    @listing-search="(newSearchValue) => search = newSearchValue"
  >
    <template v-slot:button="slotProps">
      <PickUpAndReturnButton :pickUpAndReturn="slotProps['expedition']"></PickUpAndReturnButton>
    </template>
    <template v-slot:details>
      <PickUpAndReturnDetails
        :expedition="currentChoice"
        :key="currentChoice.id">
      </PickUpAndReturnDetails>
    </template>
  </ListingTemplate>
</template>

<script>
import PickUpAndReturnDetails from '@/apps/pick_up_and_return/pick_up_and_return/details';
import PickUpAndReturnButton from '@/apps/pick_up_and_return/components/PickUpAndReturnButton';
import ListingTemplate from '@/components/templates/ListingTemplate'
import { ModalData } from '@/models/modal'
import expeditionListMixin from '@/mixins/expeditionListMixin'
import deniedAccessMixin from '@/mixins/deniedAccessMixin'

export default {
  name: 'PickUpAndReturnList',
  mixins: [expeditionListMixin, deniedAccessMixin],
  components: {
    PickUpAndReturnButton, ListingTemplate, PickUpAndReturnDetails
  },
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      collectionStorePath: "pickUpAndReturnModule/pickUpAndReturns",
    }
  },
  beforeMount() {
    this.$store.dispatch('commonData/getStorageTypes')
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.havePickUpAccess){
          return true
        }else{
          this.accessDeniedError()
          return false
        }
      }
    },
  },
  mounted () {
    if(this.user !== null && this.accessGranted) this.get()
  },
  methods: {
    setCurrentChoice(choice){
      if(choice.id !== this.id){
        this.$router.push({ path: `/pick-up-and-return-module/list/${choice.id}`})
      }
    },
    async get(){
      this.$store.dispatch('pickUpAndReturnModule/getPickUpAndReturns')
      .catch(() => {
        this.error = true;
        this.loading = false;
        this.$store.dispatch (
          'openModal',
          new ModalData(
            {
              text: this.$t('error-title'),
              css: 'is-danger'
            },
            [{
              text: this.$t('pick-up-and-returns-retrieve-error'),
            }],
            {
              refresh: {
                active: true,
                css: 'is-danger',
                icon: 'fa-redo',
                onClick: () => {
                  this.$router.go()
                  this.$store.dispatch('closeModal');
                }
              },
              home: {
                active: true,
                css: 'is-info',
                icon: 'fa-home',
                onClick: () => {
                  this.$router.push('/')
                  this.$store.dispatch('closeModal');
                }
              },
            }
          )
        )
      });
    }
  },
}
</script>
