<template>
  <div class="box container is-shadowless has-background-white-ter">
    <div class="has-text-centered">
      <button
        v-if="!stockOpen && contentList.length>0"
        type="button"
        class="button is-link"
        @click="toggleStock">
        {{$t("open-array-button")}}
      </button>
    </div>
    <modal v-show="stockOpen" @close="stockOpen = false" :extraCss="['big']">
      <template slot="header">
        <p class="modal-card-title">{{$t("content-title")}}</p>
        <button
          type="button"
          class="button is-pulled-right"
          aria-label="close"
          @click="stockOpen = false">
          <i class="fas fa-times"></i>
        </button>
      </template>
      <!-- actionValue to true if we add article, false otherwise -->
      <div slot="body">

        <transition name="slide-fade">
          <div v-if="notif" class="is-success notification article-notif">
            {{$t("successfully-add-article")}}
          </div>
        </transition>

        <div class="field">
          <p class="control has-icons-left">
            <input :placeholder="$t('research-label')" class="input" type="search" v-model="searchValue" />
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filteredContent"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 100,
            dropdownAllowAll: false,
          }">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <button :disabled="notif" type="button" class="button is-success" @click="addArticle(props)">
                <i class="fas fa-plus-circle"></i>
              </button>
            </span>
            <span v-else-if="props.column.field == 'status'">
              {{$t(props.formattedRow[props.column.field])}}
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import vModelMixin from '@/mixins/vModelMixin'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'ContentField',
  mixins: [vModelMixin],
  components: {Modal, VueGoodTable},
  props: {
    // All content available
    contentList: {
      type: Array
    }
  },
  data: function(){
    return {
      searchValue: '',
      stockOpen: false,
      notif: false,
      columns: [
        {label: this.$t("qr-code-label"), field: "qrcode"},
        {label: this.$t("article-state-label"), field: "state"},
        {label: this.$t("article-denomination-label"), field: "denomination"},
        {label: this.$t("description-label"), field: "type"},
        {label: this.$t("serial-number-label"), field: "serialNumber"},
        {label: this.$t("note-label"), field: "note"},
        {label: this.$t("owner-label"), field: "owner"},
        {label: this.$t("status-label"), field: "status"},
        {label: this.$t("purchase-reference-label"), field: "purchaseReference"},
        {label: "", field: "action", html: true, sortable: false}
      ]
    }
  },
  computed: {
    filteredContent() {
      if (this.searchValue === "") return this.contentList.filter(article =>
        this.value.findIndex(alreadyAddedArticle => alreadyAddedArticle.qrcode == article.qrcode) === -1
      )
      let filters = this.searchValue.split(" ")
      const filteredContent = [];
      this.contentList.forEach((element) => {
        let filterInSEARCH = this.value.findIndex(alreadyAddedArticle => alreadyAddedArticle.qrcode == element.qrcode) === -1
        if (!filterInSEARCH) return false
        // property is equivalent to stock headers
        filters.forEach(filter => {
          if(!element["SEARCH"].toLowerCase().includes(filter.toLowerCase())){
            filterInSEARCH = false
          }
        })
        if (filterInSEARCH) {
          filteredContent.push(element)
        }
      })
      return filteredContent
    }
  },
  methods: {
    openNotification(){
      this.notif = true
      setTimeout(() => {this.notif = false}, 800)
    },
    toggleStock(){
      this.stockOpen= !this.stockOpen
    },
    addArticle(lineProps) {
      const newContent = this.deepCopy(this.value)
      newContent.push(this.filteredContent[lineProps.index])
      this.openNotification()
      this.$emit('input', newContent)
    }
  }
}
</script>
<style scoped>
  .article-notif{
    position: sticky;
    top: 5%;
    z-index: 1000;
    width: 100%;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
  }
  .slide-fade-enter{
    opacity: 1;
  }
  .slide-fade-leave{
    opacity: 1;
  }
  .slide-fade-leave-active{
    transition: opacity 0.3s;
    opacity: 0;
  }
</style>>
