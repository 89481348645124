import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import JsonExcel from 'vue-json-excel'


Vue.config.productionTip = false;
Vue.component('downloadExcel', JsonExcel)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.directive('click-outside', {
  bind: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value(event)
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});
