<template>
  <div>
    <div class="expedition-button columns is-mobile is-multiline vcentered">
      <div class="column is-two-third">
        <h3 class="is-size-6 has-text-primary has-text-weight-bold">{{ expedition.reference }} <span class="purchaser">({{ enterpriseName }})</span></h3>
      </div>
      <div class="column is-one-third has-text-right">
        <p class="has-text-grey-light is-size-7">{{ expedition.deliveryDate }}</p>
      </div>
      <div class="column is-full">
        <div class="media">
          <div class="media-left">
            <i class="fas fa-user-circle"></i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{expedition.recipientEntity.address.name}}</p>
            <p>{{expedition.recipientEntity.address.zipCode}} - {{expedition.recipientEntity.address.city }}</p>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <div class="media">
          <div class="media-left">
            <i class="fas"
              :class="{
                'fa-truck': !expedition.isCanceled(),
                'fa-times': expedition.isCanceled(),
                'has-text-danger': !expedition.validation,
                'has-text-blue': expedition.validation && !expedition.isDelivered(),
                'has-text-green': expedition.validation && expedition.isDelivered()
              }"></i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{expedition.transporter}}</p>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="media">
          <div class="media-left">
            <i class="fas fa-box-open"></i>
          </div>
          <div class="media-content has-text-grey">
            <p>{{ expedition.articleQuantity }} {{$t("articles-label")}} </p>
          </div>
        </div>
      </div>
      <div class="column is-two-thirds">
        <div class="media">
          <div class="media-left">
            <i class="fas fa-exchange-alt"></i>
          </div>
          <div class="media-content has-text-grey">
            <span v-if="expedition.pickUp">
              {{$t("planned-pick-up-and-return")}}
            </span>
            <span v-else>
              {{$t("no-planned-pick-up-and-return")}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name: 'ExpeditionButton',
  props: {
    expedition: {
      type: Object
    }
  },
  computed: {
    enterpriseName () {
      return this.expedition.purchaserEntity.address.name
    },
  }
}
</script>
<style lang="scss" scoped>
  .has-text-blue {
    color: #8cd2d2;
  }
  .expedition-button{
    font-size: .85rem;
  }
  .media + .media {
    border-top: transparent;
    margin-top: 0;
    padding-top: 0;
  }
  .media-left{
    margin-right: .5em;
  }
  .expedition-item{
    .column{
      padding: .25em .5em;
    }
  }
  .purchaser{
    color: grey;
    font-size: 0.7em;
  }
</style>
