const axios = require('axios')
// import store from '@/store'
import humps from 'humps'

// Axios adaptation for django rest framework
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

let baseURL = null
if (process.env.NODE_ENV == 'development'){
  baseURL = 'http://komaks.logistics:8000'
} else if (process.env.VUE_APP_ENV == 'preproduction'){
  baseURL = 'https://logistics.hashbang.fr'
} else if (process.env.VUE_APP_ENV == 'production'){
  baseURL = 'https://logistics.komaks.fr'
}
let baseURLAPI = baseURL + '/api/'

let api = {
  baseURL,
  apiClient(options) {
    let base = {
      baseURL: baseURLAPI,
      headers: {
      },
      data: {},
      // Change to camel case
      transformResponse: [
        ...axios.defaults.transformResponse,
        data => humps.camelizeKeys(
          data,
          {
            process: function (key, convert, options) {
              return /^[A-Z_]+$/.test(key) ? key : convert(key, options);
            }
          }
        )
      ],
      // Change to snake case
      transformRequest: [
        data => {if(data instanceof FormData){ return data} else { return humps.decamelizeKeys(data)}},
        ...axios.defaults.transformRequest
      ]
    };
    const token = localStorage.getItem('token') // TODO add getter in store
    if (token !== undefined && token !== null) {
      base.headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      };
    }
    if (typeof options === 'object') {
      base = { ...base, ...options }
    }
    const axiosInstance = axios.create(base)
    axiosInstance.interceptors.response.use(response => {
        if(response) return response
      },
      error => {
        if(error.response && error.response.status === 401 && error.response.data &&
          error.response.data['detail'] === "Out of date token.") {
          // Return to login screen
          this.logout() // Not available at the declaration but available after
        }
        return Promise.reject(error)
      }
    )
    return axiosInstance
  },
};

const login = require('@/api/login')
Object.assign(api, login.default)

const chatApi = require('@/api/chat.js')
Object.assign(api, chatApi.default)

const enterpriseEntityApi = require('@/api/enterpriseEntity.js')
Object.assign(api, enterpriseEntityApi.default)

const documentApi = require('@/api/document.js')
Object.assign(api, documentApi.default)

const expeditionApi = require('@/apps/expedition/api/api')
Object.assign(api, expeditionApi.default)

const pickUpAndReturnApi = require('@/apps/pick_up_and_return/api/api')
Object.assign(api, pickUpAndReturnApi.default)

const loanApi = require('@/apps/demo/api/api')
Object.assign(api, loanApi.default)

const transferApi = require('@/apps/transfer/api/api')
Object.assign(api, transferApi.default)

const stockApi = require('@/apps/stock/api/api')
Object.assign(api, stockApi.default)

export default api;
