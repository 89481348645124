<template>
  <div>
    <progress v-if="loadingInitialData" class="progress is-small is-primary" max="100">15%</progress>
    <TransferForm
      v-if="!loadingInitialData"
      ref="form"
      v-model="demo"
      :originalLoan="originalLoan"
      :errorsData="errorsData"
      :transporters="transporterList"
      @validate-transfer="createTransfer"
      @cancel="cancel"
      :loading="loading"></TransferForm>
  </div>
</template>
<script>
import apiClient from '@/client/client_logistics'
import TransferForm from '@/apps/transfer/components/transferForm'
import { Expedition, Entity } from '@/models/expedition'
import { ModalData } from '@/models/modal'
import { handlePromise } from '@/utils/helpers'
import confirmationCancelMixin from '@/mixins/confirmationCancelMixin.js'
import transporterCreationMixin from '@/mixins/transporterCreationMixin.js'
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'

import isEqual from 'lodash/isEqual'

export default {
  name: 'TransferCreate',
  components: {
    TransferForm
  },
  props: {
    idDemo: {
      type: Number,
      required: true
    }
  },
  mixins: [
    confirmationCancelMixin,
    deniedAccessMixin,
    transporterCreationMixin,
  ],
  data: function(){
    return {
      demo: {
        expedition: new Expedition(
        ),
        reprise: new Expedition()
      },
      loadingInitialData: true,
      loading: false,
      isExpeditionCreated: false,
      isPickUpCreated: false,
      originalLoan: new Expedition(),
      finalRoutePath: '/demo-module/validate/:id/:idPickUpAndReturn',
      errors: {
        errorsExpedition: null,
        errorsPickUp: null
      },
      dataExpeditionSended: {},
      dataPickUpSended: {},
      transporterList: [],
    }
  },

  created() {
    this.getTransporterList()
    this.demo.expedition.kind = 'LOAN'
    this.demo.expedition.transferedFrom = this.idDemo
    this.demo.reprise.kind = 'PICK_UP_AND_RETURN'

    // Data are not modified in front end
    // There are just used in resume step to display some datas fron the original demo
    apiClient.getLoan(this.idDemo).then(result => {
      const originalLoan = new Expedition(result.data)
      this.originalLoan = originalLoan;
      this.demo.expedition.purchaser = originalLoan.purchaser
      this.demo.expedition.purchaserEntity = originalLoan.purchaserEntity
      this.demo.expedition.sender = originalLoan.pickUp.sender
      this.demo.expedition.senderEntity = originalLoan.pickUp.senderEntity
      this.demo.expedition.content = originalLoan.content

      this.demo.reprise.purchaser = originalLoan.purchaser
      this.demo.reprise.purchaserEntity = originalLoan.purchaserEntity
      this.demo.reprise.recipient = originalLoan.pickUp.recipient
      this.demo.reprise.recipientEntity = originalLoan.pickUp.recipientEntity
      this.loadingInitialData = false;
    }).catch((e) => {
      let errorsMsg =  []
      Object.keys(e.response.data).forEach(key => {
        if (Array.isArray(e.response.data[key])) {
          e.response.data[key].forEach(error => {
            errorsMsg.push(error)
          })
        }
      })
      this.loading = false
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('error-title'),
            css: 'is-danger'
          },
          [{
              text: [this.$t('loading-error')].concat(errorsMsg),
          }],
          {
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.forceChangeRoute = true;
                this.$router.push('/demo-module/')
                this.$store.dispatch('closeModal');
              }
            }
          }
        )
      )
    })
  },
  computed: {
    errorsData() {
      const errorsData = {}
      if (this.errors.errorsExpedition === null) {
        errorsData['errorsExpedition'] = null
      } else {
        errorsData['errorsExpedition'] = {}
        Object.keys(this.errors.errorsExpedition).forEach(key => {
          // Special case for Entity
          if (this.demo.expedition[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataExpeditionSended[key]), new Entity(this.demo.expedition[key]))) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataExpeditionSended[key], this.demo.expedition[key])) {
              errorsData['errorsExpedition'][key] = this.errors.errorsExpedition[key]
            }
          }
        })
      }

      if (this.errors.errorsPickUp === null) {
        errorsData['errorsPickUp'] = null
      } else {
        errorsData['errorsPickUp'] = {}
        Object.keys(this.errors.errorsPickUp).forEach(key => {
          // Special case for Entity
          if (this.demo.reprise[key] instanceof Entity) {
            // Recreate Entity with data to avoid extra data added by vue
            // then compare the two "new" entities to have a correct comparaison
            if (isEqual(new Entity(this.dataPickUpSended[key]), new Entity(this.demo.reprise[key]))) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          } else {
            // Change each time expedition error field is changed
            if (isEqual(this.dataPickUpSended[key], this.demo.reprise[key])) {
              errorsData['errorsPickUp'][key] = this.errors.errorsPickUp[key]
            }
          }
        })
      }
      return errorsData
    },
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(!user.isTransporter && user.haveStockAccess && user.haveDemoAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    },
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    getTransporterList(){
      apiClient.getListTransporters().then((response) => {
        this.transporterList = [...new Set(response.data.results)]
      }).catch(() => {
        this.trowErrorLoading()
      })
    },
    cancel() {
      this.$store.dispatch (
        'openModal',
        new ModalData(
          {
            text: this.$t('warning-title'),
            css: 'is-warning'
          },
          [{
              text: this.$t('cancel-warning-sentence'),
          }],
          {
            validate: {
              active: true,
              css: 'is-warning',
              onClick: () => {
                this.forceChangeRoute = true
                this.$router.push('/demo-module/')
                this.$store.dispatch('closeModal')
              }
            },
            cancel: {
              active: true,
              css: '',
              onClick: () => {
                this.$store.dispatch('closeModal')
              }
            },
          }
        )
      )
    },
    async createTransfer() {
      this.loading = true
      if(this.demo.expedition.transporterId === null) {
        let transporter = {
          businessName: this.demo.expedition.transporter,
          contactsList: this.demo.expedition.transporterContacts
        }
        const [transporterResp, transporterError] = await handlePromise(
          this.createTransporter(transporter)
        )
        if (transporterError) { // createTransporter have an issue
          this.loading = false
          this.throwTransporterError()
          return // Stop here, block the rest of the creation
        } else {
          this.demo.expedition.transporterId = transporterResp.id
        }
      }

      if(!this.isExpeditionCreated){
        this.createExpedition()
      }else if(!this.isPickUpCreated){
        this.updateExpedition()
      }
    },
    async updateExpedition() {
      this.dataExpeditionSended = this.demo.expedition.getFormData(this.user.isAdministrator)
      this.errors.errorsExpedition = null
      return apiClient.partialUpdateTransfer(
        this.dataExpeditionSended, this.demo.expedition.id
      ).then(() => {
        this.createPickUp()
      }).catch((e) => {
        this.loading = false
        if(typeof e.response.data !== 'string'){
          this.errors.errorsExpedition= e.response.data
          window.scrollTo(0, 0)
        } else {
          let errorsMsg =  []
          Object.keys(e.response.data).forEach(key => {
            if (Array.isArray(e.response.data[key])) {
              e.response.data[key].forEach(error => {
                errorsMsg.push(error)
              })
            }
          })
          this.$store.dispatch (
            'openModal',
            new ModalData(
              {
                text: this.$t('error-title'),
                css: 'is-danger'
              },
              [{
                  text: [this.$t('expedition-update-error')].concat(errorsMsg),
              }],
              {
                cancel: {
                  active: true,
                  css: '',
                  onClick: () => {
                    this.$store.dispatch('closeModal');
                  }
                }
              }
            )
          )
        }});
    },
    async createExpedition() {
      this.dataExpeditionSended = this.demo.expedition.getFormData(this.user.isAdministrator)
      this.errors.errorsExpedition = null
      return apiClient
        .createTransfer(this.dataExpeditionSended)
        .then((response) => {
          this.demo.expedition.id = response.data.id
          this.demo.reprise.expedition = this.demo.expedition.id
          this.isExpeditionCreated = true
          this.createPickUp()
          return response
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsExpedition= e.response.data
            window.scrollTo(0, 0)
          } else {
            let errorsMsg = []
            Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  errorsMsg.push(error)
                })
              }
            })
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                    text: [this.$t('expedition-create-error')].concat(errorsMsg),
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
        }});
    },
    async createPickUp() {
      this.dataPickUpSended = this.demo.reprise.getFormData()
      this.errors.errorsPickUp = null
      return apiClient
        .createPickUpAndReturn(this.dataPickUpSended)
        .then((response) => {
          this.demo.reprise.pdfUrl = response.data.pdfUrl
          this.demo.reprise.reference = response.data.reference
          this.demo.reprise.id = response.data.id
          this.$router.push(`/demo-module/validate/${this.demo.reprise.expedition}/${this.demo.reprise.id}`)
          this.loading = false;
        }).catch((e) => {
          this.loading = false
          if(typeof e.response.data !== 'string'){
            this.errors.errorsPickUp = e.response.data
            window.scrollTo(0, 0)
          }else{
            let errorsMsg =  []
            Object.keys(e.response.data).forEach(key => {
              if (Array.isArray(e.response.data[key])) {
                e.response.data[key].forEach(error => {
                  errorsMsg.push(error)
                })
              }
            })
            this.loading = false
            this.$store.dispatch (
              'openModal',
              new ModalData(
                {
                  text: this.$t('error-title'),
                  css: 'is-danger'
                },
                [{
                  text: [this.$t('pick-up-and-return-demo-create-error')].concat(errorsMsg),
                }],
                {
                  cancel: {
                    active: true,
                    css: '',
                    onClick: () => {
                      this.$store.dispatch('closeModal');
                    }
                  }
                }
              )
            )
          }});
    },
  }
}
</script>
<style>
  form > .columns:not(:last-child){
    margin-bottom: 3em;
  }
</style>
