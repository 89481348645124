<template>
  <section>
    <form @submit.prevent="$emit(stepFinishEventName)" @keydown.enter="pressEnter">
      <slot name="content">
      </slot>
      <slot name="buttons">
        <div class="buttons is-centered">
          <div v-if="!isFirstStep" class="button is-warning" @click="$emit('previous-step')">
            <span class="icon"><i class="fas fa-arrow-left"></i></span>
            <span>{{$t("previous-button")}}</span>
          </div>
          <button class="button is-primary">
            <span>{{$t("next-button")}}</span>
            <span class="icon"><i class="fas fa-arrow-right"></i></span>
          </button>
        </div>
        <div class="buttons is-centered">
          <div class="button is-danger is-pulled-left" @click="$emit(stepCancelEventName)">
            {{$t('cancel-button')}}
          </div>
        </div>
      </slot>
    </form>
  </section>
</template>
<script>
export default {
  name: "ExpeditionStepFormTemplate",
  props: {
    stepFinishEventName: {
      type: String,
      default: 'step-finish'
    },
    stepCancelEventName: {
      type: String,
      default: 'step-cancel'
    },
    isFirstStep: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    pressEnter(event) {
      if (event.target.type !== "textarea") {
        event.preventDefault()
      }
    },
  }
}
</script>
