import DetailsStepTemplate from '@/components/templates/detailsStepTemplate'
import {mapGetters} from 'vuex'

export default {
  props: {
    step: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    getUrl: {
      type: Function,
      required: true,
    },
    updateCondition: {
      type: Boolean,
      required: true,
    },
    expedition: {
      type: Object,
    }
  },
  components: {
    DetailsStepTemplate
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isRemovalDatePassed() {
      return this.expedition.isRemovalDatePassed()
    },
  }
}
