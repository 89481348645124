<template>
  <div v-if="accessGranted" class="container">
    <section v-if="!loading" class="articleDetail_title">
      <h1 class="columns articleDetail_title_h1">
      <button class="button title-button mr1em">
        <a target="blank" :href="'/stock-module/articles/' + id">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </a>
      </button>
      <div class="column is-three-fifths label-bold is-pulled-left mb10px-small-screen">
        <span class="green-komaks small"> HISTORIQUE ARTICLE - </span><span class="font-35">{{ articleQrcode }}</span>
      </div>
    </h1>
  </section>
  <section v-else class="section">
    <div class="container">
      <div class="loader-wrapper">
        <div class="loader is-loading"></div>
      </div>
    </div>
  </section>
<br>
  <div v-for="history in qualityHistory" :key="history.date">
    <div class="card resume-card">
      <div class="card-header">
        <h2 class="card-header-title">
          {{ history.date.slice(0,16) }}
        </h2>
      </div>
      <div class="card-content">
        <div v-for="change in history.changes" :key="change.key">
          {{ change.key }}: <b>{{ change.value }}</b>
        </div>
      </div>
      <div class="card-content">
        Note : <b>{{ history.note }}</b>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import deniedAccessMixin from '@/mixins/deniedAccessMixin.js'
import apiClient from '@/client/client_logistics'

export default {
  name: 'ArticleQualityHistory',
  mixins: [deniedAccessMixin],
  props: {
    id: {
      type: Number,
      required: true
    },
  },
  data (){
    return {
      loading: true,
      articleQrcode: "",
      qualityHistory: []
    }
  },
  computed: {
    accessGranted(){
      const user = this.$store.state.user
      if (user === null) {
        return false
      } else {
        if(user.haveStocksLogisticsAccess){
          return true
        }else{
          this.updateForceChangeRoute(true)
          this.accessDeniedError()
          return false
        }
      }
    }
  },
  created(){
    this.getArticleQualityHistory()
  },
  watch: {
    '$route.path': function(){
      this.getArticleQualityHistory();
    }
  },
  methods: {
    print() {
      return window.print()
    },
    getArticleQualityHistory(){
      this.loading = true
      apiClient.getArticleQualityHistory(this.id).then(
        response => {
          window.console.log(response)
          this.articleQrcode = response.data.qrcode
          this.qualityHistory = response.data.qualityHistory
          this.loading = false
        }
      ).catch(() => {
        this.loading = false
        this.article = null
      })
    }
  }
}

</script>

<style>
</style>
