import { cloneDeep } from 'lodash'
import apiClient from '@/client/client_logistics'
import { Entity, Expedition } from '@/models/expedition'

export default {
  namespaced: true,
  state: {
    staircaseTypes: null,
    storageTypes: null,
    // All entities available for the user (used for LOAN expedition)
    allEntitiesCollection: {
      isLoading: true,
      collection: null,
    },

    // All entities available for the NORMAL expedition process
    expeditionEntitiesCollection: {
      isLoading: true,
      collection: null,
    }
  },
  getters: {
    staircaseTypes: state => {
      return state.staircaseTypes;
    },
    storageTypes: state => {
      return state.storageTypes;
    },
    expeditionEntitiesCollectionIsLoading: state => {
      return state.expeditionEntitiesCollection.isLoading
    },
    expeditionEntitiesCollection: state => {
      if (state.expeditionEntitiesCollection.isLoading) {
        return []
      }
      return state.expeditionEntitiesCollection.collection
    },
    allEntitiesCollectionIsLoading: state => {
      return state.allEntitiesCollection.isLoading
    },
    allEntitiesCollection: state => {
      if (state.allEntitiesCollection.isLoading) {
        return []
      }
      return state.allEntitiesCollection.collection
    },
  },
  mutations: {
    // Staircase
    setStaircaseTypes(state, staircaseTypes) {
      state.staircaseTypes = staircaseTypes
    },
    // Storage
    setStorageTypes(state, storageTypes) {
      state.storageTypes = storageTypes
    },

    setCollectionLoading(state, {loading, key}) {
      const collectionContainer = cloneDeep(state[key])
      collectionContainer.isLoading = loading
      state[key] = collectionContainer
    },

    setCollection(state, {data, key}) {
      const collectionContainer = cloneDeep(state[key])
      collectionContainer.collection = data
      state[key] = collectionContainer
    },
  },
  actions: {
    async getStaircaseTypes({ commit, state }) {
      if(state.staircaseTypes === null) {
        apiClient.getStaircaseTypes().then(response => {
          commit("setStaircaseTypes", response.data)
        })
      }
    },
    async getStorageTypes({ commit, state }) {
      if(state.storageTypes === null) {
        apiClient.getStorageTypes().then(response => {
          const storageTypes = response.data
          delete storageTypes['']
          commit("setStorageTypes", storageTypes)
        })
      }
    },
    async getAllEntitiesCollection({ commit, state }) {
      if(state.allEntitiesCollection.collection === null) { // Charge only once
        commit("setCollectionLoading", {loading: true, key:"allEntitiesCollection"})
        const entityList = await apiClient.getUserEntity().then(response => {
          const entityList = response.data.map((el) => new Entity(el))
          commit("setCollection", {data: entityList, key:"allEntitiesCollection"})
          commit("setCollectionLoading", {loading: false, key:"allEntitiesCollection"})
          return response.data
        }).catch(e => window.console.log(e))
        return entityList
      }
      return state.allEntitiesCollection.collection
    },

    async getExpeditionEntitiesCollection({ commit, state }) {
      if(state.allEntitiesCollection.collection === null) { // Charge only once
        commit("setCollectionLoading", {loading: true, key:"expeditionEntitiesCollection"})
        const entityList = await apiClient.getUserEntity({kind: Expedition.NORMAL}).then(response => {
          const entityList = response.data.map((el) => new Entity(el))
          commit("setCollection", {data: entityList, key:"expeditionEntitiesCollection"})
          commit("setCollectionLoading", {loading: false, key:"expeditionEntitiesCollection"})
          return response.data
        })
        return entityList
      }
      return state.expeditionEntitiesCollection.collection
    },
  },
}
