import {transform, isEqual, isObject} from 'lodash'

function getBaseUrlWarehouse(){
  if (process.env.NODE_ENV == 'development'){
    return 'http://komaks.warehouse:8000'
  } else if (process.env.VUE_APP_ENV == 'preproduction'){
    return 'https://warehouse.hashbang.fr'
  } else if (process.env.VUE_APP_ENV == 'production'){
    return 'https://warehouse.komaks.fr'
  }
}

function dateToString(date, separator='-') {
  const month = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  const day =  date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())
  const year =  date.getFullYear()
  return `${year}${separator}${month}${separator}${day}`
}

function isPassedDate(dateStr) {
  const date = new Date(dateStr)
  date.setDate(date.getDate() + 1);
  return date < new Date()
}

function deepCopy(obj) {
  const isArray = Array.isArray(obj);
  let clone = {};
  if (isArray) {
    clone = new Array(obj.length);
  }
  for (let i in obj) {
    if (obj[i] instanceof Date) {
      clone[i] = obj[i];
    } else if (obj[i] !== null && typeof (obj[i]) === 'object') {
      clone[i] = deepCopy(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
}

function difference(object, base) {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

function checkAddress(address) {
  const errors = []
  const fields = ['name', 'line', 'zipCode', 'city', 'country']
  fields.forEach(field => {
    if (address[field] === undefined || address[field] === "") {
      errors.push(field)
    }
  })
  return errors
}

function checkContacts(contacts){
  let noError = true
  contacts.forEach(contact => {
    if(contact.name === undefined || contact.name === "" || contact.name === null){
      noError = false
    }
  })
  return noError
}


function addBusinessDays(d, n) {
    d = new Date(d);
    var day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
    return dateToString(d);
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function downloadPdf (stream, filename) {
  let data = new Blob([stream], { type: 'application/pdf' });
  data = window.URL.createObjectURL(data);
  let link = document.createElement('a');
  link.href = data;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  window.setTimeout(() => {
    window.URL.revokeObjectURL(data);
    document.body.removeChild(link);
    link.remove();
  }, 1);
}

const handlePromise = (promise) => {
  return promise
    .then(data => ([data, undefined]))
    .catch(error => Promise.resolve([undefined, error]));
}

export {
  addBusinessDays,
  addDays,
  checkAddress,
  checkContacts,
  dateToString,
  deepCopy,
  difference,
  downloadPdf,
  getBaseUrlWarehouse,
  handlePromise,
  isPassedDate,
}
