import apiClient from '@/client/client_logistics'
import { Expedition } from '@/models/expedition.js'

export default {
  namespaced: true,
  state: {
    expeditions: {
      loading: true,
      collection: []
    },
  },
  getters: {
    expeditions: state => {
      return state.expeditions
    },
  },
  mutations: {
    setExpeditions(state, expeditions) {
      state.expeditions = {
        loading: false,
        collection: expeditions
      }
    },
    // Set the loading to true, remove the current collections
    loadExpeditions(state) {
      state.expeditions = {
        loading: true,
        collection: []
      }
    },

    updateExpedition(state, expedition) {
      const expeditions = state.expeditions
      const index = expeditions.collection.findIndex((exp) => exp.id === expedition.id)
      if (index !== -1) {
        expeditions.collection[index] = expedition
      }
      state.expeditions = Object.assign({}, expeditions) // Copy to force update !
    },
    removeExpedition(state, expeditionId) {
      const expeditions = state.expeditions
      const index = expeditions.collection.findIndex((exp) => expeditionId === exp.id)
      if (index !== -1) {
        expeditions.collection.splice(index, 1)
      }
      state.selectedExpedition = expeditions
    },
  },
  actions: {
    async getExpeditions({ commit }) {
      commit('loadExpeditions') // Set global expeditions loadings
      return apiClient.getExpeditions().then((response) => {
        commit('setExpeditions', response.data.map(expedition => {
          let searchKey = expedition.recipientEntity.address.name
          searchKey += " " + expedition.recipientEntity.address.nameAdditionalInfo
          searchKey += " " + expedition.recipientEntity.address.line
          searchKey += " " + expedition.recipientEntity.address.lineAdditionalInfo
          searchKey += " " + expedition.recipientEntity.address.zipCode
          searchKey += " " + expedition.recipientEntity.address.city

          searchKey += " " + expedition.senderEntity.address.name
          searchKey += " " + expedition.senderEntity.address.nameAdditionalInfo
          searchKey += " " + expedition.senderEntity.address.line
          searchKey += " " + expedition.senderEntity.address.lineAdditionalInfo
          searchKey += " " + expedition.senderEntity.address.zipCode
          searchKey += " " + expedition.senderEntity.address.city

          searchKey += " " + expedition.purchaserEntity.address.name
          searchKey += " " + expedition.purchaserEntity.address.nameAdditionalInfo
          searchKey += " " + expedition.purchaserEntity.address.line
          searchKey += " " + expedition.purchaserEntity.address.lineAdditionalInfo
          searchKey += " " + expedition.purchaserEntity.address.zipCode
          searchKey += " " + expedition.purchaserEntity.address.city

          searchKey += " " + expedition.reference

          expedition.content.forEach(item => {
            if (item.attributeQuality !== undefined) {
              searchKey += " " + item.attributeQuality.serialNumber
            }
            if (item.serialNumber !== undefined && item.serialNumber !== null) {
              searchKey += " " + item.serialNumber
            }
            if (item.purchaseReference) {
              searchKey += " " + item.purchaseReference
            }
            if (item.qrcode) {
              searchKey += " " + item.qrcode
            }
          })
          const expeditionObject = new Expedition(expedition)
          expeditionObject["SEARCH"] = searchKey
          return expeditionObject
        }))
      })
    },

    updateExpedition({ commit }, expedition) {
      commit('updateExpedition', expedition) // Update the expedition
    },
    async cancelExpedition({ commit }, expeditionId) {
      return apiClient.partialUpdateExpedition({status: 'CANCELED'}, expeditionId).then((response) => {
        commit('updateExpedition', new Expedition(response.data)) // Update the expedition
      })
    },
  },
}
