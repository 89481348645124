import apiClient from '@/client/client_logistics'
import { deepCopy, isPassedDate, dateToString, addDays } from '@/utils/helpers'
import store from '@/store'

import countries from '@/country.json'

import cloneDeep from 'lodash/cloneDeep'

function isStoredUserAdmin() {
  if (store && store.getters)
    return store.getters['isAdmin']
  return false
}

class Contact {
  constructor(rawData=null) {
    this.cellphone = (rawData !== null) ? rawData['cellphone'] : ''
    this.phone = (rawData !== null) ? rawData['phone'] : ''
    this.civility = (rawData !== null) ? rawData['civility'] : 'MR'
    this.email = (rawData !== null) ? rawData['email'] : ''
    this.name = (rawData !== null) ? rawData['name'] : ''
  }
}


const countriesCodeToName = {}
countries.forEach((countryObject) => {
  countriesCodeToName[countryObject["code"]] = countryObject["name"]
})

class Address {
  constructor(rawData=null) {
    this.name = (rawData !== null) ? rawData['name'] : ''
    this.nameAdditionalInfo = (rawData !== null) ? rawData['nameAdditionalInfo'] : ''
    this.line = (rawData !== null) ? rawData['line'] : ''
    this.lineAdditionalInfo = (rawData !== null) ? rawData['lineAdditionalInfo'] : ''
    this.zipCode = (rawData !== null) ? rawData['zipCode'] : ''
    this.city = (rawData !== null) ? rawData['city'] : ''
    if (rawData === null || rawData['country'] === undefined) {
      this.country = ''
    } else if (rawData['country'].code !== undefined) {
      this.country = rawData['country'].code
    } else {
      this.country = rawData['country']
    }
  }
  getCountryName() {
    return countriesCodeToName[this.country] ? countriesCodeToName[this.country] : ''
  }
}

class Entity {
  constructor(rawData=null) {
    this.address = (rawData !== null) ? new Address(rawData['address']) : new Address()
    if(rawData !== null && rawData['contacts'] !== null && rawData['contacts'] !== undefined){
      this.contacts = rawData['contacts'].map(contact => new Contact(contact))
    }else{
      this.contacts = [new Contact()]
    }
    this.enterprise = (rawData !== null && rawData['enterprise'] !== undefined) ? cloneDeep(rawData['enterprise']) : null
    this.floorNumber = (rawData !== null) ? rawData['floorNumber'] : 0
    this.stairNumber = (rawData !== null) ? rawData['stairNumber'] : 0
    this.elevator = (rawData !== null) ? rawData['elevator'] : false

    if (rawData === null) {
      this.staircaseType = 'NO'
      this.staircaseType = (rawData !== null) ? rawData['staircaseType'].code : 'NO'
    } else if(rawData['staircaseType'] !== undefined && rawData['staircaseType'].code !== undefined) {
      this.staircaseType =  rawData['staircaseType'].code
    } else {
      this.staircaseType =  rawData['staircaseType']
    }
    this.otherInformations = (rawData !== null) ? rawData['otherInformations'] : ''
    this.id = (rawData !== null) ? rawData['id'] : -1
    this.haveAmSchedule = (rawData !== null) ? rawData['haveAmSchedule'] : false
    this.havePmSchedule = (rawData !== null) ? rawData['havePmSchedule'] : false
    this.amOpenStart = (rawData !== null) ? rawData['amOpenStart'] :  ''
    this.amOpenEnd = (rawData !== null) ? rawData['amOpenEnd'] : ''
    this.pmOpenStart = (rawData !== null) ? rawData['pmOpenStart'] : ''
    this.pmOpenEnd = (rawData !== null) ? rawData['pmOpenEnd'] : ''
    this.haveTimePreferences = (rawData !== null && rawData['haveTimePreferences'] !== undefined)? rawData['haveTimePreferences'] : !(this.haveAmSchedule || this.havePmSchedule)

    if(this.amOpenStart === null) {
      this.amOpenStart = ''
    }

    if(this.amOpenEnd === null) {
      this.amOpenEnd = ''
    }

    if(this.pmOpenStart === null) {
      this.pmOpenStart = ''
    }

    if(this.pmOpenEnd === null) {
      this.pmOpenEnd = ''
    }
  }

  removeEnterpriseData() {
    this.enterprise = null
  }
}

class Expedition {
  static NORMAL = "NORMAL"
  constructor(rawData=null) {
     this.id = (rawData !== null) ? rawData['id'] : null
     this.created = (rawData !== null) ? rawData['created'] : undefined
     this.kind = (rawData !== null) ? rawData['kind'] : 'NORMAL'
     this.reference = (rawData !== null) ? rawData['reference'] : null
     this.purchaser = (rawData !== null && rawData['purchaser'] !== undefined) ? cloneDeep(rawData['purchaser']) : null
     this.purchaserEntity = (rawData !== null) ?  new Entity(rawData["purchaserEntity"]) : new Entity ()
     this.sender = (rawData !== null && rawData['sender'] !== undefined) ? cloneDeep(rawData['sender']) : null
     this.senderEntity = (rawData !== null) ? new Entity(rawData["senderEntity"]) : new Entity()
     this.removalInstructions = (rawData !== null) ? rawData['removalInstructions'] : ''
     this.transporter = (rawData !== null) ? rawData['transporter'] : null
     this.transporterContacts = (rawData !== null) ? cloneDeep(rawData['transporterContacts']) : [{}]
     this.transporterImmatriculation = (rawData !== null) ? rawData['transporterImmatriculation'] : ''
     this.recipient = (rawData !== null && rawData['recipient'] !== undefined) ? cloneDeep(rawData['recipient']) : null
     this.recipientEntity = (rawData !== null) ? new Entity(rawData.recipientEntity) : new Entity()
     this.removalDate = (rawData !== null) ? rawData['removalDate'] : null
     this.deliveryDate = (rawData !== null) ? rawData['deliveryDate'] : null
     this.haveTimePreferences = (rawData !== null) ? rawData['haveTimePreferences'] : false
     this.content = (rawData !== null) ? cloneDeep(rawData['content']) : []
     this.insurance = (rawData !== null) ? rawData['insurance'] : false
     this.amountToEnsure = (rawData !== null) ? rawData['amountToEnsure'] : 0
     this.expeditionReturn = (rawData !== null) ? cloneDeep(rawData['expeditionReturn']) : false
     this.validation = (rawData !== null) ? rawData['validation'] : false
     this.chatPk = (rawData !== null) ? rawData['chatPk'] : -1
     this.status = (rawData !== null) ? rawData['status'] : "TODO"
     this.articleQuantity = (rawData !== null) ? rawData['articleQuantity'] : 0
     this.storageType = (rawData !== null) ? rawData['storageType'] : ""
     this.transporterId = (rawData !== null && rawData['transporterId'] !== undefined) ? rawData['transporterId']: null
     this.observations = (rawData !== null) ? rawData['observations'] : ""
     this.expedition = (rawData !== null && rawData['expedition'] !== undefined && rawData['expedition'] !== null) ? cloneDeep(rawData['expedition']) : null
     this.pickUp = (rawData !== null && rawData['pickUp'] !== undefined && rawData['pickUp'] !== null) ? new Expedition(cloneDeep(rawData['pickUp'])) : null
     this.transferedFrom = (rawData !== null && rawData['transferedFrom'] !== undefined && rawData['transferedFrom'] !== null) ? cloneDeep(rawData['transferedFrom']) : null

     this.deliveryId = (rawData !== null) ? rawData['deliveryId'] : null
     this.transfers = (rawData !== null && rawData['transfers'] !== undefined && rawData['transfers'] !== null) ? cloneDeep(rawData['transfers']) : []
     this.isReturnLoan = (rawData !== null && rawData['isReturnLoan']) ? rawData['isReturnLoan'] : false
  }

  isTransfer() {
    return this.transferedFrom !== null
  }

  getHrefUpdate(step){
    if(this.kind === 'NORMAL') {
      return '/expedition-module/update/' + this.id + '/' + step + '/'
    } else if (this.kind === 'PICK_UP_AND_RETURN') {
      return '/pick-up-and-return-module/update/' + this.id + '/' + step + '/'
    } else if(this.isTransfer()){
      return '/transfer-module/update/' + this.id + '/' + this.pickUp.id  + '/' + this.transferedFrom.id + '/' + step + '/'
    } else { // Demo
      return '/demo-module/update/' + this.id + '/' + this.pickUp.id  + '/' + step + '/'
    }
  }

  isNormalExpedition() {
    return this.kind === 'NORMAL'
  }

  isPickUp() {
    return this.kind === 'PICK_UP_AND_RETURN'
  }

  isLoan() {
    return this.kind === 'LOAN'
  }

  isSenderKomaks() {
    if(this.senderEntity !== undefined && this.senderEntity !== null) {
     if(this.senderEntity['enterprise'] !== undefined && this.senderEntity['enterprise'] !== null) {
        if(this.senderEntity.enterprise['isKomaks'] !== undefined) {
          return this.senderEntity.enterprise['isKomaks']
        }
     }
    }
    return false
  }

  // Update expedition date and removal date of the expedition
  async updateDates(removalDate, deliveryDate, validation) {
    const expeditionDates = {
      validation: validation
    }
    // Don't send if not modified
    if (removalDate !== this.removalDate) {
      expeditionDates['removalDate'] = removalDate
    }
    if (deliveryDate !== this.deliveryDate) {
      expeditionDates['deliveryDate'] = deliveryDate
    }

    if(this.kind === 'NORMAL') {
      return apiClient.partialUpdateExpedition(expeditionDates, this.id)
    }else if(this.kind === 'LOAN'){
      return apiClient.partialUpdateLoan(expeditionDates, this.id)
    } else if (this.kind === 'PICK_UP_AND_RETURN') {
      return apiClient.updatePickUpAndReturn(expeditionDates, this.id)
    }
  }

  async updateTransporterInfo(transporterInfo){
    if(this.kind === 'NORMAL'){
      return apiClient.partialUpdateExpedition(transporterInfo, this.id)
    }else if(this.kind === 'PICK_UP_AND_RETURN'){
      return apiClient.updatePickUpAndReturn(transporterInfo, this.id)
    }else if(this.kind === 'LOAN' ){
      return Promise.all([
        apiClient.partialUpdateExpedition(transporterInfo, this.id),
        apiClient.updatePickUpAndReturn(transporterInfo, this.pickUp.id)
      ])
    }
  }

  isExpedition() {
    return (this.kind === 'NORMAL' || this.kind === 'LOAN')
  }

  isRemovalDatePassed() {
    const isAdmin = isStoredUserAdmin()
    if (this.id === null) return false
    if (this.removalDate === null) return false
    if(!isAdmin) {
      return isPassedDate(dateToString(addDays(this.removalDate, -1)))
    }
    return isPassedDate(this.removalDate)
  }

  isDeliveryDatePassed() {
    if (this.id === null) return false
    if (this.deliveryDate === null) return false
    return isPassedDate(this.deliveryDate)
  }

  isValidate(){
    if(this.kind === 'LOAN'){
      return this.validation && this.pickUp.validation
    }
    return this.validation
  }

  isDelivered() {
    return this.status === 'DELIVERED'
  }

  isCanceled() {
    return this.status === 'CANCELED' || this.status === 'HISTORY'
  }

  isHistory() {
    return this.status === 'HISTORY'
  }

  canBeUpdated() {
    // Admin can update anything anytime
    if(isStoredUserAdmin()) {
      return true
    }
    /* Normal user
     - They can update expedition or loan until two days after the delivery date
     - They can update a pick up delivery until two days after the delivery date
     - They can't update a return loan after the removal date
    */
    if (this.isReturnLoan) {
        return (
          !isPassedDate(dateToString(addDays(this.removalDate, 0))) &&
          !this.isCanceled()
        )
    } else {
      return (
        !isPassedDate(dateToString(addDays(this.deliveryDate, 2))) &&
        !this.isCanceled()
      )
    }
  }
  // Return a copy of the object without some data which should not be updated after some dates
  getFormData() {
    const isAdmin = isStoredUserAdmin()
    // If the date of delivery is passed after 2 days, we update nothing
    // Admin can always update
    if (!this.canBeUpdated()) {
      return;
    }
    // Copy the current object
    const dataToSend = deepCopy(this)

    // Cleanup data which should'nt be sent by the global form
    delete dataToSend.chatPk
    delete dataToSend.reference
    delete dataToSend.validation
    delete dataToSend.transporterImmatriculation
    delete dataToSend.status
    delete dataToSend.transfers

    if(this.isPickUp()) {
      // We don't send purchaser / purchaserEntity for pickUp
      delete dataToSend.purchaser
      delete dataToSend.purchaserEntity
      delete dataToSend.sender
    }

    if(!this.isTransfer()) {
      delete dataToSend.transferedFrom
      delete dataToSend.transfers
    }

    if (this.id === null) { // CREATE
      if(this.kind !== 'PICK_UP_AND_RETURN') {
        if(!isAdmin || dataToSend.removalDate === "" || dataToSend.removalDate === null) {
          delete dataToSend.removalDate // We don't send removalDate of the expedition with form
        }
      }
    }
    else { // UPDATE
      // Delete data
      if (this.isRemovalDatePassed() && !isAdmin) {
        // If removal date is already passed we don't send the following datas:
        delete dataToSend.purchaser
        delete dataToSend.purchaserEntity

        delete dataToSend.sender
        delete dataToSend.senderEntity

        delete dataToSend.transporterId
        delete dataToSend.transporter
        delete dataToSend.transporterContacts

        delete dataToSend.content
        delete dataToSend.removalDate // Even for admin
      } else if(this.kind !== 'PICK_UP_AND_RETURN') {
        if(!isAdmin || dataToSend.removalDate === "" || dataToSend.removalDate === null) {
          delete dataToSend.removalDate // We don't send removalDate of the expedition with form
        }
      }
    }

    if(dataToSend.content){
      dataToSend.content.forEach((item) => {
        delete item.SEARCH
        delete item.vgtId
        delete item.originalIndex
      })
    }

    return dataToSend
  }

  // STORE
  getUpdateStorePath() {
    if (this.isLoan()) {
      return "demoModule/updateDemo"
    } else if(this.isPickUp()) {
      return "pickUpAndReturnModule/updatePickUpAndReturn"
    } else {
      return "expeditionModule/updateExpedition"
    }
  }

}

export {
  Expedition,
  Entity,
}
