const api = {
  createLoan(loan){
    return this.apiClient().post('expedition/loans/', loan)
  },
  updateLoan(loan, id){
    return this.apiClient().put('expedition/loans/' + id + '/', loan)
  },
  partialUpdateLoan(loan, id){
    return this.apiClient().patch('expedition/loans/' + id + '/', loan)
  },
  getLoans(){
    return this.apiClient().get('expedition/loans/')
  },
  getLoan(id){
    return this.apiClient().get('expedition/loans/' + id + '/')
  },
  switchTransfer(idDemoAndTransfer){
    return this.apiClient().post('expedition/transfers/switch/',  idDemoAndTransfer)
  }
};

export default api;
