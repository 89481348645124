const api = {
  getStocks(enterprises, entities, displaySoldArticles, loan) {
    const params = {
      'enterprises': enterprises,
      'entities': entities,
      'display_sold_articles': displaySoldArticles,
      'loan': loan
    }
    return this.apiClient().get(
      'stocks-logistics/stock/', {params}
    )
  },
  getKomaksCatalogue(paramsData=null){
    return this.apiClient().get('catalogue/product-logistics/', {params: paramsData})
  },
  getArticleDetail(id){
    return this.apiClient().get('stocks-logistics/article-details/' + id + '/')
  },
  getArticleQualityHistory(id){
    return this.apiClient().get('stocks-logistics/article-quality-history/' + id + '/')
  },
  getArticlePictures(id){
    return this.apiClient().get('stocks-logistics/article-pictures/' + id + '/')
  },
  getArticleDocuments(id){
    return this.apiClient().get('stocks-logistics/article-documents/' + id + '/')
  },
  getArticleDocZip(id){
    return this.apiClient().get('/stock/article-medias-zip/' + id + '/')
  }
};

export default api;
