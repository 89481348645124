class ModalData {
  constructor(title, content, buttons) {
    this.title = title || {
      text: "",
      css: ""
    }
    this.content = content || []
    this.buttons = buttons || {}
  }
}
const CSS_PRIORITIES = {
  '': 0,
  'is-warning': 5,
  'is-danger': 10
}
class Modal {
  constructor() {
    this.title = {
        text: '',
        css: ''
    }
    this.buttons = {}
    this.content = []
    this.isOpen = false
  }

  addContent(modalData) {
    if (CSS_PRIORITIES[modalData.title.css] !== undefined && CSS_PRIORITIES[this.title.css] < CSS_PRIORITIES[modalData.title.css]) {
      this.title = modalData.title
    }
    Object.keys(modalData.buttons).forEach(key => {
        this.buttons[key] = modalData.buttons[key]
    })
    this.content = this.content.concat(modalData.content)
  }

  static fromState(modalState) {
    const modal = new Modal()
    Object.assign(modal, modalState)
    return modal
  }
}

export {
  Modal,
  ModalData,
}
