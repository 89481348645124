<template>
  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">

          <router-link v-if="user && user.haveExpeditionAccess" to="/expedition-module/" class="column is-5-tablet is-4-desktop is-4-widescreen">
            <div>
              <img class="img-dashboard" src="@/assets/icons/dashboard/Expedition1_icon.png" >
              <p align="center">{{$t('dashboard-expedition-title')}}</p>
            </div>
          </router-link>

          <router-link v-if="user && user.havePickUpAccess" to="/pick-up-and-return-module/" class="column is-5-tablet is-4-desktop is-4-widescreen">
            <div>
              <img class="img-dashboard" src="@/assets/icons/dashboard/TakeBack_icon.png" >
              <p align="center">{{$t('dashboard-turn-back-title')}}</p>
            </div>
          </router-link>

          <router-link v-if="user && user.haveDemoAccess" to="/demo-module/" class="column is-5-tablet is-4-desktop is-4-widescreen">
            <div>
              <img class="img-dashboard" src="@/assets/icons/dashboard/Demo_icon.png" >
              <p align="center">{{$t('dashboard-demo-title')}}</p>
            </div>
          </router-link>

          <router-link v-if="user && user.haveStocksLogisticsAccess" to="/stock-module/" class="column is-5-tablet is-4-desktop is-4-widescreen">
            <div>
              <img class="img-dashboard" src="@/assets/icons/dashboard/Stock_icon.png" >
              <p align="center">{{$t('dashboard-stock-title')}}</p>
            </div>
          </router-link>

          <a v-if="user && user.havePlanningAccess" href="https://erp.komaks.fr/timetable/" class="column is-5-tablet is-4-desktop is-4-widescreen" target="_blank">
            <div>
              <img class="img-dashboard" src="@/assets/icons/dashboard/Planning_icon.png" >
              <p align="center">{{$t('planning-dashboard-title')}}</p>
            </div>
          </a>
          <!-- <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/TakeBack_icon.png">
                <p align="center">{{$t('REPRISE')}}</p>
            </div>
          </router-link>


          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/Stock_icon.png">
                <p align="center">STOCKS</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/FollowUp_icon.png">
                <p align="center">SUIVI PRESTATION</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/Saisie_icon.png">
                <p align="center">SAISIE</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/Mail_icon.png">
                <p align="center">MESSAGERIE</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/Security_icon.png">
                <p align="center">VIDEO SURVEILLANCE</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/Catalog_icon.png">
                <p align="center">CATALOGUE</p>
            </div>
          </router-link>

          <router-link to="/" class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div>
                <img class="img-dashboard" src="@/assets/icons/dashboard/MyFiles_icon.png">
                <p align="center">MES FICHIERS</p>
            </div>
          </router-link> -->
        </div>
      </div>
    </div>
  </section>
</template>


<style>
.img-dashboard {
  display : block;
  margin: auto;
  width: 128px;
  height: 128px;
}
</style>

<script>
export default {
  name: 'Dashboard',
  components: {},
  computed: {
    user() {
      return this.$store.state.user
    }
  }
};
</script>
