import cloneDeep from 'lodash/cloneDeep'
import tap from 'lodash/tap'
import set from 'lodash/set'


export default{
  props:{
    value: {
      required: true,
    },
  },
  methods: {
    update(key, value) {
      this.$emit('input', tap(cloneDeep(this.value), v => set(v, key, value)))
    },

    updateFromObject(newValue) {
      this.$emit('input', {...cloneDeep(this.value), ...newValue})
    },
    deepCopy(obj) {
      return cloneDeep(obj)
    }
  }
}
