<template>
  <div class="container">
    <SectionTitle :title="title" imgName="fas fa-map-marker-alt"></SectionTitle>

    <div class="field">
      <label class="label"> {{$t("line-label")}} *</label>
      <input
        class="input" :class="{'is-danger': errorOnValue('line')}"
        type="text"
        :value="value.line" @change="update('line', $event.target.value)"
        required :disabled="autoComplete">
      <p
        v-if="errorOnValue('line')"
        class="help is-danger">
        {{errorsData.line[0]}}
      </p>
    </div>
    <div class="field">
      <label class="label">{{$t("line-additional-info-label")}}</label>
      <input
        class="input" :class="{'is-danger': errorOnValue('lineAdditionalInfo')}"
        :value="value.lineAdditionalInfo" @change="update('lineAdditionalInfo', $event.target.value)"
        :disabled="autoComplete"
        type="text">
      <p
        v-if="errorOnValue('lineAdditionalInfo')"
        class="help is-danger">
        {{errorsData.lineAdditionalInfo[0]}}
      </p>
    </div>
    <div class="columns">
      <div class="column is-5">
          <div class="field">
            <label class="label">{{$t("zip-code-label")}} *</label>
            <input
              class="input"
              :value="value.zipCode" @change="update('zipCode', $event.target.value)"
              :class="{'is-danger': errorOnValue('zipCode')}"
              :disabled="autoComplete"
              type="text"
              maxlength="15"
              required
              >
            <p
              v-if="errorOnValue('zipCode')"
              class="help is-danger">
              {{errorsData.zipCode[0]}}
            </p>
          </div>
        </div>
      <div class="column is-7">
        <div class="field">
          <label class="label">{{$t("city-label")}} *</label>
          <input
            class="input" :class="{'is-danger': errorOnValue('city')}"
            :value="value.city" @change="update('city', $event.target.value)"
            :disabled="autoComplete"
            type="text"
            required>
          <p
            v-if="errorOnValue('city')"
            class="help is-danger">
            {{errorsData.city[0]}}
          </p>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">{{$t("country-label")}} *</label>
      <select
        class="select is-fullwidth" :class="{'is-danger': errorOnValue('country')}"
        :value="value.country" @change="update('country', $event.target.value)"
        :disabled="autoComplete"
        required>
        <option v-for="(country, countryIndex) in countries"
          v-bind:key="countryIndex"
          :value="country.code">
          {{country.name}}
        </option>
      </select>
      <p
        v-if="errorOnValue('country')"
        class="help is-danger">
        {{errorsData.country[0]}}
      </p>
    </div>
  </div>
</template>
<script>
import countries from '@/country.json'
import SectionTitle from '@/components/SectionTitle'
import vModelMixin from '@/mixins/vModelMixin'

export default {
  name: 'AddressField',
  components: {
    SectionTitle
  },
  mixins: [vModelMixin],
  props: {
    title: {
      type: String,
      required: true
    },
    autoComplete: {
      type: Boolean,
      required: true
    },
    errorsData: {
      type: Object
    }
  },
  data: function() {
    return {
      countries,
    }
  },
  methods: {
    errorOnValue(nameField){
      if(this.errorsData && this.errorsData[nameField]){
        return true
      }
      return false
    }
  }
}
</script>
