<template>

  <div>
    <div v-if="!getError && expedition!==null" class="container">
      <ExpeditionDetails v-model="expedition"></ExpeditionDetails>
    </div>
    <div v-else>
      Pas d'expédition
    </div>
  </div>

</template>

<script>
import apiClient from '@/client/client_logistics'
import ExpeditionDetails from '@/apps/expedition/expedition/details';


export default {
  name: 'Expedition',
  components: { ExpeditionDetails },
  data: function(){
    return {
      getError : false,
      expedition: null
    }
  },
  beforeMount(){
    this.getExpedition()
  },
  methods: {
    getExpedition(){
      let id = this.$route.params.id
      if(!isNaN(parseInt(id))){
        apiClient.getExpedition(id).then((response) => {
          this.expedition = response.data
        }).catch(() => {
          this.getError = true
        })
      }else{
        this.$router.push('/expedition-module')
      }
    }
  }
}

</script>
