<template>
  <DetailsStepTemplate
    :step="step"
    :title="title"
    :getUrl="getUrl"
    :updateCondition="updateCondition">
    <template v-slot:details-content>
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-third has-text-right">
          <strong>{{$t("storage-type-title")}}{{$t("colon")}}</strong><br />
        </div>
        <div class="column is-two-thirds">
          <span v-if="storageTypes !== null">
          {{storageTypes[expedition.storageType]}}
          </span>
        </div>
      </div>
    </template>
  </DetailsStepTemplate>
</template>
<script>
import detailsStepMixin from '@/mixins/detailsStepMixin'
export default {
  name: 'detailsPurchaser',
  mixins: [detailsStepMixin],
  computed: {
    storageTypes(){
      return this.$store.getters['commonData/storageTypes']
    }
  }
}
</script>
